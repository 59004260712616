/*
* PurchaseOverviewHeader.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
import { IonRow, IonCol } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from "styled-components";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const PurchaseOverviewHeader: React.FC = () => {

    const gl_shared_language = useSettingsLanguageStore(state => state.language);

    
    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <StyledPurchaseOverviewHeaderContainer>
            <IonRow>
                <IonCol size="3">
                    <StyledPurchaseOverviewHeaderColumn>
                        <p className="sdr-font-weight-500">#</p>
                    </StyledPurchaseOverviewHeaderColumn>
                </IonCol>
                <IonCol size="6">
                    <StyledPurchaseOverviewHeaderColumn>
                        <p className="sdr-font-weight-500">{ lang.components.FlightDashboard.PurchaseOverviewHeader.Texts.Amount[gl_shared_language] }</p>
                    </StyledPurchaseOverviewHeaderColumn>
                </IonCol>
                <IonCol size="2">
                    <StyledPurchaseOverviewHeaderColumn>
                        <p className="sdr-font-weight-500">{ lang.components.FlightDashboard.PurchaseOverviewHeader.Texts.Seat[gl_shared_language] }</p>
                    </StyledPurchaseOverviewHeaderColumn>
                </IonCol>
                <IonCol size="1">
                    <StyledPurchaseOverviewHeaderColumn>
                        <p className="sdr-font-weight-500">{ lang.components.FlightDashboard.PurchaseOverviewHeader.Texts.PM[gl_shared_language] }</p>
                    </StyledPurchaseOverviewHeaderColumn>
                </IonCol>
            </IonRow>
        </StyledPurchaseOverviewHeaderContainer>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledPurchaseOverviewHeaderContainer
* 
*/
const StyledPurchaseOverviewHeaderContainer = styled.div`
    position: sticky;
    top: 0;
    background: white;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 8px;
    margin-left: -19px;
    margin-right: -19px;
    padding-left: 23px;
    padding-right: 23px;
`;

/****************************************************************************** 
* StyledPurchaseOverviewHeaderColumn
* 
*/
const StyledPurchaseOverviewHeaderColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;

    p {
        margin-bottom: 0;
    }

    ion-icon {
        margin-left: 8px;
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default PurchaseOverviewHeader;
