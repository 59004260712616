/*
* Purchase.tsx
*
* Description: Flight Overview and Selection Screen
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useHistory } from 'react-router';
import PubSub from "pubsub-js";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import OrderCard from "../../components/Purchase/OrderCard";
import PopOver from "../../components/Purchase/CurrencyPopOver/PopOver";
import CategoryContainer from '../../components/Purchase/CategoryContainer';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../stores/shared/settings';
import { useDeviceIdentifierStore } from '../../stores/shared/deviceIdentifier';
import { useFlightSelectionStore } from "../../stores/flightselection/flights";
import { useCurrentOrderStore } from '../../stores/shared/currentOrder';
import { useCurrencyStore } from "../../stores/currency/currency";
import { useProductsStore } from "../../stores/products/products";
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, hideSpinner, showSpinner } from '../../tsx/utilities';
import { DBOrders_getSerialNumber } from '../../databases/orders';
import { type_products_Product_Extended } from '../../types/products';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const Purchase: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [showCurrencyPopOver, setShowCurrencyPopOver] = useState<boolean>(false);
    const [bestseller, setBestseller] = useState<Array<type_products_Product_Extended>>([]);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_flightselection_selectedFlight = useFlightSelectionStore((state) => state.selectedFlight);
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_deviceIdentifier_deviceIdentifier = useDeviceIdentifierStore(state => state.deviceIdentifier);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_currentOrder_setOrderInvoiceId = useCurrentOrderStore(state => state.actn_setOrderInvoiceId);
    const get_bestsellerProducts = useProductsStore(state => state.get_bestsellerProducts);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useIonViewDidEnter()
    *
    * Desc: Create a new unique invoice Id.
    * Note: Invoice id: FlighidUniquedeviceidSerialnumber
    *
    */
    useIonViewDidEnter(async () => {

        let invoiceId = "",
            serialNumber = "";


        debugWriter(">>> /pages/Purchase/Purchase.tsx: onClick_showCurrencyPopOver()");
        showSpinner();
        serialNumber = await DBOrders_getSerialNumber(gl_flightselection_selectedFlight!.id);
        invoiceId += gl_flightselection_selectedFlight!.id;
        invoiceId += gl_deviceIdentifier_deviceIdentifier;
        invoiceId += serialNumber;

        actn_currentOrder_setOrderInvoiceId(invoiceId);

        setBestseller(await get_bestsellerProducts());
        hideSpinner();
        
    }); //eo function useIonViewDidEnter()


    /***************************************************************************
    * useIonViewDidLeave()
    *
    * Desc: Unselect category.
    * Note: 
    *
    */
    useIonViewDidLeave(() => {

        PubSub.publish("viewdidleave_purchase");
        
    }); //eo function useIonViewDidLeave()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_showCurrencyPopOver()
    *
    * Desc: Shows the currency pop over to select a currency.
    * Note: 
    *
    */
    const onClick_showCurrencyPopOver = () => {

        debugWriter(">>> /pages/Purchase/Purchase.tsx: onClick_showCurrencyPopOver()");
        setShowCurrencyPopOver(true);
        
    }; //eo function onClick_showCurrencyPopOver()


    /*******************************************************************************
    * onClick_backToOverview()
    *
    * Desc:
    * Note: 
    *
    */
    const onClick_backToOverview = () => {

        history.goBack();

    }; // eo function onClick_backToOverview()


    /*******************************************************************************
    * onDismiss_hideCurrencyPopOver()
    *
    * Desc: Hides the currency pop over.
    * Note: 
    *
    */
    const onDismiss_hideCurrencyPopOver = () => {

        debugWriter(">>> /pages/Purchase/Purchase.tsx: onDismiss_hideCurrencyPopOver()");
        setShowCurrencyPopOver(false);

    }; //eo function onDismiss_hideCurrencyPopOver()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{gl_flightselection_selectedFlight!.departureAirportInfo.iataCode} - {gl_flightselection_selectedFlight!.destinationAirportInfo.iataCode}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClick_showCurrencyPopOver()}>
                            <p className="sdr-text-color-primary sdr-text-biggernormal">
                                {gl_currency_currentCurrency.symbol}
                            </p>
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="start">
                        <IonButton onClick={onClick_backToOverview}>
                            { lang.global.Labels.Back[gl_shared_language] }
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent slot="fixed" style={{"--padding-top": 0}}>

                <CategoryContainer
                    bestseller={bestseller} />

            </IonContent>

            <OrderCard />

            <PopOver 
                onDidDismiss={onDismiss_hideCurrencyPopOver} 
                isOpen={showCurrencyPopOver} />

        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Purchase;