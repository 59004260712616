/*
* AdminAreaDatabases.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import Table from "./Table";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBCategories_getAllCategories, DBCategories_getTableHeader } from '../../../databases/categories';
import { DBCurrency_getAllCurrencies, DBCurrency_getTableHeader } from '../../../databases/currency';
import { DBFlights_breakdown, DBFlights_getTableHeader } from '../../../databases/flights';
import { DBLogin_getAllEntries, DBLogin_getTableHeader } from '../../../databases/login';
import { DBOrders_getAllEntries, DBOrders_getTableHeader } from '../../../databases/orders';
import { DBProducts_getAllProducts, DBProducts_getTableHeader } from '../../../databases/products';
import { debugWriter, hideSpinner, showSpinner } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
import { IndexSpec } from 'dexie';
import { type_Schema_DBCategories, type_Schema_DBCurrencies, type_Schema_DBLogin, type_Schema_DBOrders, type_Schema_DBProducts, type_Schema_DBVats } from '../../../types/databases';
import { type_flight_flight } from '../../../types/flight';
import { DBVats_getAllVats, DBVats_getTableHeader } from '../../../databases/vats';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    presentingElementRef: RefObject<HTMLElement>;
}
const AdminAreaDatabases: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showDatabasesModal, setShowDatabasesModal] = useState<boolean>(false);
    const [productsTableSchema, setProductsTabelSchema] = useState<Array<IndexSpec>>([]);
    const [productsTableContent, setProductsTabelContent] = useState<Array<type_Schema_DBProducts>>([]);
    const [categoriesTableSchema, setCategoriesTabelSchema] = useState<Array<IndexSpec>>([]);
    const [categoriesTableContent, setCategoriesTabelContent] = useState<Array<type_Schema_DBCategories>>([]);
    const [currenciesTableSchema, setCurrenciesTabelSchema] = useState<Array<IndexSpec>>([]);
    const [currenciesTableContent, setCurrenciesTabelContent] = useState<Array<type_Schema_DBCurrencies>>([]);
    const [flightsTableSchema, setFlightsTabelSchema] = useState<Array<IndexSpec>>([]);
    const [flightsTableContent, setFlightsTabelContent] = useState<Array<type_flight_flight>>([]);
    const [loginTableSchema, setLoginTabelSchema] = useState<Array<IndexSpec>>([]);
    const [loginTableContent, setLoginTabelContent] = useState<Array<type_Schema_DBLogin>>([]);
    const [ordersTableSchema, setOrdersTabelSchema] = useState<Array<IndexSpec>>([]);
    const [ordersTableContent, setOrdersTabelContent] = useState<Array<type_Schema_DBOrders>>([]);
    const [vatsTableSchema, setVatsTabelSchema] = useState<Array<IndexSpec>>([]);
    const [vatsTableContent, setVatsTabelContent] = useState<Array<type_Schema_DBVats>>([]);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onDidPresent_LoadDatabases()
    *
    * Desc: Read all data from the database and save it into the local state.
    *
    */
    const onDidPresent_LoadDatabases = async () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaDatabases: onDidPresent_LoadDatabases");
        showSpinner();

        setProductsTabelSchema(await DBProducts_getTableHeader());
        setProductsTabelContent(await DBProducts_getAllProducts());

        setCategoriesTabelSchema(await DBCategories_getTableHeader());
        setCategoriesTabelContent(await DBCategories_getAllCategories());

        setCurrenciesTabelSchema(await DBCurrency_getTableHeader());
        setCurrenciesTabelContent(await DBCurrency_getAllCurrencies());

        setFlightsTabelSchema(await DBFlights_getTableHeader());
        setFlightsTabelContent(await DBFlights_breakdown());

        setLoginTabelSchema(await DBLogin_getTableHeader());
        setLoginTabelContent(await DBLogin_getAllEntries());

        setOrdersTabelSchema(await DBOrders_getTableHeader());
        setOrdersTabelContent(await DBOrders_getAllEntries());

        setVatsTabelSchema(await DBVats_getTableHeader());
        setVatsTabelContent(await DBVats_getAllVats());

        hideSpinner();

    }; //eo function onDidPresent_LoadDatabases()


    /*******************************************************************************
    * onClick_showDatabsesModal()
    *
    * Desc: Show the Database Modal.
    *
    */
    const onClick_showDatabsesModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaDatabases: onClick_showDatabsesModal");
        setShowDatabasesModal(true);

    }; //eo function onClick_showDatabsesModal()


    /*******************************************************************************
    * onDidDismiss_hideDatabasesModal()
    *
    * Desc: Hide the Database Modal.
    *
    */
    const onDidDismiss_hideDatabasesModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaDatabases: onDidDismiss_hideDatabasesModal");
        setShowDatabasesModal(false);

    }; //eo function onDidDismiss_hideDatabasesModal()


    /*******************************************************************************
    * onClick_dismissModal()
    *
    * Desc: Close Button pressed -> close Modal.
    *
    */
    const onClick_dismissModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaModal: onClick_dismissModal");
        onDidDismiss_hideDatabasesModal();

    }; //eo function onClick_dismissModal()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <p className="sdr-text-large sdr-font-weight-500" style={{ marginTop: 64 }}>
                {lang.pages.AdminPanel.Titles.DatabaseTables[gl_settings_language]}
            </p>
            <p>
                {lang.pages.AdminPanel.Texts.ShowDatabase[gl_settings_language]}
            </p>

            <IonButton onClick={onClick_showDatabsesModal} expand="block">
                {lang.pages.AdminPanel.Labels.ShowTables[gl_settings_language]}
            </IonButton>

            <IonModal
                isOpen={showDatabasesModal}
                presentingElement={props.presentingElementRef?.current || undefined}
                swipeToClose
                onDidDismiss={onDidDismiss_hideDatabasesModal}
                onDidPresent={onDidPresent_LoadDatabases}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            {lang.components.UserDashboard.AdminAreaDatabases.Titles.DatabseTables[gl_settings_language]}
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={onClick_dismissModal}>
                                {lang.global.Labels.Close[gl_settings_language]}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonHeader className="ion-no-border" collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">
                                {lang.components.UserDashboard.AdminAreaDatabases.Titles.DatabseTables[gl_settings_language]}
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <Table 
                        title="Products"
                        tableSchema={productsTableSchema}
                        tableContent={productsTableContent} />

                    <Table 
                        title="Categories"
                        tableSchema={categoriesTableSchema}
                        tableContent={categoriesTableContent} />

                    <Table 
                        title="Currencies"
                        tableSchema={currenciesTableSchema}
                        tableContent={currenciesTableContent} />

                    <Table 
                        title="Flights"
                        tableSchema={flightsTableSchema}
                        tableContent={flightsTableContent} />

                    <Table 
                        title="Logins"
                        tableSchema={loginTableSchema}
                        tableContent={loginTableContent} />

                    <Table 
                        title="Orders"
                        tableSchema={ordersTableSchema}
                        tableContent={ordersTableContent} />

                    <Table 
                        title="Vats"
                        tableSchema={vatsTableSchema}
                        tableContent={vatsTableContent} />

                </IonContent>

            </IonModal>
        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AdminAreaDatabases;
