/*
* utitlities.tsx
*
* Description: Collection of some useful global Functions.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from "react";
import { Storage } from '@capacitor/storage';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { toast } from "react-toastify";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from "../stores/shared/settings";
import { useSpinnerStore } from "../stores/shared/spinner";
import { useLoginStore } from "../stores/login/login";
import { useCurrencyStore } from "../stores/currency/currency";
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "./language.json";
import { LanguageType } from "./types";
import { CONF_API_KEY, CONF_ENVIRONMENT } from "./config";
// <<< Resources ---------------------------------------------------------------



/*******************************************************************************
* isObjectEmpty()
*
* Desc: Checks if an object has the form {} (is empty)
* Note:
*
* @param {Object} obj (obj to check)
*
*/
export const isObjectEmpty = (obj: Object) => {

    return Object.keys(obj).length === 0 && obj.constructor === Object;

}; // eo function isObjectEmpty()


/*******************************************************************************
* renderDate()
*
* Desc: Returns a formatted date based on the language
* Note:
*
* @param {dayjs} date (date to render)
* @param {string} lang (language)
* @param {boolean} showDayName (day name render flag)
* @param {boolean} showTime (time render flag)
* @return {string} formatted string
*/
export const renderDate = (date: dayjs.Dayjs, showDayName?: boolean, showTime?: boolean) => {

    let langCode: LanguageType = useSettingsLanguageStore.getState().language;


    if (langCode === "de-DE") {
        let dateFormat = "D. MMM YYYY";
        let dayNameFormat = "dd. ";
        let timeFormat = " HH:mm";

        if (showDayName) {
            dateFormat = dayNameFormat + dateFormat;
        }

        if (showTime) {
            dateFormat = dateFormat + timeFormat;
        }

        return date.locale("de").format(dateFormat);
    } else {
        let dateFormat = "MMM D, YYYY";
        let dayNameFormat = "dd, ";
        let timeFormat = " HH:mm";

        if (showDayName) {
            dateFormat = dayNameFormat + dateFormat;
        }

        if (showTime) {
            dateFormat = dateFormat + timeFormat;
        }

        return date.locale("en-gb").format(dateFormat);
    }

}; // eo function renderDate()


/*******************************************************************************
* renderDayName()
*
* Desc: Returns a formatted date based on the language
* Note:
*
* @param {dayjs} date (date to render)
* @param {string} lang (language)
* @return {string} formatted string
*/
export const renderDayName = (date: dayjs.Dayjs) => {

    let langCode: LanguageType = useSettingsLanguageStore.getState().language,
        dayName = "";


    if (langCode === "de-DE") {
        dayName = date.locale("de").format("dddd");
    } else {
        dayName = date.locale("en-gb").format("dddd");
    }


    return dayName;

}; // eo function renderDayName()


/*******************************************************************************
* renderTime()
*
* Desc: Returns a formatted time string
* Note:
*
* @param {string} date (date to render)
* @param {string} lang (language)
* @return {string} formatted time string (HH:mm)
*/
export const renderTime = (date: string) => {

    let regexIsoDate: RegExp,
        m: RegExpMatchArray | null | false,
        // day = "",
        // month = "",
        // year = "",
        hour = "",
        minute = "",
        result = "";


    regexIsoDate = /^(\d{4})-(\d{2})-(\d{2})(T)(\d{2}):(\d{2}):(\d{2})([-+])(\d{2})(\d{2})$/;
    m = date.match(regexIsoDate);

    if (m) {
        // year = m[1];
        // month = m[2];
        // day = m[3];
        hour = m[5];
        minute = m[6];

        result = hour + ":" + minute;
    }

    return result;

}; // eo function renderTime()


/*******************************************************************************
* renderDuration()
*
* Desc: Returns the duration of the flight given by props
* Desc: in the form "# Std. # Min."" or "# H # M"
* Note: if there is - somehow - no duration given, it returns ""
*
*/
export const renderDuration = (duration: number) => {

    let ret = "";


    if (duration) {
        let h = Math.floor(duration / 3600);
        let m = Math.floor((duration - h * 3600) / 60);

        ret = `${h ? `${h} H` : ""} ${m ? `${m} M` : ""}`;
    }

    return ret;

}; // eo renderDuration()


/*******************************************************************************
* showToastError()
*
* Desc:
* Note:
*
*/
export const showToastError = (title: string, content: string) => {

    toast.error(
        <React.Fragment>
            <p className="sdr-text-normal sdr-font-weight-500 sdr-text-color-white sdr-font-family ion-no-margin">
                {title}
            </p>
            <p style={{ marginTop: "4px", marginBottom: "12px" }} className="sdr-text-normal sdr-text-color-white sdr-font-family">
                {content}
            </p>
        </React.Fragment>
    );

}; // eo function showToastError()


/*******************************************************************************
* showToastWarning()
*
* Desc:
* Note:
*
*/
export const showToastWarning = (title: string, content: string) => {

    toast.warn(
        <React.Fragment>
            <p className="sdr-text-normal sdr-font-weight-500 sdr-text-color-white sdr-font-family ion-no-margin">
                {title}
            </p>
            <p style={{ marginTop: "4px", marginBottom: "12px" }} className="sdr-text-normal sdr-text-color-white sdr-font-family">
                {content}
            </p>
        </React.Fragment>
    );

}; // eo function showToastWarning()


/*******************************************************************************
* showToastInformation()
*
* Desc:
* Note:
*
*/
export const showToastInformation = (title: string, content: string) => {

    toast.info(
        <React.Fragment>
            <p className="sdr-text-normal sdr-font-weight-500 sdr-text-color-white sdr-font-family ion-no-margin">
                {title}
            </p>
            <p style={{ marginTop: "4px", marginBottom: "12px" }} className="sdr-text-normal sdr-text-color-white sdr-font-family">
                {content}
            </p>
        </React.Fragment>
    );

}; // eo function showToastInformation()


/*******************************************************************************
* showAlert()
*
* Desc: Shows an easy Javasciprt alert.
* Note:
*
*/
export const showAlert = (content: string) => {

    alert(content);

}; // eo function showAlert()


/*******************************************************************************
* showSpinner()
*
* Desc: Shows the Sundair Loading Spinner.
* Note:
*
*/
export const showSpinner = () => {

    useSpinnerStore.getState().actn_showSpinner();

}; // eo function showSpinner()


/*******************************************************************************
* hideSpinner()
*
* Desc: Hides the Sundair Loading Spinner.
* Note:
*
*/
export const hideSpinner = () => {

    useSpinnerStore.getState().actn_hideSpinner();

}; // eo function hideSpinner()


/*******************************************************************************
* createHeaders()
*
* Desc: Create the Call Headers.
* Note: ONLY for login.
*
*/
export const createHeaders = (username: string, password: string) => {

    let headers: Object;


    headers = {
        Authorization: "Basic " + btoa(username + ":" + password),
        apiKey: CONF_API_KEY
    }


    return headers;

}; // eo function createHeaders()


/*******************************************************************************
* createAuthorizationHeaders()
*
* Desc: Create the Call Headers.
* Note:
*
*/
export const createAuthorizationHeaders = () => {

    const gl_authorization = useLoginStore.getState().authorization;
    let headers: Object;


    headers = {
        Authorization: "Basic " + String(gl_authorization),
        apiKey: CONF_API_KEY
    };


    return headers;

}; // eo function createAuthorizationHeaders();


/*******************************************************************************
* renderCurrency()
*
* Desc: Returns a formatted
* Note:
*
*/
export const renderCurrency = (value: number) => {

    const gl_settings_language = useSettingsLanguageStore.getState().language;
    const gl_currency_currentCurrency = useCurrencyStore.getState().currentCurrency;
    const gl_currency_exchangeRates = useCurrencyStore.getState().exchangeRates;
    let currencyString = new Intl.NumberFormat(gl_settings_language, { style: 'currency', currency: gl_currency_currentCurrency.code }).format(value * gl_currency_exchangeRates![gl_currency_currentCurrency.code]);


    return currencyString;

}; // eo function renderCurrency();


/*******************************************************************************
* renderFixedCurrency()
*
* Desc: Returns a formatted currency string.
* Note:
*
*/
export const renderFixedCurrency = (value: number, currency: "EUR" | "USD", options?: { recalculateWithExchangeRate?: boolean, currencyFormat?: "symbol" | "code" } ) => {

    const gl_settings_language = useSettingsLanguageStore.getState().language,
          gl_currency_exchangeRates = useCurrencyStore.getState().exchangeRates;
    let currencyString = "",
        currencyDisplay = "symbol",
        recalculateWithExchangeRate = false;


    // >>> OPTIONS
    if (options && options.currencyFormat) {
        currencyDisplay = options.currencyFormat;
    }
    if (options && options.recalculateWithExchangeRate) {
        recalculateWithExchangeRate = options.recalculateWithExchangeRate;
    }
    // <<< OPTIONS


    if (recalculateWithExchangeRate === true) {
        currencyString = new Intl.NumberFormat(gl_settings_language, { style: 'currency', currency: currency, currencyDisplay: currencyDisplay }).format(value * gl_currency_exchangeRates![currency]);
    }
    if (recalculateWithExchangeRate === false) {
        currencyString = new Intl.NumberFormat(gl_settings_language, { style: 'currency', currency: currency, currencyDisplay: currencyDisplay }).format(value);
    }


    return currencyString;

}; // eo function renderFixedCurrency()


/*******************************************************************************
* debugWriter()
*
* Desc: Writes a Debug string in the console.
* Note:
*
* @param {string} text
*
*/
export const debugWriter = (text: string) => {

    if (CONF_ENVIRONMENT === "TEST"){
        console.log(text);
    }

}; // eo function debugWriter()


/***************************************************************************
* interpretPattern()
*
* Desc: Returns pattern with RegExp, if key "pattern" exists in object
* Desc: If not it does nothing
* Note: interpret pattern bc JSON can't store RegExp.
*
* @param {object} rules
*
*/
export const interpretPattern = (rules: any) => {

    debugWriter('>>> tsx/utilities.tsx >>> interpretPattern');
    if (rules.pattern !== undefined) {
        rules.pattern.value = new RegExp(rules.pattern.value);
    }


    return rules;

}; // eo function interpretPattern()



/***************************************************************************
* getErrorMessage()
*
* Desc:
* Note:
*
* @param {string} message
*
*/
export const getErrorMessage = (message: undefined | string | React.ReactElement): string | undefined => {

    const gl_shared_language = useSettingsLanguageStore.getState().language;
    let errorMessage: string | undefined = undefined;


    debugWriter('>>> tsx/utilities.tsx >>> getErrorMessage');
    if (typeof message === "string") {
        if (message === "ProvideValidEMail" ||
           message === "FieldNotEmpty" ||
           message === "LengthMin3" ||
           message === "OnlyCapitalLetters" ||
           message === "LengthMax3" ||
           message === "LengthMin4" ||
           message === "PermittedPattern" ||
           message === "LengthMax8" ||
           message === "LengthMin5" ||
           message === "AllowedLength2to30" ||
           message === "Min5Crewmembers") {
            errorMessage = lang.global.Rules[message][gl_shared_language];
        }
    }


    return errorMessage;

}; // eo function getErrorMessage()


/***************************************************************************
* round()
*
* Desc: Rounds a given number to two decimals.
* Note: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
*
* @param {number} toRound
*/
export const round = (toRound: number) => {

    let rounded = 0;


    debugWriter('>>> tsx/utilities.tsx >>> round');
    rounded = Math.round( ( toRound + Number.EPSILON ) * 100 ) / 100;


    return rounded;

}; // ei function round()


/*******************************************************************************
* setLocalStorageLanguage()
*
* Desc: Set the 'language' into the local storage
*
*/
export async function setLocalStorageLanguage(languageCode: LanguageType) {


    debugWriter('>>> tsx/utilities.tsx >>> setLocalStorageLanguage');
    await Storage.set({
        key: "@SDR-Language",
        value: languageCode,
    });

}; // eo function setLocalStorageLanguage()


/*******************************************************************************
* getLocalStorageLanguage()
*
* Desc: Get the 'language' from the local storage
*
*/
export async function getLocalStorageLanguage() {

    let localStorageLanguage = await Storage.get({ key: "@SDR-Language" });


    debugWriter('>>> tsx/utilities.tsx >>> getLocalStorageLanguage');
    if (localStorageLanguage.value === "de-DE" || localStorageLanguage.value === "en-EN") {
        return localStorageLanguage.value;
    }


    return null;

}; // eo function getLocalStorageLanguage()


/*******************************************************************************
* setLocalStorage_LastOnlineUpdate()
*
* Desc: Set the 'last online update' into the local storage.
*
*/
export async function setLocalStorage_LastOnlineUpdate() {


    debugWriter('>>> tsx/utilities.tsx >>> setLocalStorage_LastOnlineUpdate');
    await Storage.set({
        key: "@SDR-LastOnlineUpdate",
        value: dayjs().toISOString(),
    });

}; // eo function setLocalStorage_LastOnlineUpdate()


/*******************************************************************************
* getLocalStorage_LastOnlineUpdate()
*
* Desc: Get the 'last online update' from the local storage.
*
*/
export async function getLocalStorage_LastOnlineUpdate() {

    let localStorage_LastOnliineUpdate = await Storage.get({ key: "@SDR-LastOnlineUpdate" });


    debugWriter('>>> tsx/utilities.tsx >>> getLocalStorage_LastOnlineUpdate');
    return localStorage_LastOnliineUpdate.value;

}; // eo function getLocalStorage_LastOnlineUpdate()


/*******************************************************************************
* exchangeCalculation()
*
* Desc:
*
*/
export const exchangeCalculation = (price: number, currencyCode: string) => {

    const gl_exchangeRates = useCurrencyStore.getState().exchangeRates;
    let rate:number = 0;


    debugWriter('>>> tsx/utilities.tsx >>> exchangeCalculation');
    switch (currencyCode) {
        case 'EUR':
            rate = round(price * (gl_exchangeRates?.EUR ?? 1));
            break;
        case 'USD':
            rate = round(price * (gl_exchangeRates?.USD ?? 1));
            break;
        default:
            rate = round(price * (gl_exchangeRates?.EUR ?? 1)); // default
            break;
    }


    return rate;

}; // eo function exchangeCalculation()


/*******************************************************************************
* vatCalculation()
*
* Desc:
*
*/
export const vatCalculation = (price: number, currencyCode: string, vat: any) => {

    const gl_exchangeRates = useCurrencyStore.getState().exchangeRates;
    let rate:number = 0;


    debugWriter('>>> tsx/utilities.tsx >>> vatCalculation');
    switch (currencyCode) {
        case 'EUR':
            rate = (round(price * (gl_exchangeRates?.EUR ?? 1)) / (100 + vat)) * vat;
            break;
        case 'USD':
            rate = ((round(price * (gl_exchangeRates?.USD ?? 1)) / (100 + vat)) * vat)
            break;
        default:
            rate = ((round(price * (gl_exchangeRates?.EUR ?? 1)) / (100 + vat)) * vat) // default
            break;
    }


    return rate;

}; // eo function vatCalculation()
