/*
* EMailModal.tsx
*
* Description: 
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { RefObject, useEffect } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonInput, IonItem, IonLabel } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useForm, Controller } from "react-hook-form";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrentOrderStore } from '../../stores/shared/currentOrder';
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, getErrorMessage, hideSpinner, interpretPattern, showSpinner, showToastInformation } from '../../tsx/utilities';
import { DBOrders_changeEMailAddress, DBOrders_getOrder } from '../../databases/orders';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import rules from '../../tsx/validationrules/EMailModal.json'
import lang from "../../tsx/language.json"
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface type_Props{
    isOpen: boolean;
    onClose: () => void;
    presentingElement: RefObject<HTMLElement>;
}
type type_formValues_email = {
    email: string;
}
const EMailModal: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_currentOrder_invoiceId = useCurrentOrderStore(state => state.currentOrderInvoiceId);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const { handleSubmit, errors, control, setValue } = useForm<type_formValues_email>();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: 
    * Note:
    *
    */
    useEffect(() => {

        if(props.isOpen === true) {
            if(gl_currentOrder_invoiceId !== null) {
                DBOrders_getOrder(gl_currentOrder_invoiceId)
                    .then(order => {
                        if(order) {
                            setValue("email", order.emailAddress, false);
                        }
                    });
            }
        }

    }, [props.isOpen]); // eo function useEffect()


    /***************************************************************************
    * onSubmit()
    *
    * Desc: Add the new email to the current order.
    * Note:
    *
    */
    const onSubmit = async (data: type_formValues_email) => {

        debugWriter(">>> /components/CompleteOrder.tsx: onSubmit()");

        showSpinner();

        setTimeout(() => {
            DBOrders_changeEMailAddress(gl_currentOrder_invoiceId!, data.email);
        }, 300); // UI Magic

        hideSpinner();

        props.onClose();
        showToastInformation(lang.global.Labels.Information[gl_shared_language] , lang.components.Shared.Toasts.EMailSavedToOrder[gl_shared_language]);

    }; // eo function onSubmit()



    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <IonModal
            isOpen={props.isOpen}
            presentingElement={props.presentingElement.current!}
            swipeToClose={true}
            onDidDismiss={props.onClose}>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={props.onClose}>{ lang.global.Labels.Cancel[gl_shared_language] }</IonButton>
                    </IonButtons>
                    <IonTitle>{ lang.components.Shared.EMailModal.Titles.SendInvopicePerMail[gl_shared_language] }</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <ul>
                    <li>
                        <p>
                            { lang.components.Shared.EMailModal.Texts.OnlyUsedForInvoiceTransmission[gl_shared_language] }
                        </p>
                    </li>
                    <li>
                        <p>
                            { lang.components.Shared.EMailModal.Texts.SavedOnServerForLater[gl_shared_language] }
                        </p>
                    </li>
                    <li>
                        <p>
                            { lang.components.Shared.EMailModal.Texts.NoAds[gl_shared_language] }
                        </p>
                    </li>
                    <li>
                        <p>
                            { lang.components.Shared.EMailModal.Texts.OnlyOneEmailPerOrder[gl_shared_language] }
                        </p>
                    </li>
                </ul>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        <IonLabel position="floating">
                            <p>{ lang.components.Shared.EMailModal.Labels.EMail[gl_shared_language] }</p>
                        </IonLabel>
                        <Controller
                            as={<IonInput inputMode="email"></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                return selected.detail.value;
                            }}
                            name={"email"}
                            rules={interpretPattern(rules.email)}
                        />
                    </IonItem>
                    <p className="sdr-text-color-warning sdr-text-small">
                        { errors.email && getErrorMessage(errors.email.message) }
                    </p>

                    <IonButton type="submit" expand="block" className="ion-margin-bottom">
                        { lang.components.Shared.EMailModal.Labels.SaveEMail[gl_shared_language] }
                    </IonButton>

                </form>
            </IonContent>
        </IonModal>
    );
};



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default EMailModal;
