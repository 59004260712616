/*
* Login.tsx 
*
* Description: Login Screen
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from 'styled-components';
import { useHistory } from 'react-router';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import LoginCard from "../components/Login/LoginCard";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useLoginStore } from "../stores/login/login";
import { useSettingsLanguageStore } from '../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_VERSION_NUMBER, CONF_REVISIONS_NUMBER, CONF_ENVIRONMENT } from '../tsx/config';
import lang from "../tsx/language.json";
import SyncCard from '../components/Login/SyncCard';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const Login: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_lang = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_auth_resetLoginStore = useLoginStore(state => state.actn_resetLoginStore);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References


    
    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /*******************************************************************************
    * useIonViewDidEnter()
    *
    * Desc: Reset the whole global state while entering this view.
    * Note: At the moment we only reset the login state.
    *
    */
    useIonViewDidEnter(async () => {

        actn_auth_resetLoginStore();

    }); // eo function useIonViewDidEnter()


    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('call_getLoginInformations_Success', onEmit_LoginCallSuccess);

        return () => {
            PubSub.unsubscribe('call_getLoginInformations_Success');
        }

    }); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onEmit_LoginCallSuccess()
    *
    * Desc: Go on to next page.
    * Note:
    *
    */
    const onEmit_LoginCallSuccess = () => {

        debugWriter(">>> /pages/Login.tsx: onEmit_LoginCallSuccess()");
        history.push('/userdashboard');

    }; // eo function onEmit_LoginCallSuccess()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledCardContainer>
                    <LoginCard />

                    <SyncCard />

                    <div className="ion-no-margin ion-margin-vertical">
                        <p className="ion-text-center sdr-text-small">
                            {lang.components.Login.SettingsCard.Texts.Version[gl_settings_lang]}  {CONF_VERSION_NUMBER} ({CONF_REVISIONS_NUMBER}) {CONF_ENVIRONMENT === "TEST" ? "TEST" : ""}
                        </p>
                        <p className="ion-text-center sdr-text-small">
                            { CONF_ENVIRONMENT === "TEST" ? "ATTENTION: Do not use in flight!" : "" }
                        </p>
                    </div>
                </StyledCardContainer>
            </IonContent>
        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledCardContainer
* 
*/
const StyledCardContainer = styled.div`
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Login;