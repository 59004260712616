/*
* PurchaseOverview.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
import { IonCard } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import EmptyState from "../EmptyState";
import PurchaseOverviewHeader from './PurchaseOverviewHeader';
import OrderItem from '../../Purchase/Transactions/OrderItem';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from '../../../tsx/types';
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    orders: Array<OrderType>;
    presentingElement: React.RefObject<HTMLElement>;
}
const PurchaseOverview: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <IonCard className="ion-no-margin mt-3">
            <PurchaseOverviewHeader />

            { props.orders.length === 0 ? 
                <EmptyState 
                    title={ lang.components.FlightDashboard.PurchaseOverview.Texts.EmptyHere[gl_shared_language] }
                    content={ lang.components.FlightDashboard.PurchaseOverview.Texts.StartCatering[gl_shared_language] } />
            : null }

            { props.orders.map((order, index) => (
                <OrderItem 
                    key={`order-item-${index}`} 
                    order={order}
                    presentingElementRef={props.presentingElement} />
            )) }

        </IonCard>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default PurchaseOverview;
