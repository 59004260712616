/*
* PrintModal.tsx
*
* Description:
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { RefObject } from 'react';
import { IonAlert } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrentOrderStore } from '../../stores/shared/currentOrder';
import { useSettingsLanguageStore } from '../../stores/shared/settings';
import { useLoginStore } from '../../stores/login/login';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { printInvoice, printSalesReport } from '../../tsx/printer';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    isOpen: boolean;
    onClose: () => void;
    presentingElement: RefObject<HTMLElement>;
    type: "INVOICE" | "SALESREPORT";
    invoiceId?: string; // for INVOICE
    flightId?: number; // for SALESREPORT
}
const PrintModal: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_currentOrder_invoiceId = useCurrentOrderStore(state => state.currentOrderInvoiceId);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_auth_idResource = useLoginStore(state => state.idResource);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_print()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_print = async () => {

        let invoiceId: string | null = null,
            flightId: number | null = null;


        if(props.type === "INVOICE") {
            if(props.invoiceId) {
                invoiceId = props.invoiceId;
            } else {
                invoiceId = gl_currentOrder_invoiceId!;
            }

            printInvoice(invoiceId)
        }

        if (props.type === "SALESREPORT") {
            if (props.flightId) {
                flightId = props.flightId;
            }

            if (flightId) {
                printSalesReport(flightId, gl_auth_idResource!);
            }
        }

    }; // eo function onClick_print()


    /***************************************************************************
    * onClick_cancel()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_cancel = () => {

        props.onClose();

    }; // eo fucntion onClick_cancel()


    /***************************************************************************
    * onClick_confirm()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_confirm = () => {

        onClick_print();

    }; // eo function onClick_confirm()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div>
            <IonAlert
                isOpen={props.isOpen}
                onDidDismiss={ () => props.onClose() }
                header={ lang.components.CompleteOrder.PrintModal.Titles.Print[gl_shared_language] }
                message={ lang.components.CompleteOrder.PrintModal.Texts.PrinterActivcated[gl_shared_language] }
                buttons={[
                    {
                        text: lang.global.Labels.Cancel[gl_shared_language],
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: onClick_cancel
                    },
                    {
                        text: lang.global.Labels.Yes[gl_shared_language],
                        handler: onClick_confirm
                    }
                ]}
            />
        </div>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default PrintModal;
