/*
* Table.tsx
*
* Description: Component: AdminPanel: Table
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from "styled-components";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { IndexSpec } from 'dexie';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    title: string;
    tableSchema: Array<IndexSpec>;
    tableContent: Array<any>;
}
const Table: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <StyledTableContainer>
            
            <p className="sdr-text-large sdr-font-weight-500">
                {props.title}
            </p>

            <StyledTable>
                <thead>
                    <tr>

                        { props.tableSchema.map((header, index) => (
                            <td key={`table-header-${props.title}-${index}`}>
                                <p className="sdr-text-normal sdr-font-weight-500">{header.name}</p>
                            </td>
                        )) }

                    </tr>
                </thead>

                <tbody>

                    { props.tableContent.map((entry, index1) => {

                        let ret: JSX.Element[] = [];


                        props.tableSchema.forEach((content, index2) => {
                            ret.push(
                                <td key={`table-body-${props.title}-${index1}-${index2}`}>
                                    {JSON.stringify(entry[content.name])}
                                </td>
                            );
                        });


                        return <tr key={`table-body-row-${props.title}-${index1}`}>{ret}</tr>;

                    }) }

                </tbody>
            </StyledTable>
        </StyledTableContainer>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledTableContainer
* 
*/
const StyledTableContainer = styled.div`
    width: 100%; 
    overflow: scroll;
`;

/****************************************************************************** 
* StyledTable
* 
*/
const StyledTable = styled.table`
    thead {
        background: var(--ion-color-primary-tint);

        td {
            padding: 0 8px; 
            border: 1px solid grey;
        }
    }

    tbody {
        width: 100%;

        td {
            padding: 4px 8px;
            border: 1px solid lightgrey;
        }
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Table;
