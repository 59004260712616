/*
* currentCustomer.tsx
*
* Description: Global Store for Customer
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    paymentMethod: "CC" | "CS" | "DD" | null,
    cc_brand: null | string,
    cc_holder: null | string,
    cc_number: null | string,
    cc_expiryMonth: null | string,
    cc_expiryYear: null | string,
    cc_cvc: null | string,
    seat: null | string,
    sendInvoicePerEmail: boolean,
    emailAddress: null | string,

    mut_setPaymentMethod: (method: "CC" | "CS" | "DD") => void,
    mut_setSeat: (seat: string) => void,
    mut_resetCustomerStore: () => void

    actn_setPaymentMethod: (method: "CC" | "CS" | "DD") => void,
    actn_setSeat: (seat: string) => void,
    actn_resetCustomerStore: () => void
};



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    paymentMethod: null,
    cc_brand: null,
    cc_holder: null,
    cc_number: null,
    cc_expiryMonth: null,
    cc_expiryYear: null,
    cc_cvc: null,
    seat: null,
    sendInvoicePerEmail: false,
    emailAddress: null
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useCurrentCustomerStore = create<StateType>((set, get) => ({
    ...initalState,



    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * mut_setPaymentMethod()
    *
    * Desc: Sets the payment method.
    *
    */
    mut_setPaymentMethod: (method) => {

        set({ paymentMethod: method });

    }, // eo funciton mut_setPaymentMethod()

    /***************************************************************************
    * mut_setSeat()
    *
    * Desc: Sets the seat.
    *
    */
    mut_setSeat: (seat) => {

        set({ seat: seat });

    }, // eo function mut_setSeat()


    /***************************************************************************
    * mut_resetCustomerStore()
    *
    * Desc: Reset Store to inital state.
    *
    */
    mut_resetCustomerStore: () => {

        set(initalState);

    }, // eo function mut_resetCustomerStore()



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$
    // #   /$$__  $$            | $$    |__/
    // #  | $$  \ $$  /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_setPaymentMethod()
    *
    * Desc: Sets the payment method.
    *
    */
    actn_setPaymentMethod: (paymentMethod) => {

        get().mut_setPaymentMethod(paymentMethod);

    }, // eo funciton actn_setPaymentMethod()

    /***************************************************************************
    * actn_setSeat()
    *
    * Desc: Sets the seat.
    *
    */
    actn_setSeat: (seat: string) => {

        get().mut_setSeat(seat);

    }, // eo function actn_setSeat()


    /***************************************************************************
    * actn_resetCustomerStore()
    *
    * Desc: Reset Store to inital state.
    *
    */
    actn_resetCustomerStore: () => {

        get().mut_resetCustomerStore();

    }, // eo function actn_resetCustomerStore()

}));