/*
* flightselection.tsx
*
* Description: Global Store for FlightSelection
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import axios from "axios";
import dayjs from 'dayjs';
import produce from "immer";
import objectSupport from "dayjs/plugin/objectSupport";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useNetworkStore } from '../shared/network';
import { useLoginStore } from "../login/login";
import { useSettingsLanguageStore } from '../shared/settings';
import { useProductsStore } from '../products/products';

// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBLogs_addLog } from '../../databases/logs';
import { hideSpinner, showSpinner, showToastError, showToastWarning } from '../../tsx/utilities';
import { DBFlights_breakdown, DBFlights_addFlights, DBFlights_addFlight, DBFlights_deleteFlightByFlightId, DBFlights_breakdownOnlyUserCreatedFlights, } from "../../databases/flights";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_DAYS_IN_ADVANCE } from "../../tsx/config";
import { type_flight_flight, type_flight_getBreakdown_Response } from "../../types/flight";
import { type_Response } from "../../types/response";
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------


dayjs.extend(isSameOrAfter);
dayjs.extend(objectSupport);




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    selectedFlight: type_flight_flight | null,
    availableFlights: Array<type_flight_flight>,

    mut_call_getAvaiableFlights_API: (response: type_flight_getBreakdown_Response.RootObject) => void,
    mut_call_getAvaiableFlights_DB: (response: Array<type_flight_flight>) => void,

    actn_setSelectedFlight: (flight: type_flight_flight) => Promise<void>,
    actn_addFlight: (flightToAdd: type_flight_flight) => void,
    actn_deleteFlightByFlightId: (flightId: number) => void,
    actn_call_getAvaiableFlights: () => void,
};



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    selectedFlight: null,
    availableFlights: [],
};



//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useFlightSelectionStore = create<StateType>((set, get) => ({
    ...initalState,



    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * mut_call_getAvaiableFlights_API()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getAvaiableFlights_API: async (response) => {

        const gl_auth_idResource = useLoginStore.getState().idResource;
        let flights: Array<type_flight_flight> = [];


        // "HACK"
        showSpinner();

        response.flightInfos.forEach(flight => {
            if(flight.aircraftType !== null && (flight.flightMode === null || flight.flightMode === 'C')) { // filter out unknown flights
                flights.push({
                    aircraftType: flight.aircraftType,
                    aptFromDepartureTime: flight.aptFromDepartureTime,
                    aptToArrivalTime: flight.aptToArrivalTime,
                    crew: flight.crew,
                    departureAirportInfo: flight.departureAirportInfo,
                    destinationAirportInfo: flight.destinationAirportInfo,
                    flightNo: flight.flightNo,
                    id: flight.id,
                    createdByUser: false,
                    flightMode: flight.flightMode,
                    idResource: gl_auth_idResource!,
                });
            }
        });

        DBFlights_addFlights(flights, gl_auth_idResource!);

        //merge these loaded flights with the user created flights from the database
        flights = flights.concat(await DBFlights_breakdownOnlyUserCreatedFlights(gl_auth_idResource!));

        //delete older flights (they could still be in the database)
        flights = _deleteOlderFlights(flights);
        flights = _sortFlightsByDepartureDate(flights);


        // "HACK"
        hideSpinner();


        set({ availableFlights: flights });

    }, // eo funciton mut_call_getAvaiableFlights_API()


    /***************************************************************************
    * mut_call_getAvaiableFlights_DB()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getAvaiableFlights_DB: (response) => {

        let flights: Array<type_flight_flight> = [...response];


        //delete older flights (they could still be in the database)
        flights = _deleteOlderFlights(flights);
        flights = _sortFlightsByDepartureDate(flights);


        set({ availableFlights: flights });

    }, // eo function mut_call_getAvaiableFlights_DB()



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$             /$$     /$$
    // #   /$$__  $$           | $$    |__/
    // #  | $$  \ $$  /$$$$$$$/$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$       | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$       | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$ |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/  \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_setSelectedFlight()
    *
    * Desc: Sets the selected flight.
    * Note:
    *
    */
    actn_setSelectedFlight: async (selectedFlight) => {

        const gl_products_mut_rebuild_Products_DB = useProductsStore.getState().mut_rebuild_Products_DB;

        DBLogs_addLog(`Flight selected: ${selectedFlight.id}`);

        set({ selectedFlight: selectedFlight });
        await gl_products_mut_rebuild_Products_DB(selectedFlight);
        
    }, // eo function actn_setSelectedFlight()


    /*******************************************************************************
    * actn_addFlight()
    *
    * Desc: Adds a new flight to the available flights array.
    * Note: Use immer (produce) to work with the nested array.
    *
    */
    actn_addFlight: (flightToAdd) => {

        const gl_idResource = useLoginStore.getState().idResource;

        let nextState_availableFlights = produce(get().availableFlights, draft => {
            draft.push(Object.assign(flightToAdd, {
                createdByUser: true,
            }));
        });


        DBLogs_addLog(`Flight selected: ${flightToAdd.departureAirportInfo.iataCode} - ${flightToAdd.destinationAirportInfo.iataCode} (${flightToAdd.id})`);
        DBFlights_addFlight(flightToAdd, gl_idResource!);
        nextState_availableFlights = _sortFlightsByDepartureDate(nextState_availableFlights);


        set({ availableFlights: nextState_availableFlights });

    }, // eo function actn_addFlight()


    /*******************************************************************************
    * actn_deleteFlightByFlightId()
    *
    * Desc: Deletes a flight by it's id.
    * Note: Use immer (produce) to work with the nested array.
    * Note: If there is no flight with this id, ignore this call and do nothing.
    *
    */
    actn_deleteFlightByFlightId: async (flightToDeleteFlightId) => {

        showSpinner();

        let nextState_availableFlights = produce(get().availableFlights, draft => {
            let indexOfFlightToDelete = null;

            for (let i = 0; i < draft.length; i++) {
                if (draft[i].id === flightToDeleteFlightId) {
                    indexOfFlightToDelete = i;
                }
            }

            if (indexOfFlightToDelete !== null) {
                draft.splice(indexOfFlightToDelete, 1);
            }
        });

        await DBFlights_deleteFlightByFlightId(flightToDeleteFlightId);

        DBLogs_addLog(`Flight deleted: ${flightToDeleteFlightId}`);

        set({ availableFlights: nextState_availableFlights });

        hideSpinner();

    }, // eo function actn_deleteFlightByFlightId()


    /*******************************************************************************
     * actn_call_getAvaiableFlights()
     *
     * Desc: Read the flights from the database or from the API.
     * Note: If there are no infos in the database table, call the API.
     * Note: Call the API only if there is a network connection.
     *
     */
    actn_call_getAvaiableFlights: async () => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected,
              gl_idResource = useLoginStore.getState().idResource,
              gl_shared_language = useSettingsLanguageStore.getState().language;

        let readDataFromDB = gl_networkConnected === false;


        showSpinner();

        if(readDataFromDB === false) {

            DBLogs_addLog(`Loading Flights with API`);
            await axios
                .put("/humanresources/resource/report/breakdown", _createBodyForBreakdown())
                .then((response: type_Response.response) => {
                    if (response.headers["air41-status"] === "OK") {
                        get().mut_call_getAvaiableFlights_API(response.data);
                    } else {
                        showToastWarning(
                            lang.components.Shared.Toasts.Warning[gl_shared_language],
                            lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                        );
                        readDataFromDB = true;
                    }
                })
                .catch(() => {
                    showToastWarning(
                        lang.components.Shared.Toasts.Warning[gl_shared_language],
                        lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                    );
                    readDataFromDB = true;
                });
        }

        if(readDataFromDB === true) {

            let flights = await DBFlights_breakdown(gl_idResource!);


            DBLogs_addLog(`Loading Flights with DB`);
            if (flights.length > 0) {
                get().mut_call_getAvaiableFlights_DB(flights);
            } else {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                );
            }
        }


        PubSub.publish("call_getAvailableFlights_Done");
        hideSpinner();

    }, // eo function actn_call_getAvaiableFlights()


    /*******************************************************************************
    * actn_resetFlightSelectionStore()
    *
    * Desc: Reset Store to inital state.
    *
    */
    actn_resetFlightSelectionStore: () => {

        set(initalState);

    }, // eo function actn_resetFlightSelectionStore()

}));



/*******************************************************************************
* _createBodyForBreakdown()
*
* Desc: Creates the body which will be send to backend.
* Note:
*
*/
const _createBodyForBreakdown = () => {

    let fromDate: string,
        toDate: string,
        idResource = useLoginStore.getState().idResource;


    fromDate = dayjs().format("YYYY-MM-DD[T]00:00:00[Z]");
    toDate = dayjs().add(CONF_DAYS_IN_ADVANCE, "day").format("YYYY-MM-DD[T]23:59:59[Z]");


    return {
        from: fromDate,
        to: toDate,
        idResource: idResource!,
    };

}; // eo function _createBodyForBreakdown()


/*******************************************************************************
* _sortFlightsByDepartureDate()
*
* Desc: Sorts a flight array by the departure date of the specific flights.
* Note:
*
*/
const _sortFlightsByDepartureDate = (flightsToSort: Array<type_flight_flight>) => {

    return produce(flightsToSort, (draft) => {
        draft.sort((a, b) => {
            return a.aptFromDepartureTime < b.aptFromDepartureTime ? -1 : 1;
        });
    });

}; // eo function _sortFlightsByDepartureDate()


/*******************************************************************************
* _deleteOlderFlights()
*
* Desc:
* Note:
*
*/
const _deleteOlderFlights = (flightsToUpdate: Array<type_flight_flight>) => {

    let today = dayjs();


    today = today.set('hour', 0);
    today = today.set('minute', 0);
    today = today.set('second', 0);
    today = today.set('millisecond', 0);


    return produce(flightsToUpdate, (draft) => {

        draft = flightsToUpdate.filter((flight) => dayjs(flight.aptFromDepartureTime).isSameOrAfter(today));

        return draft;

    });

}; // eo function _deleteOlderFlights