/*
* SyncModal.tsx
*
* Description: Modal with Sync process.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useRef } from 'react';
import { IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonSpinner } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../stores/shared/settings';
import { useSyncStore } from '../../stores/sync/sync';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBOrders_deleteOrders, DBOrders_getAllOrdersForSync, DBOrders_markOrdersAsSynced } from '../../databases/orders';
import { DBFlights_deletePassedFlights } from '../../databases/flights';
import { DBLogin_deleteAllEntries } from '../../databases/login';
import { createEMailInvoice } from '../../tsx/email';
import { debugWriter } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
import { OrderType } from '../../tsx/types';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type In = {
    isOpen: boolean;
    onDismiss: () => void;
};
const SyncModal: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State
    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_lang = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_call_processInvoices = useSyncStore(state => state.actn_call_processInvoices);
    // <<< --------------------------------------------------------------------- Global Actions
    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions
    // >>> --------------------------------------------------------------------- References
    const modalRef = useRef<HTMLIonModalElement>(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onDidPresent_syncData()
    *
    * Desc: Sync orders with backend.
    * Note: Sort orders from DB:
    * Note:          - Orders that were not synced yet
    * Note:          - Orders that were synced before, control if they ex. on backend
    * Note:          - Orders that were canceled
    *
    */
    const onDidPresent_syncData = async () => {

        let ordersToSync = await DBOrders_getAllOrdersForSync(),
            orderToSyncObject: any = null,
            orderIdsToDeleteFromDB: Array<string> = [],
            orderIdsToUpdateAsSynced: Array<string> = [],
            skipOrder = false;


        debugWriter(">>> SyncCard: onDidPresent_syncData");
        for(let i = 0; i < ordersToSync.length; i++) {

            skipOrder = false;

            if(ordersToSync[i].canceled === true) {
                orderIdsToDeleteFromDB.push(ordersToSync[i].invoiceId);
                skipOrder = true;
            }

            if(ordersToSync[i].syncedBefore === true) {
                orderIdsToDeleteFromDB.push(ordersToSync[i].invoiceId);
                skipOrder = true;
            }

            if(skipOrder === false) {

                orderToSyncObject = await createOrderObject(ordersToSync[i]);

                let syncOK = await actn_call_processInvoices(orderToSyncObject);

                if(orderToSyncObject.paymentMethod === "CC") {
                    orderIdsToDeleteFromDB.push(ordersToSync[i].invoiceId);
                } else {
                    if(syncOK === true) {
                        orderIdsToUpdateAsSynced.push(ordersToSync[i].invoiceId);
                    }
                }
            }
        }

        DBOrders_deleteOrders(orderIdsToDeleteFromDB);
        DBOrders_markOrdersAsSynced(orderIdsToUpdateAsSynced);

        DBFlights_deletePassedFlights();
        DBLogin_deleteAllEntries();

        modalRef.current?.dismiss();

    }; // eo fucntion onDidPresent_syncData()




    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * createOrderObject()
    *
    * Desc: Build the order object to sync with backend.
    * Note:
    *
    */
    const createOrderObject = async (orderFromDB: OrderType) => {

        debugWriter(">>> SyncCard: createOrderObject");
        return {
            aptFrom: orderFromDB.aptFrom,
            aptTo: orderFromDB.aptTo,
            crewMembers: orderFromDB.crewMembers,
            crewLogin: orderFromDB.crewLogin,
            flightNo: orderFromDB.flightNo,
            flightDate: orderFromDB.flightDate,
            passengerSeat: "",
            paymentMethod: orderFromDB.paymentMethod,
            ccBrand: orderFromDB.ccBrand,
            ccCvc: orderFromDB.ccCvc,
            ccExpiryMonth: orderFromDB.ccExpiryMonth,
            ccExpiryYear: orderFromDB.ccExpiryYear,
            ccHolder: orderFromDB.ccHolder,
            ccNumber: orderFromDB.ccNumber,
            ccSignature: orderFromDB.ccSignature,
            invoiceAmount: orderFromDB.invoiceAmount,
            invoiceVat: orderFromDB.invoiceVat,
            paymentAmount: orderFromDB.paymentAmount,
            invoiceId: orderFromDB.invoiceId,
            entries: orderFromDB.entriesForProcess,
            currency: orderFromDB.currency,
            emailInvoice: orderFromDB.emailInvoice,
            email: orderFromDB.emailAddress ?? "",
            emailText: orderFromDB.emailInvoice ? await createEMailInvoice(orderFromDB.invoiceId) : null,
            hasWarning: orderFromDB.hasWarning,
            warningComment: orderFromDB.warningComment
        }

    }; // eo function createOrderObject()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonModal
            onWillDismiss={props.onDismiss}
            ref={modalRef}
            onDidPresent={onDidPresent_syncData}
            backdropDismiss={false}
            isOpen={props.isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        { lang.components.Login.SyncModal.Titles.Synchroinzation[gl_settings_lang] }
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">
                            { lang.components.Login.SyncModal.Titles.Synchroinzation[gl_settings_lang] }
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <p className="sdr-text-normal">
                    {lang.components.Login.SyncModal.Texts.SyncDataNow[gl_settings_lang]}
                </p>
                <p className="sdr-font-weight-500">
                    {lang.components.Login.SyncModal.Texts.DoNotStopThisTask[gl_settings_lang]}
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <IonSpinner style={{ marginTop: 32 }} color="primary" />
                </div>
            </IonContent>
        </IonModal>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default SyncModal;
