/*
* AdminArea.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonButton, IonIcon, } from '@ionic/react';
import { buildOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import AdminAreaModal from "./AdminAreaModal";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    presentingElementRef: RefObject<HTMLElement>;
}
const AdminArea: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showAdminAreaModal, setShowAdminAreaModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language)
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_openAdminAreaModal()
    *
    * Desc: Show the Admin Modal.
    *
    */
    const onClick_openAdminAreaModal = () => {

        debugWriter(">>> UserDashboard/AdminArea: onClick_openAdminAreaModal");
        setShowAdminAreaModal(true);

    }; //eo function onClick_openAdminAreaModal()


    /*******************************************************************************
    * onDidDismiss_hideAdminAreaModal()
    *
    * Desc: Hide the Admin Modal.
    *
    */
    const onDidDismiss_hideAdminAreaModal = () => {

        debugWriter(">>> UserDashboard/AdminArea: onDidDismiss_hideAdminAreaModal");
        setShowAdminAreaModal(false);

    }; //eo function onDidDismiss_hideAdminAreaModal




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>

            <IonButton onClick={onClick_openAdminAreaModal} style={{ marginTop: 64 }} fill="clear">
                <IonIcon icon={buildOutline} slot="start" />
                {lang.components.UserDashboard.AdminArea.Texts.AdminArea[gl_settings_language]}
            </IonButton>

            <AdminAreaModal 
                isOpen={showAdminAreaModal}
                presentingElementRef={props.presentingElementRef}
                onDidDismiss={onDidDismiss_hideAdminAreaModal} />

        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AdminArea;
