/*
* logs.tsx
*
* Description: Database Management with DEXIE for the Logs Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../tsx/utilities';
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBLogs } from '../types/databases';
import { useLoginStore } from "../stores/login/login";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type type_DBLogs_addLog = (logToAdd: string) => void;
type type_DBLogs_getAllLogs = (date: undefined | null | string) => Promise<Array<type_Schema_DBLogs>>;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                              /$$     /$$
// #  | $$_____/                             | $$    |__/
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBLogs_addLog()
*
* Desc: Adds log to the database.
* Note:
*
*/
export const DBLogs_addLog: type_DBLogs_addLog = async (logToAdd) => {

    const db = await DB_openDatabase(),
          logsTable = db.table<type_Schema_DBLogs>("logs"),
          gl_login_username = useLoginStore.getState().username;


    debugWriter(">>> Database: DBLogs_addLog");
    logsTable
        .put({
            log: logToAdd,
            timestamp: dayjs().format("YYYY-MM-DD-HH:mm:ss"),
            loggedInUsername: gl_login_username ?? "APP"
        })
        .catch(() => {
            // do nothing
        });

}; // eo function DBLogs_addLog()


/*******************************************************************************
* ASYNC DBLogs_getAllLogs()
*
* Desc: Returns all logs.
* Note:
*
*/
export const DBLogs_getAllLogs: type_DBLogs_getAllLogs = async (date) => {

    const db = await DB_openDatabase(),
          logsTable = db.table<type_Schema_DBLogs>("logs");

    let logs: Array<type_Schema_DBLogs> = [];


    debugWriter(">>> Database: DBLogs_getAllLogs");
    if(date !== null && date !== undefined) {
        logs = await logsTable.where("timestamp").startsWith(dayjs(date).format("YYYY-MM-DD")).toArray();
    } else {
        logs = await logsTable.toArray();
    }

    logs.reverse()


    return logs;

}; // eo function DBLogs_getAllLogs()