/*
* Flights.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { RefObject, useEffect, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
import isTomorrow from "dayjs/plugin/isTomorrow";
import isToday from "dayjs/plugin/isToday";
import { useHistory } from 'react-router';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import FlightCardsContainer from '../../../components/flightselection/FlightCardsContainer';
import EmptyState from '../../../components/FlightDashboard/EmptyState';
import AddFlightModal from '../../../components/flightselection/AddFlightModal/AddFlightModal';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useFlightSelectionStore } from "../../../stores/flightselection/flights";
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
import { useLoginStore } from '../../../stores/login/login';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, showAlert } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
import { type_flight_flight } from '../../../types/flight';
// <<< Resources ---------------------------------------------------------------

dayjs.extend(isToday);
dayjs.extend(isTomorrow);




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    pageRef: RefObject<HTMLElement>;
}
const Flights: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showAddFlightModal, setShowAddFlightModal] = useState<boolean>(false);
    const [preFilledFlightForAddModal, setPreFilledFlightForAddModal] = useState<type_flight_flight | null>(null);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_login_isAdmin = useLoginStore(state => state.isAdmin);
    const gl_flightselection_availableFlights = useFlightSelectionStore(state => state.availableFlights);
    const gl_login_idResource = useLoginStore((state) => state.idResource);
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_call_getAvaiableFlights = useFlightSelectionStore(state => state.actn_call_getAvaiableFlights);
    const actn_setSelectedFlight = useFlightSelectionStore(state => state.actn_setSelectedFlight);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('call_getProducts_Done', onEmit_getProducts_Done);

        return () => {
            PubSub.unsubscribe('call_getProducts_Done');
        }

    }); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onEmit_getProducts_Done()
    *
    * Desc: 
    * Note: 
    *
    */
    const onEmit_getProducts_Done = () => {

        let loadNewFlights = true,
            tmp_availableFlights = useFlightSelectionStore.getState().availableFlights;


        debugWriter(">>> FlightSelection: useIonViewDidEnter()");
        //check if there are flights from the current user in gl_availableflights
        //we can do it with the idResource
        if (tmp_availableFlights.length > 0) {
            if (tmp_availableFlights[0].idResource === gl_login_idResource) {
                loadNewFlights = false;
            }
        }

        if (loadNewFlights) {
            actn_call_getAvaiableFlights()
        }

    }; // eo function onEmit_getProducts_Done()


    /*******************************************************************************
    * onClick_setSelectedFlight()
    *
    * Desc: Saves the selected Flight in global store.
    * Note: If the selected flight is not today, show an warning alert.
    * Note: The alert "stops" the app at the point it is shown. On click on "OK"
    * Note: the app goes on.
    *
    */
    const onClick_setSelectedFlight = async (selectedFlightId: number) => {

        let selectedFlight = gl_flightselection_availableFlights.filter(flight => flight.id === selectedFlightId),
            selectedFlightDate = dayjs(selectedFlight[0].aptFromDepartureTime),
            today = dayjs();


        debugWriter(">>> FlightSelection: onClick_setSelectedFlight()");
        if (!selectedFlightDate.isSame(today, 'day')) {
            showAlert(lang.pages.FlightSelection.Texts.FlightIsNotToday[gl_settings_language]);
        }

        await actn_setSelectedFlight(selectedFlight[0]);

        history.push("/flightdashboard");

    }; // eo function onClick_setSelectedFlight()


    /*******************************************************************************
    * onClick_editSelectedFlight()
    *
    * Desc: Opens the Add Flight Modal.
    * Note: It has prefilled entries made by the selected flight.
    *
    */
    const onClick_editSelectedFlight = (selectedFlightId: number) => {

        let flightObject = gl_flightselection_availableFlights.filter(flight => flight.id === selectedFlightId);


        debugWriter(">>> FlightSelection: onClick_editSelectedFlight()");
        if(flightObject.length > 0){
            setPreFilledFlightForAddModal(flightObject[0]);
        }
        setShowAddFlightModal(true);

    }; // eo function onClick_editSelectedFlight()


    /*******************************************************************************
    * onClose_hideAddFlightModal()
    *
    * Desc: Closes the Add Flight Modal.
    * Note: It clears all prefilled entries.
    *
    */
    const onClose_hideAddFlightModal = () => {

        debugWriter(">>> FlightSelection: onClose_hideAddFlightModal()");
        setPreFilledFlightForAddModal(null);
        setShowAddFlightModal(false);

    }; //eo function onClose_hideAddFlightModal()


    const filter_isFlightEarlier = (flight: type_flight_flight) => {

        return dayjs(flight.aptFromDepartureTime).isBefore(dayjs().startOf('day'));

    }; // eo function filter_isFlightEarlier


    /*******************************************************************************
    * filter_isFlightToday()
    *
    * Desc: Filter Helper.
    * Note: 
    *
    */
    const filter_isFlightToday = (flight: type_flight_flight) => {

        return dayjs(flight.aptFromDepartureTime).isToday();

    }; //eo function filter_isFlightToday()


    /*******************************************************************************
    * filter_isFlightTomorrow()
    *
    * Desc: Filter Helper.
    * Note: 
    *
    */
    const filter_isFlightTomorrow = (flight: type_flight_flight) => {

        return dayjs(flight.aptFromDepartureTime).isTomorrow();

    }; //eo function filter_isFlightTomorrow()


    /*******************************************************************************
    * filter_isFlightLater()
    *
    * Desc: Filter Helper.
    * Note: 
    *
    */
    const filter_isFlightLater = (flight: type_flight_flight) => {

        return dayjs(flight.aptFromDepartureTime).isAfter(dayjs().add(1, 'day').set('hour', 23).set('minute', 59).set('second', 59));
    
    }; //eo function filter_isFlightLater()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <div className="flex flex-row flex-ai-cen" style={{ marginTop: 64 }}>
                <h2>{lang.components.UserDashboard.Flights.Titles.Flights[gl_settings_language]}</h2>

                <IonButton fill="clear" onClick={() => setShowAddFlightModal(true)}>
                    <IonIcon style={{ marginBottom: -6 }} icon={addOutline} slot="start" />
                </IonButton>
            </div>


            { gl_flightselection_availableFlights.length === 0 ? 
                <EmptyState
                    title={lang.components.UserDashboard.Flights.Texts.NoFlights[gl_settings_language]}
                    content={lang.components.UserDashboard.Flights.Texts.ConnectInternetOrAdd[gl_settings_language]} />
            : null }

            { gl_login_isAdmin === true ? 
                <FlightCardsContainer
                    type="EARLIER"
                    isOpen={true}
                    showWarningIfEmpty={true}
                    flights={ gl_flightselection_availableFlights.filter(flight => filter_isFlightEarlier(flight)) }
                    onClick_edit={ (flightId) => onClick_editSelectedFlight(flightId) }
                    onClick_select={ (flightId) => onClick_setSelectedFlight(flightId) } />
            : null }

            <FlightCardsContainer
                type="TODAY"
                isOpen={true}
                showWarningIfEmpty={true}
                flights={ gl_flightselection_availableFlights.filter(flight => filter_isFlightToday(flight)) }
                onClick_edit={ (flightId) => onClick_editSelectedFlight(flightId) }
                onClick_select={ (flightId) => onClick_setSelectedFlight(flightId) } />

            <FlightCardsContainer
                type="TOMORROW"
                flights={ gl_flightselection_availableFlights.filter(flight => filter_isFlightTomorrow(flight)) }
                onClick_edit={ (flightId) => onClick_editSelectedFlight(flightId) }
                onClick_select={ (flightId) => onClick_setSelectedFlight(flightId) } />

            <FlightCardsContainer
                type="LATER"
                flights={ gl_flightselection_availableFlights.filter(flight => filter_isFlightLater(flight)) }
                onClick_edit={ (flightId) => onClick_editSelectedFlight(flightId) }
                onClick_select={ (flightId) => onClick_setSelectedFlight(flightId) } />
            
            <AddFlightModal 
                presentingElement={props.pageRef}
                preFilledFlight={preFilledFlightForAddModal} 
                isOpen={showAddFlightModal} 
                onClose={onClose_hideAddFlightModal} />

        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Flights;
