/*
* SyncCard.tsx
*
* Description: Card with Sync Informations.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonButton, IonSkeletonText } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import SyncModal from "./SyncModal";
import SyncAlert from "./SyncAlert";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../../tsx/utilities';
import { DBOrders_countAllEntries } from '../../databases/orders';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
import { useNetworkStore } from '../../stores/shared/network';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const SyncCard: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [numberOfOrders, setNumberOfOrders] = useState<number>(0);
    const [showSyncAlert, setShowSyncAlert] = useState<boolean>(false);
    const [showSyncModal, setShowSyncModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_lang = useSettingsLanguageStore(state => state.language);
    const gl_network_connected = useNetworkStore(state => state.networkConnected);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: Load the number of orders from DB.
    * Note: Show only the number of orders, that were not synced yet.
    *
    */
    useEffect(() => {

        loadNumberOfOrders();

    }, []); // eo function useEffect()


    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_showSyncAlert()
    *
    * Desc: Sync orders with Backend.
    * Note: 
    *
    */
    const onClick_showSyncAlert = () => {

        debugWriter(">>> SyncCard: onClick_sync");
        setShowSyncAlert(true);

    }; // eo function onClick_showSyncAlert()


    /***************************************************************************
    * onClick_showSyncModal()
    *
    * Desc: Open the Sync Modal.
    * Note: 
    *
    */
    const onClick_showSyncModal = () => {

        debugWriter(">>> SyncCard: onClick_showSyncModal");
        setShowSyncModal(true);

    }; // eo function onClick_showSyncModal()


    /***************************************************************************
    * onDismiss_SyncModal()
    *
    * Desc: 
    * Note: 
    *
    */
    const onDismiss_SyncModal = () => {

        loadNumberOfOrders();
        setShowSyncModal(false);

    }; // eo function onDismiss_SyncModal()


    /***************************************************************************
    * onClick_hideSyncAlert()
    *
    * Desc: Hide the Sync Alert.
    * Note: 
    *
    */
    const onClick_hideSyncAlert = () => {

        debugWriter(">>> SyncCard: onClick_hideSyncAlert");
        setShowSyncAlert(false);

    }; // eo function onClick_hideSyncAlert()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * loadNumberOfOrders()
    *
    * Desc: 
    * Note: 
    *
    */
    const loadNumberOfOrders = async () => {

        let numberOfOrders = await DBOrders_countAllEntries();


        debugWriter(">>> SyncCard: loadNumberOfOrders");
        if(typeof numberOfOrders === "number"){
            setNumberOfOrders(numberOfOrders);
        }

        setShowLoading(false);

    }; // eo function loadNumberOfOrders()

    

    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <IonCard className="ion-no-margin ion-margin-vertical">
                <IonCardContent>
                    { showLoading === true ? <IonSkeletonText animated style={{ width: "15%", maxWidth: 50, height: "2rem", borderRadius: 8, margin: "0 auto" }} /> : null }
                    { showLoading === true ? <IonSkeletonText animated style={{ width: "80%", maxWidth: 350, margin: "8px auto", height: "1.2rem", borderRadius: 8 }} /> : null }
                    { showLoading === true ? <IonSkeletonText animated style={{ width: "100%", margin: "8px auto", height: "3rem", borderRadius: 8 }} /> : null }
                    
                    { showLoading === false ? 
                        <div className="flex flex-col flex-ai-cen">
                            <p className="sdr-text-extra-large sdr-font-weight-500 mr-2">
                                {numberOfOrders}
                            </p>
                            <p className="sdr-text-normal">
                                { lang.components.Login.SyncCard.Titles.OrdersAreReadyForSync[gl_settings_lang] }
                            </p>
                        </div>
                    : null }
                    { showLoading === false ? 
                        <IonButton 
                            disabled={numberOfOrders === 0 || gl_network_connected === false} 
                            onClick={onClick_showSyncAlert}
                            className="ion-margin-top" 
                            expand="block">
                            {lang.components.Login.SyncCard.Labels.SubmitOrders[gl_settings_lang]}
                        </IonButton>
                    : null }
                </IonCardContent>
            </IonCard>

            <SyncAlert 
                isOpen={showSyncAlert}
                onClick_confirm={onClick_showSyncModal}
                onClick_cancel={onClick_hideSyncAlert} />

            <SyncModal 
                isOpen={showSyncModal}
                onDismiss={onDismiss_SyncModal} />

        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default SyncCard;
