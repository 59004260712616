/*
* EditSegment.tsx
*
* Description: Component: EditSegment.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonSegment, IonSegmentButton, } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    onChange: (value: "editPrecomma" | "editDecimal") => void;
}
const EditSegment: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [selectedSegment, setSelectedSegment] = useState<"editPrecomma" | "editDecimal">("editPrecomma");
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References

    

    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onChange_editSelectionChanged()
    *
    * Desc: Trigger the onChange Callback if the segmentbutton changes.
    * Note: 
    * 
    */
    const onChange_editSelectionChanged = (segmentButtonValue: string | undefined) => {

        if(segmentButtonValue === "editPrecomma") {
            props.onChange("editPrecomma");
            setSelectedSegment("editPrecomma");
        } else {
            props.onChange("editDecimal");
            setSelectedSegment("editDecimal");
        }

    }; // eo function onChange_editSelectionChanged()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div style={{ background: "var(--ion-color-light)", paddingTop: "0.5rem" }}>
            <IonSegment onIonChange={(e) => onChange_editSelectionChanged(e.detail.value)} value={selectedSegment} style={{ width: "calc(100% - 1rem)", margin: "0 0.5rem" }}>
                <IonSegmentButton value="editPrecomma">
                    <p className="sdr-font-weight-400 sdr-text-normal">
                        <span className="sdr-font-weight-600 sdr-text-biggernormal">XXX</span>.xx
                    </p>
                </IonSegmentButton>
                <IonSegmentButton value="editDecimal">
                    <p className="sdr-font-weight-400 sdr-text-normal">
                        xxx.<span className="sdr-font-weight-600 sdr-text-biggernormal">XX</span>
                    </p>
                </IonSegmentButton>
            </IonSegment>
        </div>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default EditSegment;
