/*
* OrderItemModal.tsx
*
* Description: Page: Transactions
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonButtons, IonContent, IonHeader, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonModal, IonItem, IonLabel, IonButton, IonAlert, IonIcon } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import PrintModal from '../../CompleteOrder/PrintModal';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, renderFixedCurrency, round, showToastError } from '../../../tsx/utilities';
import { DBOrders_cancelOrder } from '../../../databases/orders';
import { DBLogin_login } from '../../../databases/login';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from '../../../tsx/types';
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    order: OrderType;
    presentingElementRef: RefObject<HTMLElement>;
    onDidDismiss: () => void;
    isOpen: boolean;
}
const OrderItemModal: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
    const [showCalcelationAlert, setShowCancelationAlert] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State
    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions
    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions
    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_printInvoice()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_printInvoice = () => {

        debugWriter(">>> /components/Purchase/Transactions/OrderItemModal.tsx: onClick_printInvoice()");
        setShowPrintModal(true);

    }; // eo function onClick_printInvoice()


    /***************************************************************************
    * onClick_showConfirmAlert()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_showConfirmAlert = () => {

        debugWriter(">>> /components/Purchase/Transactions/OrderItemModal.tsx: onClick_showConfirmAlert()");
        setShowCancelationAlert(true);

    }; // eo function onClick_showConfirmAlert()


    /***************************************************************************
    * onClick_cancelOrder()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_cancelOrder = async (username: string, password: string) => {

        let userObject = await DBLogin_login(username.toUpperCase(), password),
            loginCredentialsValid = false;


        debugWriter(">>> /components/Purchase/Transactions/OrderItemModal.tsx: onClick_cancelOrder()");
        if (userObject !== null && userObject !== undefined) {
            loginCredentialsValid = true;
        }

        if (loginCredentialsValid === true) {
            DBOrders_cancelOrder(props.order.invoiceId);
            props.onDidDismiss();
        }

        if (loginCredentialsValid === false) {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.CheckFailed[gl_shared_language]
            );
        }

    }; // eo function onClick_cancelOrder()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <IonModal
                isOpen={props.isOpen}
                onDidDismiss={props.onDidDismiss}
                swipeToClose={true}
                presentingElement={props.presentingElementRef.current || undefined}>

                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={props.onDidDismiss}>{ lang.global.Labels.Done[gl_shared_language] }</IonButton>
                        </IonButtons>
                        <IonTitle>{props.order.invoiceId.substr(-3)}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent style={{ "--padding-top": 0 }}>

                    { props.order.canceled === true ?
                        <div style={{ position: "sticky", top: 0, zIndex: 2, background: "var(--ion-color-danger-extra-tint)", marginLeft: -19, marginRight: -19, padding: "16px 19px", display: "flex", alignItems: "center" }}>
                            <IonIcon style={{ color: "var(--ion-color-danger-contrast)" }} className="ion-margin-end" src="./assets/customIcons/icon54/Attention.1.svg" />
                            <p style={{ color: "var(--ion-color-danger-contrast)" }} className="sdr-text-bigger-normal sdr-font-weight-500 ion-no-margin">
                                { lang.components.Purchase.OrderItemModal.Texts.OrderCanceled[gl_shared_language] }
                            </p>
                        </div> : null }

                    <div style={{ marginBottom: 48 }}>
                        <p style={{ marginTop: 0, marginBottom: 0, padding: "8px 0", background: "white" }} className="sdr-text-normal sdr-text-large sdr-font-weight-500">
                            { lang.components.Purchase.OrderItemModal.Texts.FlightDetails[gl_shared_language] }
                        </p>

                        <IonItem style={{ marginTop: 8 }}>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.DepartureIATACode[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.aptFrom}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.DestinationIATACode[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.aptTo}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.FlightNo[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.flightNo}</p>
                        </IonItem>
                    </div>



                    <div style={{ marginBottom: 48 }}>
                        <p style={{ marginTop: 0, marginBottom: 0, padding: "8px 0", background: "white" }} className="sdr-text-normal sdr-text-large sdr-font-weight-500">
                            { lang.components.Purchase.OrderItemModal.Texts.PaymentDetails[gl_shared_language] }
                        </p>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.Amount[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{renderFixedCurrency(props.order.invoiceAmount, props.order.currency)}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.VAT[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{renderFixedCurrency(props.order.invoiceVat , props.order.currency)}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.PaymentMethod[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.paymentMethod === "CS" ? "Cash" : props.order.paymentMethod === "CC" ? "Credit Card" : props.order.paymentMethod === "DD" ? "Debit Card"  : ''}</p>
                        </IonItem>
                    </div>



                    <div style={{ marginBottom: 48 }}>
                        <p style={{ marginTop: 0, marginBottom: 0, padding: "8px 0", background: "white" }} className="sdr-text-normal sdr-text-large sdr-font-weight-500">
                            { lang.components.Purchase.OrderItemModal.Texts.InvoiceDetails[gl_shared_language] }
                        </p>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.InvoiceID[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.invoiceId}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.SendInvoicePerEMail[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.emailInvoice === true ? lang.global.Labels.Yes[gl_shared_language] : lang.global.Labels.No[gl_shared_language]}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.EMail[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.emailInvoice === true ? props.order.emailAddress : "-"}</p>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">
                                { lang.components.Purchase.OrderItemModal.Labels.InvoiceTimestamp[gl_shared_language] }
                            </IonLabel>
                            <p style={{ marginBottom: 4, marginTop: 8 }}>{props.order.timestamp}</p>
                        </IonItem>
                    </div>



                    <div style={{ marginBottom: 48 }}>
                        <p style={{ marginTop: 0, marginBottom: 0, padding: "8px 0", background: "white" }} className="sdr-text-normal sdr-text-large sdr-font-weight-500">
                            { lang.components.Purchase.OrderItemModal.Texts.OrderDetails[gl_shared_language] }
                        </p>

                        <IonGrid>

                            {props.order.entriesForPresentation.map((entry, index) => (
                                <React.Fragment key={`order-item-${index}`}>
                                    <IonRow>
                                        <IonCol size="1">
                                            <p style={{ marginBottom: 0 }}>
                                                {entry.counter}x
                                            </p>
                                        </IonCol>
                                        <IonCol size="8">
                                            <p style={{ marginBottom: 0 }}>
                                                {entry.name}
                                            </p>
                                        </IonCol>
                                        <IonCol size="3">
                                            <p style={{ marginBottom: 0 }}>
                                                {renderFixedCurrency(round((props.order.currency === "EUR" ? entry.priceEUR : entry.priceUSD) * entry.counter), props.order.currency, { recalculateWithExchangeRate: true })}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="1">

                                        </IonCol>
                                        <IonCol size="10">
                                            <p className="sdr-text-small" style={{ marginTop: 0 }}>
                                                { lang.components.Purchase.OrderItemModal.Texts.PricePerUnit[gl_shared_language] }: {renderFixedCurrency((props.order.currency === "EUR" ? entry.priceEUR : entry.priceUSD), props.order.currency, { recalculateWithExchangeRate: true })}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </React.Fragment>
                            ))}

                        </IonGrid>

                    </div>

                    <IonButton fill="clear" onClick={onClick_printInvoice} disabled={props.order.canceled === true} expand="block">
                        { lang.components.Purchase.OrderItemModal.Labels.PrintInvoice[gl_shared_language] }
                    </IonButton>

                    <IonButton fill="clear" disabled={props.order.canceled === true} onClick={onClick_showConfirmAlert} style={{ marginTop: 32 }} expand="block" color="danger">
                        { lang.components.Purchase.OrderItemModal.Labels.CancelOrder[gl_shared_language] }
                    </IonButton>
                </IonContent>
            </IonModal>

            <IonAlert
                isOpen={showCalcelationAlert}
                onDidDismiss={() => setShowCancelationAlert(false)}
                header={ lang.components.Purchase.OrderItemModal.Titles.ConfirmCancelation[gl_shared_language] }
                message={ lang.components.Purchase.OrderItemModal.Texts.PleaseLoginToConfirm[gl_shared_language] }
                inputs={[
                    {
                        name: 'username',
                        type: 'text',
                        placeholder: lang.components.Purchase.OrderItemModal.Labels.Username[gl_shared_language]
                    },
                    {
                        name: 'password',
                        type: 'password',
                        placeholder: lang.components.Purchase.OrderItemModal.Labels.Password[gl_shared_language]
                    }
                ]}
                buttons={[
                    {
                        text: lang.global.Labels.Cancel[gl_shared_language],
                        role: 'cancel',
                    },
                    {
                        text: lang.global.Labels.Confirm[gl_shared_language],
                        handler: (values: { username: string, password: string }) => {
                            onClick_cancelOrder(values.username, values.password)
                        }
                    }
                    ]} />

            <PrintModal
                isOpen={showPrintModal}
                onClose={() => setShowPrintModal(false)}
                presentingElement={props.presentingElementRef}
                type="INVOICE"
                invoiceId={props.order.invoiceId} />

        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default OrderItemModal;
