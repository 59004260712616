/*
* CategoryRow.tsx
*
* Description: Container of Category and Icon
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useState } from 'react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import PubSub from "pubsub-js";
import AnimateHeight from 'react-animate-height';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import ProductItem from './ProductItem';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useProductsStore } from '../../stores/products/products';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Category, type_products_Product_Extended } from '../../types/products';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    categories: Array<type_products_Category>;
    elementsPerRow: number;
    bestseller: Array<type_products_Product_Extended>;
}
const CategoryRow: React.FC<type_Props> = (props) => {
    const animationDurationAnimatedHeight = 200;

    // >>> --------------------------------------------------------------------- Local State
    const [productsListHeight, setProductsListHeight] = useState<"auto" | 0>(0);
    const [activeCategory, setActiveCategory] = useState<type_products_Category | null>(null);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const get_ProductsOfCategory = useProductsStore(state => state.get_ProductsOfCategory);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('viewdidleave_purchase', () => {
            setActiveCategory(null);
            setProductsListHeight(0);
        });

        return () => {
            PubSub.unsubscribe('viewdidleave_purchase');
        }

    }); // eo function useEffect()



    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('categorySelected', onEmit_categorySelected);

        return () => {
            PubSub.unsubscribe('categorySelected');
        }

    }, []); // eo function useEffect()



    // TODO: doppelter code
    const onClick_Category = (category: type_products_Category) => {

        if(activeCategory !== null) {
            if(category.code === activeCategory.code) {
                setProductsListHeight(0);
                setTimeout(() => {
                    setActiveCategory(null);
                }, animationDurationAnimatedHeight);
            }
            if(category.code !== activeCategory.code) {
                setActiveCategory(category);
                setProductsListHeight("auto");
                PubSub.publish('categorySelected', { categoryCode: category.code });
            }
        }

        if(activeCategory === null) {
            setActiveCategory(category);
            setTimeout(() => {
                setProductsListHeight("auto");
            });
            PubSub.publish('categorySelected', { categoryCode: category.code });
        }

    }; // eo function onClick_Category()



    /***************************************************************************
    * onEmit_categorySelected()
    *
    * Desc: 
    * Note: 
    *
    */
    const onEmit_categorySelected = (payload: any, data: any) => {

        let clickedCategoryIsInMyRow: Boolean = false;


        props.categories.forEach(category => {
            if(category.code === data.categoryCode) {
                clickedCategoryIsInMyRow = true;
            }
        });

        if(clickedCategoryIsInMyRow === false) {
            setProductsListHeight(0); 
            setTimeout(() => {
                setActiveCategory(null);
            }, animationDurationAnimatedHeight);
        }

    }; // eo function onEmit_categorySelected()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * getCategoryIconName()
    *
    * Desc: Returns Icon path.
    * Note: 
    *
    */
    const getCategoryIconName = (categoryCode: string) => {

        switch(categoryCode) {
            case 'ALKF':
                return 'ALKF';
            case 'ALKO':
                return 'ALKO';
            case 'HOTG':
                return 'HOTG';
            case 'OTHER':
                return 'OTHERS';
            case 'SAND':
                return 'SAND';
            case 'SNAC':
                return 'SNAC';
            case 'WARM':
                return 'WARM';
            case 'BESTSELLER': 
                return "BEST";
            default:
                return 'OTHERS';
        }

    }; // eo function getCategoryIconName()


    const renderEmptyCategoryDivs = (numberOfEmptyDivs: Number) => {

            let elements: Array<JSX.Element> = [];
            
            
            for(let i = 0; i < numberOfEmptyDivs; i++) {
                elements.push(
                    <div key={`${i}-cat-empty`} style={{ width: 150, height: 120, background: '#FFF', marginRight: 12, marginLeft: 12, marginBottom: 25 }}>
                    </div> 
                )
            }
            
            
            return elements;

    }; // eo function renderEmptyCategoryDivs()


    const getProducts = (category: type_products_Category | null) => {

        let products: Array<type_products_Product_Extended> = [];


        if(category) {
            if(category.code === "BESTSELLER") {
                products = props.bestseller;
            }
            if(category.code !== "BESTSELLER") {
                products = get_ProductsOfCategory(category.id);
            }
        }

        products.sort((a, b) => a.name > b.name ? 1 : -1);


        return products;

    }; // eo function getProducts()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: "space-between", marginTop: 32 }}>
            { props.categories.map((category, index) => (
                <div onClick={() => onClick_Category(category)} key={`${index}-cat`} style={{ display: 'flex', width: 150, height: 120, background: `${activeCategory?.code === category.code ? 'var(--ion-color-primary-tint)' : '#eee'}`, alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', borderRadius: 10, marginRight: 12, marginLeft: 12, marginBottom: 25, padding: 16 }}>
                    <img alt="icon for category" style={{ width: '50%', height: '50%' }} src={`assets/customIcons/flaticon/${getCategoryIconName(category.code)}.svg`} />
                    <p style={{ fontSize: '1.1rem', textAlign: 'center', margin: 0 }}>{ category.name }</p>
                </div> 
            )) }

            { /* Add empty product divs - UI Magic */ }
            { renderEmptyCategoryDivs(props.elementsPerRow - props.categories.length) }

            <AnimateHeight duration={ animationDurationAnimatedHeight } style={{ flexBasis: "100%" }} height={productsListHeight}>
                {
                    getProducts(activeCategory).map((product, index) => (
                        <ProductItem
                            product={product} 
                            key={`product-${activeCategory?.code}-${index}`} />
                    ))
                }
            </AnimateHeight>
        </div>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default CategoryRow;