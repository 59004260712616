/*
* currency.tsx
*
* Description: Global Store for Currency
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import axios from "axios";
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useNetworkStore } from '../shared/network';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBLogs_addLog } from '../../databases/logs';
import { DBCurrency_getAllExchangeRates, DBCurrency_addCurrencies } from "../../databases/currency";
import { hideSpinner, showSpinner, showToastError } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_COMPANY_BASE_CURRENCY } from "../../tsx/config";
import { type_currency_GetExchangeRates_Response, type_shared_Currency, type_shared_Currency_ExchangeRates } from '../../types/currency';
import { type_Schema_DBCurrencies } from '../../types/databases';

// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    currentCurrency: type_shared_Currency.RootObject,
    exchangeRateTimestamp: dayjs.Dayjs,
    exchangeRates: type_shared_Currency_ExchangeRates | null,

    mut_call_getExchangeRates_API: (response: type_currency_GetExchangeRates_Response) => void,
    mut_call_getExchangeRates_DB: (response: Array<type_Schema_DBCurrencies>) => void,

    actn_call_getExchangeRates: () => Promise<void>,
};


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    currentCurrency: CONF_COMPANY_BASE_CURRENCY,
    exchangeRateTimestamp: dayjs(),
    exchangeRates: null
};



//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useCurrencyStore = create<StateType>((set, get) => ({
    ...initalState,



    //-----------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-----------------------------------------------------------------------------/
    /***************************************************************************
    * mut_call_getExchangeRates_API()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getExchangeRates_API: (response) => {

        DBCurrency_addCurrencies(response);

        set({ exchangeRates: response.conversion_rates });
        set({ exchangeRateTimestamp: dayjs() });

    }, // eo function mut_call_getExchangeRates_API()


    /***************************************************************************
    * mut_call_getExchangeRates_DB()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getExchangeRates_DB: (response) => {

        let exchangeRates = {
                EUR: response.find(rate => rate.code === "EUR")!.exchangeRate,
                USD: response.find(rate => rate.code === "USD")!.exchangeRate
            },
            exchangeRateTimestamp = response[0].updateTime;


        set({ exchangeRates: exchangeRates });
        set({ exchangeRateTimestamp: dayjs(exchangeRateTimestamp) });

    }, // eo function mut_call_getExchangeRates_DB()



    //-----------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$
    // #   /$$__  $$            | $$    |__/
    // #  | $$  \ $$  /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-----------------------------------------------------------------------------/
    /*******************************************************************************
    * actn_call_getExchangeRates()
    *
    * Desc: Load the newest exchange rates.
    * Note: Since we have to get the exchange rates from another source we
    * Note: only have 1.500 Calls per month.
    * Note: We only trigger once per day - othwerwise read from DB.
    *
    */
    actn_call_getExchangeRates: async () => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected;

        let readDataFromDB = gl_networkConnected === false,
            exchangeRatesFromDB: Array<type_Schema_DBCurrencies> = [];


        showSpinner();

        // 1.500 Calls Limit - see Note
        exchangeRatesFromDB = await DBCurrency_getAllExchangeRates();
        if (exchangeRatesFromDB.length > 0) {
            if (dayjs().diff(dayjs(exchangeRatesFromDB[0].updateTime), 'hour') < 24) {
                readDataFromDB = true;
            }
        }


        if (readDataFromDB === false) {
            await axios.get('https://v6.exchangerate-api.com/v6/5c25860a4cbaa90a7d76eeee/latest/EUR', { headers: null })
                .then(async (response) => {
                    DBLogs_addLog("Load new Exchange Rates via API");
                    get().mut_call_getExchangeRates_API(response.data);
                })
                .catch((err) => {
                    DBLogs_addLog("Load new Exchange Rates via API failed");
                    readDataFromDB = true;
                    showToastError("Fehler", "FEHLERMELDUNG SCHREIBEN77");
                });
        }

        if(readDataFromDB === true) {
            DBLogs_addLog("Load new Exchange Rates via DB");
            get().mut_call_getExchangeRates_DB(await DBCurrency_getAllExchangeRates());
        }

        PubSub.publish("call_getExchangeRates_Done");
        hideSpinner();

    }, // eo function actn_call_getExchangeRates()
}));