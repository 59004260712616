/*
* email.tsx
*
* Description: Component: E-Mail Template for invoices.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useFlightSelectionStore } from "../stores/flightselection/flights";
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBOrders_getOrder } from "../databases/orders";
import { type_Schema_DBOrders } from "../types/databases";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { renderFixedCurrency } from "./utilities";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$   /$$   /$$     /$$ /$$ /$$   /$$     /$$                    
// #  | $$  | $$  | $$    |__/| $$|__/  | $$    |__/                    
// #  | $$  | $$ /$$$$$$   /$$| $$ /$$ /$$$$$$   /$$  /$$$$$$   /$$$$$$$
// #  | $$  | $$|_  $$_/  | $$| $$| $$|_  $$_/  | $$ /$$__  $$ /$$_____/
// #  | $$  | $$  | $$    | $$| $$| $$  | $$    | $$| $$$$$$$$|  $$$$$$ 
// #  | $$  | $$  | $$ /$$| $$| $$| $$  | $$ /$$| $$| $$_____/ \____  $$
// #  |  $$$$$$/  |  $$$$/| $$| $$| $$  |  $$$$/| $$|  $$$$$$$ /$$$$$$$/
// #   \______/    \___/  |__/|__/|__/   \___/  |__/ \_______/|_______/ 
// #
//-----------------------------------------------------------------------------/ 
/*******************************************************************************
* getHead()
*
* Desc: 
* Note: Just collapse this one. 
*
*/
const getHead = (order: type_Schema_DBOrders) => {


    return `
    <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1" />
        <title>Sundair Rechnung ${order.invoiceId}</title>
        <style>
            img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%;
            }

            body {
                background-color: #f6f6f6;
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
            }

            table {
                border-collapse: separate;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%;
            }

            table td {
                font-family: sans-serif;
                font-size: 14px;
                vertical-align: top;
            }

            div.img-container img {
                width: 20%;
                min-width: 100px;
                display: block;
                margin: 0 auto;
            }

            div.img-container {
                margin-bottom: 20px;
            }

            .body {
                background-color: #f6f6f6;
                width: 100%;
            }

            .container {
                display: block;
                margin: 0 auto !important;
                /* makes it centered */
                max-width: 580px;
                padding: 10px;
                width: 580px;
            }

            .content {
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 580px;
                padding: 10px;
            }

            .main {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
            }

            .wrapper {
                box-sizing: border-box;
                padding: 20px;
            }

            .content-block {
                padding-bottom: 10px;
                padding-top: 10px;
            }

            .footer {
                clear: both;
                margin-top: 10px;
                text-align: center;
                width: 100%;
            }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
                color: #999999;
                font-size: 12px;
                text-align: center;
            }

            h1,
            h2,
            h3,
            h4 {
                color: #000000;
                font-family: sans-serif;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 30px;
            }

            h1 {
                font-size: 35px;
                font-weight: 300;
                text-align: center;
                text-transform: capitalize;
            }

            p,
            ul,
            ol {
                font-family: sans-serif;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px;
            }

            p li,
            ul li,
            ol li {
                list-style-position: inside;
                margin-left: 5px;
            }

            a {
                color: #3498db;
                text-decoration: underline;
            }

            p span {
                font-weight: 600;
            }

            .btn {
                box-sizing: border-box;
                width: 100%;
            }

            .btn>tbody>tr>td {
                padding-bottom: 15px;
            }

            .btn table {
                width: auto;
            }

            .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
            }

            .btn a {
                background-color: #ffffff;
                border: solid 1px #3498db;
                border-radius: 5px;
                box-sizing: border-box;
                color: #3498db;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                padding: 12px 25px;
                text-decoration: none;
                text-transform: capitalize;
            }

            .btn-primary table td {
                background-color: #3498db;
            }

            .btn-primary a {
                background-color: #3498db;
                border-color: #3498db;
                color: #ffffff;
            }

            .last {
                margin-bottom: 0;
            }

            .first {
                margin-top: 0;
            }

            .align-center {
                text-align: center;
            }

            .align-right {
                text-align: right;
            }

            .align-left {
                text-align: left;
            }

            .clear {
                clear: both;
            }

            .mt0 {
                margin-top: 0;
            }

            .mb0 {
                margin-bottom: 0;
            }

            hr {
                border: 0;
                border-bottom: 1px solid #f6f6f6;
                margin: 20px 0;
            }

            @media only screen and (max-width: 620px) {
                table[class=body] h1 {
                    font-size: 28px !important;
                    margin-bottom: 10px !important;
                }

                table[class=body] p,
                table[class=body] ul,
                table[class=body] ol,
                table[class=body] td,
                table[class=body] span,
                table[class=body] a {
                    font-size: 16px !important;
                }

                table[class=body] .wrapper,
                table[class=body] .article {
                    padding: 10px !important;
                }

                table[class=body] .content {
                    padding: 0 !important;
                }

                table[class=body] .container {
                    padding: 0 !important;
                    width: 100% !important;
                }

                table[class=body] .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                }

                table[class=body] .btn table {
                    width: 100% !important;
                }

                table[class=body] .btn a {
                    width: 100% !important;
                }

                table[class=body] .img-responsive {
                    height: auto !important;
                    max-width: 100% !important;
                    width: auto !important;
                }
            }

            @media all {
                .ExternalClass {
                    width: 100%;
                }

                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                    line-height: 100%;
                }

                .apple-link a {
                    color: inherit !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                }

                #MessageViewBody a {
                    color: inherit;
                    text-decoration: none;
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                }

                .btn-primary table td:hover {
                    background-color: #34495e !important;
                }

                .btn-primary a:hover {
                    background-color: #34495e !important;
                    border-color: #34495e !important;
                }
            }
        </style>
    </head>
    `;

}; // eo function getHead()


/*******************************************************************************
* getIntroduction()
*
* Desc: 
* Note:
*
*/
const getIntroduction = (order: type_Schema_DBOrders) => {

    const gl_flightselection_selectedFlight = useFlightSelectionStore.getState().selectedFlight;
    

    return `
        <p>Sehr geehrte Damen und Herren,</p>
        <p>
            anbei erhalten Sie die Rechnung <span>${order.invoiceId}</span> vom
            Flug <span>${order.aptFrom}</span> 
            nach <span>${order.aptTo}</span>
            am <span>${dayjs(gl_flightselection_selectedFlight?.aptFromDepartureTime).format("DD.MM.YYYY")}</span>.
        </p>
        <p>
            Rechnungsnummer: <span>${order.invoiceId}</span><br>
            Rechnungsdatum: <span>${dayjs().format("DD.MM.YYYY")}</span><br>
            Zahlungsart: <span>${order.paymentMethod === "CC" ? 'Kreditkarte' : 'Bar'}</span><br>
        </p>
    `;

}; // eo function getIntroduction()


/*******************************************************************************
* getTableHeader()
*
* Desc: 
* Note:
*
*/
const getTableHeader = () => {

    return `
        <tr>
            <td>
                <p>
                    Anzahl
                </p>
            </td>
            <td>
                <p>
                    Name
                </p>
            </td>
            <td>
                <p>
                    Preis
                </p>
            </td>
            <td>
                <p>
                    Gesamt
                </p>
            </td>
        </tr>
    `;

}; // eo function getTableHeader()


/*******************************************************************************
* getOrderItemsTable()
*
* Desc: 
* Note:
*
*/
const getOrderItemsTable = (order: type_Schema_DBOrders) => {

    let ret = '';


    order.entriesForPresentation.forEach(item => {
        ret += `
            <tr>
                <td>
                    <p>
                        ${item.counter}
                    </p>
                </td>
                <td>
                    <p>
                        ${item.name}
                    </p>
                </td>
                <td>
                    <p>
                        ${order.currency === "EUR" ? renderFixedCurrency(item.priceEUR, "EUR", { currencyFormat: "code" }) : ""}
                        ${order.currency === "USD" ? renderFixedCurrency(item.priceUSD, "USD", { currencyFormat: "code" }) : ""}
                    </p>
                </td>
                <td>
                    <p>
                        ${order.currency === "EUR" ? (renderFixedCurrency(item.priceEUR * item.counter, "EUR", { currencyFormat: "code" })) : ""}
                        ${order.currency === "USD" ? (renderFixedCurrency(item.priceUSD * item.counter, "USD", { currencyFormat: "code" })) : ""}
                    </p>
                </td>
            </tr>
        `;
    });


    return ret;

}; // eo function getOrderItemsTable()


/*******************************************************************************
* getOrderTotalTable()
*
* Desc: 
* Note:
*
*/
const getOrderTotalTable = (order: type_Schema_DBOrders) => {    

    return `
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <p>
                    ${renderFixedCurrency(order.totalInOrderCurrency, order.currency, { currencyFormat: "code" })}
                </p>
            </td>
        </tr>
    `;

}; // eo function getOrderTotalTable()


/*******************************************************************************
* getTaxesTable()
*
* Desc: 
* Note:
*
*/
const getTaxesTable = (order: type_Schema_DBOrders) => {

    let orderVat: number | string = '';


    if(order.entriesForPresentation.length > 0) {
        orderVat = order.entriesForPresentation[0].vat;
    }
    
    return `
        <p>
            Netto: ${renderFixedCurrency(order.totalInOrderCurrency - order.vatInOrderCurrency, order.currency, { currencyFormat: "code" })}<br>
            Steuer (${orderVat}%): ${renderFixedCurrency(order.vatInOrderCurrency, order.currency, { currencyFormat: "code" })}<br>
            Gesamtsumme: <span>${renderFixedCurrency(order.totalInOrderCurrency, order.currency, { currencyFormat: "code" })}</span>
        </p>
    `;

}; // eo function getTaxesTable()


/*******************************************************************************
* getClosureText()
*
* Desc: 
* Note:
*
*/
const getClosureText = () => {

    return `
        <p>
            Sollten Sie mit Kreditkarte bezahlt haben, wird der Rechnungsbetrag 
            von der Air41 GmbH eingezogen.
        </p>
        <p>
            Mit freundlichen Gr&uuml;&szlig;en<br>
            Sundair GmbH
        </p>
    `;

}; // eo function getClosureText() 


/*******************************************************************************
* getImprint()
*
* Desc: 
* Note:
*
*/
const getImprint = () => {

    return `
        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
            <tr>
                <td class="content-block">
                    <span class="apple-link">
                        SUNDAIR GmbH<br>
                        Knieperdamm 79<br>
                        18435 Stralsund (Verwaltungssitz)<br>
                        Amtsgericht Stralsund, HRB 20267<br>
                        USt-ID (VAT-No.) DE305307186<br><br>
                    </span>
                </td>
            </tr>
        </table>
    `;

}; // eo function getImprint() 



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$                               /$$                    
// #  |_  $$_/                              |__/                    
// #    | $$   /$$$$$$$  /$$    /$$ /$$$$$$  /$$  /$$$$$$$  /$$$$$$ 
// #    | $$  | $$__  $$|  $$  /$$//$$__  $$| $$ /$$_____/ /$$__  $$
// #    | $$  | $$  \ $$ \  $$/$$/| $$  \ $$| $$| $$      | $$$$$$$$
// #    | $$  | $$  | $$  \  $$$/ | $$  | $$| $$| $$      | $$_____/
// #   /$$$$$$| $$  | $$   \  $/  |  $$$$$$/| $$|  $$$$$$$|  $$$$$$$
// #  |______/|__/  |__/    \_/    \______/ |__/ \_______/ \_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* createEMailInvoice()
*
* Desc: 
* Note: 
*
*/
export const createEMailInvoice = async (invoiceId: string) => {

    let order = await DBOrders_getOrder(invoiceId);


    if(order) {
        return `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                <html xmlns="http://www.w3.org/1999/xhtml">
                    
                    ${ getHead(order) }

                    <body>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                            <tr>
                                <td>&nbsp;</td>
                                <td class="container">
                                    <div class="content">
                                        <table role="presentation" class="main">
                                            <tr>
                                                <td class="wrapper">
                                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                        <div class='img-container'>
                                                            <img src='https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo-SundAir.png' alt='Logo Sundair' />
                                                        </div>
                                                        <tr>
                                                            <td>

                                                                ${ getIntroduction(order) }

                                                                <table>
                                                                    
                                                                    ${ getTableHeader() }

                                                                    ${ getOrderItemsTable(order) }

                                                                    ${ getOrderTotalTable(order) }

                                                                </table>
                                                                
                                                                ${ getTaxesTable(order) }

                                                        ${ getClosureText() }

                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>            
                                <div class="footer">
                                    ${ getImprint() }
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </table>
            </body>
        </html>`.replace(/(?:\r\n|\r|\n)/g, '').replace(/\s\s+/g, ' ');;
    } else {
        return "";
    }

}; // eo function createEMailInvoice()
