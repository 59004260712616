/*
* Keyboard.tsx
*
* Description: Component: Keyboard.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonButton } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from 'styled-components';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    onClick: (value: string) => void;
}
const Keyboard: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_keyPressed()
    *
    * Desc: Triggers the callback onClick and delivers the value of the pressed key.
    * Note: 
    *
    */
    const onClick_keyPressed = (pressedKeyValue: string) => {

        props.onClick(pressedKeyValue);

    }; // eo function onClick_keyPressed()



    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <StyledKeyboardContainer>
            <IonRow>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("1")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            1
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("2")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            2
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("3")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            3
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
            </IonRow>
            <IonRow>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("4")}>
                <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            4
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("5")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            5
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("6")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            6
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
            </IonRow>
            <IonRow>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("7")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            7
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("8")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            8
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("9")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            9
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
            </IonRow>
            <IonRow>
                <StyledKeyboardKey style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => onClick_keyPressed("EXACT")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-normal">
                            { lang.components.Proceed.Keyboard.Labels.Exact[gl_shared_language] }
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey onClick={() => onClick_keyPressed("0")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <p className="ion-text-center sdr-text-large">
                            0
                        </p>
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
                <StyledKeyboardKey style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => onClick_keyPressed("DEL")}>
                    <StyledKeyboardKeyButton mode="ios" expand="full" fill="clear">
                        <IonIcon slot="icon-only" style={{ fontSize: "1.5rem", color: "#666" }} src="assets/customIcons/icon54/Backspace-2.svg" />
                    </StyledKeyboardKeyButton>
                </StyledKeyboardKey>
            </IonRow>
        </StyledKeyboardContainer>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledKeyboardContainer
* 
*/
const StyledKeyboardContainer = styled(IonGrid)`
    background: var(--ion-color-light);
    padding-bottom: env(safe-area-inset-bottom);
`;

/****************************************************************************** 
* StyledKeyboardKey
* 
*/
const StyledKeyboardKey = styled(IonCol)`
    background: var(--ion-color-primary-contrast);
    margin: 5px;
    padding: 0;
    border-radius: 5px;
    color: black !important;
`;

/****************************************************************************** 
* StyledKeyboardKeyButton
* 
*/
const StyledKeyboardKeyButton = styled(IonButton)`
    --background-activated: var(--ion-color-medium);
    margin: 0;

    &::part(native) {
        border-radius: 5px;
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Keyboard;
