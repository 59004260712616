/*
* Menu.tsx
*
* Description: Main Menu of Catering App
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
import React from 'react';
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonListHeader,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';

import { useLoginStore } from '../../stores/login/login';
import { useSettingsLanguageStore } from '../../stores/shared/settings';

import './Menu.css';
import { LanguageType } from '../../tsx/types';
import { CONF_VERSION_NUMBER, CONF_REVISIONS_NUMBER, CONF_ENVIRONMENT, CONF_COMPANY_APP_NAME } from '../../tsx/config';
import lang from "../../tsx/language.json";


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const Menu: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    const gl_loggedIn = useLoginStore(state => state.loggedIn);
    const gl_username = useLoginStore(state => state.username);
    const gl_login_isAdmin = useLoginStore(state => state.isAdmin);
    const gl_lang: LanguageType = useSettingsLanguageStore(state => state.language);


    /*******************************************************************************
    * onClick_navigateToURL()
    *
    * Desc: Menu navigation to the given url.
    * 
    * @param {string} url (url to navigate to)
    *
    */
    const onClick_navigateToURL = (url: string, type?: "replace") => {
        if(type === "replace"){
            history.push(url);
        }else{
            history.push(url);
        }
    }


    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        gl_loggedIn === true ?
            <IonMenu swipeGesture={false} side="start" contentId="main" type="overlay">
                <IonContent>
                    <IonList id="inbox-list">
                        <img alt="Company Logo" style={{width: "58px", margin: "0 auto", display: "block" }} src="./assets/img/logo_58x58.png" />
                        <IonListHeader>{CONF_COMPANY_APP_NAME}</IonListHeader>
                        <IonNote>{gl_username}</IonNote>

                        {/***************************************************************************
                         * Flight Overview
                         *
                         */}
                        <IonMenuToggle autoHide={false}>
                            <IonItem 
                                className={location.pathname === '/flightselection' ? 'selected' : ''} 
                                onClick={() => onClick_navigateToURL('/flightselection', "replace")} 
                                routerDirection="none" 
                                lines="none" 
                                detail={false}>

                                <IonIcon slot="start" src={`assets/customIcons/icon54/Airplane.svg`} />
                                <IonLabel>{lang.menus.Menu.Labels.FlightOverview[gl_lang]}</IonLabel>

                            </IonItem>
                        </IonMenuToggle>

                        {/***************************************************************************
                         * Sales
                         *
                         */}
                        <IonMenuToggle autoHide={false}>
                            <IonItem 
                                className={location.pathname === '/sales' ? 'selected' : ''} 
                                onClick={() => onClick_navigateToURL('/sales', "replace")} 
                                routerDirection="none" 
                                lines="none" 
                                detail={false}>

                                <IonIcon slot="start" src={`assets/customIcons/icon54/Bill2.svg`} />
                                <IonLabel>{lang.menus.Menu.Labels.Sales[gl_lang]}</IonLabel>

                            </IonItem>
                        </IonMenuToggle>

                        {/***************************************************************************
                         * Reminders
                         *
                         */}
                        {history.location.pathname.includes("purchase") === true ? 
                            <IonMenuToggle autoHide={false}>
                                <IonItem 
                                    style={{ borderTop: "1px solid var(--ion-color-light)" }}
                                    className={location.pathname === '/purchase/reminders' ? 'selected' : ''} 
                                    onClick={() => onClick_navigateToURL('/purchase/reminders')} 
                                    routerDirection="none" 
                                    lines="none" 
                                    detail={false}>

                                    <IonIcon slot="start" src={`assets/customIcons/icon54/Bell.1.svg`} />
                                    <IonLabel>{lang.menus.Menu.Labels.Reminders[gl_lang]}</IonLabel>

                                </IonItem>
                            </IonMenuToggle> : null}

                        {/***************************************************************************
                         * Transactions
                         *
                         */}
                        {history.location.pathname.includes("purchase") === true ? 
                            <IonMenuToggle autoHide={false}>
                                <IonItem 
                                    className={location.pathname === '/purchase/transactions' ? 'selected' : ''} 
                                    onClick={() => onClick_navigateToURL('/purchase/transactions')} 
                                    routerDirection="none" 
                                    lines="none" 
                                    detail={false}>

                                    <IonIcon slot="start" src={`assets/customIcons/icon54/Bill2.svg`} />
                                    <IonLabel>{lang.menus.Menu.Labels.TransactionsOverview[gl_lang]}</IonLabel>

                                </IonItem>
                            </IonMenuToggle> : null}

                        {/***************************************************************************
                         * Settings
                         *
                         */}
                        <IonMenuToggle autoHide={false}>
                            <IonItem 
                                style={{ borderTop: "1px solid var(--ion-color-light)" }}
                                className={location.pathname === '/settings' ? 'selected' : ''} 
                                onClick={() => onClick_navigateToURL('/settings')} 
                                routerDirection="none" 
                                lines="none" 
                                detail={false}>

                                <IonIcon slot="start" src={`assets/customIcons/icon54/Gear-2.svg`} />
                                <IonLabel>{lang.menus.Menu.Labels.Settings[gl_lang]}</IonLabel>

                            </IonItem>
                        </IonMenuToggle>

                        {/***************************************************************************
                         * Admin Panel
                         *
                         */}
                        {gl_login_isAdmin === true ? 
                            <IonMenuToggle autoHide={false}>
                                <IonItem 
                                    className={location.pathname === '/adminpanel' ? 'selected' : ''} 
                                    onClick={() => onClick_navigateToURL('/adminpanel', "replace")} 
                                    routerDirection="none" 
                                    lines="none" 
                                    detail={false}>

                                    <IonIcon slot="start" src={`assets/customIcons/icon54/Add User.1.svg`} />
                                    <IonLabel>{lang.menus.Menu.Labels.AdminPanel[gl_lang]}</IonLabel>

                                </IonItem>
                            </IonMenuToggle> : null}

                        {/***************************************************************************
                         * Logout
                         *
                         */}
                        <IonMenuToggle autoHide={false}>
                            <IonItem className="sdr-text-error" onClick={() => console.log("abmelden")} routerDirection="none" lines="none" detail={false}>
                                <IonIcon color="danger" slot="start" src={`assets/customIcons/icon54/Logout.1.svg`} />
                                <IonLabel>{lang.menus.Menu.Labels.Logout[gl_lang]}</IonLabel>
                            </IonItem>
                        </IonMenuToggle>

                        <p className="ion-text-center sdr-text-small">
                            {lang.components.Menu.Version[gl_lang]}  {CONF_VERSION_NUMBER} ({CONF_REVISIONS_NUMBER}) {CONF_ENVIRONMENT === "TEST" ? "TEST" : ""}
                        </p>
                    </IonList>
                </IonContent>
            </IonMenu> : null
    );
};


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Menu;
