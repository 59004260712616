/*
* App.tsx
*
* Description: App Start with Routing.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
import FlightDashboard from "./pages/FlightDashboard";
import UserDashboard from "./pages/UserDashboard";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import FAQ from "./pages/FAQ";
import Purchase from "./pages/Purchase/Purchase";
import PaymentCS from "./pages/Proceed/PaymentCS";
import CompleteOrder from "./pages/CompleteOrder";

import React, { useRef } from 'react';
import { useEffect } from "react";
import { IonApp, IonRouterOutlet, IonLoading, IonSplitPane, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import OfflineModeBar from "./components/shared/OfflineModeBar";

import { useNetworkStore } from "./stores/shared/network";
import { useSpinnerStore } from "./stores/shared/spinner";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./theme/global.css";
import "./theme/flex.css";
import "./theme/spacing.css";

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';

import { useLoginStore } from "./stores/login/login";
import { useDeviceIdentifierStore } from "./stores/shared/deviceIdentifier";
import axios from "axios";
import Menu from "./components/shared/Menu";
import OrderMenu from "./components/shared/OrderMenu/OrderMenu";
import { CONF_API_AUTHORIZATION, CONF_API_DEFAULT_HEADERS, CONF_BASE_URL } from "./tsx/config";
import { useSettingsLanguageStore } from "./stores/shared/settings";
import { getLocalStorageLanguage, setLocalStorageLanguage } from "./tsx/utilities";

setupConfig({
    swipeBackEnabled: false,
    hardwareBackButton: false
});

const Application: React.FC = () => {
    const actn_setNetworkConnected = useNetworkStore((state) => state.actn_setNetworkConnected);
    const actn_setDeviceIdentifier = useDeviceIdentifierStore(state => state.actn_setDeviceIdentifier);
    const actn_shared_setLanguage = useSettingsLanguageStore(state => state.actn_setLanguage);

    const gl_showSpinner = useSpinnerStore((state) => state.showSpinner);
    const gl_loggedIn = useLoginStore(state => state.loggedIn);


    const routerRef = useRef<IonReactRouter>(null);


    /*******************************************************************************
    * useEffect()
    *
    * Desc: Listen to the network. Save to the store the current connection (yes or no).
    * Note: This triggeres every time the network status changes.
    *
    */
    useEffect(() => {

        Network.addListener('networkStatusChange', async (status) => {

            let isConnectedAndServerIsAvailable = true;

            if (status.connected === false) {
                isConnectedAndServerIsAvailable = false;
            }

            actn_setNetworkConnected(isConnectedAndServerIsAvailable);

        });

    }, []);


    /***************************************************************************
    * useEffect()
    *
    * Desc: Read/write a 6-random-digit from/to a file.
    * Note: On fist start: create this file, after that just read the file.
    *
    */
    // useEffect(() => {

    //     const createUniqueID = (length: number) => {

    //         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    //               charactersLength = 6;
    //         let id = "";


    //         for ( var i = 0; i < length; i++ ) {
    //             id += characters.charAt(Math.floor(Math.random() * charactersLength));
    //         }


    //         return id;

    //     };


    //     let uniqueID = createUniqueID(6);


    //     Device.getInfo()
    //         .then(result => {
    //             console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    //             console.log(JSON.stringify(result));
    //             console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    //         });


    //     Filesystem.readFile({ path: "identifier.sr", directory: Directory.Documents })
    //         .then((result) => {
    //             uniqueID = result.data;
    //         })
    //         .catch((err) => {

    //             Filesystem.writeFile({ path: "identifier.sr", data: uniqueID, directory: Directory.Documents })
    //                 .catch(err => {
    //                     // ERROR!!
    //                     uniqueID = "XXXXXX";
    //                 });

    //         })
    //         .finally(() => {
    //             actn_setDeviceIdentifier(uniqueID);
    //         });

    // }, []);


    /*******************************************************************************
    * useEffect()
    *
    * Desc: Listen to the network on app mounting. Save to the store the current
    * Desc: connection (yes or no).
    * Note: This is done once, only on the app start.
    *
    */
    useEffect(() => {
        Network.getStatus().then((status) => {
            if (status.connected === false) {
                actn_setNetworkConnected(false);
            } else {
                actn_setNetworkConnected(true);
            }
        })
    }, []);


    /*******************************************************************************
    * useEffect()
    *
    * Desc: Listen to the network on app mounting. Save to the store the current
    * Desc: uuid of the device.
    * Note: This is done once, only on the app start.
    * Note: The uuid is used for identifing the tablet later and to create
    * Note: custom invoice numbers.
    * Note: The last 6 digits of the uuid are the same as the last 6 digits of
    * Note: the MAC address of this device.
    *
    */
    useEffect(() => {

        Device.getId().then((deviceID) => {

            let deviceIdentifier = "";


            if (deviceID && deviceID.uuid.length > 0) {
                //get the last 6 digits of the uuid
                deviceIdentifier = deviceID.uuid.slice(-6).toUpperCase();
            }

            actn_setDeviceIdentifier(deviceIdentifier);
        });

    }, []); // eo function useEffect()


    /*******************************************************************************
    * useEffect()
    *
    * Desc: On App start set the default header of axios.
    * Desc: Set the current logged in users authorization and langCode in
    * Desc: Header in the request interceptor.
    * Note: If a user is logged in, use the authorization from thsi user.
    * Note: Is no user is logged in, use the CONF authorization.
    *
    */
    useEffect(() => {

        let authHeaderSet = false;


        axios.defaults.baseURL = CONF_BASE_URL;

        axios.defaults.headers.Accept = CONF_API_DEFAULT_HEADERS.Accept;
        axios.defaults.headers.apiKey = CONF_API_DEFAULT_HEADERS.apiKey;

        axios.interceptors.request.use((config) => {
            if(config.url) {
                // >>> EXTERNAL
                // Exchange-Rate Call: complete Call "out" of our environment
                if(config.url.includes("https://v6.exchangerate-api.com/")) {
                    return config;
                }
                // <<< EXTERNAL

                // >>> INTERNAL
                config.headers.langCode = useSettingsLanguageStore.getState().language === "de-DE" ? "DE" : "EN";

                // Category-Call is "Catering-App-Owned" - so we need another
                // Auth-Object
                if(config.url.includes("/acm/acl/login")) {
                    // Auth Header comes via the zustand action - we do not
                    // to do anything
                    authHeaderSet = true;
                }

                // Category-Call & Process-Call are "Catering-App-Owned" - so we need another
                // Auth-Object
                if(config.url.includes("/shop/category") || config.url.includes("/shop/processinvoice")) {
                    config.headers.Authorization = "Basic " + CONF_API_AUTHORIZATION;
                    authHeaderSet = true;
                }

                // All other Calls - use user Auth-Object
                if(authHeaderSet === false) {
                    config.headers.Authorization = "Basic " + useLoginStore.getState().authorization;
                }
                // <<< INTERNAL
            }


            return config;

        });


        getLocalStorageLanguage().then(async (languageCode: "de-DE" | "en-EN" | null) => {
            //if langauge Code is not set in local Storage
            if (!languageCode) {
                let deviceLanguageCode = await Device.getLanguageCode();

                if (deviceLanguageCode.value === "de-DE" || deviceLanguageCode.value === "de") {
                    setLocalStorageLanguage("de-DE");
                    actn_shared_setLanguage("de-DE");
                } else if (deviceLanguageCode.value === "en-EN" || deviceLanguageCode.value === "en") {
                    setLocalStorageLanguage("en-EN");
                    actn_shared_setLanguage("en-EN");
                }
            } else {
                if (languageCode === "de-DE" || languageCode === "en-EN") {
                    actn_shared_setLanguage(languageCode);
                }
            }
        });

    }); // eo function useEffect()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonApp>
            <OfflineModeBar />
            <IonReactRouter ref={routerRef}>
                <IonSplitPane /* style={{ height: `${ 100 - footerHeight }vh` }} */ contentId="main">
                    <Menu />
                    <OrderMenu />
                    <IonRouterOutlet id="main">
                        <Route render={() => <Login />} path="/login" exact />
                        <Route render={() => <UserDashboard />} path="/userdashboard" exact />
                        <Route render={() => <FlightDashboard />} path="/flightdashboard" exact />
                        <Route path="/purchase" exact>
                            { gl_loggedIn === true ? <Purchase /> : <Redirect to="/login" /> }
                        </Route>
                        <Route path="/proceed/payment/cashdesk" exact>
                            <PaymentCS />
                        </Route>
                        <Route path="/settings" exact>
                            <Settings />
                        </Route>
                        <Route path="/faq" exact>
                            <FAQ />
                        </Route>
                        <Route path="/complete" exact>
                            <CompleteOrder />
                        </Route>
                        <Redirect from="/" to="login" exact />
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                transition={Slide}
                closeButton={false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                toastClassName="renewed-toast-toast"
                pauseOnHover={false} />

            <IonLoading
                isOpen={gl_showSpinner} />
        </IonApp>
    );
};

export default Application;
