/*
* FAQ.tsx
*
* Description: FAQ Section with tutorial videos
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import VideoAccordion from "../components/FAQ/VideoAccordion";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const FAQ: React.FC = () => {

    const [activeVideoID, setActiveVideoID] = useState<String | null>(null);


    const faqs = [
        {
            title: "How to add a flight manually?",
            id: "A",
            content: [
                {
                    headline: "How to add a flight manually?",
                    text: `First, hit the plus button in the flight overview.<br>
                    Next, inset the desired data.<br>
                    Lastly tap “add flight”.`
                }
            ],
            videoURL: "/assets/videos/A_HowToAddAFlight.mp4",
            posterURL: "/assets/img/A_Poster.jpg"
        },
        {
            title: "How to add a crew member to a flight?",
            id: "B",
            content: [
                {
                    headline: "How to add a crew member to a flight?",
                    text: `In the flight overview tap the pen to edit the flight.<br>
                    Enter the three-letter code of the crew member,<br> 
                    select “add flight” and confirm.`
                }
            ],
            videoURL: "/assets/videos/B_AddCrewMember.mp4",
            posterURL: "/assets/img/B_Poster.png"
        },
        {
            title: "How to edit a flight?",
            id: "C",
            content: [
                {
                    headline: "How to edit a flight?",
                    text: `In the flight overview tap the pen to edit the flight.<br>
                    You can then change the flight details.<br>
                    To save your changes tap “add flight” and confirm.`
                }
            ],
            videoURL: "/assets/videos/C_EditFlight.mp4",
            posterURL: "/assets/img/C_Poster.png"
        },
        {
            title: "How to start the catering for a flight?",
            id: "D",
            content: [
                {
                    headline: "How to start the catering for a flight?",
                    text: `First enter your username and password and select “login”.<br>
                    Select the flight you want to start the catering for and tap “start catering”.`
                }
            ],
            videoURL: "/assets/videos/D_StartCatering.mp4",
            posterURL: "/assets/img/D_Poster.png"
        },
        {
            title: "How to add products to the shopping cart?",
            id: "E",
            content: [
                {
                    headline: "How to add products to the shopping cart?",
                    text: `In the started catering tap on the products to add them to the shopping cart.<br>
                    The minus button on the left removes the drinks and beverages from the order.`
                }
            ],
            videoURL: "/assets/videos/E_AddProducts.mp4",
            posterURL: "/assets/img/E_Poster.png"
        },
        {
            title: "How to change the currency for payment?",
            id: "F",
            content: [
                {
                    headline: "How to change the currency for payment?",
                    text: `In the upper right corner select the currency symbol.<br>
                    Here you can choose from a list of currencies.`
                }
            ],
            videoURL: "/assets/videos/F_ChangeCurrency.mp4",
            posterURL: "/assets/img/F_Poster.png"
        },
        {
            title: "How to complete an order?",
            id: "G",
            content: [
                {
                    headline: "How to complete an order?",
                    text: `Tap “proceed to payment” and enter the received amount.<br>
                    Tap “finish order” to complete the order.`
                }
            ],
            videoURL: "/assets/videos/G_FinishOrder.mp4",
            posterURL: "/assets/img/G_Poster.png"
        },
        {
            title: "How to create an invoice?",
            id: "H",
            content: [
                {
                    headline: "How to create an invoice?",
                    text: `After the payment select “print invoice”.<br>
                    Alternatively, the customer can receive an invoice by mail.<br>
                    Enter the email address and hit “save”.`
                }
            ],
            videoURL: "/assets/videos/H_CreateInvoice.mp4",
            posterURL: "/assets/img/H_Poster.png"
        },
        {
            title: "How to see an overview of the products ordered?",
            id: "I",
            content: [
                {
                    headline: "How to see an overview of the products ordered?",
                    text: `Select the receipt button in the lower right corner.<br>
                    Alternatively, tap the “order” button in the payment screen.`
                }
            ],
            videoURL: "/assets/videos/I_Overview.mp4",
            posterURL: "/assets/img/I_Poster.png"
        },
        {
            title: "How to cancel an order?",
            id: "J",
            content: [
                {
                    headline: "How to cancel an order?",
                    text: `To cancel an order hit “finish” in the catering screen.<br>
                    Tap the order you want to cancel.<br>
                    Select “cancel order” and confirm with your account.`
                }
            ],
            videoURL: "/assets/videos/J_CancelOrder.mp4",
            posterURL: "/assets/img/J_Poster.png"
        },
        {
            title: "How to create a sales report?",
            id: "K",
            content: [
                {
                    headline: "How to create a sales report?",
                    text: `From the catering screen select “finish”.<br>
                    Tap on “show sales report”.<br>
                    Tap “print sales report” and make sure that the printer is switched on.`
                }
            ],
            videoURL: "/assets/videos/K_CreateSalesReport.mp4",
            posterURL: "/assets/img/K_Poster.png"
        },
        {
            title: "How to submit orders after a flight?",
            id: "L",
            content: [
                {
                    headline: "How to submit orders after a flight?",
                    text: `In the start screen tap “submit orders”.<br>
                    Confirm with “yes” to send the orders.`
                }
            ],
            videoURL: "/assets/videos/L_SubmitOrders.mp4",
            posterURL: "/assets/img/L_Poster.png"
        }
    ];



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Help Center</IonTitle> {/** TODO: */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">Help Center</IonTitle> {/** TODO: */}
                    </IonToolbar>
                </IonHeader>

                <div>
                    {
                        faqs.map(faq => (
                            <VideoAccordion
                                key={`faq-video-${faq.id}`}
                                isOpen={activeVideoID === faq.id}
                                title={faq.title}
                                content={faq.content}
                                videoURL={faq.videoURL}
                                posterURL={faq.posterURL}
                                videoID={faq.id}
                                onToggle={id => setActiveVideoID(id)} />
                        ))
                    }
                </div>

            </IonContent>
        </IonPage>
    );
};


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FAQ;
