/*
* ValueSuggestionBar.tsx
*
* Description: Component: ValueSuggestionBar.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonRow, IonGrid, IonCol, IonChip, useIonViewDidEnter, IonSpinner } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from "../../../../stores/currency/currency";
import { useCurrentOrderStore } from '../../../../stores/shared/currentOrder';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, renderFixedCurrency } from '../../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_AVAILABLE_MONEY_VALUES } from "../../../../tsx/config";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    onClick: (value: number) => void;
}
const ValueSuggestionBar: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [paymentSuggestions, setPaymentSuggestions] = useState<Array<number>>([0, 0, 0]);
    const [calculatingSuggestions, setCalculatingSuggestions] = useState<Boolean>(true);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_currentOrder_totalSum = useCurrentOrderStore(state => state.get_totalSum());
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useIonViewDidEnter()
    *
    * Desc: Calculate the payment value suggestions on view enter. 
    *
    */
    useIonViewDidEnter(() => {

        let possibleValueWithSmallestMoneyValue = calculateValueSuggestions_withSmallesMoneyValue(),
            possibleValueWithoutSmallestMoneyValue = calculateValueSuggestions_withoutSmallesMoneyValue(possibleValueWithSmallestMoneyValue),
            possibleValueNextBiggerMoneyValue = calculateValueSuggestions_nextBiggerMoneyValue(possibleValueWithSmallestMoneyValue, possibleValueWithoutSmallestMoneyValue);


        setPaymentSuggestions([possibleValueWithSmallestMoneyValue, possibleValueWithoutSmallestMoneyValue, possibleValueNextBiggerMoneyValue]);
        setCalculatingSuggestions(false);

    }); // eo function useIonViewDidEnter()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_valueClicked()
    *
    * Desc: 
    * Note:
    *
    */
    const onClick_valueClicked = (value: number) => {

        props.onClick(value);

    }; // eo function onClick_valueClicked()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /*******************************************************************************
    * calculateValueSuggestions_nextBiggerMoneyValue()
    *
    * Desc: Calculate the payment value suggestions.
    * Note: Select the next bigger money value to pay the order total sum.
    *
    */
    const calculateValueSuggestions_nextBiggerMoneyValue = (possibleValueWithSmallestMoneyValue: number, possibleValueWithoutSmallestMoneyValue: number) => {
        
        let orderSum = gl_currentOrder_totalSum,
            possibleValueNextBiggerMoneyValue = 0,
            moneyValues = CONF_AVAILABLE_MONEY_VALUES[gl_currency_currentCurrency.code].filter(value => (value !== possibleValueWithSmallestMoneyValue && value !== possibleValueWithoutSmallestMoneyValue));


        debugWriter("calculateValueSuggestions_nextBiggerMoneyValue");
        while(orderSum > 0){
            let billFound = false;

            for(let i = 0; i < moneyValues.length; i++) {
                if(moneyValues[i] > orderSum) {
                    billFound = true;

                    orderSum -= moneyValues[i];
                    possibleValueNextBiggerMoneyValue += moneyValues[i];

                    break;
                }
            }

            if(billFound === false) {
                //decrease the total sum with the highest possible bill
                orderSum -= moneyValues[moneyValues.length - 1];
                possibleValueWithoutSmallestMoneyValue += moneyValues[moneyValues.length - 1];
            }
        }


        return possibleValueNextBiggerMoneyValue;

    }; // eo function calculateValueSuggestions_nextBiggerMoneyValue()


    /*******************************************************************************
    * calculateValueSuggestions_withoutSmallesMoneyValue()
    *
    * Desc: Calculate the payment value suggestions.
    * Note: Calculate the next bigger possible value by combination of bank notes.
    * Note: Without the smallest possible money value.
    *
    */
    const calculateValueSuggestions_withoutSmallesMoneyValue = (possibleValueWithSmallestMoneyValue: number) => {

        let orderSum = gl_currentOrder_totalSum,
            possibleValueWithoutSmallestMoneyValue = 0,
            moneyValues = CONF_AVAILABLE_MONEY_VALUES[gl_currency_currentCurrency.code];

            
        debugWriter("calculateValueSuggestions_withoutSmallesMoneyValue");
        while(orderSum > 0){
            let billFound = false;

            for(let i = 1; i < moneyValues.length; i++){
                if(moneyValues[i] > orderSum) {
                    billFound = true;

                    if(i === 1){
                        if(possibleValueWithoutSmallestMoneyValue + moneyValues[i] !== possibleValueWithSmallestMoneyValue) {
                            orderSum -= moneyValues[i];
                            possibleValueWithoutSmallestMoneyValue += moneyValues[i];

                            break;
                        }
                    }else{
                        if(possibleValueWithoutSmallestMoneyValue + moneyValues[i - 1] !== possibleValueWithSmallestMoneyValue) {
                            orderSum -= moneyValues[i - 1];
                            possibleValueWithoutSmallestMoneyValue += moneyValues[i - 1];

                            break;
                        }
                    }
                }
            }

            if(billFound === false) {
                //decrease the total sum with the highest possible bill
                orderSum -= moneyValues[moneyValues.length - 1];
                possibleValueWithoutSmallestMoneyValue += moneyValues[moneyValues.length - 1];
            }
        }


        return possibleValueWithoutSmallestMoneyValue;
        
    }; // eo function calculateValueSuggestions_withoutSmallesMoneyValue()


    /*******************************************************************************
    * calculateValueSuggestions_withSmallesMoneyValue()
    *
    * Desc: Calculate the payment value suggestions.
    * Note: Calculate the next bigger possible value by combination of bank notes.
    *
    */
    const calculateValueSuggestions_withSmallesMoneyValue = () => {

        let orderSum = gl_currentOrder_totalSum,
            possibleValueWithSmallestMoneyValue = 0,
            moneyValues = CONF_AVAILABLE_MONEY_VALUES[gl_currency_currentCurrency.code];


        debugWriter("calculateValueSuggestions_withSmallesMoneyValue");
        while(orderSum > 0){
            let billFound = false;

            for(let i = 0; i < moneyValues.length; i++){
                if(moneyValues[i] > orderSum) {
                    billFound = true;

                    if(i === 0){
                        orderSum -= moneyValues[i];
                        possibleValueWithSmallestMoneyValue += moneyValues[i];
                    }else{
                        orderSum -= moneyValues[i - 1];
                        possibleValueWithSmallestMoneyValue += moneyValues[i - 1];
                    }
                    
                    break;
                }
            }

            if(billFound === false) {
                // decrease the total sum with the highest possible bill
                orderSum -= moneyValues[moneyValues.length - 1];
                possibleValueWithSmallestMoneyValue += moneyValues[moneyValues.length - 1];
            }
        }
        

        return possibleValueWithSmallestMoneyValue;

    }; // eo function calculateValueSuggestions_withSmallesMoneyValue()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div style={{ background: "var(--ion-color-light)", paddingTop: "0.5rem" }}>
            <IonGrid>
                <IonRow>
                        <IonCol>
                            <IonChip onClick={() => onClick_valueClicked(paymentSuggestions[0])} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                { paymentSuggestions[0] !== 0 ? 
                                    <p className="sdr-text-biggernormal ion-text-center">
                                        {renderFixedCurrency(paymentSuggestions[0], gl_currency_currentCurrency.code)}
                                    </p>
                                    : null }
                                
                                { calculatingSuggestions === true ? 
                                    <IonSpinner />
                                    : null }
                            </IonChip>
                        </IonCol>

                        <IonCol>
                            <IonChip onClick={() => onClick_valueClicked(paymentSuggestions[1])} style={{ width: "100%", display: "flex", justifyContent: "center" }}> 
                                { paymentSuggestions[1] !== 0 ? 
                                    <p className="sdr-text-biggernormal ion-text-center">
                                        {renderFixedCurrency(paymentSuggestions[1], gl_currency_currentCurrency.code)}
                                    </p>
                                    : null }

                                { calculatingSuggestions === true ? 
                                    <IonSpinner />
                                    : null }
                            </IonChip>
                        </IonCol>

                        <IonCol>
                            <IonChip onClick={() => onClick_valueClicked(paymentSuggestions[2])} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                { paymentSuggestions[2] !== 0 ? 
                                    <p className="sdr-text-biggernormal ion-text-center">
                                        {renderFixedCurrency(paymentSuggestions[2], gl_currency_currentCurrency.code)}
                                    </p>
                                    : null }

                                { calculatingSuggestions === true ? 
                                    <IonSpinner />
                                    : null }
                            </IonChip>
                        </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default ValueSuggestionBar;
