/*
* AdminAreaLogs.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBLogs_getAllLogs } from '../../../databases/logs';
import { debugWriter, hideSpinner, showSpinner } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
import { type_Schema_DBLogs } from '../../../types/databases';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    presentingElementRef: RefObject<HTMLElement>;
}
const AdminAreaLogs: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showLogsModal, setShowLogsModal] = useState<boolean>(false);
    const [logs, setLogs] = useState<Array<type_Schema_DBLogs>>([]);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References


    
    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onDateChange_loadLogsFromDB()
    *
    * Desc: Read new logs from database.
    * Note:
    *
    */
    const onDateChange_loadLogsFromDB = async (date: string | null | undefined) => {

        let logs: Array<type_Schema_DBLogs> = [];


        debugWriter(">>> UserDashboard/AdminArea/AdminAreaLogs: onDateChange_loadLogsFromDB");
        showSpinner();

        logs = await DBLogs_getAllLogs(date);

        hideSpinner();


        return logs;

    }; //eo function onDateChange_loadLogsFromDB()


    /*******************************************************************************
    * onChange_setDate()
    *
    * Desc: Load new logs from database and set the selected date in local state.
    * Note:
    *
    */
    const onChange_setDate = async (selectedDate: string | null | undefined) => {

        let logsFromDB: Array<type_Schema_DBLogs> = [];


        debugWriter(">>> UserDashboard/AdminArea/AdminAreaLogs: onChange_setDate");
        logsFromDB = await onDateChange_loadLogsFromDB(selectedDate);
        setLogs(logsFromDB);

    }; //eo function onChange_setDate()


    /*******************************************************************************
    * onClick_showLogsModal()
    *
    * Desc: Open the Logs Modal.
    * Note:
    *
    */
    const onClick_showLogsModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaLogs: onClick_showLogsModal");
        setShowLogsModal(true);

    }; //eo function onClick_showLogsModal()


    /*******************************************************************************
    * onDidDismiss_hideLogsModal()
    *
    * Desc: Hides the Logs Modal.
    * Note:
    *
    */
    const onDidDismiss_hideLogsModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaLogs: onDidDismiss_hideLogsModal");
        setShowLogsModal(false);

    }; //eo function onDidDismiss_hideLogsModal()


    /*******************************************************************************
    * onClick_dismissModal()
    *
    * Desc: Close Button pressed -> close Modal.
    *
    */
    const onClick_dismissModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaModal: onClick_dismissModal");
        onDidDismiss_hideLogsModal();

    }; //eo funciton onClick_dismissModal()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <p className="sdr-text-large sdr-font-weight-500">
                {lang.pages.AdminPanel.Titles.Logs[gl_settings_language]}
            </p>
            <p>
                {lang.pages.AdminPanel.Texts.ShowLogs[gl_settings_language]}
            </p>

            <IonButton onClick={onClick_showLogsModal} expand="block">
                {lang.pages.AdminPanel.Labels.ShowLogs[gl_settings_language]}
            </IonButton>

            <IonModal
                isOpen={showLogsModal}
                presentingElement={props.presentingElementRef?.current || undefined}
                swipeToClose
                onDidDismiss={onDidDismiss_hideLogsModal}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            {lang.components.UserDashboard.AdminAreaLogs.Titles.Logs[gl_settings_language]}
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={onClick_dismissModal}>
                                {lang.global.Labels.Close[gl_settings_language]}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonHeader className="ion-no-border" collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">
                                {lang.components.UserDashboard.AdminAreaLogs.Titles.Logs[gl_settings_language]}
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonItem>
                        <IonLabel position="floating">
                            {lang.components.UserDashboard.AdminAreaLogs.Labels.Date[gl_settings_language]}
                        </IonLabel>

                        <IonDatetime 
                            onIonChange={(e) => onChange_setDate(e.detail.value)} 
                            max={dayjs().format("YYYY-MM-DD")} 
                            min="2020-01-01">
                        </IonDatetime>
                    </IonItem>

                    { logs.map((log, index) => (
                        <p key={`log-${index}`}>
                            {log.timestamp}/{log.loggedInUsername}: {log.log}
                        </p>
                    )) }

                </IonContent>
            </IonModal>
        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AdminAreaLogs;
