/*
* OrderCard.tsx
*
* Description: Flight Overview and Selection Screen
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useRef, useState } from 'react';
import { IonCardTitle, IonCardContent, IonButton, IonFooter, createAnimation, IonIcon } from '@ionic/react';
import { Animation } from '@ionic/core';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useHistory } from 'react-router';
import styled from 'styled-components';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrentOrderStore } from "../../stores/shared/currentOrder";
import { useCurrencyStore } from '../../stores/currency/currency';
import { useCurrentCustomerStore } from '../../stores/shared/currentCustomer';
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
import { useNetworkStore } from '../../stores/shared/network';
import OrderMenuItem from '../shared/OrderMenu/OrderMenuItem';
import { chevronDownOutline, chevronUpOutline, trashOutline } from 'ionicons/icons';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const OrderCard: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [itemsListExpanded, setItemsListExpanded] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_currentOrder_cummulatedSum = useCurrentOrderStore().get_totalSum();
    const gl_currentOrder_orderItems = useCurrentOrderStore(state => state.currentOrderItems);
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_network_networkConnected = useNetworkStore(state => state.networkConnected);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_setPaymentMethod = useCurrentCustomerStore(state => state.actn_setPaymentMethod);
    const actn_clearCurrentOrder = useCurrentOrderStore(state => state.actn_clearCurrentOrder);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const listRef = useRef<HTMLDivElement>(null);
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_goToPaymentCS()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_goToPaymentCS = () => {

        actn_setPaymentMethod("CS");
        history.push('/proceed/payment/cashdesk');

    }; // eo function onClick_goToPaymentCS()



    /***************************************************************************
    * onClick_toggleOrderCard()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_toggleOrderCard = () => {

        let animationDown: Animation | null = null,
            animationUp: Animation | null = null;


        if(listRef.current) {
            animationDown = createAnimation()
                .addElement(listRef.current!)
                .duration(400)
                .keyframes([
                    { offset: 0, maxHeight: "60vh" },
                    { offset: 0.2, maxHeight: "17vh" },
                    { offset: 1, maxHeight: "17vh" }
                ]);

            animationUp = createAnimation()
                .addElement(listRef.current!)
                .duration(400)
                .keyframes([
                    { offset: 0, maxHeight: "17vh" },
                    { offset: 0.8, maxHeight: "60vh" },
                    { offset: 1, maxHeight: "60vh" }
                ]);
        }

        if(animationDown && animationUp) {
            if(itemsListExpanded === false) {
                animationUp.play();
            }
            if(itemsListExpanded === true) {
                animationDown.play();
            }

            setItemsListExpanded(!itemsListExpanded);
        }

    }; // eo funciton onClick_toggleOrderCard()


    /***************************************************************************
    * onClick_cancelOrder()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_cancelOrder = () => {

        actn_clearCurrentOrder();
        onClick_toggleOrderCard();

    }; // eo function onClick_cancelOrder()


    /***************************************************************************
    * isProceedButtonDisabled()
    *
    * Desc:
    * Note:
    *
    */
    const isProceedButtonDisabled = () => {

        return gl_currentOrder_orderItems.length === 0;

    }; // eo function isProceedButtonDisabled()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonFooter style={{ background: '#FFF' }} className="ion-no-border">
            <StyledOrderContainer className="ion-no-margin ion-margin-horizontal">

                <div onClick={onClick_toggleOrderCard} style={{ display: "flex", justifyContent: "center" }}>
                    { itemsListExpanded === false ? <IonIcon color="primary" icon={chevronUpOutline} /> : null }
                    { itemsListExpanded === true ? <IonIcon color="primary" icon={chevronDownOutline} /> : null }
                </div>

                <IonCardTitle style={{position: "relative"}} className="ion-margin-horizontal">
                    <p className="sdr-text-biggernormal sdr-font-weight-500 ion-no-margin ion-margin-top ion-margin-bottom">
                        { lang.components.Purchase.OrderCard.Texts.Order[gl_shared_language] }

                        <span style={{position: "absolute", right: 0}} className="sdr-text-large sdr-font-weight-light sdr-text-color-primary">
                            {renderFixedCurrency(gl_currentOrder_cummulatedSum, gl_currency_currentCurrency.code)}
                        </span>
                    </p>
                    { gl_currentOrder_orderItems.length > 0 ?
                        <div onClick={onClick_cancelOrder} className="flex flex-row flex-ai-cen pb-8">
                            <IonIcon color="primary" className="sdr-text-normal" icon={trashOutline} />
                            <p className="mt-0 mb-0 ml-3 sdr-text-normal sdr-text-color-primary sdr-font-weight-400">{ lang.components.Purchase.OrderCard.Texts.CancelOrder[gl_shared_language] }</p>
                        </div>
                    : null }
                    <div ref={listRef} style={{ maxHeight: "17vh", overflowX: "scroll" }}>
                        {
                            gl_currentOrder_orderItems.map((item, index) => (
                                    <OrderMenuItem
                                        item={item}
                                        key={`prod-${index}`}
                                        changeNotAllowed={itemsListExpanded === false} />
                            ))
                        }
                    </div>
                </IonCardTitle>
                <IonCardContent>
                    <StyledButton moreSpaceBottom={gl_network_networkConnected === false} disabled={isProceedButtonDisabled()} onClick={onClick_goToPaymentCS} expand="block">
                        { lang.components.Purchase.OrderCard.Labels.ToPayment[gl_shared_language] }
                    </StyledButton>
                </IonCardContent>
            </StyledOrderContainer>
        </IonFooter>
    );
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/
/******************************************************************************
* StyledOrderContainer
*
*/
const StyledOrderContainer = styled.div`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: env(safe-area-inset-bottom);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: #666 0 0 7px 0;
    padding-top: 16px;
    background: #FFF;
`;


/******************************************************************************
* StyledPrice
*
* Note: Expand bottom margin, if the Offline Mode Bar is beyond the button.
*
*/
const StyledButton = styled(IonButton)<{ moreSpaceBottom: boolean }>`
    ${ props => props.moreSpaceBottom === true ? "margin-bottom: calc(var(--ion-safe-area-bottom) + 24px)" : "" }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default OrderCard;