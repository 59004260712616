/*
* FlightCard.tsx
*
* Description: Card with Flight Informations.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from "react";
import { IonCard, IonText, IonIcon, IonChip, IonButton } from "@ionic/react";
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import TextButton from "../shared/TextButton";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from "../../stores/shared/settings";
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderDate, renderTime, renderDayName } from "../../tsx/utilities";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import "./FlightCard.css";
import { type_flight_flight } from "../../types/flight";
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface type_Props {
    onClick: () => void;
    onClick_edit: () => void;
    flightObject: type_flight_flight;
}
const FlightCard: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonCard className="sr-flight-container ion-no-margin ion-margin-vertical">
            <div className="sr-flight-hint-container">
                <IonText>
                    <p className="sr-flight-label">{props.flightObject.flightNo} • {props.flightObject.aircraftType} { props.flightObject.createdByUser === true ? "•" : "" } <span className="sdr-text-color-primary sdr-font-weight-500">{props.flightObject.createdByUser === true ? lang.components.FlightSelection.FlightCard.Labels.CreatedFlight[gl_shared_language] : ""}</span></p>
                </IonText>
                <IonButton style={{ marginLeft: "auto" }} onClick={props.onClick_edit} size="small" fill="clear">
                    <IonIcon icon="assets/customIcons/icon54/Pencil.svg" />
                </IonButton>
            </div>
            <div className="sr-flight-date-and-price-container">
                <div className="sr-flight-date">
                    <p className="ion-no-margin sdr-text-small sdr-text-color-medium">{renderDayName(dayjs(props.flightObject.aptFromDepartureTime))}</p>
                    <h2 className="sdr-letter-spacing-closer sdr-text-large">{renderDate(dayjs(props.flightObject.aptFromDepartureTime))}</h2>
                </div>
            </div>
            <div className="sr-flight-time-container ion-padding-bottom">
                <div className="sr-flight-time">
                    <p className="ion-no-margin sdr-font-weight-light sdr-text-extra-large ion-text-left">{props.flightObject.departureAirportInfo.iataCode}</p>
                    {props.flightObject.createdByUser === false ? <h3 className="ion-no-margin ion-text-left">{renderTime(props.flightObject.aptFromDepartureTime)}</h3> : null}
                </div>
                <div className="sr-flight-plane-container">
                    <IonIcon className="sr-flight-plane" src="/assets/customIcons/icon54/Airplane.svg" />
                </div>
                <div className="sr-flight-time">
                    <p className="ion-no-margin sdr-font-weight-light sdr-text-extra-large ion-text-right">{props.flightObject.destinationAirportInfo.iataCode}</p>
                    {props.flightObject.createdByUser === false ? <h3 className="ion-no-margin ion-text-right">{renderTime(props.flightObject.aptToArrivalTime)}</h3> : null}
                </div>
            </div>
            <div className="flightcard-border-bottom ion-margin-horizontal ion-padding-vertical">
                { props.flightObject.crew.map((crewmember, index) => {
                    return (
                        <IonChip key={`flightcard-cremember-${props.flightObject.flightNo}-${index}`} color="primary">
                            {crewmember.contract3Code}
                        </IonChip>
                    )
                }) }
            </div>
            <div className="sr-flight-selectflight-container ion-activatable ripple-parent">
                <TextButton slot="end" title={ lang.components.FlightSelection.FlightCard.Labels.SelectFlight[gl_shared_language] } onClick={() => props.onClick()} />
            </div>
        </IonCard>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FlightCard;
