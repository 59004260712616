/*
* OrderItem.tsx
*
* Description: Page: Transactions
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonRow, IonCol } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from "styled-components";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import OrderItemModal from './OrderItemModal';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from '../../../tsx/types';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    order: OrderType;
    presentingElementRef: RefObject<HTMLElement>;
}
const OrderItem: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_showDetailModal()
    *
    * Desc: 
    * Note:
    *
    */
    const onClick_showDetailModal = () => {

        setShowDetailModal(true);

    }; // eo function onClick_showDetailModal()




    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <StyledOrderItemContainer>

            <IonRow style={ props.order.canceled === true ? { background: "var(--ion-color-danger-extra-tint)" } : {}} onClick={() => onClick_showDetailModal()}>
                <IonCol size="3">
                    <StyledParagraph canceled={props.order.canceled === true ? 1 : 0}>{props.order.invoiceId.substr(-3)}</StyledParagraph>
                </IonCol>
                <IonCol size="6">
                    <StyledParagraph canceled={props.order.canceled === true ? 1 : 0}>{renderFixedCurrency(props.order.invoiceAmount, props.order.currency)}</StyledParagraph>
                </IonCol>
                <IonCol size="2">
                    <StyledParagraph canceled={props.order.canceled === true ? 1 : 0}>{props.order.passengerSeat ?? "-"}</StyledParagraph>
                </IonCol>
                <IonCol size="1">
                    <StyledParagraph canceled={props.order.canceled === true ? 1 : 0}>{props.order.paymentMethod}</StyledParagraph>
                </IonCol>
            </IonRow>

            <OrderItemModal
                presentingElementRef={ props.presentingElementRef }
                order={ props.order }
                isOpen={ showDetailModal }
                onDidDismiss={ () => setShowDetailModal(false) } />

        </StyledOrderItemContainer>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledParagraph
* 
*/
const StyledParagraph = styled.p<{ canceled: 1 | 0 }>`
    text-decoration: ${ props => props.canceled === 1 ? "line-through" : "none" };
    color: ${ props => props.canceled === 1 ? "white" : "" };
`;

/****************************************************************************** 
* StyledOrderItemContainer
* 
*/
const StyledOrderItemContainer = styled.div`
    &:nth-child(2n) ion-row{
        background: var(--ion-color-light);
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default OrderItem;
