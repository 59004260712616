/*
* FlightSelection.tsx
*
* Description: Flight Overview and Selection Screen
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
import { IonIcon, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from "../../../stores/currency/currency";
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
import { CONF_COMPANY_AVAILABLE_CURRENCIES } from '../../../tsx/config';
import { CurrencyType } from '../../../tsx/types';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    isOpen: boolean;
    onDidDismiss: () => void;
}
const PopOver: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    const gl_currencies_exchangeRates = useCurrencyStore(state => state.exchangeRates);
    const gl_currencies_exchangeRateTimestamp = useCurrencyStore(state => state.exchangeRateTimestamp);
    const gl_currencies_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References


    
    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_setCurrency()
    *
    * Desc: Saves selected currency to global state.
    * Note: After setting, hide this popover.
    *
    */
    const onClick_setCurrency = (currency: CurrencyType) => {

        useCurrencyStore.setState({ currentCurrency: currency })
        props.onDidDismiss();

    }; // eo function onClick_setCurrency()

    

    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <IonPopover onDidDismiss={props.onDidDismiss} cssClass="sdr-currency-popover" isOpen={props.isOpen}>
            <div className="ma-4">
                <p className="mb-0 sdr-text-color-primary sdr-text-large sdr-font-weight-500">
                    { lang.components.Purchase.PopOver.Texts.SelectCurrency[gl_settings_language] }
                </p>
                <p className="mt-0 sdr-text-small">
                    { lang.components.Purchase.PopOver.Texts.Status[gl_settings_language] } {gl_currencies_exchangeRateTimestamp.format("DD.MM.YYYY HH:mm")}
                </p>
                <IonList>
                    {CONF_COMPANY_AVAILABLE_CURRENCIES.map((currency, index) => (
                        <IonItem key={`currency-${index}`} style={{ "--inner-padding-end": 0 }} onClick={() => onClick_setCurrency(currency)}>
                            <IonLabel>
                                <p className="sdr-text-normal">
                                    { lang.global.Currencies[currency.code][gl_settings_language] }

                                    <span className="sdr-text-small sdr-half-margin-horizontal">
                                        1,00 € = {renderFixedCurrency(gl_currencies_exchangeRates![currency.code], currency.code)}
                                    </span>
                                </p>
                            </IonLabel>
                            {currency.code === gl_currencies_currentCurrency.code ? <IonIcon style={{ marginRight: 0 }} color="primary" slot="end" icon={checkmark} /> : null}
                        </IonItem>
                    ))}
                </IonList>
            </div>
        </IonPopover>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default PopOver;
