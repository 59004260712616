/*
* Login.tsx
*
* Description: Login Screen.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect } from 'react';
import { IonCard, IonCardContent, IonInput, IonLabel, IonItem, IonButton, IonCardHeader } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { Controller, useForm } from 'react-hook-form';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useLoginStore } from "../../stores/login/login";
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, getErrorMessage, interpretPattern } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../tsx/language.json";
import rules from "../../tsx/validationrules.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_FormValues = {
    username: string,
    password: string
}
const LoginCard: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State

    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_lang = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_call_auth_login = useLoginStore(state => state.actn_call_login);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const { handleSubmit, control, errors, setValue } = useForm<type_FormValues>();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('call_getLoginInformations_Success', onEmit_LoginCallSuccess);

        return () => {
            PubSub.unsubscribe('call_getLoginInformations_Success');
        }

    }); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onSubmit_login()
    *
    * Desc: Trigger the login process (database/backend).
    * Note:
    *
    */
    const onSubmit_login = (data: type_FormValues) => {

        debugWriter(">>> /components/Login/LoginCard: onSubmit_login()");
        actn_call_auth_login(data.username, data.password);

    }; // eo function onSubmit_login()


    /***************************************************************************
    * onEmit_LoginCallSuccess()
    *
    * Desc: Trigger the login process (database/backend).
    * Note:
    *
    */
    const onEmit_LoginCallSuccess = () => {

        setValue("username", "", false);
        setValue("password", "", false);

    }; // eo function onEmit_LoginCallSuccess()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonCard className="ion-no-margin ion-margin-vertical">
            <IonCardHeader>
                <p className="ion-no-margin ion-margin-top sdr-text-large sdr-font-weight-500">
                    {lang.components.Login.LoginCard.Titles.Login[gl_settings_lang]}
                </p>
            </IonCardHeader>
            <IonCardContent>
                <form onSubmit={handleSubmit(onSubmit_login)}>
                    <IonItem>
                        <IonLabel position="floating">
                            <p>
                                {lang.components.Login.LoginCard.Labels.Username[gl_settings_lang]}
                            </p>
                        </IonLabel>
                        <Controller
                            as={<IonInput></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                return selected.detail.value;
                            }}
                            defaultValue="" // catering-test
                            name={"username"}
                            rules={interpretPattern(rules.username)}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.username && getErrorMessage(errors.username.message) }
                    </p>


                    <IonItem>
                        <IonLabel position="floating">
                            <p>
                                {lang.components.Login.LoginCard.Labels.Password[gl_settings_lang]}
                            </p>
                        </IonLabel>
                        <Controller
                            as={<IonInput type="password"></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                return selected.detail.value;
                            }}
                            defaultValue="" // sundair
                            name={"password"}
                            rules={interpretPattern(rules.password)}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.password && getErrorMessage(errors.password.message) }
                    </p>

                    <IonButton
                        type="submit"
                        className="mt-4"
                        expand="block">
                        {lang.components.Login.LoginCard.Labels.Login[gl_settings_lang]}
                    </IonButton>
                </form>

            </IonCardContent>
        </IonCard>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default LoginCard;
