/*
* sync.tsx
*
* Description: Global Store for Syncing
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import axios from "axios";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useNetworkStore } from '../shared/network';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { showToastError } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_Response } from '../../types/response';
import lang from "../../tsx/language.json";
import { useSettingsLanguageStore } from '../shared/settings';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    actn_call_processInvoices: (orderToSync: any) => Promise<boolean>,
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useSyncStore = create<StateType>((set, get) => ({
    ...initalState,




    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$
    // #   /$$__  $$            | $$    |__/
    // #  | $$  \ $$  /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_call_processInvoices()
    *
    * Desc: Read the products from the database or from the API.
    * Note: If there are no infos in the database table, call the API.
    * Note: Call the API only if there is a network connection.
    *
    */
    actn_call_processInvoices: (orderToSync) => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected,
              gl_shared_language = useSettingsLanguageStore.getState().language;


        return new Promise<boolean>(resolve => {
            if (gl_networkConnected === true) {
                axios.put('/shop/processinvoice', orderToSync)
                    .then(async (response: type_Response.response) => {
                        if (response.headers["air41-status"] === "OK") {
                            resolve(true);
                        } else {
                            resolve(false);
                            showToastError(
                                lang.components.Shared.Toasts.Error[gl_shared_language],
                                lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                            );
                        }
                    }).catch(() => {
                        resolve(false);
                        showToastError(
                            lang.components.Shared.Toasts.Error[gl_shared_language],
                            lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                        );
                    });
            } else {
                resolve(false);
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.NetworkError[gl_shared_language]
                );
            }
        });

    }, // eo funciton actn_call_processInvoices()

}));