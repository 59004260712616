/*
* SalesReportItem.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, RefObject } from 'react';
import { IonIcon, IonItem, IonLabel, } from '@ionic/react';
import { warningOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
import styled from 'styled-components';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import SalesReportDetailsModal from "./SalesReportDetailsModal";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, renderDate } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    flight: { flightId: number, flightNo: string, aptFrom: string, aptTo: string, aptFromDepartureTime: string };
    presentingElementRef: RefObject<HTMLElement>;
}
const SalesReportItem: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [showSalesReportDetailsModal, setShowSalesReportDetailsModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_showDetailsModal()
    *
    * Desc: 
    * Note:
    *
    */
    const onClick_showDetailsModal = () => {

        debugWriter(">>> UserDashboard/SalesReports: onClick_showDetailsModal()");
        setShowSalesReportDetailsModal(true);

    }; // eo function onClick_showDetailsModal()


    /***************************************************************************
    * onDidDismiss_closeDetailsModal()
    *
    * Desc: 
    * Note:
    *
    */
    const onDidDismiss_closeDetailsModal = () => {

        debugWriter(">>> UserDashboard/SalesReports: onDidDismiss_closeDetailsModal()");
        setShowSalesReportDetailsModal(false);

    }; // eo function onDidDismiss_closeDetailsModal()

    

    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /*******************************************************************************
    * showWarningMessageOnSalesReport()
    *
    * Desc: Show an error message for specitic sales report.
    * Note: Only show if the orders are older than 24 hours.
    *
    * @param {dayjs.Dayjs} salesReportDate
    */
    const showWarningMessageOnSalesReport = (salesReportDate: dayjs.Dayjs) => {
        
        let showWarningMessage = false;


        debugWriter(">>> UserDashboard/SalesReports: showWarningMessageOnSalesReport()");
        if(salesReportDate.isBefore(dayjs().subtract(1, 'day'))) {
            showWarningMessage = true;
        }


        return showWarningMessage;

    }; //eo function showWarningMessageOnSalesReport()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <StyledSalesReportItemContainer 
                onClick={onClick_showDetailsModal} 
                showSubmitWarning={showWarningMessageOnSalesReport(dayjs(props.flight.aptFromDepartureTime))}>
                <IonItem detail lines="none">
                    <IonLabel>
                        <p className="sdr-text-normal sdr-font-weight-500">{props.flight.aptFrom} - {props.flight.aptTo}</p>
                        <p className="sdr-text-normal">{renderDate(dayjs(props.flight.aptFromDepartureTime))}</p>
                        <p className="sdr-text-small">{props.flight.flightNo}</p>
                    </IonLabel>
                </IonItem>

                <p className="sdr-text-small submitWarning">
                    <IonIcon color="danger" icon={warningOutline} />
                    {lang.components.UserDashboard.SalesReportItem.Texts.OlderThen24Hours[gl_settings_language]}
                </p>

            </StyledSalesReportItemContainer>

            <SalesReportDetailsModal
                isOpen={showSalesReportDetailsModal}
                onDidDismiss={onDidDismiss_closeDetailsModal}
                presentingElementef={props.presentingElementRef}
                flight={props.flight} />
                
        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledPrice
* 
*/
const StyledSalesReportItemContainer = styled.div<{ showSubmitWarning: boolean }>`
    ion-item {
        max-width: 400px;
        --background: var(--ion-color-light);
        border-radius: 8px;

        ion-label {
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 8px;

            p {
                color: #666;
            }
        }
    }

    p.submitWarning {
        margin-top: 0;
        color: var(--ion-color-danger);
        display: ${props => props.showSubmitWarning === true ? "flex" : "none"};
        align-items: center;

        ion-icon {
            margin-right: 8px;
        }
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default SalesReportItem;
