/*
* currency.tsx
*
* Description: Database Management with DEXIE for the Currency Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from "dexie";
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBCurrencies } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_currency_GetExchangeRates_Response } from "../types/currency";
import { debugWriter } from "../tsx/utilities";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                                     
// #  |__  $$__/                                     
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$ 
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/ 
// #          /$$  | $$| $$                          
// #         |  $$$$$$/| $$                          
// #          \______/ |__/ 
// #
//-----------------------------------------------------------------------------/
type type_DBCurrency_updateCurrencies = (currenciesToUpdate: type_currency_GetExchangeRates_Response) => void;
type type_DBCurrency_updateCurrency = (currencyToUpdate: { code: string, exchangeRate: number }, timestamp: string) => void;
type type_DBCurrency_getAllExchangeRates = () => Promise<Array<type_Schema_DBCurrencies>>;
type type_DBCurrency_getAllCurrencies = () => Promise<Array<type_Schema_DBCurrencies>>;
type type_DBCurrency_getTableHeader = () => Promise<Array<IndexSpec>>;




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                              /$$     /$$                              
// #  | $$_____/                             | $$    |__/                              
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$ 
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/ 
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBCurrency_addCurrencies()
*
* Desc: Adds or updates currencies to database table.
* Note: 
*
*/
export const DBCurrency_addCurrencies: type_DBCurrency_updateCurrencies = async (currenciesToUpdate) => {

    debugWriter(" >>> Database: DBCurrency_addCurrencies");
    for (const [key, value] of Object.entries(currenciesToUpdate.conversion_rates)) {
        DBCurrency_addCurrency({ code: key, exchangeRate: value }, dayjs().toISOString());
    }

}; // eo function DBCurrency_addCurrencies()


/*******************************************************************************
* ASYNC DBCurrency_addCurrency()
*
* Desc: Adds od updates currency to the database, which were send from the backend.
* Note: 
*
*/
export const DBCurrency_addCurrency: type_DBCurrency_updateCurrency = async (currencyToUpdate, timestamp) => {

    const db = await DB_openDatabase(),
          currenciesTable = db.table<type_Schema_DBCurrencies>("currencies");


    debugWriter(" >>> Database: DBCurrency_addCurrency");
    currenciesTable
        .put({
            code: currencyToUpdate.code,
            exchangeRate: currencyToUpdate.exchangeRate,
            updateTime: timestamp
        }, "code")
        .catch(() => {
            //do nothing
        });

}; // eo function DBCurrency_addCurrency()


/*******************************************************************************
* ASYNC DBCurrency_getAllExchangeRates()
*
* Desc: Returns the exchange rates which were saved.
* Note: 
*
*/
export const DBCurrency_getAllExchangeRates: type_DBCurrency_getAllExchangeRates = async () => {

    const db = await DB_openDatabase(),
          currenciesTable = db.table<type_Schema_DBCurrencies>("currencies");


    debugWriter(" >>> Database: DBCurrency_getAllExchangeRates");
    return await currenciesTable
        .toArray()
        .catch(() => {
            return [];
        });

}; // eo function DBCurrency_getAllExchangeRates()


/*******************************************************************************
* ASYNC DBCurrency_getAllCurrencies()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBCurrency_getAllCurrencies: type_DBCurrency_getAllCurrencies = async () => {

    const db = await DB_openDatabase(),
          currencyTable = db.table<type_Schema_DBCurrencies>("currencies");


    debugWriter(" >>> Database: DBCurrency_getAllCurrencies");
    return await currencyTable
        .toArray()
        .catch(() => {
            return [];
        });

}; // eo function DBCurrency_getAllCurrencies()


/*******************************************************************************
* ASYNC DBCurrency_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBCurrency_getTableHeader: type_DBCurrency_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          currencyTable = db.table<type_Schema_DBCurrencies>("currencies");


    debugWriter(" >>> Database: DBCurrency_getTableHeader");
    let tableSchema = currencyTable
        .schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBCurrency_getTableHeader()