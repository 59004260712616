/*
* AddFlightModal.tsx
*
* Description: 
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useState, RefObject, useRef } from 'react';
import { IonModal, IonButton, IonItem, IonLabel, IonInput, IonDatetime, IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonAlert } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import CrewMembers from './CrewMembers/CrewMembers';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
import { useLoginStore } from '../../../stores/login/login';
import { useFlightSelectionStore } from '../../../stores/flightselection/flights';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBFlights_getNumberOfEntries } from '../../../databases/flights';
import { debugWriter, getErrorMessage, interpretPattern } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import rules from "../../../tsx/validationrules/AddFlightModal.json";
import { type_flight_flight } from '../../../types/flight';
import { CrewMemberType } from '../../../tsx/types';
import lang from "../../../tsx/language.json";
import iataCodes from "../../../tsx/iataCodes.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    isOpen: boolean;
    onClose: () => void;
    preFilledFlight?: type_flight_flight | null;
    presentingElement: RefObject<HTMLElement>;
}
type type_FormValues = {
    departureAirportIataCode: string,
    destinationAirportIataCode: string,
    flightNo: string,
    aircraftType: string,
    departureDate: string
}
const AddFlightModal: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [crewmembers, setCrewmembers] = useState<Array<string>>([]);
    const [errorMessageCrewMembers, setErrorMessageCrewMembers] = useState<string | null>(null);
    const [showDepartureAlert, setShowDepartureAlert] = useState<boolean>(false);
    const [showDestinationAlert, setShowDestinationAlert] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_login_idResource = useLoginStore(state => state.idResource);
    const gl_login_isAdmin = useLoginStore(state => state.isAdmin);
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_addFlight = useFlightSelectionStore(state => state.actn_addFlight);
    const get_3LC = useLoginStore(state => state.get_3LC);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const { handleSubmit, control, errors, setValue, clearError, getValues } = useForm<type_FormValues>();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const formRef = useRef<HTMLFormElement>(null);
    const departureAirportConfirmed_Ref = useRef<Boolean>(false);
    const destinationAirportConfirmed_Ref = useRef<Boolean>(false);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /*******************************************************************************
    * useEffect()
    *
    * Desc: Set Values if there is a prefill object.
    * Note: Set date to today, if there is no prefill object.
    *
    */
    useEffect(() => {

        if(props.isOpen === true) {
            if(props.preFilledFlight !== null && props.preFilledFlight !== undefined) {
                setValue('departureAirportIataCode', props.preFilledFlight.departureAirportInfo.iataCode, false);
                setValue('destinationAirportIataCode', props.preFilledFlight.destinationAirportInfo.iataCode, false);
                setValue('flightNo', props.preFilledFlight.flightNo, false);
                setValue('aircraftType', props.preFilledFlight.aircraftType, false);
                setValue('departureDate', props.preFilledFlight.aptFromDepartureTime, false);
            } else {
                setValue('departureDate', dayjs().format("YYYY-MM-DD[T]00:00:00+0000"), false);
            }
        }

    }, [props.isOpen]); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onSubmit()
    *
    * Desc: Triggered when submit button is pressed.
    * Note: 
    *
    */
    const onSubmit = async (data: type_FormValues) => {

        let flightToAdd = await buildFlightObject(data),
            departureAirportKnown = false,
            destinationAirportKnown = false;


        debugWriter(">>> /components/flightselection/AddFlightModal.tsx: onSubmit()");
        if(crewmembers.length < 4) {
            setErrorMessageCrewMembers("Min5Crewmembers");
            return;
        }

        departureAirportKnown = iataCodes.airports.includes(data.departureAirportIataCode);
        destinationAirportKnown = iataCodes.airports.includes(data.destinationAirportIataCode);

        if(departureAirportConfirmed_Ref.current === false) {
            if(departureAirportKnown === false) {
                updateDepartureAirportConfirmed(false);
            } else {
                updateDepartureAirportConfirmed(true);
            }
        }

        if(departureAirportConfirmed_Ref.current === false) {
            setShowDepartureAlert(true);
            return;
        }

        if(destinationAirportConfirmed_Ref.current === false) {
            if(destinationAirportKnown === false) {
                updateDestinationAirportConfirmed(false);
            } else {
                updateDestinationAirportConfirmed(true);
            }
        }

        if(destinationAirportConfirmed_Ref.current === false) {
            setShowDestinationAlert(true);
            return;
        }

        actn_addFlight(flightToAdd);
        closeModal();

    }; // eo function onSubmit()


    /*******************************************************************************
    * onChange_setCrewmembers()
    *
    * Desc: Save the new crew members array locally.
    * Note: 
    *
    */
    const onChange_setCrewmembers = (crewMembersToSet: Array<string>) => {

        debugWriter(">>> /components/flightselection/AddFlightModal: onChange_setCrewmembers()");
        if(errorMessageCrewMembers !== null && crewMembersToSet.length < 4) {
            setErrorMessageCrewMembers("Min5Crewmembers");
        } else {
            setErrorMessageCrewMembers(null);
        }
        setCrewmembers(crewMembersToSet);

    }; // eo function onChange_setCrewmembers()


    /*******************************************************************************
    * onClick_AlertDeparture_Cancel()
    *
    * Desc: Reset local state.
    * Note: 
    *
    */
    const onClick_AlertDeparture_Cancel = () => {

        updateDepartureAirportConfirmed(false);

    }; // eo function onClick_AlertDeparture_Cancel()


    /*******************************************************************************
    * onClick_AlertDeparture_Yes()
    *
    * Desc: 
    * Note: 
    *
    */
    const onClick_AlertDeparture_Yes = () => {

        updateDepartureAirportConfirmed(true);
        handleSubmit(onSubmit)();

    }; // eo function onClick_AlertDeparture_Yes()


    /*******************************************************************************
    * onClick_AlertDestination_Cancel()
    *
    * Desc: Reset local state.
    * Note: 
    *
    */
    const onClick_AlertDestination_Cancel = () => {

        updateDestinationAirportConfirmed(false);

    }; // eo function onClick_AlertDestination_Cancel()


    /*******************************************************************************
    * onClick_AlertDestination_Yes()
    *
    * Desc: 
    * Note: 
    *
    */
    const onClick_AlertDestination_Yes = () => {

        updateDestinationAirportConfirmed(true);
        handleSubmit(onSubmit)();

    }; // eo function onClick_AlertDestination_Yes()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /*******************************************************************************
    * createFlightId()
    *
    * Desc: Creates a unique ID for an user created flight.
    * Note: Strucutre: [DATE] [FLIGHT NO WITHOUT LETTERS] [TABLE ID]
    *
    */
    const createFlightId = async (data: type_FormValues) => {

        let flightId = "",
            formattedDate = dayjs(data.departureDate).format("YYMMDD"),
            flightNo = data.flightNo,
            flightNoWithoutLetters = flightNo.replace(/[A-Za-z]/gi, ''),
            lastFlightTableId = await DBFlights_getNumberOfEntries(),
            newFlightTableId = lastFlightTableId !== null ? lastFlightTableId + 1 : 0;


        debugWriter(">>> /components/flightselection/AddFlightModal: createFlightId()");
        flightId = formattedDate + String(flightNoWithoutLetters) + String(newFlightTableId);


        return parseInt(flightId);

    }; // eo function createFlightId()


    /*******************************************************************************
    * buildAirportObject()
    *
    * Desc: Creates an airport object.
    * Note: countryCode, location, name needed for structure only.
    *
    */
    const buildAirportObject = (data: type_FormValues, airportType: "departure" | "destination") => {

        debugWriter(">>> /components/flightselection/AddFlightModal: buildAirportObject()");

        return {
            countryCode: "",
            iataCode: airportType === "departure" ? data.departureAirportIataCode : data.destinationAirportIataCode,
            location: "",
            name: ""
        }

    }; // eo function buildAirportObject()


    /*******************************************************************************
    * buildCrewArray()
    *
    * Desc: Creates an crew array.
    * Note: Adds the currently logged in user too.
    *
    */
    const buildCrewArray = () => {

        let crewArray: Array<CrewMemberType> = [];


        debugWriter(">>> /components/flightselection/AddFlightModal: buildCrewArray()");
        //first add the user him/herself
        crewArray.push({
            cockpitSeatSide: null,
            confirmedRevision: null,
            contract3Code: get_3LC()!,
            contractAddFunction: ""
        });

        //then all other crewmembers
        crewmembers.forEach((member3LetterCode) => {
            crewArray.push({
                cockpitSeatSide: null,
                confirmedRevision: null,
                contract3Code: member3LetterCode,
                contractAddFunction: ""
            })
        });


        return crewArray;

    }; // eo function buildCrewArray()

    
    /*******************************************************************************
    * buildFlightObject()
    *
    * Desc: Creates an flight object to save.
    * Note:
    * 
    */
    const buildFlightObject = async (data: type_FormValues) => {

        debugWriter(">>> /components/flightselection/AddFlightModal: buildFlightObject()");

        return {
            aircraftType: data.aircraftType,
            aptFromDepartureTime: createSDRDateFormat(data.departureDate),
            aptToArrivalTime: "",
            crew: buildCrewArray(),
            departureAirportInfo: buildAirportObject(data, "departure"),
            destinationAirportInfo: buildAirportObject(data, "destination"),
            flightNo: data.flightNo,
            id: await createFlightId(data),
            createdByUser: true,
            flightMode: null,
            idResource: gl_login_idResource!,
        }

    }; // eo function buildFlightObject()


    /*******************************************************************************
    * createSDRDateFormat()
    *
    * Desc: Creates an flight object to save.
    * Note:
    * 
    */
    const createSDRDateFormat = (dateString: string) => {

        debugWriter(">>> /components/flightselection/AddFlightModal: buildFlightObject()");

        return dayjs(dateString).format("YYYY-MM-DD[T]00:00:00+0000");

    }; // eo function createSDRDateFormat()


    /*******************************************************************************
    * updateDepartureAirportConfirmed()
    *
    * Desc: Helper for "HACK": stale state. 
    * Note:
    * 
    */
    const updateDepartureAirportConfirmed = (b: boolean) => {

        departureAirportConfirmed_Ref.current = b;

    }; // eo function updateDepartureAirportConfirmed()


    /*******************************************************************************
    * updateDestinationAirportConfirmed()
    *
    * Desc: Helper for "HACK": stale state. 
    * Note:
    * 
    */
    const updateDestinationAirportConfirmed = (b: boolean) => {

        destinationAirportConfirmed_Ref.current = b;

    }; // eo function updateDestinationAirportConfirmed()


    /*******************************************************************************
    * closeModal()
    *
    * Desc: Reset states.
    * Note:
    * 
    */
    const closeModal = () => {
        
        updateDepartureAirportConfirmed(false);
        updateDestinationAirportConfirmed(false);
        clearError();
        props.onClose();

    }; // eo function closeModal()

    


    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonModal
            isOpen={props.isOpen}
            swipeToClose={true}
            onDidDismiss={props.onClose}
            presentingElement={props.presentingElement.current ?? undefined}>

            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={props.onClose}>{ lang.global.Labels.Cancel[gl_settings_language] }</IonButton>
                    </IonButtons>
                    <IonTitle>{lang.components.FlightSelection.AddFlightModal.Titles.AddFlight[gl_settings_language]}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        <IonLabel position="floating">
                            <p>
                                {lang.components.FlightSelection.AddFlightModal.Labels.IATACodeStart[gl_settings_language]}
                            </p>
                        </IonLabel>
                        <Controller
                            as={<IonInput style={{ textTransform: "uppercase" }}></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                if(selected && selected.detail && selected.detail.value) {
                                    return selected.detail.value.toUpperCase();
                                }
                                return selected.detail.value;
                            }}
                            name={"departureAirportIataCode"}
                            rules={interpretPattern(rules.IATACode)}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.departureAirportIataCode && getErrorMessage(errors.departureAirportIataCode.message) }
                    </p>

                    <IonItem>
                        <IonLabel position="floating">
                            <p>{lang.components.FlightSelection.AddFlightModal.Labels.IATACodeDestination[gl_settings_language]}</p>
                        </IonLabel>
                        <Controller
                            as={<IonInput style={{ textTransform: "uppercase" }}></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                if(selected && selected.detail && selected.detail.value) {
                                    return selected.detail.value.toUpperCase();
                                }
                                return selected.detail.value;
                            }}
                            name={"destinationAirportIataCode"}
                            rules={interpretPattern(rules.IATACode)}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.destinationAirportIataCode && getErrorMessage(errors.destinationAirportIataCode.message) }
                    </p>

                    <IonItem>
                        <IonLabel position="floating">
                            <p>{lang.components.FlightSelection.AddFlightModal.Labels.FlightNumber[gl_settings_language]}</p>
                        </IonLabel>
                        <Controller
                            as={<IonInput></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                return selected.detail.value;
                            }}
                            name={"flightNo"}
                            rules={interpretPattern(rules.FlightNo)}
                            defaultValue={props.preFilledFlight?.flightNo ?? "SR"}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.flightNo && getErrorMessage(errors.flightNo.message) }
                    </p>

                    <IonItem>
                        <IonLabel position="floating">
                            <p>{lang.components.FlightSelection.AddFlightModal.Labels.AircraftIdentification[gl_settings_language]}</p>
                        </IonLabel>
                        <Controller
                            as={<IonInput style={{ textTransform: "uppercase" }}></IonInput>}
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                if(selected && selected.detail && selected.detail.value) {
                                    return selected.detail.value.toUpperCase();
                                }
                                return selected.detail.value;
                            }}
                            name={"aircraftType"}
                            rules={interpretPattern(rules.AircraftIdentification)}
                            defaultValue={props.preFilledFlight?.aircraftType ?? "D-"}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.aircraftType && getErrorMessage(errors.aircraftType.message) }
                    </p>

                    <IonItem>
                        <IonLabel position="floating">
                            <p>{lang.components.FlightSelection.AddFlightModal.Labels.FlightDate[gl_settings_language]}</p>
                        </IonLabel>
                        <Controller
                            as={
                                <IonDatetime
                                    max="2030-12-31"
                                    min={ gl_login_isAdmin === true ? '2019-01-01' : dayjs().format("YYYY-MM-DD") }
                                    displayFormat="DD. MMM YYYY"
                                />
                            }
                            control={control}
                            onChangeName="onIonChange"
                            onChange={([selected]) => {
                                return selected.detail.value;
                            }}
                            name={"departureDate"}
                            rules={interpretPattern(rules.FlightDate)}
                        />
                    </IonItem>
                    <p className="sdr-text-error sdr-text-small">
                        { errors.departureDate && getErrorMessage(errors.departureDate.message) }
                    </p>

                    <CrewMembers defaultValue={props.preFilledFlight?.crew} onChange={crewArray => onChange_setCrewmembers(crewArray)} />
                    <p className="sdr-text-error sdr-text-small">
                        { errorMessageCrewMembers && getErrorMessage(errorMessageCrewMembers) }
                    </p>

                    <IonButton className="sdr-paxdata-button ion-margin-top" expand="block" type="submit">
                        {lang.components.FlightSelection.AddFlightModal.Labels.AddFlight[gl_settings_language]}
                    </IonButton>
                </form>

                <IonAlert
                    isOpen={showDepartureAlert}
                    onDidDismiss={() => setShowDepartureAlert(false)}
                    header={ lang.global.Labels.Attention[gl_settings_language] }
                    message={`${ lang.components.FlightSelection.AddFlightModal.Texts.DepartureAirport[gl_settings_language] } ${getValues('departureAirportIataCode')} ${ lang.components.FlightSelection.AddFlightModal.Texts.DoesNotExistAreYouSure[gl_settings_language] }`}
                    buttons={[
                        {
                          text: lang.global.Labels.Cancel[gl_settings_language],
                          role: 'cancel',
                          cssClass: 'secondary',
                          handler: onClick_AlertDeparture_Cancel
                        },
                        {
                          text: lang.global.Labels.Yes[gl_settings_language],
                          handler: onClick_AlertDeparture_Yes
                        }
                    ]} />

                <IonAlert
                    isOpen={showDestinationAlert}
                    onDidDismiss={() => setShowDestinationAlert(false)}
                    header={ lang.global.Labels.Attention[gl_settings_language] }
                    message={`${ lang.components.FlightSelection.AddFlightModal.Texts.DestinationAirport[gl_settings_language] } ${getValues('destinationAirportIataCode')} ${ lang.components.FlightSelection.AddFlightModal.Texts.DoesNotExistAreYouSure[gl_settings_language] }`}
                    buttons={[
                        {
                          text: lang.global.Labels.Cancel[gl_settings_language],
                          role: 'cancel',
                          cssClass: 'secondary',
                          handler: onClick_AlertDestination_Cancel
                        },
                        {
                          text: lang.global.Labels.Yes[gl_settings_language],
                          handler: onClick_AlertDestination_Yes
                        }
                    ]} />
            </IonContent>
            
        </IonModal>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AddFlightModal;
