/*
* orders.tsx
*
* Description: Database Management with DEXIE for the Orders Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBOrders } from '../types/databases';
import { useSettingsLanguageStore } from "../stores/shared/settings";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from "../tsx/types";
import { debugWriter, showToastError, showToastInformation } from '../tsx/utilities';
import { IndexSpec } from 'dexie';
import lang from "../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type type_DBOrders_addOrder = (orderToAdd: OrderType) => void;
type type_DBOrders_changeEMailAddress = (orderToChangeID: string, eMailAddressToChange: string) => void;
type type_DBOrders_cancelOrder = (orderToChangeID: string) => void;
type type_DBOrders_deleteOrders = (ordersToDeleteIds: Array<string>) => void;
type type_DBOrders_deleteOrder = (orderToDeleteId: string) => void;
type type_DBOrders_markOrdersAsSynced = (orderToChangeIds: Array<string>) => void;
type type_DBOrders_markOrderAsSynced = (orderToChangeId: string) => void;
type type_DBOrders_getSerialNumber = (flightId: number) => Promise<string>;
type type_DBOrders_getAllFlightsWithOrders = (idResource: number, notAlreadySyncedOrders?: boolean) => Promise<Array<{ flightId: number, flightNo: string, aptFrom: string, aptTo: string, aptFromDepartureTime: string }>>;
type type_DBOrders_getAllOrders = (idResource: number, flightId: number) => Promise<Array<OrderType>>;
type type_DBOrders_getOrder = (invoiceId: string) => Promise<type_Schema_DBOrders | null | undefined>;
type type_DBOrders_getAllOrdersForSync = () => Promise<Array<OrderType>>;
type type_DBOrders_getSums = (flightId: number, idResource: number) => Promise<{
    total: { EUR: number, USD: number },
    cs: { EUR: number, USD: number },
    cc: { EUR: number, USD: number },
    tip: { EUR: number, USD: number }
}>;
type type_DBOrders_getTableHeader = () => Promise<Array<IndexSpec>>;
type type_DBOrders_getAllEntries = () => Promise<Array<type_Schema_DBOrders>>;
type type_DBOrders_countAllEntries = () => Promise<number>;



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                              /$$     /$$
// #  | $$_____/                             | $$    |__/
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBOrders_addOrder()
*
* Desc: Adds order to the database, will be send to the backend.
* Note:
*
*/
export const DBOrders_addOrder: type_DBOrders_addOrder = async (orderToAdd) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBOrders_addOrder");
    orderssTable
        .put({
            invoiceId: orderToAdd.invoiceId,
            aptFrom: orderToAdd.aptFrom,
            aptTo: orderToAdd.aptTo,
            crewMembers: orderToAdd.crewMembers,
            crewLogin: orderToAdd.crewLogin,
            flightNo: orderToAdd.flightNo,
            flightDate: orderToAdd.flightDate,
            passengerSeat: orderToAdd.passengerSeat,
            paymentMethod: orderToAdd.paymentMethod,
            ccBrand: orderToAdd.ccBrand,
            ccCvc: orderToAdd.ccCvc,
            ccExpiryMonth: orderToAdd.ccExpiryMonth,
            ccExpiryYear: orderToAdd.ccExpiryYear,
            ccHolder: orderToAdd.ccHolder,
            ccNumber: orderToAdd.ccNumber,
            ccSignature: orderToAdd.ccSignature,
            invoiceAmount: orderToAdd.invoiceAmount,
            invoiceVat: orderToAdd.invoiceVat,
            paymentAmount: orderToAdd.paymentAmount,
            entriesForProcess: orderToAdd.entriesForProcess,
            entriesForPresentation: orderToAdd.entriesForPresentation,
            currency: orderToAdd.currency,
            emailInvoice: orderToAdd.emailInvoice === true ? 1 : 0,
            emailText: orderToAdd.emailText,
            emailAddress: orderToAdd.emailAddress,
            canceled: orderToAdd.canceled === true ? 1 : 0,
            flightId: orderToAdd.flightId,
            idResource: orderToAdd.resourceId,
            timestamp: orderToAdd.timestamp,
            syncedBefore: 0,
            tipInOrderCurrency: 0, // remove: not needed anymore
            tipInEur: 0, // remove: not needed anymore
            printCounter: 0,
            totalInEur: orderToAdd.totalInEur,
            totalInOrderCurrency: orderToAdd.totalInOrderCurrency,
            vatInEur: orderToAdd.vatInEur,
            vatInOrderCurrency: orderToAdd.vatInOrderCurrency,
            hasWarning: orderToAdd.hasWarning,
            warningComment: orderToAdd.warningComment,
            changesInEuro: orderToAdd.changesInEuro
        }, "invoiceId")
        .catch(() => {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.CouldNotSaveOrder[gl_shared_language]
            );
        });

}; // eo function DBOrders_addOrder()


/*******************************************************************************
* ASYNC DBOrders_changeEMailAddress()
*
* Desc: Changes the E-Mail Address of an order.
* Note:
*
*/
export const DBOrders_changeEMailAddress: type_DBOrders_changeEMailAddress = async (orderToChangeID, eMailAddressToChange) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBOrders_changeEMailAddress");
    orderssTable
        .where('invoiceId')
        .equals(orderToChangeID)
        .modify({ emailAddress: eMailAddressToChange, emailInvoice: 1 })
        .catch(() => {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.CouldNotSaveEMail[gl_shared_language]
            );
        });

}; // eo function DBOrders_changeEMailAddress()


/*******************************************************************************
* ASYNC DBOrders_cancelOrder()
*
* Desc: Cancel an order.
* Note:
*
*/
export const DBOrders_cancelOrder: type_DBOrders_cancelOrder = async (orderToChangeID) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBOrders_cancelOrder");
    orderssTable
        .where('invoiceId')
        .equals(orderToChangeID)
        .modify({ canceled: 1 })
        .then(() => {
            PubSub.publish("DBOrders_cancelOrder_Success");
            showToastInformation(
                lang.components.Shared.Toasts.Success[gl_shared_language],
                lang.components.Shared.Toasts.CanceledOrder[gl_shared_language]
            );
        })
        .catch(() => {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.CouldNotCancelOrder[gl_shared_language]
            );
        });

}; // eo function DBOrders_cancelOrder()


/*******************************************************************************
* ASYNC DBOrders_deleteOrders()
*
* Desc: Delete orders.
* Note:
*
*/
export const DBOrders_deleteOrders: type_DBOrders_deleteOrders = async (ordersToDeleteIds) => {

    ordersToDeleteIds.forEach(async (orderId) => {
        await DBOrders_deleteOrder(orderId);
    });

}; // eo function DBOrders_deleteOrders()


/*******************************************************************************
* ASYNC DBOrders_deleteOrder()
*
* Desc: Delete orders.
* Note:
*
*/
export const DBOrders_deleteOrder: type_DBOrders_deleteOrder = async (orderToDeleteId) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBOrders_deleteOrder");
    orderssTable
        .where('invoiceId')
        .equals(orderToDeleteId)
        .delete()
        .catch(() => {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
            );
        });

}; // eo function DBOrders_deleteOrder()


/*******************************************************************************
* ASYNC DBOrders_markOrdersAsSynced()
*
* Desc: Cancel an order.
* Note:
*
*/
export const DBOrders_markOrdersAsSynced: type_DBOrders_markOrdersAsSynced = async (orderToChangeIds) => {

    debugWriter(">>> Database: DBOrders_markOrdersAsSynced");
    orderToChangeIds.forEach(async (orderId: string) => {
        await DBOrders_markOrderAsSynced(orderId);
    });

}; // eo function DBOrders_markOrdersAsSynced()


/*******************************************************************************
* ASYNC DBOrders_markOrderAsSynced()
*
* Desc: Cancel an order.
* Note:
*
*/
export const DBOrders_markOrderAsSynced: type_DBOrders_markOrderAsSynced = async (orderToChangeId: string) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders");


    debugWriter(">>> Database: DBOrders_markOrderAsSynced");
    orderssTable
        .where('invoiceId')
        .equals(orderToChangeId)
        .modify({ syncedBefore: 1 })
        .catch(() => {
            // do nothing
        });

}; // eo function DBOrders_markOrderAsSynced()


/*******************************************************************************
* ASYNC DBOrders_getSerialNumber()
*
* Desc: Returns the next available serial number fot the invoice id.
* Note: Format three digitis, leading zeros if needed "001", "013", "908", ...
*
*/
export const DBOrders_getSerialNumber: type_DBOrders_getSerialNumber = async (flightId) => {

    const db = await DB_openDatabase(),
          orderssTable = db.table<type_Schema_DBOrders>("orders");

    let formattedSerialNumber: string,
        currentHighestInvoiceId = 0,
        orders: Array<type_Schema_DBOrders> | void;


    debugWriter(">>> Database: DBOrders_getSerialNumber");
    formattedSerialNumber= "000";


    orders = await orderssTable
        .where({ flightId: flightId })
        .toArray()
        .catch(() => {
            currentHighestInvoiceId = 0;
        });

    if(orders && orders.length > 0) {
        orders.sort((a, b) => a.invoiceId < b.invoiceId ? 1 : -1);

        currentHighestInvoiceId = +orders[0].invoiceId.substr(orders[0].invoiceId.length - 3);
        orders.forEach(order => {
            let currentOrderInvoiceId = +order.invoiceId.substr(order.invoiceId.length - 3);

            if(currentOrderInvoiceId > currentHighestInvoiceId) {
                currentHighestInvoiceId = currentOrderInvoiceId;
            }
        });
    }


    currentHighestInvoiceId += 1;
    formattedSerialNumber = currentHighestInvoiceId.toString().padStart(3, '0');


    return formattedSerialNumber;

}; // eo function DBOrders_getSerialNumber()


/*******************************************************************************
* ASYNC DBOrders_getSums()
*
* Desc: Returns the summed up amounts of the orders from a sepcific flight and
* Desc: a specific crew member.
*
*/
export const DBOrders_getSums: type_DBOrders_getSums = async (flightId, idResource) => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");


    let sum_total = { "EUR": 0, "USD": 0 },
        sum_cc = { "EUR": 0, "USD": 0 },
        sum_cs = { "EUR": 0, "USD": 0 },
        sum_tip = { "EUR": 0, "USD": 0 }; // TODO: remove tip

    let allOrders = await ordersTable
        .where({ flightId: flightId, idResource: idResource, canceled: 0, syncedBefore: 0 })
        .toArray()
        .catch(() => {
            return [];
        });


    debugWriter(">>> Database: DBOrders_getSums");
    allOrders.forEach(order => {
        sum_total[order.currency] += order.invoiceAmount;
        if(order.paymentMethod === "CC") {
            sum_cc[order.currency] += order.invoiceAmount;
        }else if(order.paymentMethod === "CS") {
            sum_cs[order.currency] += order.invoiceAmount;
        }
        sum_tip[order.currency] += order.tipInOrderCurrency; // TODO: remove
    });


    console.log('ACHTUNG MICHAEL : Hier stimmt noch etwas nicht')

    return {
        total: sum_total,
        cc: sum_cc,
        cs: sum_cs,
        tip: sum_tip // TODO: remove
    };

}; // eo function DBOrders_getSums()


/*******************************************************************************
* ASYNC DBOrders_getAllOrders()
*
* Desc: Returns the orders which were saved.
* Note:
*
*/
export const DBOrders_getAllOrders: type_DBOrders_getAllOrders = async (idResource, flightId) => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");

    let ordersToReturn: Array<OrderType> = [],
        savedOrders = await ordersTable
            .where({ idResource: idResource, flightId: flightId })
            .toArray()
            .catch(() => {
                return [];
            });


    debugWriter(">>> Database: DBOrders_getAllOrders");
    savedOrders.forEach((order) => {
        ordersToReturn.push(Object.assign(order, {
            canceled: order.canceled === 1,
            emailInvoice: order.emailInvoice === 1,
            syncedBefore: order.syncedBefore === 1,
            resourceId: order.idResource,
            tip: 0 // TODO: remove tip from database - not needed
        }));
    });


    return ordersToReturn;

}; // eo function DBOrders_getAllOrders()


/*******************************************************************************
* ASYNC DBOrders_getOrder()
*
* Desc: Returns the orders which were saved.
* Note:
*
*/
export const DBOrders_getOrder: type_DBOrders_getOrder= async (invoiceId) => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");

    let savedOrder = null;


    debugWriter(">>> Database: DBOrders_getOrder");
    savedOrder = await ordersTable
        .where({ invoiceId: invoiceId })
        .toArray()
        .catch(() => {
            return [];
        });

    if(savedOrder.length === 0) {
        return null;
    }

    if(savedOrder.length > 0) {
        return savedOrder[0];
    }

}; // eo function DBOrders_getAllOrders()


/*******************************************************************************
* ASYNC DBOrders_getAllOrdersForSync()
*
* Desc: Returns the orders which were saved.
* Note:
*
*/
export const DBOrders_getAllOrdersForSync : type_DBOrders_getAllOrdersForSync = async () => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");

    let ordersToReturn: Array<OrderType> = [],
        savedOrders = await ordersTable
            .toArray()
            .catch(() => {
                return [];
            });


    debugWriter(">>> Database: DBOrders_getAllOrdersForSync");
    savedOrders.forEach((order) => {

        ordersToReturn.push(Object.assign(order, {
            canceled: order.canceled === 1 ? true : false,
            emailInvoice: order.emailInvoice === 1 ? true : false,
            syncedBefore: order.syncedBefore === 1 ? true : false,
            resourceId: order.idResource,
            tip: 0, // TODO: remove
        }));

    });


    return ordersToReturn;

}; // eo function DBOrders_getAllOrdersForSync()


/*******************************************************************************
* ASYNC DBOrders_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note:
*
*/
export const DBOrders_getTableHeader: type_DBOrders_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");


    debugWriter(">>> Database: DBOrders_getTableHeader");
    let tableSchema = ordersTable
        .schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBOrders_getTableHeader()


/*******************************************************************************
* ASYNC DBOrders_getAllEntries()
*
* Desc: Returns the orders which were saved.
* Note: Only return save to view infos.
* Note: Means in this case: filter out the cc payed orders completely.
*
*/
export const DBOrders_getAllEntries: type_DBOrders_getAllEntries = async () => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");

    let savedOrders = await ordersTable
        .filter((entry) => (
            entry.paymentMethod === "CS"
        ))
        .toArray()
        .catch(() => {
            return [];
        });


    debugWriter(">>> Database: DBLogs_addLog");


    return savedOrders;

}; // eo function DBOrders_getAllEntries()


/*******************************************************************************
* ASYNC DBOrders_countAllEntries()
*
* Desc:
* Note:
*
*/
export const DBOrders_countAllEntries: type_DBOrders_countAllEntries = async () => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");


    debugWriter(">>> Database: DBOrders_countAllEntries");
    return await ordersTable
        .where({ syncedBefore: 0, canceled: 0 })
        .count()
        .catch(() => {
            return 0;
        });

}; // eo function DBOrders_countAllEntries()


/*******************************************************************************
* ASYNC DBOrders_getAllFlightsWithOrders()
*
* Desc:
* Note:
*
*/
export const DBOrders_getAllFlightsWithOrders: type_DBOrders_getAllFlightsWithOrders = async (idResource, notAlreadySyncedOrders) => {

    const db = await DB_openDatabase(),
          ordersTable = db.table<type_Schema_DBOrders>("orders");

    let flights: Array<{ flightId: number, flightNo: string, aptFrom: string, aptTo: string, aptFromDepartureTime: string }> = [],
        savedOrders = await ordersTable
            .filter((entry) => (
                entry.idResource === idResource
            ))
            .toArray()
            .catch(() => {
                return [];
            });


    debugWriter(">>> Database: DBOrders_getAllFlightsWithOrders");
    if(notAlreadySyncedOrders === true) {
        savedOrders = savedOrders.filter(order => order.syncedBefore === 0);
    }

    savedOrders.forEach((order) => {
        let flightObject = {
            flightId: order.flightId,
            flightNo: order.flightNo,
            aptFrom: order.aptFrom,
            aptTo: order.aptTo,
            aptFromDepartureTime: order.flightDate
        }

        if(flights.some(flight => flight.flightId === flightObject.flightId) === false) {
            flights.push(flightObject);
        }
    });


    return flights;

}; // eo function DBOrders_getAllFlightsWithOrders()