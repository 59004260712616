/*
* GetBooking.tsx
*
* Description: Component/Page: GetBooking (Entry Point of Rebooking)
*
*
* This file is part of: "http://apps.sundair.com/", "Tickets/Sundair"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useState } from 'react';
import { IonButton, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { addOutline, checkmarkCircleOutline, removeOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import AnimateHeight from 'react-animate-height';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from '../../../stores/currency/currency';
import { useCurrentOrderStore } from '../../../stores/shared/currentOrder';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderCurrency, renderFixedCurrency } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderProductType } from '../../../tsx/types';
import lang from "../../../tsx/language.json";
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In {
    item: OrderProductType;
    changeNotAllowed?: boolean;
}
const OrderMenuItem: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [itemOptionsHeight, setItemOptionsHeight] = useState<0 | "auto">(0);
    const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
    const [currentDiscount, setCurrentDiscount] = useState<number>(props.item.discountPercent ?? 0);
    // <<< --------------------------------------------------------------------- Local State
    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_shared_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_addProductToCurrentOrder = useCurrentOrderStore(state => state.actn_addProductToCurrentOrder);
    const actn_removeProductFromCurrentOrder = useCurrentOrderStore(state => state.actn_removeProductFromCurrentOrder);
    const actn_removeCompleteProductFromCurrentOrder = useCurrentOrderStore(state => state.actn_removeCompleteProductFromCurrentOrder);
    const actn_addDiscountToCurrentOrderProduct = useCurrentOrderStore(state => state.actn_addDiscountToCurrentOrderProduct);
    // <<< --------------------------------------------------------------------- Global Actions
    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions
    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc:
    * Note:
    *
    */
    useEffect(() => {

        if(props.changeNotAllowed === true) {
            setItemOptionsHeight(0);
        }

    }, [props.changeNotAllowed]); // eo funciton useEffect()




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_addProductToCurrentOrder()
    *
    * Desc: Add product to current order.
    * Note:
    *
    */
    const onClick_addProductToCurrentOrder = () => {

        actn_addProductToCurrentOrder(props.item);

    }; // eo function onClick_addProductToCurrentOrder()


    /***************************************************************************
    * onClick_removeProductFromCurrentOrder()
    *
    * Desc: Removes one unit of the product from current order.
    * Note:
    *
    */
    const onClick_removeProductFromCurrentOrder = () => {

        actn_removeProductFromCurrentOrder(props.item);

    }; // eo funciton onClick_removeProductFromCurrentOrder()


    /***************************************************************************
    * onClick_removeCompleteProductFromCurrentOrder()
    *
    * Desc: Removes all units of the product from current order.
    * Note:
    *
    */
    const onClick_removeCompleteProductFromCurrentOrder = () => {

        actn_removeCompleteProductFromCurrentOrder(props.item);

    }; // eo function onClick_removeCompleteProductFromCurrentOrder()


    /***************************************************************************
    * onClick_calculateDiscount()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_calculateDiscount = () => {

        setShowDiscountModal(true);

    }; // eo function onClick_calculateDiscount()


    /***************************************************************************
    * onClick_saveDiscount()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_saveDiscount = () => {

        actn_addDiscountToCurrentOrderProduct(props.item.id, currentDiscount);
        setShowDiscountModal(false);

    }; // eo function onClick_saveDiscount


    /***************************************************************************
    * onClick_toggleItemOptions()
    *
    * Desc: Toggle the options down under the item.
    * Note:
    *
    */
    const onClick_toggleItemOptions = () => {

        // if(props.changeNotAllowed !== true) {
            if(itemOptionsHeight === 0) {
                setItemOptionsHeight("auto");
            } else {
                setItemOptionsHeight(0);
            }
        // }

    }; // eo function onClick_toggleItemOptions()




    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * renderItemPrice()
    *
    * Desc: Toggle the options down under the item.
    * Note:
    *
    */
    const renderItemPrice = (counter?: number) => {

        const gl_currentCurrency = useCurrencyStore.getState().currentCurrency;
        let priceToReturn: number | string = 0;


        if(gl_currentCurrency.code === "EUR") {
            priceToReturn = props.item.priceEUR;
        }

        if(gl_currentCurrency.code === "USD") {
            priceToReturn = props.item.priceUSD;
        }


        if(counter !== undefined) {
            priceToReturn *= counter;
        }

        priceToReturn = renderFixedCurrency(priceToReturn, gl_currentCurrency.code);


        return priceToReturn;

    }; // eo function renderItemPrice()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div style={{width: "100%"}}>
            <IonItem style={{ "--inner-padding-end": 0 }}>
                <IonLabel style={{ margin: 0 }} className="ion-text-wrap">
                    <IonGrid className="ion-no-padding">
                        <div onClick={onClick_toggleItemOptions}>
                            <IonRow>
                                <IonCol size="1">
                                    <p className="sdr-text-normal sdr-font-weight-400">
                                        {props.item.counter}x
                                    </p>
                                </IonCol>
                                <IonCol size="8">
                                    <p className="sdr-text-normal sdr-font-weight-400">
                                        {props.item.name}
                                    </p>
                                </IonCol>
                                <IonCol size="3">
                                    <p className="sdr-text-normal sdr-text-color-primary ion-text-right sdr-font-weight-light">
                                        {renderItemPrice(props.item.counter)}
                                    </p>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <p className="sdr-text-small ion-text-right">
                                    { lang.components.Shared.OrderMenuItem.Texts.PricePerUnit[gl_shared_language] }: {renderItemPrice()}
                                    {" "}
                                    { (props.item.discountPercent ?? 0) > 0 ? `(${props.item.discountPercent}% ${lang.components.Shared.OrderMenuItem.Texts.Discount[gl_shared_language]}, ${ gl_shared_currentCurrency.code === "EUR" ? renderFixedCurrency(props.item.discountEUR ?? 0, "EUR") : renderFixedCurrency(props.item.discountUSD ?? 0, "USD") })` : "" }
                                </p>
                            </IonRow>
                        </div>
                        <IonRow>
                            <AnimateHeight style={{ width: "100%", marginTop: 8 }} duration={200} height={itemOptionsHeight}>
                                <IonGrid className="ion-no-padding">
                                    <IonRow>
                                        <IonCol onClick={onClick_removeCompleteProductFromCurrentOrder} size="3">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px 0" }}>
                                                <IonIcon style={{ fontSize: "1.3rem" }} color="primary" src="assets/customIcons/icon54/Trash.1.svg" />
                                            </div>
                                        </IonCol>
                                        <IonCol onClick={onClick_removeProductFromCurrentOrder} size="3">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px 0" }}>
                                                <IonIcon style={{ fontSize: "1.3rem" }} color="primary" icon={removeOutline} />
                                            </div>
                                        </IonCol>
                                        <IonCol onClick={onClick_addProductToCurrentOrder} size="3">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px 0" }}>
                                                <IonIcon style={{ fontSize: "1.3rem" }} color="primary" icon={addOutline} />
                                            </div>
                                        </IonCol>
                                        <IonCol onClick={onClick_calculateDiscount} size="3">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px 0" }}>
                                                <IonIcon style={{ fontSize: "1.3rem" }} color="primary" icon="./assets/customIcons/icon54/Percent.svg" />
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </AnimateHeight>
                        </IonRow>
                    </IonGrid>
                </IonLabel>
            </IonItem>

            <IonModal
                isOpen={showDiscountModal}
                swipeToClose={true}
                onDidDismiss={() => setShowDiscountModal(false)}
                presentingElement={undefined}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            { lang.components.Shared.OrderMenuItem.Texts.PricePerUnit[gl_shared_language] }
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense" className="ion-no-border">
                        <IonToolbar>
                            <IonTitle size="large">
                                { lang.components.Shared.OrderMenuItem.Texts.PricePerUnit[gl_shared_language] }
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <div>
                        <p className='sdr-text-biggernormal sdr-font-weight-500'>
                            { props.item.name }
                        </p>
                        <p className="sdr-text-normal">
                            { lang.components.Shared.OrderMenuItem.Texts.CurrentPrice[gl_shared_language] }: <span className="sdr-text-color-primary" style={ currentDiscount > 0 ? { textDecoration: 'line-through' } : {} }>{ renderCurrency(props.item.price1) }</span>
                        </p>
                        { currentDiscount > 0 ?
                            <p className="sdr-text-normal">
                                { lang.components.Shared.OrderMenuItem.Texts.NewPrice[gl_shared_language] }: <span className="sdr-text-biggernormal sdr-text-color-primary">{ renderCurrency(((100 - currentDiscount) * props.item.price1) / 100) }</span>
                            </p>
                        : null }
                    </div>

                </IonContent>

                <IonFooter>
                    <div style={{ padding: '24px 12px' }}>
                        <IonChip onClick={() => setCurrentDiscount(0)} color={currentDiscount === 0 ? 'primary' : ''}>
                            { currentDiscount === 0 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>0%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(5)} color={currentDiscount === 5 ? 'primary' : ''}>
                            { currentDiscount === 5 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>5%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(10)} color={currentDiscount === 10 ? 'primary' : ''}>
                            { currentDiscount === 10 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>10%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(20)} color={currentDiscount === 20 ? 'primary' : ''}>
                            { currentDiscount === 20 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>20%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(30)} color={currentDiscount === 30 ? 'primary' : ''}>
                            { currentDiscount === 30 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>30%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(40)} color={currentDiscount === 40 ? 'primary' : ''}>
                            { currentDiscount === 40 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>40%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(50)} color={currentDiscount === 50 ? 'primary' : ''}>
                            { currentDiscount === 50 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>50%</IonLabel>
                        </IonChip>
                        <IonChip onClick={() => setCurrentDiscount(100)} color={currentDiscount === 100 ? 'primary' : ''}>
                            { currentDiscount === 100 ?
                                <IonIcon icon={checkmarkCircleOutline} />
                            : null }
                            <IonLabel>100%</IonLabel>
                        </IonChip>
                    </div>
                    <IonButton expand='block' onClick={onClick_saveDiscount}>
                        { lang.global.Labels.Save[gl_shared_language] }
                    </IonButton>
                </IonFooter>

            </IonModal>
        </div>
    )
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default OrderMenuItem;
