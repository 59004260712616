/*
* login.tsx
*
* Description:
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import axios from "axios";
import PubSub from "pubsub-js";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../shared/settings';
import { useNetworkStore } from '../shared/network';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBLogs_addLog } from '../../databases/logs';
import { hideSpinner, showSpinner, showToastError, createHeaders } from '../../tsx/utilities';
import { DBLogin_login, DBLogin_addUser } from "../../databases/login";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_Response } from "../../types/response";
import { type_call_getLoginInformations_DB, type_call_getLoginInformations_Reponse } from '../../types/login';
import lang from "../../tsx/language.json";
import { CONF_ADMIN_GRANT, CONF_USAGE_GRANT } from '../../tsx/config';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    loggedIn: boolean,
    username: string | null,
    realname: string | null,
    idResource: number | null,
    authorization: string | null,
    isAdmin: boolean,

    get_3LC: () => string,

    mut_call_login: (reponse: type_call_getLoginInformations_Reponse | type_call_getLoginInformations_DB, mode: "DB" | "API") => void,

    actn_call_login: (username: string, password: string) => void,
    actn_resetLoginStore: () => void,
};



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    loggedIn: false,
    username: null,
    realname: null,
    idResource: null,
    authorization: null,
    isAdmin: false
};



//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useLoginStore = create<StateType>((set, get) => ({
    ...initalState,



    /***************************************************************************
    * get_3LC()
    *
    * Desc: Returns the 3 letter code of a user.
    * Note: Only if the username has the format XXX-YYY, otherwise it returns
    * Note: the whole username.
    *
    */
    get_3LC: () => {

        let threeLetterCode = "",
            usernameParts = get().username!.split("-");


        // in usernameParts is at least one element (the username itself if the seperator is not found)
        threeLetterCode = usernameParts[0];


        return threeLetterCode;

    }, // eo function get_3LC()



    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * mut_call_login()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_login: (response, mode) => {

        let tmp_loggedIn = true,
            tmp_username: string,
            tmp_realname: string,
            tmp_idResource: number,
            tmp_authorization: string,
            tmp_isAdmin: boolean;


        if(mode === "DB") {
            DBLogs_addLog("Login with DB");
            DBLogs_addLog(`Admin: ${(response as type_call_getLoginInformations_DB).isAdmin}`);

            tmp_username = (response as type_call_getLoginInformations_DB).username;
            tmp_realname = (response as type_call_getLoginInformations_DB).realname;
            tmp_idResource = (response as type_call_getLoginInformations_DB).idResource;
            tmp_authorization = (response as type_call_getLoginInformations_DB).authorization;
            tmp_isAdmin = (response as type_call_getLoginInformations_DB).isAdmin;
        }

        if(mode === "API") {
            DBLogs_addLog("Login with API");
            DBLogs_addLog(`Admin: ${(response as type_call_getLoginInformations_Reponse).grants.includes(CONF_ADMIN_GRANT)}`);

            DBLogin_addUser(
                (response as type_call_getLoginInformations_Reponse).userName,
                (response as type_call_getLoginInformations_Reponse).realName,
                (response as type_call_getLoginInformations_Reponse).password,
                (response as type_call_getLoginInformations_Reponse).idResource,
                (response as type_call_getLoginInformations_Reponse).grants.includes(CONF_ADMIN_GRANT)
            );

            tmp_username = (response as type_call_getLoginInformations_Reponse).userName;
            tmp_realname = (response as type_call_getLoginInformations_Reponse).realName;
            tmp_idResource = (response as type_call_getLoginInformations_Reponse).idResource;
            tmp_authorization = btoa((response as type_call_getLoginInformations_Reponse).userName + ':' + (response as type_call_getLoginInformations_Reponse).password);
            tmp_isAdmin = (response as type_call_getLoginInformations_Reponse).grants.includes(CONF_ADMIN_GRANT);
        }


        set({ loggedIn: tmp_loggedIn });
        set({ username: tmp_username! });
        set({ realname: tmp_realname! });
        set({ idResource: tmp_idResource! });
        set({ authorization: tmp_authorization! });
        set({ isAdmin: tmp_isAdmin! });

    }, // eo function mut_call_login()



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$             /$$     /$$
    // #   /$$__  $$           | $$    |__/
    // #  | $$  \ $$  /$$$$$$$/$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$       | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$       | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$ |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/  \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_call_login()
    *
    * Desc: Read the userinfos from the database or from the API.
    * Note: If there are no infos in the database table, call the API.
    * Note: Call the API only if there is a network connection.
    * Note: Allow login only for users with USAGE_GRANT, but also allow ADMINS
    * Note: without this USAGE_GRANT if they have the ADMIN_GRANT.
    *
    */
    actn_call_login: (username: string, password: string) => set(async () => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected,
              gl_shared_language = useSettingsLanguageStore.getState().language;


        showSpinner();

        DBLogs_addLog("Try to login: " + username);

        if (gl_networkConnected === true) {
            await axios.get('/acm/acl/login', { headers: createHeaders(username, password) })
                .then(async (response: type_Response.response) => {
                    if (response.headers["air41-status"] === "OK") {
                        if(response.data.grants.includes(CONF_USAGE_GRANT) || response.data.grants.includes(CONF_ADMIN_GRANT)) {
                            get().mut_call_login(Object.assign(response.data, { password: password }), "API");
                            PubSub.publish("call_getLoginInformations_Success");
                        } else {
                            showToastError(
                                lang.components.Shared.Toasts.Error[gl_shared_language],
                                lang.components.Shared.Toasts.NoGrants[gl_shared_language]
                            );
                        }
                    } else {
                        showToastError(
                            lang.components.Shared.Toasts.Error[gl_shared_language],
                            lang.components.Shared.Toasts.WrongEMailPassword[gl_shared_language]
                        );
                    }
                })
                .catch(() => {
                    showToastError(
                        lang.components.Shared.Toasts.Error[gl_shared_language],
                        lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                    );
                });
        } else {
            let userFromDB = await DBLogin_login(username, password);

            if (userFromDB !== undefined) {
                get().mut_call_login(userFromDB, "DB");
                PubSub.publish("call_getLoginInformations_Success");
            } else {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.UserNotAvailableForOfflineMode[gl_shared_language]
                );
            }
        }

        hideSpinner();

    }), // eo function actn_call_login()


    /***************************************************************************
    * actn_resetLoginStore()
    *
    * Desc: Reset Store to inital state.
    *
    */
    actn_resetLoginStore: () => {

        set(initalState);

    }, // eo function actn_resetLoginStore()

}));