/*
* PaymentCS.tsx
*
* Description: Page: PaymentCS.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useState } from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonNote, IonButton, IonFooter, IonMenuToggle, IonAlert, IonGrid, IonRow, IonCol } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from "styled-components";
import { useHistory } from 'react-router';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import ValueSuggestionBar from "../../components/Proceed/PaymentCS/CashDeskKeybaord/ValueSuggestionBar";
import Keyboard from "../../components/Proceed/PaymentCS/CashDeskKeybaord/Keyboard";
import EditSegment from "../../components/Proceed/PaymentCS/CashDeskKeybaord/EditSegment";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from '../../stores/currency/currency';
import { useSettingsLanguageStore } from '../../stores/shared/settings';
import { useCurrentOrderStore } from '../../stores/shared/currentOrder';
import { useCurrentCustomerStore } from '../../stores/shared/currentCustomer';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, renderFixedCurrency } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_COMPANY_BASE_CURRENCY } from '../../tsx/config';
import lang from "../../tsx/language.json";
import { useNetworkStore } from '../../stores/shared/network';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const PaymentCS: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [editPrecomma, setEditPrecomma] = useState<boolean>(true);
    const [paymentAmountPrecomma, setPaymentAmountPrecomma] = useState<string>("0");
    const [paymentAmountDecimal, setPaymentAmountDecimal] = useState<string>("00");
    const [change, setChange] = useState<number>(-1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [changeGivenInEuro, setChangeGivenInEuro] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State
    // >>> --------------------------------------------------------------------- Global State
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_currecy_exchangeRates = useCurrencyStore(state => state.exchangeRates);
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    const gl_currentOrder_totalSum = useCurrentOrderStore(state => state.get_totalSum());
    const gl_currentOrder_ChangesInEuro = useCurrentOrderStore(state => state.changesInEuro)
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_network_networkConnected = useNetworkStore(state => state.networkConnected);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_addChangesInEuro = useCurrentOrderStore(state => state.actn_addChangesInEuro);
    const actn_setPaymentMethod = useCurrentCustomerStore(state => state.actn_setPaymentMethod);
    // <<< --------------------------------------------------------------------- Global Actions
    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions
    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /*******************************************************************************
    * useEffect()
    *
    * Desc: Listen to changes on payment value to recalculate change.
    *
    */
    useEffect(() => {

        let newChange = 0,
            paymentAmountAsNumber = parseFloat(paymentAmountPrecomma + "." + paymentAmountDecimal);


        newChange = paymentAmountAsNumber - gl_currentOrder_totalSum;
        setChange(newChange);

    }, [paymentAmountPrecomma, paymentAmountDecimal]); // eo funciton useEffect()




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_setPaymentAmountByValue()
    *
    * Desc: Set the current payment amount from a numeric value.
    * Note:
    *
    */
    const onClick_setPaymentAmountByValue = (value: number) => {

        let valueAsString = value.toString();


        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_setPaymentAmountByValue');
        setPaymentAmountPrecomma(valueAsString);
        setPaymentAmountDecimal("00");

    }; // eo function onClick_setPaymentAmountByValue


    /***************************************************************************
    * onChange_setEditPrecomma()
    *
    * Desc: Set editPrecomme in local state.
    * Note: editPrecomma changes the editable part of the payment input.
    * Note: true => edit precomma, so you can not edit the decimal part.
    * Note: false => not edit precomma, so you can edit the decimal part.
    *
    */
    const onChange_setEditPrecomma = (segmentButtonValue: string | undefined) => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onChange_setEditPrecomma');
        if (segmentButtonValue === "editPrecomma") {
            setEditPrecomma(true);
        } else {
            setEditPrecomma(false);
        }

    }; // eo function onChange_setEditPrecomma()


    /*******************************************************************************
    * onClick_keyPressed()
    *
    * Desc: On Keybaord click, edit the payment amount input value.
    * Note: Based on editPrecomma (local state) edit precomma or decimal part.
    *
    */
    const onClick_keyPressed = (pressedKeyValue: string) => {

        let newPrecommaValue = "0",
            newDecimalValue = "00",
            // exact speical case means that the user clicked "exact" on keyboard
            // this means that we need to show the totalSum of the order
            // as payment value input
            exactSpecialCase = pressedKeyValue === "EXACT";


        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_keyPressed');
        if (exactSpecialCase === true){

            let totalSum_stringParts = gl_currentOrder_totalSum.toString().split(/\.(.+)/);


            if(totalSum_stringParts.length > 1){
                //more than 1 part, maeans there is a precomma and a decimal part
                newPrecommaValue = totalSum_stringParts[0];

                //format decimal part to two digits
                //example: total: 2.5 -> decimal part 5 -> formatted 50
                if(totalSum_stringParts[1].length === 1){
                    totalSum_stringParts[1] += '0';
                }

                newDecimalValue = totalSum_stringParts[1];

                setPaymentAmountPrecomma(newPrecommaValue);
                setPaymentAmountDecimal(newDecimalValue);

            } else if(totalSum_stringParts.length === 1) {
                //exctly one part means there is only a precomma part
                newPrecommaValue = totalSum_stringParts[0];

                setPaymentAmountPrecomma(newPrecommaValue);

            }
        }

        if (exactSpecialCase === false){
            if(editPrecomma === true) {
                newPrecommaValue = calculateNewPrecommaValue(pressedKeyValue);

                setPaymentAmountPrecomma(newPrecommaValue);

            }else{
                newDecimalValue = calculateNewDecimalValue(pressedKeyValue);

                setPaymentAmountDecimal(newDecimalValue);

            }
        }
    };  // eo function onClick_keyPressed()


    /*******************************************************************************
    * calculateNewPrecommaValue()
    *
    * Desc: Create a formatted payment value as string.
    * Note: Empty => "0"
    * Note: Otherwise: "12345" (add the next pressed value at the end)
    * Note: DEL => deletes the last added value (at the end of the string) LIFO
    *
    */
    const calculateNewPrecommaValue = (pressedKeyValue: string) => {

        let newPrecommaValue = paymentAmountPrecomma;


        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> calculateNewPrecommaValue');
        switch(pressedKeyValue) {
            case("0"):
            case("1"):
            case("2"):
            case("3"):
            case("4"):
            case("5"):
            case("6"):
            case("7"):
            case("8"):
            case("9"):
                if(paymentAmountPrecomma === "0") {
                    newPrecommaValue = `${pressedKeyValue}`;
                } else {
                    newPrecommaValue += pressedKeyValue;
                }
                break;
            case("DEL"):
                if(paymentAmountPrecomma.length === 1) {
                    newPrecommaValue = "0";
                }else if(paymentAmountPrecomma !== "0") {
                    newPrecommaValue = paymentAmountPrecomma.slice(0, -1);
                }
                break;
        };


        return newPrecommaValue;

    }; // eo function calculateNewPrecommaValue()


    /*******************************************************************************
    * calculateNewDecimalValue()
    *
    * Desc: Create a formatted payment value as string.
    * Note: Empty => "00"
    * Note: 1 Input => "0{INPUTVALUE}"
    * Note: Otherwise: "12" limited to two numbers
    * Note: DEL => deletes the last added value (at the end of the string) LIFO
    *
    */
    const calculateNewDecimalValue = (pressedKeyValue: string) => {

        let newDecimalValue = paymentAmountDecimal;


        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> calculateNewDecimalValue');
        switch(pressedKeyValue) {
            case("0"):
            case("1"):
            case("2"):
            case("3"):
            case("4"):
            case("5"):
            case("6"):
            case("7"):
            case("8"):
            case("9"):
                if(paymentAmountDecimal === "00") {
                    newDecimalValue = `0${pressedKeyValue}`;
                } else if(paymentAmountDecimal.match(/^0[1-9]$/g)) {
                    newDecimalValue = `${paymentAmountDecimal[1]}${pressedKeyValue}`;
                }
                break;
            case("DEL"):
                if(paymentAmountDecimal.match(/^0[1-9]$/g)) {
                    newDecimalValue = "00";
                } else if(paymentAmountDecimal.match(/^[1-9][0-9]$/g)) {
                    newDecimalValue = `0${paymentAmountDecimal[0]}`;
                }
                break;
        };


        return newDecimalValue;

    }; // eo function calculateNewDecimalValue()


    /*******************************************************************************
    * onClick_goToCompleteOrder()
    *
    * Desc: Navigate to 'Complete Order' Screen.
    * Note:
    *
    */
    const onClick_goToCompleteOrder = (paymentMethod: 'CS' | 'CC' | 'DD') => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_goToCompleteOrder');
        actn_setPaymentMethod(paymentMethod); // #41


        if (gl_currency_currentCurrency.code === 'USD') { // #35
            if (change > 0) {
                setIsOpen(true);
            } else {
                history.replace('/complete');
            }
        } else {
            history.replace('/complete');
        }

    }; // eo function onClick_goToCompleteOrder()


    /***************************************************************************
    * onClick_backToPurchase()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_backToPurchase = () => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_backToPurchase');
        history.goBack();

    }; // eo function onClick_backToPurchase()


    /***************************************************************************
    * onClick_cancel_ChangesInEuroAlert()
    *
    * Desc:
    * Note:
    *
    */
     const onClick_cancel_ChangesInEuroAlert = () => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_cancel_ChangesInEuroAlert');
        setChangeGivenInEuro(false);
        actn_addChangesInEuro(false);
        history.replace('/complete');

    }; // eo function onClick_cancel_ChangesInEuroAlert()


    /***************************************************************************
    * onClick_confirm_ChangesInEuroAlert()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_confirm_ChangesInEuroAlert = () => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClick_confirm_ChangesInEuroAlert');
        setChangeGivenInEuro(true);
        actn_addChangesInEuro(true);
        history.replace('/complete');

    }; // eo function onClick_confirm_ChangesInEuroAlert()


    /***************************************************************************
    * onClose_ChangesInEuroAlert()
    *
    * Desc:
    * Note: Check: role: 'cancel'
    *
    */
    const onClose_ChangesInEuroAlert = () => {

        debugWriter('>>> pages/Proceed/PaymentCS.tsx >>> onClose_ChangesInEuroAlert');
        setIsOpen(false); // Important (Check: role: 'cancel')

    }; // eo function onClose_ChangesInEuroAlert()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onClick_backToPurchase}>
                            { lang.global.Labels.Cancel[gl_shared_language] }
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{ lang.components.Proceed.PaymentCS.Titles.CashPayment[gl_shared_language] }</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuToggle menu="end">
                            <IonButton>
                                { lang.components.Proceed.PaymentCS.Labels.Order[gl_shared_language] }
                            </IonButton>
                        </IonMenuToggle>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonItem className="flex flex-row flex-ai-cen" style={{ "--inner-padding-end": 0 }} lines="none">
                    <IonLabel>
                        { lang.components.Proceed.PaymentCS.Texts.Amount[gl_shared_language] }
                    </IonLabel>
                    <p style={{ fontSize: "1.5rem" }} className="sdr-text-color-primary mv-0">
                        {renderFixedCurrency(gl_currentOrder_totalSum, gl_currency_currentCurrency.code)}
                    </p>
                </IonItem>

                <div>
                    <p className="ion-text-right sdr-text-giant sdr-font-weight-500">
                        <StyledPaymentInputPart active={editPrecomma === true}>
                            {paymentAmountPrecomma}
                        </StyledPaymentInputPart>
                        {gl_settings_language === "de-DE" ? "," : "."}
                        <StyledPaymentInputPart active={editPrecomma === false}>
                            {paymentAmountDecimal}
                        </StyledPaymentInputPart>
                        {" "}
                        {gl_currency_currentCurrency.symbol}
                    </p>
                </div>

                {change >= 0 ?
                    <IonItem className="flex flex-row flex-ai-cen" style={{ alignItems: "flex-start", "--inner-padding-end": 0 }} lines="none">
                        <IonLabel style={{ marginTop: 2 }}>
                            { lang.components.Proceed.PaymentCS.Texts.Change[gl_shared_language] }
                        </IonLabel>
                        <IonNote>
                            <p style={{ marginTop: 0, marginBottom: 4, fontSize: "1.5rem" }} className="sdr-text-color-primary">{renderFixedCurrency(change, gl_currency_currentCurrency.code)}</p>
                            { gl_currency_currentCurrency.code !== CONF_COMPANY_BASE_CURRENCY.code ? <p style={{ marginTop: 4, textAlign: "right" }} className="sdr-text-normal sdr-text-biggernormal">{renderFixedCurrency(change / gl_currecy_exchangeRates![gl_currency_currentCurrency.code], CONF_COMPANY_BASE_CURRENCY.code)}</p> : null }
                        </IonNote>
                    </IonItem> : null}

            </IonContent>

            <IonFooter>
                <IonGrid>
                    <IonRow>
                        {/* <IonCol>
                            <IonButton onClick={() => onClick_goToCompleteOrder("CC")} disabled={change < 0} expand="block" color="primary" fill="solid">
                                { lang.components.Proceed.PaymentCS.Labels.FinishOrder1[gl_shared_language] }
                            </IonButton>
                        </IonCol> */}

                        <IonCol>
                            <IonButton onClick={() => onClick_goToCompleteOrder("CS")} disabled={change < 0} expand="block" color="primary" fill="solid">
                                { lang.components.Proceed.PaymentCS.Labels.FinishOrder2[gl_shared_language] }
                            </IonButton>
                        </IonCol>
{/*
                        <IonCol>
                            <IonButton onClick={() => onClick_goToCompleteOrder("DD")} disabled={change < 0} expand="block" color="primary" fill="solid">
                                { lang.components.Proceed.PaymentCS.Labels.FinishOrder3[gl_shared_language] }
                            </IonButton>
                        </IonCol> */}
                    </IonRow>
                </IonGrid>


                <StyledKeyboardContainer moreSpaceBottom={gl_network_networkConnected === false} style={{ width: "100%", background: "var(--ion-color-light)" }}>
                    <div style={{ maxWidth: 550, margin: "0 auto" }}>

                        <ValueSuggestionBar onClick={(value) => onClick_setPaymentAmountByValue(value)} />

                        <EditSegment onChange={(segmentButtonValue) => onChange_setEditPrecomma(segmentButtonValue)} />

                        <Keyboard
                            onClick={(pressedKeyValue: string) => onClick_keyPressed(pressedKeyValue)} />

                    </div>
                </StyledKeyboardContainer>
            </IonFooter>

            <IonAlert
                isOpen={isOpen}
                backdropDismiss={false}
                onDidDismiss={ () => onClose_ChangesInEuroAlert() }
                header={ lang.components.Proceed.PaymentCS.Alert.Header[gl_shared_language] }
                message={ lang.components.Proceed.PaymentCS.Alert.Message[gl_shared_language] }
                buttons={[
                    {
                        text: lang.global.Labels.No[gl_shared_language],
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: onClick_cancel_ChangesInEuroAlert
                    },
                    {
                        text: lang.global.Labels.Yes[gl_shared_language],
                        handler: onClick_confirm_ChangesInEuroAlert
                    }
                ]}
            />
        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/
/******************************************************************************
* StyledPaymentInputPart
*
*/
const StyledPaymentInputPart = styled.span<{ active: boolean }>`
    ${props => props.active === true ? "text-decoration: underline;" : ""}
`;


/******************************************************************************
* StyledPrice
*
* Note: Expand bottom margin, if the Offline Mode Bar is beyond the button.
*
*/
const StyledKeyboardContainer = styled.div<{ moreSpaceBottom: boolean }>`
    ${ props => props.moreSpaceBottom === true ? "padding-bottom: calc(var(--ion-safe-area-bottom) + 24px)" : "" }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default PaymentCS;
