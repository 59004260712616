/*
* currentOrder.tsx
*
* Description: Global Store for Products
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import produce from 'immer';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from '../currency/currency';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { vatCalculation } from '../../tsx/utilities'
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderProductType, ProductType } from '../../tsx/types';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    currentOrderItems: Array<OrderProductType>,
    currentOrderInvoiceId: string | null,
    changesInEuro: boolean,

    get_totalSum: () => number,

    mut_resetOrderStore: () => void,
    mut_setOrderInvoiceId: (invoiceId: string) => void,
    mut_removeCompleteProductFromCurrentOrder: (productToRemove: ProductType) => void,
    mut_removeProductFromCurrentOrder: (productToRemove: ProductType) => void,
    mut_clearCurrentOrder: () => void,
    mut_addProductToCurrentOrder: (productToAdd: ProductType) => void,
    mut_addDiscountToCurrentOrderProduct: (productId: number, discount: number) => void,
    mut_addChangesInEuro: (changesInEuro: boolean) => void;

    actn_addProductToCurrentOrder: (productToAdd: ProductType) => void,
    actn_addDiscountToCurrentOrderProduct: (productId: number, discount: number) => void,
    actn_removeProductFromCurrentOrder: (productToRemove: ProductType) => void,
    actn_removeCompleteProductFromCurrentOrder: (productToRemove: ProductType) => void,
    actn_clearCurrentOrder: () => void,
    actn_setOrderInvoiceId: (invoiceId: string) => void,
    actn_resetOrderStore: () => void,
    actn_addChangesInEuro: (changesInEuro: boolean) => void;
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    currentOrderItems: [],
    currentOrderInvoiceId: null,
    changesInEuro: false
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useCurrentOrderStore = create<StateType>((set, get) => ({
    ...initalState,



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$
    // #   /$$__  $$            | $$    | $$
    // #  | $$  \__/  /$$$$$$  /$$$$$$ /$$$$$$    /$$$$$$   /$$$$$$
    // #  | $$ /$$$$ /$$__  $$|_  $$_/|_  $$_/   /$$__  $$ /$$__  $$
    // #  | $$|_  $$| $$$$$$$$  | $$    | $$    | $$$$$$$$| $$  \__/
    // #  | $$  \ $$| $$_____/  | $$ /$$| $$ /$$| $$_____/| $$
    // #  |  $$$$$$/|  $$$$$$$  |  $$$$/|  $$$$/|  $$$$$$$| $$
    // #   \______/  \_______/   \___/   \___/   \_______/|__/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * get_totalSum()
    *
    * Desc: Returns the cummulated sum over all items in current order.
    * Note:
    *
    */
    get_totalSum: () => {

        const gl_currency_currentCurrency = useCurrencyStore.getState().currentCurrency;
        let total = 0;


        get().currentOrderItems.forEach((item) => {
            if(gl_currency_currentCurrency.code === "EUR") {
                total += item.priceEUR * item.counter;
            }

            if(gl_currency_currentCurrency.code === "USD") {
                total += item.priceUSD * item.counter;
            }
        });


        return total;

    }, // eo function get_totalSum()


    /***************************************************************************
    * get_totalSum()
    *
    * Desc: Returns the cummulated sum over all items in current order.
    * Note:
    *
    */
    get_totalVAT: () => {

        const gl_currency_currentCurrency = useCurrencyStore.getState().currentCurrency;

        let vat = 0;


        get().currentOrderItems.forEach((item) => {
            if(gl_currency_currentCurrency.code === "EUR") {
                vat += (item.priceEUR * item.counter);
            }

            if(gl_currency_currentCurrency.code === "USD") {
                vat += (item.priceUSD * item.counter);
            }
        });


        return vat;

    }, // eo function get_totalSum()



    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /*******************************************************************************
    * mut_addProductToCurrentOrder()
    *
    * Desc:
    * Note:
    *
    */
    mut_addProductToCurrentOrder: (productToAdd) => {

        const newOrderItems = produce(get().currentOrderItems, (draft: any) => { // TODO: any weg = Fehler ?

            let productToAddIndex = getProductIndex(productToAdd);

            if(productToAddIndex === -1) {
                draft.splice(0, 0, {...productToAdd, counter: 1});
            } else {
                draft[productToAddIndex].counter += 1;
            }

        });


        set({ currentOrderItems: newOrderItems });

    }, // eo function mut_addProductToCurrentOrder()


    /*******************************************************************************
    * mut_addDiscountToCurrentOrderProduct()
    *
    * Desc:
    * Note:
    *
    */
    mut_addDiscountToCurrentOrderProduct: (productId, discount) => {

        const newOrderItems = produce<Array<OrderProductType>>(get().currentOrderItems, (draft) => {

            console.log(JSON.stringify(draft))
            draft.forEach(orderItem => {
                if (orderItem.id === productId) {
                    orderItem.discountEUR = (discount * orderItem.priceEUR) / 100;
                    orderItem.discountUSD = (discount * orderItem.priceUSD) / 100;
                    orderItem.discountPercent = discount;
                    orderItem.priceEUR = orderItem.priceEUR - orderItem.discountEUR;
                    orderItem.priceUSD = orderItem.priceUSD - orderItem.discountUSD;
                    orderItem.vatEur = vatCalculation(orderItem.priceEUR, 'EUR', orderItem.vat);
                    orderItem.vatUsd = vatCalculation(orderItem.priceUSD, 'USD', orderItem.vat);
                }
            });

        });


        set({ currentOrderItems: newOrderItems });

    }, // eo function mut_addDiscountToCurrentOrderProduct()


    /*******************************************************************************
    * mut_removeProductFromCurrentOrder()
    *
    * Desc: Removes one unit of the product from the order. (couter - 1)
    * Note:
    *
    */
    mut_removeProductFromCurrentOrder: (productToRemove) => {

        const newOrderItems = produce(get().currentOrderItems, draft => {

            let productToRemoveIndex = getProductIndex(productToRemove);


            if(productToRemoveIndex > -1) {
                if(draft[productToRemoveIndex].counter > 1) {
                    draft[productToRemoveIndex].counter -= 1;
                } else {
                    draft.splice(productToRemoveIndex, 1);
                }
            }
        });


        set({ currentOrderItems: newOrderItems });

    }, // eo function mut_removeProductFromCurrentOrder()


    /*******************************************************************************
    * mut_removeCompleteProductFromCurrentOrder()
    *
    * Desc: Remove the complete product from the order. (counter = 0)
    * Note:
    *
    */
    mut_removeCompleteProductFromCurrentOrder: (productToRemove) => {

        const newOrderItems = produce(get().currentOrderItems, draft => {

            let productToRemoveIndex = getProductIndex(productToRemove);


            if(productToRemoveIndex > -1){
               draft.splice(productToRemoveIndex, 1);
            }

        });


        set({ currentOrderItems: newOrderItems });

    }, // eo function mut_removeCompleteProductFromCurrentOrder()


    /*******************************************************************************
    * mut_clearCurrentOrder()
    *
    * Desc: Remove all products from the order.
    * Note:
    *
    */
    mut_clearCurrentOrder: () => {

        set({ currentOrderItems: [] });

    }, // eo function mut_clearCurrentOrder()


    /*******************************************************************************
    * mut_setOrderInvoiceId()
    *
    * Desc:
    * Note:
    *
    */
    mut_setOrderInvoiceId: (invoiceId) => {

        set({ currentOrderInvoiceId: invoiceId });

    }, // eo function mut_setOrderInvoiceId()


    /*******************************************************************************
    * mut_resetOrderStore()
    *
    * Desc: Reset Store to inital state.
    * Note:
    *
    */
    mut_resetOrderStore: () => {

        set(initalState);

    }, // eo function mut_resetOrderStore()


    /*******************************************************************************
    * mut_addChangesInEuro()
    *
    * Desc:
    * Note:
    *
    */
    mut_addChangesInEuro: (changesInEuro) => {

        set({ changesInEuro : changesInEuro})

    }, // eo function mut_addChangesInEuro()




    //-----------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$
    // #   /$$__  $$            | $$    |__/
    // #  | $$  \ $$  /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-----------------------------------------------------------------------------/
    /*******************************************************************************
    * actn_addProductToCurrentOrder()
    *
    * Desc: Reset Store to inital state.
    * Note:
    *
    */
    actn_addProductToCurrentOrder: (productToAdd) => {

        get().mut_addProductToCurrentOrder(productToAdd);

    }, // eo function actn_addProductToCurrentOrder


    /*******************************************************************************
    * actn_addDiscountToCurrentOrderProduct()
    *
    * Desc: Calculate discount for item
    * Note:
    *
    */
    actn_addDiscountToCurrentOrderProduct: (productId, discount) => {

        get().mut_addDiscountToCurrentOrderProduct(productId, discount);

    }, // eo function actn_addDiscountToCurrentOrderProduct()


    /*******************************************************************************
    * actn_removeProductFromCurrentOrder()
    *
    * Desc: Removes one unit of the product from the order. (couter - 1)
    * Note:
    *
    */
    actn_removeProductFromCurrentOrder: (productToRemove) => {

        get().mut_removeProductFromCurrentOrder(productToRemove);

    }, // eo function actn_removeProductFromCurrentOrder()


    /*******************************************************************************
    * actn_removeCompleteProductFromCurrentOrder()
    *
    * Desc: Remove the complete product from the order. (counter = 0)
    * Note:
    *
    */
    actn_removeCompleteProductFromCurrentOrder: (productToRemove) => {

        get().mut_removeCompleteProductFromCurrentOrder(productToRemove);

    }, // eo function actn_removeCompleteProductFromCurrentOrder()


    /*******************************************************************************
    * actn_clearCurrentOrder()
    *
    * Desc: Remove all products from the order.
    * Note:
    *
    */
    actn_clearCurrentOrder: () => {

        get().mut_clearCurrentOrder();

    }, // eo function actn_clearCurrentOrder()


    /*******************************************************************************
    * actn_setOrderInvoiceId()
    *
    * Desc:
    * Note:
    *
    */
    actn_setOrderInvoiceId: (invoiceId) => {

        get().mut_setOrderInvoiceId(invoiceId);

    }, // eo function actn_setOrderInvoiceId()


    /*******************************************************************************
    * actn_resetOrderStore()
    *
    * Desc: Reset Store to inital state.
    * Note:
    *
    */
    actn_resetOrderStore: () => {

        get().mut_resetOrderStore();

    }, // eo function actn_resetOrderStore()


    /***************************************************************************
    * actn_addChangesInEuro()
    *
    * Desc: Save if 'Changes was given back in Euro (Dollar is set)'.
    * Note:
    *
    */
    actn_addChangesInEuro: (changesInEuro) => {

        get().mut_addChangesInEuro(changesInEuro);

    } // eo function actn_addChangesInEuro()

}));





/*******************************************************************************
* getProductIndex()
*
* Desc: Returns the index of the parameter given product.
* Note: Returns -1 if product is not in current order list.
*
*/
const getProductIndex = (product: ProductType) => {

    const currentOrder = useCurrentOrderStore.getState().currentOrderItems;

    let productIndex = -1;


    for(let i = 0; i < currentOrder.length; i++){
        if(currentOrder[i].name === product.name){
            productIndex = i;
            break;
        }
    }


    return productIndex;

}; // eo function getProductIndex()

