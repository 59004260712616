/*
* login.tsx
*
* Description: Database Management with DEXIE for the Login Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from 'dexie';
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter, showToastWarning } from '../tsx/utilities';
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBLogin } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CONF_DAYS_LOGIN_EXPIRY } from '../tsx/config';
import lang from "../tsx/language.json";
import { useSettingsLanguageStore } from '../stores/shared/settings';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                                     
// #  |__  $$__/                                     
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$ 
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/ 
// #          /$$  | $$| $$                          
// #         |  $$$$$$/| $$                          
// #          \______/ |__/ 
// #
//-----------------------------------------------------------------------------/
export type type_DBLogin_UserObject = {
    username: string, 
    realname: string, 
    idResource: number, 
    isAdmin: boolean,
    expiryDate: string,
    authorization: string,
};
type type_DBLogin_addUser = (username: string, realname: string, password: string, idResource: number, isAdmin: boolean) => void;
type type_DBLogin_login = (username: string, password: string) => Promise<{ username: string, realname: string, isAdmin: boolean, idResource: number, expiryDate: string, authorization: string } | undefined>;
type type_DBLogin_getTableHeader = () => Promise<Array<IndexSpec>>;
type type_DBLogin_deleteAllEntries = () => Promise<void>;
type type_DBLogin_getAllEntries = () => Promise<Array<type_Schema_DBLogin>>;




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                              /$$     /$$                              
// #  | $$_____/                             | $$    |__/                              
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$ 
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/ 
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBLogin_addUser()
*
* Desc: Adds user to the database, when logged in successfully.
* Note: Hash the password with sha256.
*
*/
export const DBLogin_addUser: type_DBLogin_addUser = async (username, realname, password, idResource, isAdmin) => {
    
    const db = await DB_openDatabase(),
          loginTable = db.table<type_Schema_DBLogin>("login"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBLogin_addUser");
    loginTable
        .put({
            username: username, realname: realname, idResource: idResource, authorization: btoa(username + ":" + password), isAdmin: isAdmin === true ? 1 : 0, expiryDate: dayjs().add(CONF_DAYS_LOGIN_EXPIRY, "day").toISOString()
        })
        .catch(() => {
            showToastWarning(
                lang.components.Shared.Toasts.Warning[gl_shared_language],
                lang.components.Shared.Toasts.CouldNotSaveUser[gl_shared_language]
            );
        });

}; // eo function DBLogin_addFlights()


/*******************************************************************************
* ASYNC DBLogin_login()
*
* Desc: Returns the user which were saved with the given idResource.
* Note: If no user equals the given credentials, return undefined.
* Note: Check if the expiryDate of this saved user from the DB is still valid.
*
*/
export const DBLogin_login: type_DBLogin_login = async (username, password) => {

    const db = await DB_openDatabase(),
          loginTable = db.table<type_Schema_DBLogin>("login");

    let userObject: type_DBLogin_UserObject | undefined = undefined,
        foundUser: type_Schema_DBLogin | undefined;
        
        
    debugWriter(">>> Database: DBLogin_login");
    foundUser = await loginTable
        .get({ authorization: btoa(username + ":" + password) })
        .catch(() => {
            return undefined;
        });


    if (foundUser !== undefined) {
        if (dayjs(foundUser.expiryDate).isAfter(dayjs())) {
            userObject = Object.assign(foundUser, { isAdmin: foundUser.isAdmin === 1 });
        }
    }


    return userObject;

}; // eo function DBLogin_login()


/*******************************************************************************
* ASYNC DBLogin_getAllEntries()
*
* Desc: Returns the login data which were saved.
* Note: Only return "save to view" infos.
*
*/
export const DBLogin_getAllEntries: type_DBLogin_getAllEntries = async () => {

    const db = await DB_openDatabase(),
          loginTable = db.table<type_Schema_DBLogin>("login");

    let savedLogins = await loginTable
        .toArray()
        .catch((err) => {
            return [];
        });

        
    debugWriter(">>> Database: DBLogin_getAllEntries");
    savedLogins.forEach(login => {
        login.authorization = "********"
    });


    return savedLogins;

}; // eo function DBLogin_getAllEntries()


/*******************************************************************************
* ASYNC DBLogin_deleteAllEntries()
*
* Desc: Returns the login data which were saved.
* Note: Only return "save to view" infos.
*
*/
export const DBLogin_deleteAllEntries: type_DBLogin_deleteAllEntries = async () => {

    const db = await DB_openDatabase(),
          loginTable = db.table<type_Schema_DBLogin>("login");

        
    debugWriter(">>> Database: DBLogin_deleteAllEntries");
    await loginTable   
            .clear()
            .catch(() => {
                // do nothing here
            });

}; // eo function DBLogin_deleteAllEntries()


/*******************************************************************************
* ASYNC DBLogin_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBLogin_getTableHeader: type_DBLogin_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          loginTable = db.table<type_Schema_DBLogin>("login");

    debugWriter(">>> Database: DBLogin_getTableHeader");
    let tableSchema = loginTable.schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBLogin_getTableHeader()