/*
* AdminAreaDeleteEverything.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonAlert, IonButton, } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { debugWriter, showToastError, showToastInformation } from '../../../tsx/utilities';
import lang from "../../../tsx/language.json";
import { DB_openDatabase } from '../../../databases/utilities';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const AdminAreaDeleteEverything: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_showDeleteAlert()
    *
    * Desc: Show the Delete Warning Alert.
    *
    */
    const onClick_showDeleteAlert = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaDeleteEverything: onClick_showDeleteAlert");
        setShowDeleteAlert(true);

    }; //eo function onClick_showDeleteAlert()


    /*******************************************************************************
    * onClick_deleteEverything()
    *
    * Desc: Delete every database content.
    * Note: 
    *
    */
    const onClick_deleteEverything = async () => {

        const db = await DB_openDatabase(),
              gl_shared_language = useSettingsLanguageStore.getState().language;


        debugWriter(">>> UserDashboard/AdminArea/AdminAreaDeleteEverything: onClick_deleteEverything");
        db.delete()
            .then(() => {
                showToastInformation(
                    lang.components.Shared.Toasts.Success[gl_shared_language], 
                    lang.components.Shared.Toasts.DeleteEverything[gl_shared_language]
                );
            })
            .catch(() => {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language], 
                    lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                );
            });


    }; //eo function onClick_deleteEverything()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            
            <p className="sdr-text-large sdr-font-weight-500" style={{ marginTop: 64 }}>
                {lang.pages.AdminPanel.Titles.DeleteEverything[gl_settings_language]}
            </p>
            <p className="sdr-text-normal">
                {lang.pages.AdminPanel.Texts.DeleteEverything[gl_settings_language]}
            </p>
            <IonButton expand="block" onClick={onClick_showDeleteAlert} color="danger">
                {lang.pages.AdminPanel.Labels.DeleteEverything[gl_settings_language]}
            </IonButton>

            <IonAlert
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                header={lang.pages.AdminPanel.Titles.DeleteEverything[gl_settings_language]}
                message={lang.pages.AdminPanel.Texts.SureDeleteEverything[gl_settings_language]}
                buttons={[
                    {
                        text: lang.pages.AdminPanel.Labels.Cancel[gl_settings_language],
                        role: 'cancel'
                    },
                    {
                        text: lang.pages.AdminPanel.Labels.DeleteEverything[gl_settings_language],
                        role: 'delete',
                        handler: onClick_deleteEverything
                    }
                ]} />

        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AdminAreaDeleteEverything;
