/*
* utilities.tsx
*
* Description: Database Helper Functions.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
import Dexie from "dexie";
import { CONF_DATABASE_NAME } from '../tsx/config';



/*******************************************************************************
* ASYNC DB_openDatabase()
*
* Desc: Opens the database.
* Note: This is necessary for working with the database table.
* Note: It seems like that the first element in the list works as primary key.
* Note: (No source for this so far)
*
* Note: Attention: In Case of Table Updates ...
* Note: ... add 1 to the Version NUmber => db.version(3).stores (!!!)
*
*/
export const DB_openDatabase = async () => {

    const db = new Dexie(CONF_DATABASE_NAME);


    db.version(8).stores({
        login: 'idResource, username, realname, authorization, isAdmin, expiryDate',
        flights: 'flightId, aircraftType, aptFromDepartureTime, aptToArrivalTime, crew, flightNo, departureAirportInfo, destinationAirportInfo, idResource, createdByUser',
        products: 'code, description, extras, id, idCategory, name, price1, price2, priceEur, priceUsd, vatEur, vatUsd, hasWarning, warningComment, bestsellerCounter',
        categories: 'code, description, id, name',
        currencies: 'code, exchangeRate, updateTime',
        orders: 'invoiceId, aptFrom, aptTo, crewMembers, crewLogin, flightNo, flightId, flightDate, passengerSeat, paymentMethod, ccBrand, ccCvc, ccExpiryMonth, ccExpiryYear, ccHolder, ccNumber, ccSignature, invoiceAmount, invoiceVat, paymentAmount, entriesForProcess, entriesForPresentation, currency, emailInvoice, emailText, canceled, tipInOrderCurrency, tipInEur, idResource, emailAddress, timestamp, printCounter, totalInOrderCurrency, totalInEur, vatInOrderCurrency, vatInEur, syncedBefore',
        vats: 'aptIataCode, aptName, idCountry, vat, isEu, isGermany',
        logs: '++id, log, timestamp, loggedInUsername'
    });

    return db;

} // eo function DB_openDatabase()


/*******************************************************************************
* ASYNC DB_deleteDatabase()
*
* Desc: Drops the database tables.
*
*/
export const DB_clearTable = async (tableName: string) => {

    const db = await DB_openDatabase();
    let table = db.table(tableName);


    table
        .clear()
        .catch(err => null);

} // eo function DB_deleteTable()


/*******************************************************************************
* ASYNC DB_getAllTableNames()
*
* Desc: Returns all table names in database.
*
* @return {string[]} tableNames
*
*/
export const DB_getAllTableNames = async () => {

    const db = await DB_openDatabase();
    let tableNames: Array<string> = [];


    db.tables.forEach((table) => {
        tableNames.push(table.name);
    })


    return tableNames;

} // eo function DB_getAllTableNames()
