/*
* FlightDashboard.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter, useIonViewWillEnter } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useHistory } from 'react-router';
import styled from "styled-components";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import FlightDashboardSubheader from "../components/FlightDashboard/FlightDashboardSubheader";
import RevenueCard from "../components/FlightDashboard/CateringStats/RevenueCard";
import PurchaseOverview from "../components/FlightDashboard/PurchaseOverview/PurchaseOverview";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useFlightSelectionStore } from "../stores/flightselection/flights";
import { useLoginStore } from '../stores/login/login';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBOrders_getAllOrders, DBOrders_getSums } from '../databases/orders';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from '../tsx/types';
import lang from "../tsx/language.json";
import { useSettingsLanguageStore } from '../stores/shared/settings';
import { useNetworkStore } from '../stores/shared/network';
import SalesReportDetailsModal from '../components/UserDashboard/SalesReports/SalesReportDetailsModal';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type SumsType = {
    total: { "EUR": number, "USD": number },
    cc: { "EUR": number, "USD": number },
    cs: { "EUR": number, "USD": number },
    tip: { "EUR": number, "USD": number },
}
const FlightDashboard: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    const [sums, setSums] = useState<SumsType>();
    const [orders, setOrders] = useState<Array<OrderType>>([]);
    const [showSalesReportModal, setShowSalesReportModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_flightselection_selectedFlight = useFlightSelectionStore((state) => state.selectedFlight);
    const gl_login_idResource = useLoginStore((state) => state.idResource);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_network_networkConnected = useNetworkStore(state => state.networkConnected);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const pageRef = useRef<HTMLElement>(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useIonViewWillEnter()
    *
    * Desc:
    * Note:
    *
    */
    useIonViewWillEnter(() => {

        loadPurchaseData().then(sums => setSums(sums));
        loadOrdersData().then(orders => setOrders(orders.filter(order => order.syncedBefore === false)));
        // onEmit_DBOrders_cancelOrder_Success()

    }); // eo function useIonViewWillEnter()


    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('DBOrders_cancelOrder_Success', onEmit_DBOrders_cancelOrder_Success);

        return () => {
            PubSub.unsubscribe('DBOrders_cancelOrder_Success');
        }

    }); // eo function useEffect()




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_showSalesReport()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_showSalesReport = () => {

            setShowSalesReportModal(true);

    }; // eo function onClick_showSalesReport()


    /***************************************************************************
    * onClick_goToPurchase()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_goToPurchase = () => {

        history.push("/purchase");

    }; // eo function onClick_goToPurchase()


    /***************************************************************************
    * onEmit_DBOrders_cancelOrder_Success()
    *
    * Desc:
    * Note:
    *
    */
    const onEmit_DBOrders_cancelOrder_Success = () => {

        loadPurchaseData().then(sums => setSums(sums));
        loadOrdersData().then(orders => setOrders(orders.filter(order => order.syncedBefore === false)));

    }; // eo function onEmit_DBOrders_cancelOrder_Success()


    /***************************************************************************
    * onDidDismiss_hideSalesReport()
    *
    * Desc:
    * Note:
    *
    */
    const onDidDismiss_hideSalesReport = () => {

        setShowSalesReportModal(false);

    }; // eo function onDidDismiss_hideSalesReport()


    /***************************************************************************
    * onClick_goToSettings()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_goToSettings = () => {

        history.push('/settings');

    }; // eo function onClick_goToSettings()


    /***************************************************************************
    * onClick_goToFAQ()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_goToFAQ = () => {

        history.push('/faq');

    }; // eo function onClick_goToFAQ()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * getFlightObject()
    *
    * Desc:
    * Note: Orders must be more then 0 because buttons is only visible
    * Note: when there is at least one order.
    *
    */
    const getFlightObject = () => {

        return {
            flightId: gl_flightselection_selectedFlight!.id,
            flightNo: gl_flightselection_selectedFlight!.flightNo,
            aptFrom: gl_flightselection_selectedFlight!.departureAirportInfo.iataCode,
            aptTo: gl_flightselection_selectedFlight!.destinationAirportInfo.iataCode,
            aptFromDepartureTime: gl_flightselection_selectedFlight!.aptFromDepartureTime
        }

    }; // eo function getFlightObject()


    /***************************************************************************
    * loadOrdersData()
    *
    * Desc: Read current order infos from DB.
    * Note:
    *
    */
    const loadOrdersData = async () => {

        return await DBOrders_getAllOrders(gl_login_idResource!, gl_flightselection_selectedFlight!.id);

    }; // eo function loadOrdersData()


    /*******************************************************************************
    * loadPurchaseData()
    *
    * Desc: Read current order infos from DB.
    * Note:
    *
    */
    const loadPurchaseData = async () => {

        return await DBOrders_getSums(gl_flightselection_selectedFlight!.id, gl_login_idResource!);

    }; // eo function loadPurchaseData()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage ref={pageRef}>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton href="/login">
                            { lang.global.Labels.Logout[gl_shared_language] }
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={onClick_goToSettings}>
                            { gl_shared_language === "de-DE" ? "DE" : "EN" }
                        </IonButton>
                        <IonButton onClick={onClick_goToFAQ}>
                            ?
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{gl_flightselection_selectedFlight!.departureAirportInfo.iataCode} - {gl_flightselection_selectedFlight!.destinationAirportInfo.iataCode}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">{gl_flightselection_selectedFlight!.departureAirportInfo.iataCode} - {gl_flightselection_selectedFlight!.destinationAirportInfo.iataCode}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <FlightDashboardSubheader />

                <RevenueCard sums={sums} />

                <div className="mt-4" style={{ textAlign: "center" }}>
                    <IonButton
                        onClick={onClick_showSalesReport}
                        fill="clear">
                        { lang.components.FlightDashboard.PurchaseOverview.Labels.ShowSalesReport[gl_shared_language] }
                    </IonButton>
                </div>

                <PurchaseOverview
                    presentingElement={pageRef}
                    orders={orders} />

                <SalesReportDetailsModal
                    isOpen={showSalesReportModal}
                    onDidDismiss={onDidDismiss_hideSalesReport}
                    presentingElementef={pageRef}
                    flight={getFlightObject()} />

            </IonContent>
            <IonFooter className="ion-no-border">
                <StyledButton moreSpaceBottom={gl_network_networkConnected === false} onClick={onClick_goToPurchase} expand="block">
                    { lang.components.FlightDashboard.Texts.StartCatering[gl_shared_language] }
                </StyledButton>
            </IonFooter>
        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/
/******************************************************************************
* StyledPrice
*
* Note: Expand bottom margin, if the Offline Mode Bar is beyond the button.
*
*/
const StyledButton = styled(IonButton)<{ moreSpaceBottom: boolean }>`
    ${ props => props.moreSpaceBottom === true ? "margin-bottom: calc(var(--ion-safe-area-bottom) + 24px)" : "" }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FlightDashboard;
