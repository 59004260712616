import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonLabel, IonItem, IonSelect, IonSelectOption, IonBackButton } from '@ionic/react';

import { useSettingsLanguageStore } from '../stores/shared/settings';

import lang from "../tsx/language.json";
import { LanguageType } from '../tsx/types';

import { debugWriter, setLocalStorageLanguage } from "../tsx/utilities";


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const Settings: React.FC = () => {
    const gl_settings_lang: LanguageType = useSettingsLanguageStore((state) => state.language);

    const actn_setLanguage = useSettingsLanguageStore((state) => state.actn_setLanguage);


    /*******************************************************************************
    * onChange_changeLanguage()
    *
    * Desc: Change the global language.
    * Note: 
    * 
    * @param {Object} event (ionic event)
    *
    */
    const onChange_changeLanguage = (event: CustomEvent) => {
        debugWriter(">>> Settings: onChange_changeLanguage()");

        if (event.detail.value === "de-DE") {
            actn_setLanguage("de-DE");
            setLocalStorageLanguage("de-DE");
        } else {
            actn_setLanguage("en-EN");
            setLocalStorageLanguage("en-EN");
        }
    };


    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{lang.pages.Settings.Titles.Settings[gl_settings_lang]}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">{lang.pages.Settings.Titles.Settings[gl_settings_lang]}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonList>
                    <IonItem>
                        <IonLabel>
                            {lang.pages.Settings.Labels.Language[gl_settings_lang]}
                        </IonLabel>
                        <IonSelect
                            okText="OK"
                            cancelText="Abbrechen"
                            value={gl_settings_lang}
                            onIonChange={onChange_changeLanguage}>
                            <IonSelectOption value="de-DE">
                                {lang.pages.Settings.Labels.German[gl_settings_lang]}
                            </IonSelectOption>
                            <IonSelectOption value="en-EN">
                                {lang.pages.Settings.Labels.English[gl_settings_lang]}
                            </IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default Settings;
