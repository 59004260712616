/*
* vats.tsx
*
* Description: Database Management with DEXIE for the Vats Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from 'dexie';
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../tsx/utilities';
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBVats } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Vat } from '../types/products';
// <<< Resources ---------------------------------------------------------------


//-----------------------------------------------------------------------------/
// #
// #  /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type type_DBVats_addCVats = (vatsToAdd: Array<type_products_Vat>) => Promise<void>;
type type_DBVats_addCVat = (vatToAdd: type_products_Vat) => Promise<void>;
type type_DBVats_getAllVats = () => Promise<Array<type_products_Vat>>;
type type_DBVats_getTableHeader = () => Promise<Array<IndexSpec>>;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                              /$$     /$$
// #  | $$_____/                             | $$    |__/
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBVats_addVats()
*
* Desc: Adds vats to the database, which were send from the backend.
* Note:
*
*/
export const DBVats_addVats: type_DBVats_addCVats = async (vatsToAdd) => {

    debugWriter(" >>> Database: type_DBVats_addCVats");
    for(let i = 0; i < vatsToAdd.length; i++) {
        await DBVats_addVat(vatsToAdd[i]);
    }

}; // eo function DBVats_addVats()


/*******************************************************************************
* ASYNC DBVats_addVat()
*
* Desc: Adds a vat to the database, which were send from the backend.
* Note:
*
*/
export const DBVats_addVat: type_DBVats_addCVat = async (vatToAdd) => {

    const db = await DB_openDatabase(),
          vatsTable = db.table<type_Schema_DBVats>("vats");


    debugWriter(" >>> Database: DBVats_addVat");
    await vatsTable
        .put({
            aptIataCode: vatToAdd.aptIataCode,
            aptName: vatToAdd.aptName,
            idCountry: vatToAdd.idCountry,
            vat: vatToAdd.vat,
            isEu: vatToAdd.isEu,
            isGermany: vatToAdd.isGermany
        }, "aptIataCode")
        .catch(() => {
            // do nothing
        });

}; // eo function DBVats_addVat()


/*******************************************************************************
* ASYNC DBVats_getAllVats()
*
* Desc: Returns the vats which were saved.
* Note: 
*
*/
export const DBVats_getAllVats: type_DBVats_getAllVats = async () => {

    const db = await DB_openDatabase(),
          vatsTable = db.table<type_Schema_DBVats>("vats");


    debugWriter(">>> Database: DBVats_getAllVats");
    return await vatsTable
        .toArray()
        .catch(() => {
            return [];
        });

}; // eo function DBVats_getAllVats()


/*******************************************************************************
* ASYNC DBVats_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBVats_getTableHeader: type_DBVats_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          vatsTable = db.table("vats");


    debugWriter(">>> Database: DBVats_getTableHeader");
    let tableSchema = vatsTable.schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBVats_getTableHeader()