/*
* CategoryContainer.tsx
*
* Description: Container of Category and Icon
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import CategoryRow from "./CategoryRow";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useProductsStore } from '../../stores/products/products';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Category, type_products_Product_Extended } from '../../types/products';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    bestseller: Array<type_products_Product_Extended>;
}
const CategoryContainer: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_categories = useProductsStore(state => state.categories);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References

    


    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * getDeviceScreenWidth()
    *
    * Desc: Returns device screen width.
    * Note: 
    *
    */
    const getDeviceScreenWidth = () => {

        return window.innerWidth;

    }; // eo function getDeviceScreenWidth()


    /***************************************************************************
    * renderCategoryRow()
    *
    * Desc: 
    * Note: 
    *
    */
    const renderCategoryRow = () => {

        const categoryElementWidth = 174; // 150 width + 24 margin
        let rows: Array<JSX.Element> = [],
            elementsPerRow = Math.floor(getDeviceScreenWidth() / categoryElementWidth),
            categoriesForRow: Array<type_products_Category> = [],
            categoriesToRender: Array<type_products_Category> = [];


        categoriesToRender = [...gl_categories];
        categoriesToRender.unshift({ code: "BESTSELLER", name: "Bestseller", id: 0, description: "" });

        for(let i = 0; i < categoriesToRender.length; i++) {
            
            categoriesForRow.push(categoriesToRender[i]);

            // i === 0 - ignore first loop
            // (i + 1) % elementsPerRow === 0 - moment, when elements per row are collected
            // i === gl_categories.length - 1 - last loop, if it does not fit the first condition
            if((i !== 0 && (i + 1) % elementsPerRow === 0) || i === categoriesToRender.length - 1) {
                rows.push(
                    <CategoryRow 
                        key={`${i}-cat-row`} 
                        categories={categoriesForRow} 
                        elementsPerRow={elementsPerRow}
                        bestseller={props.bestseller} />
                )

                categoriesForRow = [];
            }
        }


        return rows;

    }; // eo funciton renderCategoryRow()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div>
            { renderCategoryRow() }
        </div>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default CategoryContainer;