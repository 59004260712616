/*
* VideoAccordion.tsx
*
* Description:
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import { IonItemDivider, IonIcon } from '@ionic/react';
import { chevronDownCircleOutline, chevronUpCircleOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from 'styled-components';
import AnimateHeight from "react-animate-height";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    title: string;
    content: Array<{ headline: string, text: string }>;
    videoURL: string;
    posterURL: string;
    videoID: string;
    isOpen: boolean;
    onToggle: (id: string) => void;
}
const VideoAccordion: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [videoItemHeight, setVideoItemHeight] = useState<0 | "auto">(props.isOpen ? "auto" : 0);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const videoRef = useRef<HTMLVideoElement>(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useEffect()
    *
    * Desc: Play and pause video on toggling the container.
    * Note:
    *
    */
    useEffect(() => {
        
        if(videoRef && videoRef.current) {
            if(videoItemHeight === "auto") {
                // videoRef.current.play();
            }
            if(videoItemHeight === 0) {
                videoRef.current.pause();
            }
        }

    }, [videoItemHeight]); // eo function useEffect()


    /***************************************************************************
    * useEffect()
    *
    * Desc: Auto close the video container div.
    * Note: This happens when another video container div will be opened.
    *
    */
    useEffect(() => {

        if(props.isOpen === true) {
            setVideoItemHeight("auto");
        }
        if(props.isOpen === false) {
            setVideoItemHeight(0);
        }

    }, [props.isOpen]); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_toggleItemList()
    *
    * Desc: Toggles the height of the list.
    * Note: 
    *
    */
    const onClick_toggleItemList = () => {

        if(videoItemHeight === 0) {
            setVideoItemHeight("auto");
            props.onToggle(props.videoID);
        } else {
            setVideoItemHeight(0);
            props.onToggle(props.videoID);
        }

    }; // eo function onClick_toggleItemList()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <div style={{ marginBottom: 16 }}>
            <StyledTitle onClick={() => onClick_toggleItemList()}>
                <p className="sdr-text-biggernormal sdr-font-weight-400 sdr-text-color-primary">{props.title}</p>

                { videoItemHeight === 0 ? <IonIcon className="sdr-text-large" slot="end" color="primary" icon={chevronDownCircleOutline} /> : null }
                { videoItemHeight === "auto" ? <IonIcon className="sdr-text-large" slot="end" color="medium" icon={chevronUpCircleOutline} /> : null }
            </StyledTitle>

            <AnimateHeight height={videoItemHeight}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <video poster={ props.posterURL } preload="auto" style={{ height: 800, marginTop: 32, marginBottom: 32 }} src={ props.videoURL } ref={ videoRef } controls></video>
                </div>

                { props.content.map((entry, index) => (
                    <div key={`text-block-${index}`}>
                        <p className="sdr-font-weight-500">
                            { entry.headline }
                        </p>

                        <p dangerouslySetInnerHTML={{ __html: entry.text }}>
                        </p>
                    </div>
                )) }
            </AnimateHeight>
        </div>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledTitle
* 
*/
const StyledTitle = styled(IonItemDivider)`
    background: #ffffff;
    padding: 8px 0;
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default VideoAccordion;
