/*
* CrewMembers.tsx
*
* Description: Component: CrewMembers.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import { IonChip, IonIcon, IonList, IonItem, IonInput } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from 'styled-components';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useLoginStore } from '../../../../stores/login/login';
import { useSettingsLanguageStore } from '../../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CrewMemberType, LanguageType } from '../../../../tsx/types';
import lang from "../../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
interface In{
    onChange: (crewArray: Array<string>) => void;
    defaultValue?: Array<CrewMemberType>;
}
const CrewMembers: React.FC<In> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [crewmembers, setCrewmembers] = useState<Array<string>>([]);
    const [showInputLengthError, setShowInputLengthError] = useState<boolean>(false);
    const [showInputDuplicateError, setShowInputDuplicateError] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language: LanguageType = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const get_login_3LC = useLoginStore(state => state.get_3LC);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const crewMemberInputRef = useRef<HTMLIonInputElement>(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /*******************************************************************************
    * useEffect()
    *
    * Desc: If a default value is set, save it to the local state and trigger the
    * Desc: callback onChange.
    * Note: This is done, whenever the crew defaulr value changes.
    *
    */
    useEffect(() => {

        let tmp_crewmembers: Array<string> = [];


        if(props.defaultValue !== null && props.defaultValue !== undefined) {
            for(let i = 0; i < props.defaultValue.length; i++){
                if(props.defaultValue[i].contract3Code !== get_login_3LC()) {
                    tmp_crewmembers.push(props.defaultValue[i].contract3Code);
                }
            }
        }

        setCrewmembers(tmp_crewmembers);
        props.onChange(tmp_crewmembers);

    }, [props.defaultValue]); // eo function useEffect()



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onChange_addCrewmember()
    *
    * Desc: Adds a Crewmember to the local state crewMemebrs array.
    * Note: 
    *
    */
    const onChange_addCrewmember = (crewMemberToAdd: string) => {

        let tmp_crewmembers = [...crewmembers];


        debugWriter(">>> CrewMembers: onChange_addCrewmember()");
        tmp_crewmembers.push(crewMemberToAdd);

        setCrewmembers(tmp_crewmembers);
        props.onChange(tmp_crewmembers);

    }; // eo funciton onChange_addCrewmember()


    /*******************************************************************************
    * onChange_extractCrewMember()
    *
    * Desc: Get the 3 Letter Code from the input and add it to the crew array.
    * Note:  
    *
    */
    const onChange_extractCrewMember = (addCrewmemberInput: string | null | undefined) => {

        let addCrewmemberAllowed = true,
            addCrewmemberInputUppercase = "";


        debugWriter(">>> CrewMembers: onChange_extractCrewMember()");
        if(addCrewmemberInput !== undefined && addCrewmemberInput !== null) {

            addCrewmemberInputUppercase = addCrewmemberInput.toUpperCase();

            //show Length Error
            if(addCrewmemberInputUppercase.length !== 3){
                if(addCrewmemberInputUppercase.length > 3){
                    setShowInputLengthError(true);
                }
                addCrewmemberAllowed = false;
            }

            //show Duplicate Error
            if(crewmembers.includes(addCrewmemberInputUppercase) === true ||addCrewmemberInputUppercase === get_login_3LC()){
                setShowInputDuplicateError(true);
                addCrewmemberAllowed = false;
            }else{
                setShowInputDuplicateError(false);
            }

            //save crewmember
            if(addCrewmemberAllowed === true){

                onChange_addCrewmember(addCrewmemberInputUppercase);

                setShowInputDuplicateError(false);
                setShowInputLengthError(false);
                crewMemberInputRef.current!.value = "";

            }
        }
    }; // eo funciton onChange_extractCrewMember()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /*******************************************************************************
    * deleteCrewmember()
    *
    * Desc: Deletes an crewmemeber from crewMembers array.
    * Note: 
    *
    */
    const deleteCrewmember = (index: number) => {

        let tmp_crewmembers = [...crewmembers];


        debugWriter(">>> CrewMembers: deleteCrewmember()");
        tmp_crewmembers.splice(index, 1);

        setCrewmembers(tmp_crewmembers);
        props.onChange(tmp_crewmembers);

    }; // eo function deleteCrewmember()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <React.Fragment>
            <IonList>
                <p>
                    <IonChip key={`crewmember-self`}> {get_login_3LC()} </IonChip>

                    { crewmembers.map((member, index) => {
                        return (
                            <IonChip key={`crewmember-${index}`} onClick={() => deleteCrewmember(index)}> {member} <IonIcon icon={closeCircle} /> </IonChip>
                        )
                    }) }
                    
                </p>
            </IonList>
            
            <IonItem>
                <IonInput 
                    style={{ textTransform: "uppercase" }}
                    minlength={3} 
                    maxlength={3} 
                    ref={crewMemberInputRef} 
                    onIonChange={(e) => onChange_extractCrewMember(e.detail.value)}>
                </IonInput>
            </IonItem>
            <StyledInputError visible={showInputLengthError} className="sdr-text-error sdr-text-small">
                {lang.components.FlightSelection.CrewMembers.Texts.Exact3Letters[gl_settings_language]}
            </StyledInputError>
            <StyledInputError visible={showInputDuplicateError} className="sdr-text-error sdr-text-small">
                {lang.components.FlightSelection.CrewMembers.Texts.MemberAlreadyInCrew[gl_settings_language]}
            </StyledInputError>

        </React.Fragment>
    );
};


//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledInputError
* 
*/
const StyledInputError = styled.p<{ visible: boolean }>`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default CrewMembers;
