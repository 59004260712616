/*
* CompleteOrder.tsx
*
* Description: Order Success Page
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, useRef } from 'react';
import { IonButton, IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import PrintModal from "../components/CompleteOrder/PrintModal";
import EMailModal from "../components/CompleteOrder/EMailModal";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrentOrderStore } from '../stores/shared/currentOrder';
import { useCurrentCustomerStore } from '../stores/shared/currentCustomer';
import { useFlightSelectionStore } from '../stores/flightselection/flights';
import { useLoginStore } from '../stores/login/login';
import { useCurrencyStore } from '../stores/currency/currency';
import { useNetworkStore } from '../stores/shared/network';
import { useSettingsLanguageStore } from '../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBOrders_addOrder } from '../databases/orders';
import { DBProducts_increaseProductBestsellerCounter } from '../databases/products';
import { debugWriter, round } from '../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { CrewMemberType, OrderProductType, OrderType } from '../tsx/types';
import lang from "../tsx/language.json";
import "./CompleteOrder.css";
import styled from 'styled-components';
import { useProductsStore } from '../stores/products/products';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const CompleteOrder: React.FC = () => {

    const goOnTime = 2; // 2 seconds

    // >>> --------------------------------------------------------------------- Local State
    const [savingOrderDone, setSavingOrderDone] = useState<boolean>(false);
    const [showEMailModal, setShowEMailModal] = useState<boolean>(false);
    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
    const [goOnTimer, setGoOnTimer] = useState<number>(0);
    const [countdownInterval, setCountdownInterval] = useState<number>(0);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_flightselection_selectedFlight = useFlightSelectionStore(state => state.selectedFlight);
    const gl_login_userName = useLoginStore(state => state.username);
    const gl_login_idResource = useLoginStore(state => state.idResource);
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_currency_exchangeRates = useCurrencyStore(state => state.exchangeRates);
    const gl_currentOrder_invoiceId = useCurrentOrderStore(state => state.currentOrderInvoiceId);
    const gl_currentOrder_totalSum = useCurrentOrderStore(state => state.get_totalSum());
    const gl_currentOrder_currentOrderItems = useCurrentOrderStore(state => state.currentOrderItems);
    const gl_currentCustomer_paymentMethod = useCurrentCustomerStore(state => state.paymentMethod);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    const gl_network_networkConnected = useNetworkStore(state => state.networkConnected);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const pageRef = useRef(null);
    const countdownRef = useRef<HTMLParagraphElement>(null);
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Lifecycle
    ***************************************************************************/
    /***************************************************************************
    * useIonViewDidEnter()
    *
    * Desc: Create Order Object and save in DB.
    * Note:
    *
    */
    useIonViewDidEnter(async () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: useIonViewDidEnter()");
        DBOrders_addOrder(createOrderObject());
        updateBestsellerTable();
        setSavingOrderDone(true);


        // start timer (2 seconds)
        let t = setTimeout(() => {
            onClick_goToPurchase();
        }, goOnTime * 1000);

        setGoOnTimer(t);


        let ct = goOnTime;

        let i = setInterval(() => {
            if(countdownRef && countdownRef.current) {
                countdownRef.current.innerText = String(--ct);
            }
        }, 1000);

        setCountdownInterval(i);

    }); // eo function useIonViewDidEnter()




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_goToPurchase()
    *
    * Desc: Go to purchase Screen.
    * Note: We can go "back" because the complete payment process will not be
    * Note: saved in the history. This means Purchase Screen is the "last"
    * Note: screen we can navigate back to.
    *
    */
    const onClick_goToPurchase = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: onClick_goToPurchase()");
        clearInterval(countdownInterval);
        useCurrentOrderStore.getState().actn_resetOrderStore();

        history.goBack();

    }; // eo function onClick_goToPurchase()


    /***************************************************************************
    * onClick_showEMailModal()
    *
    * Desc: Open E-Mail Modal.
    * Note:
    *
    */
    const onClick_showEMailModal = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: onClick_showEMailModal()");
        setShowEMailModal(true);

    }; // eo function onClick_showEMailModal()


    /***************************************************************************
    * onClick_showEMailModal()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_showPrintModal = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: onClick_showPrintModal()");
        setShowPrintModal(true);

    }; // eo function onClick_showPrintModal()


    /***************************************************************************
    * onClick_stopCatering()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_stopCatering = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: onClick_stopCatering()");
        history.replace('/flightdashboard');

    }; // eo function onClick_stopCatering


    /***************************************************************************
    * onClick_test()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_test = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: onClick_test()");
        clearTimeout(goOnTimer);
        clearInterval(countdownInterval);
        if(countdownRef && countdownRef.current) {
            countdownRef.current.innerText = lang.pages.CompleteOrder.Texts.Stopped[gl_shared_language];
            countdownRef.current.style.opacity = "0.5";
        }

    }; // eo function onClick_test()




    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * updateBestsellerTable()
    *
    * Desc: Increase Product Counter in Database.
    * Note:
    *
    */
    const updateBestsellerTable = () => {

        debugWriter(">>> /pages/CompleteOrder.tsx: updateBestsellerTable()");
        gl_currentOrder_currentOrderItems.forEach(item => {
            DBProducts_increaseProductBestsellerCounter(item);
        });

    }; // eo function updateBestsellerTable()


    /***************************************************************************
    * createOrderObject()
    *
    * Desc: Create Order Object.
    * Note:
    *
    */
    const createOrderObject = (): OrderType => {

        const calculateTotalOfOrder = (orderItems: Array<OrderProductType>, currencyCode: "EUR" | "USD") => {

            let total = 0;


            if(currencyCode === "EUR") {
                let t = 0;
                orderItems.forEach(item => t += (item.counter * item.priceEUR));
                total = t;
            }

            if(currencyCode === "USD") {
                let t = 0;
                orderItems.forEach(item => t += (item.counter * item.priceUSD));
                total = t;
            }


            return total;

        }; // eo function calculateTotalOfOrder()


        const calculateVatOfOrder = (orderItems: Array<OrderProductType>, currencyCode: "EUR" | "USD") => {

            let vat = 0;


            if(currencyCode === "EUR") {
                let v = 0;
                orderItems.forEach(item => v += (item.counter * item.vatEur));
                vat = v;
            }
            if(currencyCode === "USD") {
                let v = 0;
                orderItems.forEach(item => v += (item.counter * item.vatUsd));
                vat = v;
            }


            return round(vat);

        }; // eo function calculateVatOfOrder()


        const getHasWarningFromEntries = (orderItems: Array<OrderProductType>) => {

            let hasWarning = false;


            orderItems.forEach(orderItem => {
                if(orderItem.hasWarning === true) {
                    hasWarning = true;
                }
            });


            return hasWarning;

        }; // eo function getHasWarningFromEntries()


        const getWarningCommentFromEntries = (orderItems: Array<OrderProductType>) => {

            let warningComment = null;


            orderItems.forEach(orderItem => {
                if(orderItem.warningComment !== null) {
                    warningComment = orderItem.warningComment;
                }
            });


            return warningComment;

        }; // eo funciton getWarningCommentFromEntries()


        const gl_products = useProductsStore.getState().products,
            gl_changesInEuro = useCurrentOrderStore.getState().changesInEuro; // #35


        debugWriter(">>> /pages/CompleteOrder.tsx: createOrderObject()");
        // console.log('gl_currentOrder_currentOrderItems');
        // console.log(gl_products)
        // console.log(gl_currentOrder_currentOrderItems);


        // console.log('MIchael')
        // console.log(round(gl_currentOrder_totalSum))
        // console.log(gl_currentCustomer_paymentMethod!)

        return {
            invoiceId: gl_currentOrder_invoiceId!,
            aptFrom: gl_flightselection_selectedFlight!.departureAirportInfo.iataCode,
            aptTo: gl_flightselection_selectedFlight!.destinationAirportInfo.iataCode,
            crewMembers: createSimplifiedCrewArray(gl_flightselection_selectedFlight!.crew),
            crewLogin: gl_login_userName!,
            flightNo: gl_flightselection_selectedFlight!.flightNo,
            flightDate: gl_flightselection_selectedFlight!.aptFromDepartureTime,
            passengerSeat: null,
            paymentMethod: gl_currentCustomer_paymentMethod!,
            ccBrand: null,
            ccCvc: null,
            ccExpiryMonth: null,
            ccExpiryYear: null,
            ccHolder: null,
            ccNumber: null,
            ccSignature: null,
            invoiceAmount: round(gl_currentOrder_totalSum),
            invoiceVat: calculateVatOfOrder(gl_currentOrder_currentOrderItems, gl_currency_currentCurrency.code),
            paymentAmount: round(gl_currentOrder_totalSum),
            entriesForProcess: createEntriesForProcess(gl_currentOrder_currentOrderItems),
            entriesForPresentation: gl_currentOrder_currentOrderItems,
            currency: gl_currency_currentCurrency.code,
            emailInvoice: false,
            emailText: "",
            emailAddress: "",
            canceled: false,
            resourceId: gl_login_idResource!,
            flightId: gl_flightselection_selectedFlight!.id,
            tip: 0,
            timestamp: dayjs().format("YYYY-MM-DD[T]HH:mm:ss"),
            syncedBefore: false,
            totalInEur: calculateTotalOfOrder(gl_currentOrder_currentOrderItems, "EUR"),
            totalInOrderCurrency: calculateTotalOfOrder(gl_currentOrder_currentOrderItems, gl_currency_currentCurrency.code),
            vatInEur: calculateVatOfOrder(gl_currentOrder_currentOrderItems, "EUR"),
            vatInOrderCurrency: calculateVatOfOrder(gl_currentOrder_currentOrderItems, gl_currency_currentCurrency.code),
            hasWarning: getHasWarningFromEntries(gl_currentOrder_currentOrderItems),
            warningComment: getWarningCommentFromEntries(gl_currentOrder_currentOrderItems),
            changesInEuro: gl_changesInEuro
        };

    }; // eo function createOrderObject()


    /***************************************************************************
    * createEntriesForProcess()
    *
    * Desc: Create Order Entries (Products) Object.
    * Note: Object for /process-Call.
    *
    */
    const createEntriesForProcess = (orderItems: Array<OrderProductType>) => {

        const getExchangeRate = () => {

            return gl_currency_exchangeRates![gl_currency_currentCurrency.code];

        }; // eo funciton getExchangeRate()

        const getProductPrice = (product: OrderProductType) => {

            let productPrice = 0;


            if(gl_currency_currentCurrency.code === "EUR") {
                productPrice = product.priceEUR;
            }
            if(gl_currency_currentCurrency.code === "USD") {
                productPrice = product.priceUSD;
            }


            return productPrice;

        }; // eo function getProductPrice()

        let orderItemList: Array<{ exchangeRatio: number; idProduct: number; productPrice: number; productPriceEUR: number; vatEUR: number; }> = [];


        debugWriter(">>> /pages/CompleteOrder.tsx: createEntriesForProcess()");
        orderItems.forEach(item => {
            for(let i = 0; i < item.counter; i++) {
                orderItemList.push({
                    idProduct: item.id,
                    productPrice: getProductPrice(item),
                    // productPriceEUR: item.price1,
                    productPriceEUR: item.priceEUR,
                    exchangeRatio: getExchangeRate(),
                    vatEUR: round(item.vatEur)
                });
            }
        });


        return orderItemList;

    }; // eo function createEntriesForProcess()


    /***************************************************************************
    * createSimplifiedCrewArray()
    *
    * Desc: Calcualte VAT of whole order.
    * Note: VAT is global.
    *
    */
    const createSimplifiedCrewArray = (crew: Array<CrewMemberType>) => {

        let simplifiedCrewArray: Array<string> = [];


        debugWriter(">>> /pages/CompleteOrder.tsx: createSimplifiedCrewArray()");
        crew.forEach((crewMember: CrewMemberType) => {
            simplifiedCrewArray.push(crewMember.contract3Code);
        });


        return simplifiedCrewArray;

    }; // eo function createSimplifiedCrewArray()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage ref={pageRef}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{ lang.pages.CompleteOrder.Titles.OrderComplete[gl_shared_language] }</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen onClick={ onClick_test }>

                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">{ lang.pages.CompleteOrder.Titles.OrderComplete[gl_shared_language] }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                    <div style={{ marginTop: 32, marginBottom: 16 }} className="flex flex-jc-cen confirmation-successicon">
                        <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                            <path
                                id="successAnimationResult"
                                d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
                            />
                            <circle id="successAnimationCircle" cx="35" cy="35" r="24" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                            <polyline id="successAnimationCheck" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                        </svg>
                    </div>

                    <div>
                        <p ref={countdownRef} style={{ textAlign: "center", margin: 0 }} className="sdr-text-large sdr-font-weight-500">
                            { goOnTime }
                        </p>
                        <p style={{ textAlign: "center", marginBottom: 32, marginTop: 0 }} className="sdr-text-normal">
                            { lang.pages.CompleteOrder.Texts.AutomaticForwarding[gl_shared_language] }
                        </p>
                    </div>

                    { gl_currentOrder_invoiceId ?
                        <p className="sdr-text-normal" style={{ textAlign: "center" }}>
                            {gl_currentOrder_invoiceId.substr(0, gl_currentOrder_invoiceId.length - 3)}{" "}
                            <span className="sdr-text-extra-large sdr-font-weight-500">
                                {gl_currentOrder_invoiceId.substr(-3)}
                            </span>
                        </p>
                    : null }

                    <IonButton onClick={onClick_showPrintModal} expand="block" fill="clear">
                        { lang.pages.CompleteOrder.Labels.PrintInvoice[gl_shared_language] }
                    </IonButton>
                    <IonButton onClick={onClick_showEMailModal} expand="block" fill="clear">
                        { lang.pages.CompleteOrder.Labels.EMailInvoice[gl_shared_language] }
                    </IonButton>

                    <IonButton onClick={onClick_stopCatering} expand="block" fill="clear">
                        { lang.pages.CompleteOrder.Labels.StopCatering[gl_shared_language] }
                    </IonButton>

            </IonContent>

            <IonFooter className="ion-no-border" style={{ background: "white" }}>
                <StyledButton moreSpaceBottom={gl_network_networkConnected === false} disabled={!savingOrderDone} onClick={onClick_goToPurchase} expand="block">
                    { lang.pages.CompleteOrder.Labels.StartNextOrder[gl_shared_language] }
                </StyledButton>
            </IonFooter>

            <PrintModal
                isOpen={showPrintModal}
                onClose={() => setShowPrintModal(false)}
                presentingElement={pageRef}
                type="INVOICE" />

            <EMailModal
                isOpen={showEMailModal}
                onClose={() => setShowEMailModal(false)}
                presentingElement={pageRef} />

        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/
/******************************************************************************
* StyledPrice
*
* Note: Expand bottom margin, if the Offline Mode Bar is beyond the button.
*
*/
const StyledButton = styled(IonButton)<{ moreSpaceBottom: boolean }>`
    ${ props => props.moreSpaceBottom === true ? "margin-bottom: calc(var(--ion-safe-area-bottom) + 24px)" : "" }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default CompleteOrder;