/*
* flights.tsx
*
* Description: Database Management with DEXIE for the Flights Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from 'dexie';
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { useSettingsLanguageStore } from '../stores/shared/settings';
import { debugWriter, showToastError } from '../tsx/utilities';
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBFlights } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_flight_flight } from '../types/flight';
import lang from "../tsx/language.json";
import dayjs from 'dayjs';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                                     
// #  |__  $$__/                                     
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$ 
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/ 
// #          /$$  | $$| $$                          
// #         |  $$$$$$/| $$                          
// #          \______/ |__/ 
// #
//-----------------------------------------------------------------------------/
type type_DBFlights_addFlights = (flightsToAdd: Array<type_flight_flight>, idResource: number) => void;
type type_DBFlights_addFlight = (flightToAdd: type_flight_flight, idResource: number) => void;
type type_DBFlights_restoreTableConsitency = (flightsForLoggedInUserFromBackend: Array<type_flight_flight>, idResource: number) => void;
type type_DBFlights_breakdownOnlyUserCreatedFlights = (idResource: number) => Promise<Array<type_flight_flight>>;
type type_DBFlights_breakdown = (idResource?: number) => Promise<Array<type_flight_flight>>;
type type_DBFlights_deleteFlightByFlightId = (flightId: number) => void;
type type_DBFlights_getNumberOfEntries = () => Promise<number>;
type type_DBFlights_deletePassedFlights = () => Promise<void>;
type type_DBFlights_getTableHeader = () => Promise<Array<IndexSpec>>;



//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                              /$$     /$$                              
// #  | $$_____/                             | $$    |__/                              
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$ 
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/ 
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBFlights_restoreTableConsitency()
*
* Desc: Delete theses flights, which were not send from the backend, but are 
* Desc: still saved in the table.
* Note: Don't delete the user created flights.
* Note: We don't have to check the flights informations, because the 
* Note: DBFlights_addFlight function does this four us. So we only have 
* Note: to check if there are flights in the DB table, which were not send from
* Note: the backend. We do this by flightId.
*
*/
export const DBFlights_restoreTableConsitency: type_DBFlights_restoreTableConsitency = async (flightsForLoggedInUserFromBackend, idResource) => {

    let flightsForLoggedInUserFromDatabase: Array<type_flight_flight>,
        flightsForLoggedInUserFromDatabaseWithoutCustomFlights: Array<type_flight_flight>;


    debugWriter(">>> Database: DBFlights_restoreTableConsitency");
    flightsForLoggedInUserFromDatabase = await DBFlights_breakdown(idResource);

    //filter out the user created flights, they remain in all scenarios in the DB table
    flightsForLoggedInUserFromDatabaseWithoutCustomFlights = flightsForLoggedInUserFromDatabase.filter((flight) => flight.createdByUser === false);

    //compare flights from backend with flights from database table by checking their flightId
    flightsForLoggedInUserFromDatabaseWithoutCustomFlights.forEach(async (flightFromDatabase) => {
        let flightIsInDatabaseAndBackend = false;

        for (let i = 0; i < flightsForLoggedInUserFromBackend.length; i++) {
            if (flightFromDatabase.id === flightsForLoggedInUserFromBackend[i].id) {
                flightIsInDatabaseAndBackend = true;
                break;
            }
        }

        if (flightIsInDatabaseAndBackend === false) {
            DBFlights_deleteFlightByFlightId(flightFromDatabase.id);
        }
    });

}; // eo function DBFlights_restoreTableConsitency()


/*******************************************************************************
* ASYNC DBFlights_addFlights()
*
* Desc: Adds flights to the database, which were send from the backend.
* Note: Additionally we add the idResource of the logged-in user, so we 
* Note: can assign the flights to a user later.
*
*/
export const DBFlights_addFlights: type_DBFlights_addFlights = (flightsToAdd, idResource) => {
    
    debugWriter(" >>> Database: DBFlights_addFlights");
    flightsToAdd.forEach(async (flight) => {
        DBFlights_addFlight(flight, idResource);
    });

    DBFlights_restoreTableConsitency(flightsToAdd, idResource);

}; // eo function DBFlights_addFlights()


/*******************************************************************************
* ASYNC DBFlights_addFlight()
*
* Desc: Adds flight to the database, which were send from the backend or created
* Desc: by a user.
* Note: Additionally we add the idResource of the logged-in user, so we 
* Note: can assign the flights to a user later.
*
*/
export const DBFlights_addFlight: type_DBFlights_addFlight = async (flightToAdd, idResource) => {
    
    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(" >>> Database: DBFlights_addFlight");
    flightsTable
        .put({
            aircraftType: flightToAdd.aircraftType,
            aptFromDepartureTime: flightToAdd.aptFromDepartureTime,
            aptToArrivalTime: flightToAdd.aptToArrivalTime,
            crew: JSON.stringify(flightToAdd.crew),
            flightNo: flightToAdd.flightNo,
            flightId: String(flightToAdd.id),
            departureAirportInfo: JSON.stringify(flightToAdd.departureAirportInfo),
            destinationAirportInfo: JSON.stringify(flightToAdd.destinationAirportInfo),
            idResource: idResource,
            createdByUser: flightToAdd.createdByUser === true ? 1 : 0,
            flightMode: flightToAdd.flightMode
        }, "flightId")
        .catch(() => {
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.CouldNotSaveFlight[gl_shared_language]
            );
        });
        
}; // eo function DBLogin_addFlights()


/*******************************************************************************
* ASYNC DBFlights_deleteFlightByFlightId()
*
* Desc: Deletes flight from the database by id.
* Note: If there is no flight with this specific id, ignore this call.
*
*/
export const DBFlights_deleteFlightByFlightId: type_DBFlights_deleteFlightByFlightId = async (flightToDeleteFlightId) => {
    
    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");


    debugWriter(" >>> Database: DBFlights_deleteFlightByFlightId");
    flightsTable
        .where("flightId")
        .equals(String(flightToDeleteFlightId))
        .delete()
        .catch(() => {
            // do nothing here
        });

}; // eo function DBFlights_deleteFlightByFlightId()

/*******************************************************************************
* ASYNC DBFlights_breakdown()
*
* Desc: Returns the flights which were saved with the given idResource.
* Note: idResource comes from the current logged-in user.
*
*/
export const DBFlights_breakdown: type_DBFlights_breakdown = async (idResource?) => {
  
    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");

    let savedFlights: Array<type_Schema_DBFlights> = [],
        flightsToReturn: Array<type_flight_flight> = [];


    debugWriter(" >>> Database: DBFlights_breakdown");
    if(idResource !== undefined) {
        savedFlights = await flightsTable
            .where({ idResource: idResource })
            .toArray()
            .catch(() => {
                return [];
            });
    }
    
    if(idResource === undefined) {
        savedFlights = await flightsTable
            .toArray()
            .catch(() => {
                return [];
            });
    }


    savedFlights.forEach(flight => {

        flightsToReturn.push(Object.assign(flight, {
            crew: JSON.parse(flight.crew),
            departureAirportInfo: JSON.parse(flight.departureAirportInfo),
            destinationAirportInfo: JSON.parse(flight.destinationAirportInfo),
            id: Number(flight.flightId),
            createdByUser: flight.createdByUser === 1,
        }));

    });


    return flightsToReturn;

} // eo function DBFlights_breakdown()


/*******************************************************************************
* ASYNC DBFlights_breakdownOnlyUserCreatedFlights()
*
* Desc: Returns the flights which were saved with the given idResource and are flaged
* Desc: as user created.
* Note: idResource comes from the current logged-in user.
*
*/
export const DBFlights_breakdownOnlyUserCreatedFlights: type_DBFlights_breakdownOnlyUserCreatedFlights = async (idResource) => {
    
    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");

    let flightsToReturn: Array<type_flight_flight> = [];

    let savedFlights = await flightsTable
        .where({ idResource: idResource, createdByUser: 1 })
        .toArray()
        .catch(() => {
            return [];
        });


    debugWriter(" >>> Database: DBFlights_breakdownOnlyUserCreatedFlights");
    savedFlights.forEach(flight => {

        flightsToReturn.push(Object.assign(flight, {
            crew: JSON.parse(flight.crew),
            departureAirportInfo: JSON.parse(flight.departureAirportInfo),
            destinationAirportInfo: JSON.parse(flight.destinationAirportInfo),
            id: Number(flight.flightId),
            createdByUser: flight.createdByUser === 1,
        }));

    });


    return flightsToReturn;

}; // eo function DBFlights_breakdownOnlyUserCreatedFlights


/*******************************************************************************
* ASYNC DBFlights_getNumberOfEntries()
*
* Desc: Returns the last size of the database table.
*
*/
export const DBFlights_getNumberOfEntries: type_DBFlights_getNumberOfEntries = async () => {

    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");


    debugWriter(" >>> Database: DBFlights_getNumberOfEntries");
    return await flightsTable
        .count()
        .catch(() => {
            return 0;
        });

}; // eo function DBFlights_getNumberOfEntries()


/*******************************************************************************
* ASYNC DBFlights_deletePassedFlights()
*
* Desc: 
* Note:
*
*/
export const DBFlights_deletePassedFlights: type_DBFlights_deletePassedFlights = async () => {

    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");


    debugWriter(" >>> Database: DBFlights_deletePassedFlights");
    await flightsTable
        .where("aptFromDepartureTime")
        .below(dayjs().format("YYYY-MM-DD"))
        .delete()
        .catch(() => {
            // do nothing here
        });

}; // eo function DBFlights_deletePassedFlights()


/*******************************************************************************
* ASYNC DBFlights_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBFlights_getTableHeader: type_DBFlights_getTableHeader = async () => {
    const db = await DB_openDatabase(),
          flightsTable = db.table<type_Schema_DBFlights>("flights");

    debugWriter(" >>> Database: DBFlights_getTableHeader");
    let tableSchema = flightsTable.schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBFlights_getTableHeader()