/*
* deviceIdentifier.tsx
*
* Description: Store for device identification. (uuid)
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    deviceIdentifier: null | string,

    mut_setDeviceIdentifier: (identifier: string) => void, 

    actn_setDeviceIdentifier: (identifier: string) => void, 
};


//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    deviceIdentifier: null,
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useDeviceIdentifierStore = create<StateType>((set, get) => ({
    ...initalState,




    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * mut_setDeviceIdentifier()
    *
    * Desc:
    * Note: UUID
    *
    */
    mut_setDeviceIdentifier: (identifier) => {

        set({ deviceIdentifier: identifier });

    }, // eo funciton mut_setDeviceIdentifier()



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$              /$$     /$$                              
    // #   /$$__  $$            | $$    |__/                              
    // #  | $$  \ $$  /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$ 
    // #  | $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/ 
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_setDeviceIdentifier()
    *
    * Desc: 
    * Note: UUID
    * 
    */
    actn_setDeviceIdentifier: (deviceIdentifier) => {

        get().mut_setDeviceIdentifier(deviceIdentifier);

    }, // eo funciton actn_setDeviceIdentifier()

}));