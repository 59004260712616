/*
* SalesReportDetailsModal.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { RefObject, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from 'dayjs';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import PrintModal from '../../CompleteOrder/PrintModal';
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useLoginStore } from '../../../stores/login/login';
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { hideSpinner, renderFixedCurrency, showSpinner } from '../../../tsx/utilities';
import { DBOrders_getAllOrders } from '../../../databases/orders';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    isOpen: boolean;
    onDidDismiss: () => void;
    presentingElementef: RefObject<HTMLElement>;
    flight: { flightId: number, flightNo: string, aptFrom: string, aptTo: string, aptFromDepartureTime: string };
}
const SalesReportDetailsModal: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [salesReportDetails_CS_EUR, setSalesReportDetails_CS_EUR] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_CS_USD, setSalesReportDetails_CS_USD] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_CS_EURTotal, setSalesReportDetails_CS_EURTotal] = useState<number>(0);
    const [salesReportDetails_CS_USDTotal, setSalesReportDetails_CS_USDTotal] = useState<number>(0);

    const [salesReportDetails_CC_EUR, setSalesReportDetails_CC_EUR] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_CC_USD, setSalesReportDetails_CC_USD] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_CC_EURTotal, setSalesReportDetails_CC_EURTotal] = useState<number>(0);
    const [salesReportDetails_CC_USDTotal, setSalesReportDetails_CC_USDTotal] = useState<number>(0);

    const [salesReportDetails_DD_EUR, setSalesReportDetails_DD_EUR] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_DD_USD, setSalesReportDetails_DD_USD] = useState<Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }>>([]);
    const [salesReportDetails_DD_EURTotal, setSalesReportDetails_DD_EURTotal] = useState<number>(0);
    const [salesReportDetails_DD_USDTotal, setSalesReportDetails_DD_USDTotal] = useState<number>(0);

    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State
    // >>> --------------------------------------------------------------------- Global State
    const gl_login_idResource = useLoginStore(state => state.idResource);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions
    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions
    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_printSalesReport()
    *
    * Desc:
    * Note:
    *
    */
    const onClick_printSalesReport = () => {

        setShowPrintModal(true);

    }; // eo function onClick_printSalesReport()


    /***************************************************************************
    * onDidDismiss_closeModal()
    *
    * Desc: Close Modal.
    * Note:
    *
    */
    const onDidDismiss_closeModal = () => {

        props.onDidDismiss();

    }; // eo function onDidDismiss_closeModal()


    /***************************************************************************
    * onDidPresent_loadSalesReportDetails()
    *
    * Desc:
    * Note:
    *
    */
    const onDidPresent_loadSalesReportDetails = async () => {

        let orders = await DBOrders_getAllOrders(gl_login_idResource!, props.flight.flightId),
            salesReportDetails_CS_EUR: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_CS_USD: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_CS_EURTotal = 0,
            salesReportDetails_CS_USDTotal = 0,
            salesReportDetails_CC_EUR: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_CC_USD: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_CC_EURTotal = 0,
            salesReportDetails_CC_USDTotal = 0,
            salesReportDetails_DD_EUR: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_DD_USD: Array<{ productName: string, productCode: string, counter: number, price: number, discountPercent: number }> = [],
            salesReportDetails_DD_EURTotal = 0,
            salesReportDetails_DD_USDTotal = 0;


        showSpinner();

        orders.filter(order => order.canceled === false).filter(order => order.syncedBefore === false).forEach(order => {
            order.entriesForPresentation.forEach((entry) => {

                // >>> CS ---------------------------------
                if (order.paymentMethod === "CS") {
                    if (order.currency === "EUR") {
                        let item = salesReportDetails_CS_EUR.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_CS_EUR.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceEUR, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_CS_EURTotal += entry.priceEUR * entry.counter;
                    }

                    if (order.currency === "USD") {
                        let item = salesReportDetails_CS_USD.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_CS_USD.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceUSD, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_CS_USDTotal += entry.priceUSD * entry.counter;
                    }
                }
                // <<< CS ---------------------------------


                // >>> CC --------------------------------- #41
                if (order.paymentMethod === "CC") {
                    if (order.currency === "EUR") {
                        let item = salesReportDetails_CC_EUR.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_CC_EUR.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceEUR, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_CC_EURTotal += entry.priceEUR * entry.counter;
                    }

                    if (order.currency === "USD") {
                        let item = salesReportDetails_CC_USD.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_CC_USD.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceUSD, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_CC_USDTotal += entry.priceUSD * entry.counter;
                    }
                }
                // <<< CC ---------------------------------


                // >>> DD --------------------------------- #41
                if (order.paymentMethod === "DD") {
                    if (order.currency === "EUR") {
                        let item = salesReportDetails_DD_EUR.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_DD_EUR.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceEUR, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_DD_EURTotal += entry.priceEUR * entry.counter;
                    }

                    if (order.currency === "USD") {
                        let item = salesReportDetails_DD_USD.find(i => (i.productCode === entry.code && i.discountPercent === entry.discountPercent));
                        if (item === undefined) {
                            salesReportDetails_DD_USD.push({ productName: entry.name, productCode: entry.code, counter: entry.counter, price: entry.priceUSD, discountPercent: entry.discountPercent });
                        } else {
                            item.counter += entry.counter;
                        }

                        salesReportDetails_DD_USDTotal += entry.priceUSD * entry.counter;
                    }
                }
                // <<< DD ---------------------------------

            });
        });

        hideSpinner();


        setSalesReportDetails_CS_EUR(salesReportDetails_CS_EUR);
        setSalesReportDetails_CS_USD(salesReportDetails_CS_USD);
        setSalesReportDetails_CS_EURTotal(salesReportDetails_CS_EURTotal);
        setSalesReportDetails_CS_USDTotal(salesReportDetails_CS_USDTotal);

        setSalesReportDetails_CC_EUR(salesReportDetails_CC_EUR);
        setSalesReportDetails_CC_USD(salesReportDetails_CC_USD);
        setSalesReportDetails_CC_EURTotal(salesReportDetails_CC_EURTotal);
        setSalesReportDetails_CC_USDTotal(salesReportDetails_CC_USDTotal);

        setSalesReportDetails_DD_EUR(salesReportDetails_DD_EUR);
        setSalesReportDetails_DD_USD(salesReportDetails_DD_USD);
        setSalesReportDetails_DD_EURTotal(salesReportDetails_DD_EURTotal);
        setSalesReportDetails_DD_USDTotal(salesReportDetails_DD_USDTotal);

    }; // eo function onDidPresent_loadSalesReportDetails()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonModal
            isOpen={props.isOpen}
            swipeToClose
            onDidPresent={onDidPresent_loadSalesReportDetails}
            onDidDismiss={onDidDismiss_closeModal}
            presentingElement={props.presentingElementef.current || undefined}>

            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonTitle>{ lang.components.UserDashboard.SalesReportDetailsModal.Titles.SalesReport[gl_shared_language] }</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onDidDismiss_closeModal}>
                            { lang.global.Labels.Done[gl_shared_language] }
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">{ lang.components.UserDashboard.SalesReportDetailsModal.Titles.SalesReport[gl_shared_language] }</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonItem>
                    <IonLabel position="stacked">
                        { lang.components.UserDashboard.SalesReportDetailsModal.Labels.DepartureIATACode[gl_shared_language] }
                    </IonLabel>
                    <p className="ion-no-margin sdr-text-normal mb-1 mt-2">{ props.flight.aptFrom }</p>
                </IonItem>

                <IonItem>
                    <IonLabel position="stacked">
                        { lang.components.UserDashboard.SalesReportDetailsModal.Labels.DestinationIATACode[gl_shared_language] }
                    </IonLabel>
                    <p className="ion-no-margin sdr-text-normal mb-1 mt-2">{ props.flight.aptTo }</p>
                </IonItem>

                <IonItem>
                    <IonLabel position="stacked">
                        { lang.components.UserDashboard.SalesReportDetailsModal.Labels.FlightNo[gl_shared_language] }
                    </IonLabel>
                    <p className="ion-no-margin sdr-text-normal mb-1 mt-2">{ props.flight.flightNo }</p>
                </IonItem>

                <IonItem>
                    <IonLabel position="stacked">
                        { lang.components.UserDashboard.SalesReportDetailsModal.Labels.DepartureDate[gl_shared_language] }
                    </IonLabel>
                    <p className="ion-no-margin sdr-text-normal mb-1 mt-2">{ dayjs(props.flight.aptFromDepartureTime).format("DD.MM.YYYY") }</p>
                </IonItem>


                <div className="flex flex-jc-cen mt-4">
                    <IonButton
                        size="small"
                        fill="clear"
                        onClick={onClick_printSalesReport}>
                        { lang.components.UserDashboard.SalesReportDetailsModal.Labels.PrintSalesReport[gl_shared_language] }
                    </IonButton>
                </div>



                <p className="sdr-text-large sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Cash[gl_shared_language] }</p>
                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Euro[gl_shared_language] }</p>
                { salesReportDetails_CS_EUR.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`CS_EUR_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "EUR")} = {renderFixedCurrency(item.counter * item.price, "EUR")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalCashEuro[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_CS_EURTotal, "EUR") }</p>
                </div>

                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.USDollar[gl_shared_language] }</p>
                { salesReportDetails_CS_USD.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`CS_USD_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "USD")} = {renderFixedCurrency(item.counter * item.price, "USD")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalCashUSDollar[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_CS_USDTotal, "USD") }</p>
                </div>



                {/* <br /><br />
                <p className="sdr-text-large sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Creditcard[gl_shared_language] }</p>
                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Euro[gl_shared_language] }</p>
                { salesReportDetails_CC_EUR.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`CC_EUR_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "EUR")} = {renderFixedCurrency(item.counter * item.price, "EUR")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalCCEuro[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_CC_EURTotal, "EUR") }</p>
                </div>

                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.USDollar[gl_shared_language] }</p>
                { salesReportDetails_CC_USD.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`CC_USD_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "USD")} = {renderFixedCurrency(item.counter * item.price, "USD")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalCCUSDollar[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_CC_USDTotal, "USD") }</p>
                </div>



                <br /><br />
                <p className="sdr-text-large sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Directdebit[gl_shared_language] }</p>
                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.Euro[gl_shared_language] }</p>
                { salesReportDetails_DD_EUR.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`DD_EUR_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "EUR")} = {renderFixedCurrency(item.counter * item.price, "EUR")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalDDEuro[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_DD_EURTotal, "EUR") }</p>
                </div>

                <p className="sdr-text-biggernormal sdr-font-weight-500">{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.USDollar[gl_shared_language] }</p>
                { salesReportDetails_DD_USD.sort((a, b) => a.productName > b.productName ? 1 : -1).map((item, index) => (
                    <div key={`DD_USD_${index}`}>
                        <p style={{ marginBottom: 0 }}>{item.productName} { item.discountPercent > 0 ? `(-${item.discountPercent}%)`: "" }</p>
                        <p style={{ marginTop: 0 }}>{item.counter}x {renderFixedCurrency(item.price, "USD")} = {renderFixedCurrency(item.counter * item.price, "USD")}</p>
                    </div>
                )) }
                <div style={{ borderTop: "1px solid var(--ion-color-medium)", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p className="sdr-text-biggernormal" style={{ marginTop: 12 }}>{ lang.components.UserDashboard.SalesReportDetailsModal.Texts.TotalDDUSDollar[gl_shared_language] }</p>
                    <p className="sdr-text-biggernormal sdr-text-color-primary" style={{ marginTop: 12 }}>{ renderFixedCurrency(salesReportDetails_DD_USDTotal, "USD") }</p>
                </div> */}

            </IonContent>


            <PrintModal
                isOpen={showPrintModal}
                onClose={() => setShowPrintModal(false)}
                presentingElement={props.presentingElementef}
                type="SALESREPORT"
                flightId={props.flight.flightId} />

        </IonModal>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default SalesReportDetailsModal;
