/*
* config.tsx
*
* Description: Apps Store Config File.
*
* This file is part of: "http://apps.sundair.com/", "Tickets/Sundair"
* Copyright 2020 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
import { type_shared_Currency } from "../types/currency";



export let CONF_ENVIRONMENT = "TEST"; // TEST or LIVE

/*******************************************************************************
* VERSION/REVISION NUMBER
*
*/
export const CONF_VERSION_NUMBER = "2.2.6";
export const CONF_REVISIONS_NUMBER = "39";

/*******************************************************************************
* RENDER INFORMATIONS
*
*/
export const CONF_FAKE_LOADING_TIME = 800;

/*******************************************************************************
 * baseURL
 *
 */
export const CONF_BASE_URL = CONF_ENVIRONMENT === "TEST" ? "https://test-crewplaning.sundair.com/rest" : "https://suncrew-calendar.sundair.com/rest";

/*******************************************************************************
 * apiKey
 * LIVE and TEST
 */
export const CONF_API_KEY = "037ae1f2ba244a2a93b24121743bfdd6";

/*******************************************************************************
 * Catering App Login Credentials
 * LIVE and TEST
 */
export const CONF_API_USERNAME = "CRI-DEV";
export const CONF_API_PASSWORD = "871fe4y";
export const CONF_API_AUTHORIZATION = "Q1JJLURFVjo4NzFmZTR5";

/*******************************************************************************
 * API Headers
 * LIVE and TEST
 */
export const CONF_API_DEFAULT_HEADERS = {
    Accept: 'application/json',
    langCode: 'DE',
    apiKey: CONF_API_KEY,
    Authorization: null,
}

/*******************************************************************************
 * DATABASE INFORMATIONS
 *
 */
export const CONF_DATABASE_NAME = "SDR";

/*******************************************************************************
 * FLIGHT SELECTION INFORMATIONS
 *
 */
export const CONF_DAYS_IN_ADVANCE = 3;

/*******************************************************************************
 * LOGIN INFORMATIONS
 *
 */
export const CONF_DAYS_LOGIN_EXPIRY = 3;
export const CONF_USAGE_GRANT = "cm.crewsbreakdown.main";
export const CONF_ADMIN_GRANT = "catering.admin";

/*******************************************************************************
 * SEAT INFORMATIONS
 *
 */
export const CONF_MAX_NUMBER_OF_SEATROWS = 35;
export const CONF_MAX_LETTER_OF_SEATNUMBER = "F";


/*******************************************************************************
* COMPANY INFORMATIONS
*
*/
export const CONF_COMPANY_NAME = "Sundair";
export const CONF_COMPANY_APP_NAME = "SunCatering";
export const CONF_COMPANY_BASE_LANGUAGE = "de-DE";


/*******************************************************************************
* CURRENCY INFORMATIONS
* Note: ISO 4217
*/

export const CONF_COMPANY_BASE_CURRENCY: type_shared_Currency.RootObject = { code: "EUR", symbol: "€" };

export const CONF_COMPANY_CC_PAYMENT_ALLOWED = [];
export const CONF_COMPANY_MINIMUM_CC_PAYMENT_AMOUNT = 9999999; // not allowed

export const CONF_COMPANY_AVAILABLE_CURRENCIES: Array<type_shared_Currency.RootObject> = [
    CONF_COMPANY_BASE_CURRENCY,

    {
        symbol: "$",
        code: "USD",
    }];
// export const CONF_VAT = 19;


/*******************************************************************************
 * MONEY INFORMATIONS
 *
 */
export const CONF_AVAILABLE_MONEY_VALUES: { EUR: Array<number>, USD: Array<number>, GBP: Array<number> } = {
    EUR: [5, 10, 20, 50, 100, 200, 500],
    USD: [1, 2, 5, 10, 20, 50, 100, 200, 500],
    GBP: [5, 10, 20, 50]
}
