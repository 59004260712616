/*
* OrderMenu.tsx
*
* Description: Component/Page: 
*
*
* This file is part of: "http://apps.sundair.com/", "Tickets/Sundair"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useRef } from 'react';
import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonMenu, IonRow } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useLocation } from 'react-router';
import { App } from '@capacitor/app';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import OrderMenuItem from "./OrderMenuItem";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from '../../../stores/currency/currency';
import { useLoginStore } from '../../../stores/login/login';
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
import { useCurrentOrderStore } from '../../../stores/shared/currentOrder';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
import '../Menu.css';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const OrderMenu: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_loggedIn = useLoginStore(state => state.loggedIn);
    const gl_currentOrderItems = useCurrentOrderStore(state => state.currentOrderItems);
    const gl_currentOrder_cummulatedSum = useCurrentOrderStore().get_totalSum();
    const gl_currency_currentCurrency = useCurrencyStore(state => state.currentCurrency);
    const gl_currentOrder_orderInvoiceId = useCurrentOrderStore(state => state.currentOrderInvoiceId);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const location = useLocation();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const menuRef = useRef<HTMLIonMenuElement>(null);
    // <<< --------------------------------------------------------------------- References




    const onIonDidOpen_menu = () => {

        App.addListener('backButton', onEmit_backButtonPressed);

    }; // eo function onIonDidOpen_menu()


    const onIonDidClose_menu = () => {

        App.removeAllListeners();

    }; // eo function onIonDidClose_menu()


    const onEmit_backButtonPressed = () => {

        menuRef.current?.close();

    }; // eo function onEmit_backButtonPressed();




    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        gl_loggedIn === true ?
            <IonMenu ref={menuRef} onIonDidOpen={onIonDidOpen_menu} onIonDidClose={onIonDidClose_menu} swipeGesture={false} side="end" contentId="main" type="overlay">
                <IonContent>

                    <div style={{ filter: "drop-shadow(0px -2px 1px rgba(50, 50, 0, 0.5))", position: "relative", zIndex: 10000 }}>
                        <div style={{ background: "white", height: 5, marginBottom: -1, clipPath: "polygon(0% 100%,2% 10%,4% 100%,6% 10%,8% 100%,10% 10%,12% 100%,14% 10%,16% 100%,18% 10%,20% 100%,22% 10%,24% 100%,26% 10%,28% 100%,30% 10%,32% 100%,34% 10%,36% 100%,38% 10%,40% 100%,42% 10%,44% 100%,46% 10%,48% 100%,50% 10%,52% 100%,54% 10%,56% 100%,58% 10%,60% 100%,62% 10%,64% 100%,66% 10%,68% 100%,70% 10%,72% 100%,74% 10%,76% 100%,78% 10%,80% 100%,82% 10%,84% 100%,86% 10%,88% 100%,90% 10%,92% 100%,94% 10%,96% 100%,98% 10%, 100% 100%)" }}>

                        </div>
                    </div>

                    <IonList style={{ boxShadow: "0px 0px 4px rgba(50, 50, 0, 1)", paddingBottom: 16}}>
                        <p className="sdr-text-large sdr-font-weight-500 mb-1" style={{ paddingLeft: 10 }}>{ lang.components.Shared.OrderMenu.Texts.Order[gl_shared_language] }</p>
                        <p className="sdr-text-small ma-0" style={{ paddingLeft: 10 }}>{gl_currentOrder_orderInvoiceId}</p>


                        { gl_currentOrderItems.map((item, index) => (
                            <OrderMenuItem
                                key={`order-item-${index}`}
                                item={item}
                                changeNotAllowed={ location.pathname !== "/purchase" } />
                        )) }

                        <IonItem style={{ "--inner-padding-end": 0 }}>
                            <IonLabel>
                                <IonGrid className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <p className="sdr-text-biggernormal">
                                                { lang.components.Shared.OrderMenu.Texts.Total[gl_shared_language] }
                                            </p>
                                        </IonCol>
                                        <IonCol>
                                            <p className="sdr-text-biggernormal sdr-font-weight-500 ion-text-right">
                                                {renderFixedCurrency(gl_currentOrder_cummulatedSum, gl_currency_currentCurrency.code)}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonLabel>
                        </IonItem>
                        
                    </IonList>
                    
                    <div style={{ marginBottom: 32, filter: "drop-shadow(0px 2px 1px rgba(50, 50, 0, 0.5))" }}>
                        <div style={{ background: "white", height: 5, marginTop: -1, clipPath: "polygon(0% 0%,2% 90%,4% 0%,6% 90%,8% 0%,10% 90%,12% 0%,14% 90%,16% 0%,18% 90%,20% 0%,22% 90%,24% 0%,26% 90%,28% 0%,30% 90%,32% 0%,34% 90%,36% 0%,38% 90%,40% 0%,42% 90%,44% 0%,46% 90%,48% 0%,50% 90%,52% 0%,54% 90%,56% 0%,58% 90%,60% 0%,62% 90%,64% 0%,66% 90%,68% 0%,70% 90%,72% 0%,74% 90%,76% 0%,78% 90%,80% 0%,82% 90%,84% 0%,86% 90%,88% 0%,90% 90%,92% 0%,94% 90%,96% 0%,98% 90%,100% 0)" }}>

                        </div>
                    </div>

                </IonContent>
            </IonMenu> : null
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default OrderMenu;
