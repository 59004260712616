/*
* FlightCardsContainer.tsx
*
* Description: Container for the FLight Cards
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState } from 'react';
import { IonIcon, IonItemDivider } from '@ionic/react';
import { chevronDownCircleOutline, chevronUpCircleOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from "styled-components";
import AnimatedHeight from "react-animate-height";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import FlightCard from "../shared/FlightCard";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_flight_flight } from '../../types/flight';
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    type: "TODAY" | "TOMORROW" | "LATER" | "EARLIER";
    flights: Array<type_flight_flight>;
    isOpen?: boolean;
    showWarningIfEmpty?: boolean;
    onClick_select: (flightId: number) => void;
    onClick_edit: (flightId: number) => void;
}
const FlightCardsContainer: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [flightCardsContainerHeight, setFlightCardsContainerHeigt] = useState<"auto" | 0>(props.isOpen === true ? "auto" : 0);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /***************************************************************************
    * onClick_toggleFlightCardsContainer()
    *
    * Desc: Open/ Close Flight Containers.
    * Note:
    *
    */
    const onClick_toggleFlightCardsContainer = () => {

        if(flightCardsContainerHeight === 0) {
            setFlightCardsContainerHeigt("auto");
        }
        if(flightCardsContainerHeight === "auto") {
            setFlightCardsContainerHeigt(0);
        }

    }; // eo function onClick_toggleFlightCardsContainer()



    /***************************************************************************
    * Components JSX Part.
    * 
    */
    return (
        <React.Fragment>
            <StyledContainer show={props.flights.length > 0}>
                <StyledCategoryName onClick={onClick_toggleFlightCardsContainer}>
                    { props.type === "EARLIER" ? <p className="sdr-text-biggernormal sdr-font-weight-400 sdr-text-color-primary">{ lang.components.FlightSelection.FlightCardsContainer.Labels.Earlier[gl_shared_language] }</p> : null }
                    { props.type === "TODAY" ? <p className="sdr-text-biggernormal sdr-font-weight-400 sdr-text-color-primary">{ lang.components.FlightSelection.FlightCardsContainer.Labels.Today[gl_shared_language] }</p> : null }
                    { props.type === "TOMORROW" ? <p className="sdr-text-biggernormal sdr-font-weight-400 sdr-text-color-primary">{ lang.components.FlightSelection.FlightCardsContainer.Labels.Tomorrow[gl_shared_language] }</p> : null }
                    { props.type === "LATER" ? <p className="sdr-text-biggernormal sdr-font-weight-400 sdr-text-color-primary">{ lang.components.FlightSelection.FlightCardsContainer.Labels.Later[gl_shared_language] }</p> : null }

                    {flightCardsContainerHeight === 0 ? <IonIcon className="sdr-text-large" slot="end" color="primary" icon={chevronDownCircleOutline} /> : null}
                    {flightCardsContainerHeight === "auto" ? <IonIcon className="sdr-text-large" slot="end" color="medium" icon={chevronUpCircleOutline} /> : null}
                </StyledCategoryName>

                <AnimatedHeight height={flightCardsContainerHeight}>
                    <StyledFlightCardsContainer>

                        { props.flights.map((flight, index) => (
                                <FlightCard
                                    key={`flightcard-${index}`}
                                    onClick={() => props.onClick_select(flight.id) }
                                    onClick_edit={() => props.onClick_edit(flight.id) }
                                    flightObject={flight} />
                        )) }

                    </StyledFlightCardsContainer>
                </AnimatedHeight>
            </StyledContainer>
        </React.Fragment>
    );
};




//-----------------------------------------------------------------------------/
// # 
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$                        
// #                     |  $$$$$$/                        
// #                      \______/    
// #  
//-----------------------------------------------------------------------------/
/****************************************************************************** 
* StyledFlightCardsContainer
* 
*/
const StyledFlightCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    ion-card {
        width: 100%;
    }

    @media (min-width: 768px) {
        ion-card {
            flex: 0 0 calc(50% - 16px);

            :nth-child(2n) {
                margin-left: 16px;
            }

            :nth-child(2n+1) {
                margin-right: 16px;
            }

            :last-child {
                margin-right: 0;
            }
        }
    }
`;

/****************************************************************************** 
* StyledContainer
* 
*/
const StyledContainer = styled.div<{ show: boolean }>`
    display: ${props => props.show === true ? "block" : "none"};
`;

/****************************************************************************** 
* StyledCategoryName
* 
*/
const StyledCategoryName = styled(IonItemDivider)`
    background: #ffffff;
    padding: 8px 0;
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default FlightCardsContainer;
