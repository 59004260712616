/*
* printer.tsx
*
* Description: Printer logic for printing sales reports and invoices.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import dayjs from "dayjs";
import epEncoder from 'esc-pos-encoder';
import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from "../stores/shared/settings";
import { useLoginStore } from "../stores/login/login";
import { useDeviceIdentifierStore } from "../stores/shared/deviceIdentifier";
import { useCurrencyStore } from "../stores/currency/currency";
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBOrders_getAllOrders, DBOrders_getOrder } from "../databases/orders";
import { hideSpinner, showSpinner, showToastError, getLocalStorage_LastOnlineUpdate } from "./utilities";
import { type_Schema_DBOrders } from "../types/databases";
import { DBFlights_breakdown } from "../databases/flights";
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { OrderType } from "./types";
import lang from "../tsx/language.json";
import { CONF_VERSION_NUMBER, CONF_REVISIONS_NUMBER } from '../tsx/config';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$   /$$   /$$     /$$ /$$ /$$   /$$     /$$
// #  | $$  | $$  | $$    |__/| $$|__/  | $$    |__/
// #  | $$  | $$ /$$$$$$   /$$| $$ /$$ /$$$$$$   /$$  /$$$$$$   /$$$$$$$
// #  | $$  | $$|_  $$_/  | $$| $$| $$|_  $$_/  | $$ /$$__  $$ /$$_____/
// #  | $$  | $$  | $$    | $$| $$| $$  | $$    | $$| $$$$$$$$|  $$$$$$
// #  | $$  | $$  | $$ /$$| $$| $$| $$  | $$ /$$| $$| $$_____/ \____  $$
// #  |  $$$$$$/  |  $$$$/| $$| $$| $$  |  $$$$/| $$|  $$$$$$$ /$$$$$$$/
// #   \______/    \___/  |__/|__/|__/   \___/  |__/ \_______/|_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* getFormattedCurrency()
*
* Desc:
* Note:
*
*/
const getFormattedCurrency = (amount: number, currency?: "EUR" | "USD") => {

    let formattedAmount = "",
        pre = "0",
        decimal = "00",
        splittedAmount: Array<string> = [];


    splittedAmount = String(amount.toFixed(2)).split(".");
    pre = splittedAmount[0];
    if (splittedAmount.length > 1) {
        decimal = splittedAmount[1];
    }

    formattedAmount = `${pre}.${decimal.substring(0, 2).padEnd(2, '0')} ${currency ?? ''}`;


    return formattedAmount;

}; // eo function getFormattedCurrency()


/*******************************************************************************
* getESCPOSLogo()
*
* Desc:
* Note:
*
*/
const getESCPOSLogo = async () => {

    let logo = new Image();


    logo.src = "./assets/icon/icon.png";
    await logo.decode(); // Trick to be shure, that image is fully in memory


    return new epEncoder()
            .initialize()
            .align('center')
            .newline()
            .image(logo, 112, 112)
            .newline()
            .encode();

}; // eo function getESCPOSLogo()


/*******************************************************************************
* createTable()
*
* Desc:
* Note:
*
*/
const createTable = (columnWidths: Array<number>, rows: Array<Array<string>>, options?: { aligns?: Array<'left' |'right'> }) => {

    let tableString = "";


    rows.forEach((row, indexRow) => {
        row.forEach((value, indexValue) => {
            let valueText = value.padEnd(columnWidths[indexValue], ' '); // standard format

            if (options) {
                if (options.aligns) {
                    if (options.aligns[indexValue] === 'left') {
                        valueText = value.padEnd(columnWidths[indexValue], ' ');
                    }
                    if (options.aligns[indexValue] === 'right') {
                        valueText = value.padStart(columnWidths[indexValue], ' ');
                    }
                }
            }

            tableString += valueText;
        });

        if (indexRow < rows.length - 1) {
            tableString += "\n";
        }
    });


    return tableString;

}; // eo function createTable()


/*******************************************************************************
* getShortenedText()
*
* Desc:
* Note:
*
*/
const getShortenedText = (textToShorten: string, maxLength?: number) => {

    let shortenedText = textToShorten;


    if (textToShorten.length > 32) {
        shortenedText = textToShorten.substring(0, maxLength ?? 29);
        shortenedText += "...";
    }


    return shortenedText;

}; // eo function getShortenedText()




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$                               /$$
// #  |_  $$_/                              |__/
// #    | $$   /$$$$$$$  /$$    /$$ /$$$$$$  /$$  /$$$$$$$  /$$$$$$
// #    | $$  | $$__  $$|  $$  /$$//$$__  $$| $$ /$$_____/ /$$__  $$
// #    | $$  | $$  \ $$ \  $$/$$/| $$  \ $$| $$| $$      | $$$$$$$$
// #    | $$  | $$  | $$  \  $$$/ | $$  | $$| $$| $$      | $$_____/
// #   /$$$$$$| $$  | $$   \  $/  |  $$$$$$/| $$|  $$$$$$$|  $$$$$$$
// #  |______/|__/  |__/    \_/    \______/ |__/ \_______/ \_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* printInvoice()
*
* Desc:
* Note: On last Bluetooth function we nned this then-"Hack" so we have a correct
* Note: timing.
*
*/
export const printInvoice = async (invoiceId: string) => {

    const gl_shared_language = useSettingsLanguageStore.getState().language;
    let order = await DBOrders_getOrder(invoiceId);


    showSpinner();

    try {
        BluetoothSerial.isEnabled().then((enabled) => {
            if (enabled === "OK") {
                BluetoothSerial.list().then((list) => {
                    for(let i = 0; i < list.length; i++) {

                        let device = list[i];


                        if (device.name.includes("SPP")) {
                            let printer = BluetoothSerial.connect(device.address);

                            let sub = printer.subscribe(async () => {
                                try {
                                    await BluetoothSerial.write(await getESCPOSLogo());
                                    await BluetoothSerial.write(getESCPOSInvoiceHeader());
                                    await BluetoothSerial.write(getESCPOSInvoiceId(order!));
                                    await BluetoothSerial.write(getESCPOSInvoiceEntries(order!));
                                    await BluetoothSerial.write(getESCPOSInvoiceTaxesDetails(order!));
                                    await BluetoothSerial.write(getESCPOSInvoiceDetailsFooter(order!));
                                    await BluetoothSerial.write(getESCPOSInvoiceFooter());
                                    await BluetoothSerial.write(getESCPOSMarketingFooter());

                                    hideSpinner();
                                    sub.unsubscribe();
                                } catch(err) {
                                    hideSpinner();
                                    showToastError(
                                        lang.components.Shared.Toasts.Error[gl_shared_language],
                                        lang.components.Shared.Toasts.PrintError[gl_shared_language]
                                    );
                                }
                            }, () => {
                                hideSpinner();
                                showToastError(
                                    lang.components.Shared.Toasts.Error[gl_shared_language],
                                    lang.components.Shared.Toasts.CouldNotFindPrinter[gl_shared_language]
                                );
                            });

                            break;
                        }
                    }
                });
            } else {
                hideSpinner();
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.BluetoothNeedsSwitchedOn[gl_shared_language]
                );
            }
        })
        .catch(() => {
            hideSpinner();
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.BluetoothNeedsSwitchedOn[gl_shared_language]
            );
        });
    } catch(err) {
        showToastError(
            lang.components.Shared.Toasts.Error[gl_shared_language],
            lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
        );
        hideSpinner();
    }

}; // eo function printInvoice()


/***************************************************************************
* getESCPOSInvoiceHeader()
*
* Desc:
* Note:
*
*/
const getESCPOSInvoiceHeader = () => {

    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .align('center')
            .bold(true)
            .text('SUNDAIR GmbH')
            .bold(false)
            .newline()
            .text('Knieperdamm 79')
            .newline()
            .text('18435 Stralsund')
            .newline()
            .text('+49 (0) 30 53 6767 91')
            .newline()
            .text('kundenservice@sundair.com')
            .newline()
            .newline()
            .text('UStID DE305307186')
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSInvoiceHeader()


/***************************************************************************
* getESCPOSInvoiceId()
*
* Desc:
* Note:
*
*/
const getESCPOSInvoiceId = (order: type_Schema_DBOrders) => {

    return new epEncoder()
            .initialize()
            .bold(true)
            .text('Rechnung: ' + order.invoiceId)
            .newline()
            .text("Flug: " + order.aptFrom + " - " + order.aptTo + ", " + order.flightNo)
            .newline()
            .encode();

}; // eo function getESCPOSInvoiceId()


/***************************************************************************
* getESCPOSInvoiceEntries()
*
* Desc:
* Note: A = 19%, B = 0% VAT
*
*/
const getESCPOSInvoiceEntries = (order: type_Schema_DBOrders) => {

    let entries = "";


    order.entriesForPresentation.forEach((entry, index) => {
        entries += getShortenedText(entry.name);
        entries += "\n";
        entries += createTable(
            [5, 10, 15, 2],
            [
                [
                    String(entry.counter) + "x",
                    getFormattedCurrency(order.currency === "EUR" ? entry.priceEUR : entry.priceUSD, order.currency),
                    getFormattedCurrency((order.currency === "EUR" ? entry.priceEUR : entry.priceUSD) * entry.counter, order.currency),
                    String(entry.vat !== 0 ? "A" : "B") // #8
                ]
            ],
            {
                aligns: [
                    "left",
                    "left",
                    "right",
                    "right"
                ]
            });
        if (entry.discountPercent > 0) {
            entries += `     ${entry.discountPercent}% Rabatt`;
        }

        if (index < order.entriesForPresentation.length - 1) {
            entries += "\n";
        }
    });


    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text("--------------------------------")
            .newline()
            .text(entries)
            .newline()
            .text("--------------------------------")
            .newline()
            .bold(true)
            .text(createTable(
                [
                    19, 11, 2
                ],
                [
                    [
                        "Total",
                        getFormattedCurrency(order.invoiceAmount, order.currency),
                        ""
                    ]
                ],
                {
                    aligns: [
                        "left",
                        "right",
                        "left"
                    ]
                }
            ))
            .bold(false)
            .newline()
            .text("================================")
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSInvoiceEntries()


/***************************************************************************
* getESCPOSInvoiceTaxesDetails()
*
* Desc:
* Note: A = 19%, B = 0% VAT
*
*/
const getESCPOSInvoiceTaxesDetails = (order: type_Schema_DBOrders) => {

    let vatTextLine = "",
        orderVat: number | string = '';


    // TODO: save vat rate in order object itself
    if (order.entriesForPresentation.length > 0) {
        orderVat = order.entriesForPresentation[0].vat;
    }


    vatTextLine = createTable(
        [2, 7, 8, 8, 7],
        [
            [
                "",
                "MwSt.",
                "Brutto",
                "Netto",
                "Steuer"
            ],
            [
                String(orderVat !== 0 ? "A" : "B"), // #8
                String(`${orderVat}%`),
                getFormattedCurrency(order.totalInOrderCurrency),
                getFormattedCurrency(order.totalInOrderCurrency - order.vatInOrderCurrency),
                getFormattedCurrency(order.vatInOrderCurrency)
            ]
        ]
    );


    return new epEncoder()
            .initialize()
            .text(vatTextLine)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSInvoiceTaxesDetails()


/***************************************************************************
* getESCPOSInvoiceDetailsFooter()
*
* Desc:
* Note:
*
*/
const getESCPOSInvoiceDetailsFooter = (order: type_Schema_DBOrders) => {

    return new epEncoder()
            .initialize()
            .text("Zahlart: " + (order.paymentMethod === "CS" ? "Bar" : order.paymentMethod === "CC" ? "Kreditkarte" : order.paymentMethod === "DD" ? "EC-Karte" : ''))
            .newline()
            .text("Datum: " + dayjs(order.timestamp).format("DD.MM.YYYY HH:mm:ss"))
            .newline()
            .text("Bedienung: " + order.crewLogin)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSInvoiceDetailsFooter()


/***************************************************************************
* getESCPOSInvoiceFooter()
*
* Desc:
* Note:
*
*/
const getESCPOSInvoiceFooter = () => {

    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .align("center")
            .bold(true)
            .text("Vielen Dank für Ihren Einkauf.")
            .newline()
            .text("Wir wünschen Ihnen")
            .newline()
            .text("weiterhin einen angenehmen Flug.")
            .newline()
            .newline()
            .newline()
            .bold(false)
            .encode();

}; // eo function getESCPOSInvoiceFooter()



/***************************************************************************
* getESCPOSMarketingFooter()
*
* Desc:
* Note:
*
*/
const getESCPOSMarketingFooter = () => {

    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .align("left")
            .text("Jetzt neu: Mit der Sundair App")
            .newline()
            .text("Flüge buchen, umbuchen und")
            .newline()
            .text("verwalten.")
            .newline()
            .align("center")
            .qrcode('https://qrco.de/bbeEv7', 2, 8, 'h')
            .newline()
            .bold(true)
            .text("Scannen Sie einfach den QR-Code")
            .newline()
            .text("mit Ihrem Smartphone und laden")
            .newline()
            .text("Sie sich die Sundair App")
            .newline()
            .text("noch heute herunter.")
            .newline()
            .newline()
            .bold(false)
            .text("Weitere Infos: ")
            .underline()
            .text("www.sundair.com")
            .underline()
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSMarketingFooter()




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$            /$$
// #   /$$__  $$          | $$
// #  | $$  \__/  /$$$$$$ | $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$  |____  $$| $$ /$$__  $$ /$$_____/
// #   \____  $$  /$$$$$$$| $$| $$$$$$$$|  $$$$$$
// #   /$$  \ $$ /$$__  $$| $$| $$_____/ \____  $$
// #  |  $$$$$$/|  $$$$$$$| $$|  $$$$$$$ /$$$$$$$/
// #   \______/  \_______/|__/ \_______/|_______/
// #
// #
// #   /$$$$$$$                                            /$$
// #  | $$__  $$                                          | $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$$$/ /$$__  $$ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__  $$| $$$$$$$$| $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$  \ $$| $$_____/| $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$  | $$|  $$$$$$$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |__/  |__/ \_______/| $$____/  \______/ |__/         \___/
// #                      | $$
// #                      | $$
// #                      |__/
// #
//-----------------------------------------------------------------------------/
/***************************************************************************
* printSalesReport()
*
* Desc:
* Note:
*
*/
export const printSalesReport = async (flightId: number, idResource: number) => {

    const gl_shared_language = useSettingsLanguageStore.getState().language;
    let orders = await DBOrders_getAllOrders(idResource, flightId);


    showSpinner();

    try {
        BluetoothSerial.isEnabled().then((enabled) => {
            if (enabled === "OK") {
                BluetoothSerial.list().then((list) => {
                    for(let i = 0; i < list.length; i++) {

                        let device = list[i];


                        if (device.name.includes("SPP")) {
                            let printer = BluetoothSerial.connect(device.address);

                            let sub = printer.subscribe(async () => {
                                try {
                                    await BluetoothSerial.write(await getESCPOSLogo());
                                    await BluetoothSerial.write(getESCPOSSalesReportHeader());
                                    await BluetoothSerial.write(await getESCPOSSalesReportFlightDetails(flightId, idResource));
                                    await BluetoothSerial.write(getESCPOSSalesReportTabletDetails());

                                    await BluetoothSerial.write(await getESCPOSSalesReportLastOnlineUpdateDetails());

                                    await BluetoothSerial.write(getESCPOSSalesReportSales(orders));
                                    await BluetoothSerial.write(getESCPOSSalesReportExchangeRates());
                                    await BluetoothSerial.write(getESCPOSSalesReportVats(orders));
                                    await BluetoothSerial.write(getESCPOSSalesReportChangesInEuro(orders));
                                    await BluetoothSerial.write(getESCPOSSalesReportFooter());

                                    hideSpinner();
                                    sub.unsubscribe();
                                } catch(err) {
                                    hideSpinner();
                                    showToastError(
                                        lang.components.Shared.Toasts.Error[gl_shared_language],
                                        lang.components.Shared.Toasts.PrintError[gl_shared_language]
                                    );
                                }
                            }, () => {
                                hideSpinner();
                                showToastError(
                                    lang.components.Shared.Toasts.Error[gl_shared_language],
                                    lang.components.Shared.Toasts.CouldNotFindPrinter[gl_shared_language]
                                );
                            });

                            break;
                        }
                    }
                });
            } else {
                hideSpinner();
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.BluetoothNeedsSwitchedOn[gl_shared_language]
                );
            }
        })
        .catch(() => {
            hideSpinner();
            showToastError(
                lang.components.Shared.Toasts.Error[gl_shared_language],
                lang.components.Shared.Toasts.BluetoothNeedsSwitchedOn[gl_shared_language]
            );
        });
    } catch(err) {
        showToastError(
            lang.components.Shared.Toasts.Error[gl_shared_language],
            lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
        );
        hideSpinner();
    }

}; // eo function printSalesReport()


/***************************************************************************
* getESCPOSSalesReportHeader()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportHeader = () => {

    const gl_auth_username = useLoginStore.getState().username;


    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .align('center')
            .bold(true)
            .text('Sales Report')
            .newline()
            .text(gl_auth_username ?? "")
            .bold(false)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportHeader()


/***************************************************************************
* getESCPOSSalesReportFlightDetails()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportFlightDetails = async (idFlight: number, idResource: number) => {

    const gl_flights = await DBFlights_breakdown(idResource);
    let flight = gl_flights.find(flight => flight.id === idFlight);


    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text(`Flight: ${flight?.departureAirportInfo.iataCode} - ${flight?.destinationAirportInfo.iataCode}, ${flight?.flightNo}`)
            .newline()
            .text(`Date: ${dayjs(flight?.aptFromDepartureTime).format("DD.MM.YYYY")}`)
            .newline()
            .text(`Origin: ${flight?.createdByUser === true ? "USER" : "API"}`)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportFlightDetails()


/***************************************************************************
* getESCPOSSalesReportTabletDetails()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportTabletDetails = () => {

    const gl_deviceIdentifier_deviceIdentifier = useDeviceIdentifierStore.getState().deviceIdentifier;


    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text(`Tablet: ${gl_deviceIdentifier_deviceIdentifier}`)
            .newline()
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportTabletDetails()


/***************************************************************************
* getESCPOSSalesReportLastOnlineUpdateDetails()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportLastOnlineUpdateDetails = async () => {

    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text(`Version: ${CONF_VERSION_NUMBER} ${CONF_REVISIONS_NUMBER}`)
            .newline()
            .text(`Last Online: ${await getLocalStorage_LastOnlineUpdate()}`)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportLastOnlineUpdateDetails()



/***************************************************************************
* getESCPOSSalesReportSales()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportSales = (orders: Array<OrderType>) => {

    let salesReportDetails_CS_EUR = 0,
        salesReportDetails_CS_USD = 0,
        salesReportDetails_CS_Items: Array<{ code: string, counterEUR: number, priceEUR: number, counterUSD: number, priceUSD: number, name: string, discountPercent: number }> = [],
        salesReportDetails_CC_EUR = 0,
        salesReportDetails_CC_USD = 0,
        salesReportDetails_CC_Items: Array<{ code: string, counterEUR: number, priceEUR: number, counterUSD: number, priceUSD: number, name: string, discountPercent: number }> = [],
        salesReportDetails_DD_EUR = 0,
        salesReportDetails_DD_USD = 0,
        salesReportDetails_DD_Items: Array<{ code: string, counterEUR: number, priceEUR: number, counterUSD: number, priceUSD: number, name: string, discountPercent: number }> = [];

    orders.forEach(order => {
        if (order.canceled === false) {
            if (order.syncedBefore === false) {
                order.entriesForPresentation.forEach(entry => {

                    // >>> -------------------------------- CS
                    if (order.paymentMethod === "CS") {
                        if (order.currency === "EUR") {
                            salesReportDetails_CS_EUR += (entry.counter * entry.priceEUR);
                            // add or update
                            let i = salesReportDetails_CS_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_CS_Items[i].counterEUR += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_CS_Items.push({
                                    code: entry.code,
                                    counterEUR: entry.counter,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: 0,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                        if (order.currency === "USD") {
                            salesReportDetails_CS_USD += (entry.counter * entry.priceUSD);
                            // add or update
                            let i = salesReportDetails_CS_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_CS_Items[i].counterUSD += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_CS_Items.push({
                                    code: entry.code,
                                    counterEUR: 0,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: entry.counter,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                    }
                    // <<< -------------------------------- CS


                    // >>> -------------------------------- CC
                    if (order.paymentMethod === "CC") {
                        if (order.currency === "EUR") {
                            salesReportDetails_CC_EUR += (entry.counter * entry.priceEUR);
                            // add or update
                            let i = salesReportDetails_CC_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_CC_Items[i].counterEUR += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_CC_Items.push({
                                    code: entry.code,
                                    counterEUR: entry.counter,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: 0,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                        if (order.currency === "USD") {
                            salesReportDetails_CC_USD += (entry.counter * entry.priceUSD);
                            // add or update
                            let i = salesReportDetails_CC_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_CC_Items[i].counterUSD += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_CC_Items.push({
                                    code: entry.code,
                                    counterEUR: 0,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: entry.counter,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                    }
                    // <<< -------------------------------- CC


                    // >>> DD -----------------------------
                    if (order.paymentMethod === "DD") {
                        if (order.currency === "EUR") {
                            salesReportDetails_DD_EUR += (entry.counter * entry.priceEUR);
                            // add or update
                            let i = salesReportDetails_DD_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_DD_Items[i].counterEUR += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_DD_Items.push({
                                    code: entry.code,
                                    counterEUR: entry.counter,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: 0,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                        if (order.currency === "USD") {
                            salesReportDetails_DD_USD += (entry.counter * entry.priceUSD);
                            // add or update
                            let i = salesReportDetails_DD_Items.findIndex(item => (item.code === entry.code && item.discountPercent === entry.discountPercent));
                            if (i > -1) {
                                salesReportDetails_DD_Items[i].counterUSD += entry.counter;
                            }
                            if (i === -1) {
                                salesReportDetails_DD_Items.push({
                                    code: entry.code,
                                    counterEUR: 0,
                                    priceEUR: entry.priceEUR,
                                    counterUSD: entry.counter,
                                    priceUSD: entry.priceUSD,
                                    name: entry.name,
                                    discountPercent: entry.discountPercent
                                });
                            }
                        }
                    }
                    // <<< DD -----------------------------

                });
            }
        }
    });


    // >>> ------------------------------------------------ CS
    let itemsTextEUR_CS = '';
    salesReportDetails_CS_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterEUR !== 0) {
            itemsTextEUR_CS += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextEUR_CS += `(-${item.discountPercent}%)`;
            }
            itemsTextEUR_CS += '\n';
            itemsTextEUR_CS += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterEUR}x`,
                        `${getFormattedCurrency(item.priceEUR, "EUR")}`,
                        `${getFormattedCurrency(item.priceEUR * item.counterEUR, "EUR")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });

    let itemsTextUSD_CS = '';
    salesReportDetails_CS_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterUSD !== 0) {
            itemsTextUSD_CS += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextUSD_CS += `(-${item.discountPercent}%)`;
            }
            itemsTextUSD_CS += '\n';
            itemsTextUSD_CS += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterUSD}x`,
                        `${getFormattedCurrency(item.priceUSD, "USD")}`,
                        `${getFormattedCurrency(item.priceUSD * item.counterUSD, "USD")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });
    // <<< ------------------------------------------------ CS


    // >>> ------------------------------------------------ CC
    let itemsTextEUR_CC = '';
    salesReportDetails_CC_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterEUR !== 0) {
            itemsTextEUR_CC += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextEUR_CC += `(-${item.discountPercent}%)`;
            }
            itemsTextEUR_CC += '\n';
            itemsTextEUR_CC += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterEUR}x`,
                        `${getFormattedCurrency(item.priceEUR, "EUR")}`,
                        `${getFormattedCurrency(item.priceEUR * item.counterEUR, "EUR")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });

    let itemsTextUSD_CC = '';
    salesReportDetails_CC_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterUSD !== 0) {
            itemsTextUSD_CC += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextUSD_CC += `(-${item.discountPercent}%)`;
            }
            itemsTextUSD_CC += '\n';
            itemsTextUSD_CC += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterUSD}x`,
                        `${getFormattedCurrency(item.priceUSD, "USD")}`,
                        `${getFormattedCurrency(item.priceUSD * item.counterUSD, "USD")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });
    // <<< ------------------------------------------------ CC


    // >>> ------------------------------------------------ DD
    let itemsTextEUR_DD = '';
    salesReportDetails_DD_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterEUR !== 0) {
            itemsTextEUR_DD += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextEUR_DD += `(-${item.discountPercent}%)`;
            }
            itemsTextEUR_DD += '\n';
            itemsTextEUR_DD += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterEUR}x`,
                        `${getFormattedCurrency(item.priceEUR, "EUR")}`,
                        `${getFormattedCurrency(item.priceEUR * item.counterEUR, "EUR")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });

    let itemsTextUSD_DD = '';
    salesReportDetails_DD_Items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(item => {
        if (item.counterUSD !== 0) {
            itemsTextUSD_DD += `${getShortenedText(item.name, 21)}`;
            if (item.discountPercent > 0) {
                itemsTextUSD_DD += `(-${item.discountPercent}%)`;
            }
            itemsTextUSD_DD += '\n';
            itemsTextUSD_DD += createTable(
                [5, 11, 16],
                [
                    [
                        `${item.counterUSD}x`,
                        `${getFormattedCurrency(item.priceUSD, "USD")}`,
                        `${getFormattedCurrency(item.priceUSD * item.counterUSD, "USD")}`
                    ]
                ],
                {
                    aligns: [
                        'left',
                        'left',
                        'right'
                    ]
                });
        }
    });
    // <<< ------------------------------------------------ DD




    // >>> ------------------------------------------------
    // >>> ------------------------------------------------
    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
        .initialize()
        .codepage('cp437')


        .align('center')
        .bold(true)
        .text('- CASH -')
        .bold(false)
        .newline()
        .newline()
        .align('left')
        .text(itemsTextEUR_CS)
        .text('--------------------------------')
        .newline()
        .align('right')
        .bold(true)
        .text(createTable(
            [
                19, 11, 2
            ],
            [
                [
                    "Total",
                    getFormattedCurrency(salesReportDetails_CS_EUR, "EUR"),
                    ""
                ]
            ],
            {
                aligns: [
                    "left",
                    "right",
                    "left"
                ]
            }
        ))
        .bold(false)
        .align('left')
        .newline()
        .text('================================')
        .newline()
        .newline()
        .align('left')
        .text(itemsTextUSD_CS)
        .text('--------------------------------')
        .newline()
        .align('right')
        .bold(true)
        .text(createTable(
            [
                19, 11, 2
            ],
            [
                [
                    "Total",
                    getFormattedCurrency(salesReportDetails_CS_USD, "USD"),
                    ""
                ]
            ],
            {
                aligns: [
                    "left",
                    "right",
                    "left"
                ]
            }
        ))
        .bold(false)
        .align('left')
        .newline()
        .text('================================')
        .newline()
        .newline()
        .newline()


        // .align('center')
        // .bold(true)
        // .text('- CREDITCARD -')
        // .bold(false)
        // .newline()
        // .newline()
        // .align('left')
        // .text(itemsTextEUR_CC)
        // .text('--------------------------------')
        // .newline()
        // .align('right')
        // .bold(true)
        // .text(createTable(
        //     [
        //         19, 11, 2
        //     ],
        //     [
        //         [
        //             "Total",
        //             getFormattedCurrency(salesReportDetails_CC_EUR, "EUR"),
        //             ""
        //         ]
        //     ],
        //     {
        //         aligns: [
        //             "left",
        //             "right",
        //             "left"
        //         ]
        //     }
        // ))
        // .bold(false)
        // .align('left')
        // .newline()
        // .text('================================')
        // .newline()
        // .newline()
        // .align('left')
        // .text(itemsTextUSD_CC)
        // .text('--------------------------------')
        // .newline()
        // .align('right')
        // .bold(true)
        // .text(createTable(
        //     [
        //         19, 11, 2
        //     ],
        //     [
        //         [
        //             "Total",
        //             getFormattedCurrency(salesReportDetails_CC_USD, "USD"),
        //             ""
        //         ]
        //     ],
        //     {
        //         aligns: [
        //             "left",
        //             "right",
        //             "left"
        //         ]
        //     }
        // ))
        // .bold(false)
        // .align('left')
        // .newline()
        // .text('================================')
        // .newline()
        // .newline()
        // .newline()



        // .align('center')
        // .bold(true)
        // .text('- DEBITCARD -')
        // .bold(false)
        // .newline()
        // .newline()
        // .align('left')
        // .text(itemsTextEUR_DD)
        // .text('--------------------------------')
        // .newline()
        // .align('right')
        // .bold(true)
        // .text(createTable(
        //     [
        //         19, 11, 2
        //     ],
        //     [
        //         [
        //             "Total",
        //             getFormattedCurrency(salesReportDetails_DD_EUR, "EUR"),
        //             ""
        //         ]
        //     ],
        //     {
        //         aligns: [
        //             "left",
        //             "right",
        //             "left"
        //         ]
        //     }
        // ))
        // .bold(false)
        // .align('left')
        // .newline()
        // .text('================================')




        // .newline()
        // .newline()
        // .align('left')
        // .text(itemsTextUSD_DD)
        // .text('--------------------------------')
        // .newline()
        // .align('right')
        // .bold(true)
        // .text(createTable(
        //     [
        //         19, 11, 2
        //     ],
        //     [
        //         [
        //             "Total",
        //             getFormattedCurrency(salesReportDetails_DD_USD, "USD"),
        //             ""
        //         ]
        //     ],
        //     {
        //         aligns: [
        //             "left",
        //             "right",
        //             "left"
        //         ]
        //     }
        // ))
        // .bold(false)
        // .align('left')
        // .newline()
        // .text('================================')
        // .newline()
        // .newline()
        // .newline()


        .encode();

}; // eo function getESCPOSSalesReportSales()


/***************************************************************************
* getESCPOSSalesReportExchangeRates()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportExchangeRates =  () => {

    const gl_exchangeRates_exchangeRates = useCurrencyStore.getState().exchangeRates;
    const gl_exchangeRates_exchangeRateTimestamp = useCurrencyStore.getState().exchangeRateTimestamp;


    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text(`1 EUR = ${gl_exchangeRates_exchangeRates?.USD} USD`)
            .newline()
            .text(`${dayjs(gl_exchangeRates_exchangeRateTimestamp).format("DD.MM.YYYY HH:mm:ss")}`)
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportExchangeRates()


/***************************************************************************
* getESCPOSSalesReportVats()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportVats = (orders: Array<OrderType>) => {

    let orderVat: number,
        orderWarningComments: Array<string> = [],
        orderWarningComment = '';


    if (orders.length === 0) {
        return '';
    }

    if (orders[0].entriesForPresentation.length === 0) {
        return '';
    }


    orderVat = orders[0].entriesForPresentation[0].vat;

    orders.forEach(order => {
        if (order.warningComment !== null) {
            if (orderWarningComments.includes(order.warningComment) === false) {
                orderWarningComments.push(order.warningComment);
            }
        }
    });


    if (orderWarningComments.length > 0) {
        orderWarningComments.forEach(warning => {
            orderWarningComment += warning + " ";
        });
    }


    return new epEncoder()
        .initialize()
        .codepage('cp437')
        .text(`VAT: ${orderVat}%`)
        .newline()
        .text(orderWarningComment)
        .newline()
        .newline()
        .encode();

}; // eo function getESCPOSSalesReportVats()


/***************************************************************************
* getESCPOSSalesReportChangesInEuro()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportChangesInEuro = (orders: Array<OrderType>) => {

    let orderChangesInEuro: boolean = false as boolean,
        orderWarningComment: string = 'Order=>Dollars, Change=>Euro'

    if (orders.length === 0) {
        return '';
    }

    orders.forEach(order => {
        if (order.changesInEuro === true) {
            orderChangesInEuro = true;
        }
    });


    if (orderChangesInEuro === true) {
        return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text('--------------------------------')
            .newline()
            .text(orderWarningComment)
            .newline()
            .newline()
            .encode();
    }

}; // eo function getESCPOSSalesReportChangesInEuro()


/*******************************************************************************
* getESCPOSSalesReportFooter()
*
* Desc:
* Note:
*
*/
const getESCPOSSalesReportFooter = () => {

    // return new epEncoder({ codepageMapping: 'bixolon' })
    return new epEncoder()
            .initialize()
            .codepage('cp437')
            .text(`${dayjs().format("DD.MM.YYYY HH:mm:ss")}`)
            .newline()
            .newline()
            .newline()
            .newline()
            .newline()
            .encode();

}; // eo function getESCPOSSalesReportFooter()
