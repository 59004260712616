/*
* AdminAreaModal.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useRef, RefObject } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import AdminAreaLogs from "./AdminAreaLogs";
import AdminAreaDatabases from "./AdminAreaDatabases";
import AdminAreaDeleteEverything from "./AdminAreaDeleteEverything";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    isOpen: boolean;
    presentingElementRef: RefObject<HTMLElement>;
    onDidDismiss: () => void;
}
const AdminAreaModal: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_settings_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const modalRef = useRef(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_dismissModal()
    *
    * Desc: Close Button pressed -> close Modal.
    *
    */
    const onClick_dismissModal = () => {

        debugWriter(">>> UserDashboard/AdminArea/AdminAreaModal: onClick_dismissModal");
        props.onDidDismiss();

    }; //eo function onClick_dismissModal()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonModal 
            ref={modalRef}
            isOpen={props.isOpen}
            presentingElement={props.presentingElementRef?.current || undefined}
            swipeToClose
            onDidDismiss={props.onDidDismiss}>

            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonTitle>
                        {lang.components.UserDashboard.AdminAreaModal.Titles.AdminArea[gl_settings_language]}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClick_dismissModal}>
                            {lang.global.Labels.Close[gl_settings_language]}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                
                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">
                            {lang.components.UserDashboard.AdminAreaModal.Titles.AdminArea[gl_settings_language]}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <AdminAreaLogs
                    presentingElementRef={modalRef} />

                <AdminAreaDatabases
                    presentingElementRef={modalRef} />

                <AdminAreaDeleteEverything />

            </IonContent>
        </IonModal>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default AdminAreaModal;
