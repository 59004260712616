/*
* ProductItem.tsx
*
* Description: Flight Overview and Selection Screen
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonNote } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import styled from 'styled-components';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import PlusMinusButton from "../shared/PlusMinusButton";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useCurrencyStore } from '../../stores/currency/currency';
import { useCurrentOrderStore } from "../../stores/shared/currentOrder";
import { useProductsStore } from '../../stores/products/products';
import { useSettingsLanguageStore } from '../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Product_Extended } from '../../types/products';
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type type_Props = {
    product: type_products_Product_Extended;
}
const ProductItem: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    const [currentCounter, setCurrentCounter] = useState<number>(0);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_currentOrder_currentOrderItems = useCurrentOrderStore(state => state.currentOrderItems);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_addProductToCurrentOrder = useCurrentOrderStore(state => state.actn_addProductToCurrentOrder);
    const actn_removeProductFromCurrentOrder = useCurrentOrderStore(state => state.actn_removeProductFromCurrentOrder);
    const actn_setSoldOutProduct = useProductsStore(state => state.actn_setSoldOutProduct);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const itemOptionsRef = useRef<HTMLIonItemSlidingElement>(null);
    // <<< --------------------------------------------------------------------- References




    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /*******************************************************************************
    * useEffect()
    *
    * Desc: Saves the current counter info changes to the local state.
    * Note: Sets currentCounter and IsSelected whenever gl_currentOrder_currentOrderItems
    * Note: changes.
    *
    */
    useEffect(() => {

        let currentCounter = 0;


        for(let i = 0; i < gl_currentOrder_currentOrderItems.length; i++){
            if(gl_currentOrder_currentOrderItems[i].name === props.product.name){
                currentCounter = gl_currentOrder_currentOrderItems[i].counter;
                break;
            }
        }

        setCurrentCounter(currentCounter);

        if(currentCounter > 0){
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }

    }, [gl_currentOrder_currentOrderItems]); // eo function useEffect()




    /***************************************************************************
    * Listeners:
    ***************************************************************************/
    /*******************************************************************************
    * onClick_addProductToCurrentOrder()
    *
    * Desc: Add product to current order.
    * Note:
    *
    */
    const onClick_addProductToCurrentOrder = () => {

        if(props.product.soldOut === false) {
            actn_addProductToCurrentOrder(props.product);
        }

    }; // eo function onClick_addProductToCurrentOrder()


    /*******************************************************************************
    * onClick_removeProductFromCurrentOrder()
    *
    * Desc: Removes product from current order.
    * Note:
    *
    */
    const onClick_removeProductFromCurrentOrder = () => {

        actn_removeProductFromCurrentOrder(props.product);

    }; // eo function onClick_removeProductFromCurrentOrder()


    /*******************************************************************************
    * onClick_toggleAvailableStatus()
    *
    * Desc: Toggle the available status.
    * Note:
    *
    */
    const onClick_toggleAvailableStatus = () => {

        itemOptionsRef.current?.close();

        // Timeout = UI Magic
        setTimeout(() => {
            actn_setSoldOutProduct(props.product.code, !props.product.soldOut);
        }, 300);

    }; // eo function onClick_toggleAvailableStatus()



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /*******************************************************************************
    * isMinusButtonDisabled()
    *
    * Desc: Checks if minus button has to be disabled.
    * Note:
    *
    */
    const isMinusButtonDisabled = () => {

        let minusButtonIsDisabled = false;


        if(currentCounter <= 0){
            minusButtonIsDisabled = true;
        }


        return minusButtonIsDisabled;

    }; // eo function isMinusButtonDisabled()


    /***************************************************************************
    * renderItemPrice()
    *
    * Desc:
    * Note:
    *
    */
    const renderItemPrice = () => {

        const gl_currentCurrency = useCurrencyStore.getState().currentCurrency;
        let priceToReturn: number | string = 0;


        if(gl_currentCurrency.code === "EUR") {
            priceToReturn = renderFixedCurrency(props.product.priceEUR, gl_currentCurrency.code);
        }

        if(gl_currentCurrency.code === "USD") {
            priceToReturn = renderFixedCurrency(props.product.priceUSD, gl_currentCurrency.code);
        }


        return priceToReturn;

    }; // eo function renderItemPrice()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonItemSliding ref={itemOptionsRef}>
            <IonItemOptions side="end">
                <IonItemOption color={ props.product.soldOut === false ? "warning" : "success" }>
                    { props.product.soldOut === false ?
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={onClick_toggleAvailableStatus}>
                            <IonIcon style={{ color: '#666' }} size="large" icon={ closeOutline } />
                            <p style={{ color: '#666' }} className="ion-no-margin">{ lang.components.Purchase.ProductItem.Labels.SoldOut[gl_shared_language] }</p>
                        </div>
                    : null }
                    { props.product.soldOut === true ?
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={onClick_toggleAvailableStatus}>
                            <IonIcon style={{ color: "#FFFFFF" }} size="large" icon={ checkmarkOutline } />
                            <p style={{ color: '#FFFFFF' }} className="ion-no-margin">{ lang.components.Purchase.ProductItem.Labels.StockedUp[gl_shared_language] }</p>
                        </div>
                    : null }
                </IonItemOption>
            </IonItemOptions>

            <StyledItem active={isSelected}>
                <StyledLabel>
                    <StyledCounterAndNameContainer onClick={onClick_addProductToCurrentOrder}>
                        <StyledCounter className="sdr-text-extra-large sdr-font-weight-500">
                            { currentCounter }
                        </StyledCounter>
                        <div>
                            <p className="sdr-text-normal ion-text-wrap">
                                {props.product.name}
                            </p>

                            <StyledPrice className="sdr-text-biggernormal sdr-font-weight-light">
                                { props.product.soldOut === false ? renderItemPrice() : null }
                                { props.product.soldOut === true ? lang.components.Purchase.ProductItem.Labels.SoldOut[gl_shared_language] : null }
                                <p className="sdr-font-weight-500 sdr-text-small sdr-text-color-medium" style={{ display: 'inline', marginLeft: 8 }}>
                                    { props.product.vat }% { lang.components.Purchase.ProductItem.Labels.VAT[gl_shared_language] }
                                </p>
                            </StyledPrice>
                        </div>
                    </StyledCounterAndNameContainer>

                    <StyledButtonContainer active={isSelected}>
                        <PlusMinusButton
                            type="minus"
                            disabled={isMinusButtonDisabled()}
                            onClick={onClick_removeProductFromCurrentOrder} />
                    </StyledButtonContainer>
                </StyledLabel>
            </StyledItem>
        </IonItemSliding>
    );
};




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$               /$$                 /$$
// #   /$$__  $$ | $$              | $$                | $$
// #  | $$  \__//$$$$$$   /$$   /$$| $$  /$$$$$$   /$$$$$$$
// #  |  $$$$$$|_  $$_/  | $$  | $$| $$ /$$__  $$ /$$__  $$
// #   \____  $$ | $$    | $$  | $$| $$| $$$$$$$$| $$  | $$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$| $$_____/| $$  | $$
// #  |  $$$$$$/ |  $$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$$
// #   \______/   \___/   \____  $$|__/ \_______/ \_______/
// #                      /$$  | $$
// #                     |  $$$$$$/
// #                      \______/
// #
//-----------------------------------------------------------------------------/
/******************************************************************************
* StyledPrice
*
*/
const StyledPrice = styled(IonNote)`
    color: var(--ion-color-primary);
`;

/******************************************************************************
* StyledItem
*
*/
const StyledItem = styled(IonItem)<{active: boolean}>`
    ${props => props.active === true ? "--background: var(--ion-color-primary-tint-extra);" : ""}
`;

/******************************************************************************
* StyledLabel
*
*/
const StyledLabel = styled(IonLabel)`
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    padding: 8px;
    padding-right: 0;
`;

/******************************************************************************
* StyledCounterAndNameContainer
*
*/
const StyledCounterAndNameContainer = styled.div`
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
`;

/******************************************************************************
* StyledCounter
*
*/
const StyledCounter = styled.p`
    padding: 0 24px;
`;

/******************************************************************************
* StyledButtonContainer
*
*/
const StyledButtonContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    background: ${ props => props.active ? "var(--ion-color-primary-tint)" : "inherite" };
    border-radius: 2px;

    &> div {
        padding-left: 25px;
        padding-right: 25px;
    }
`;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default ProductItem;
