/*
* RevenueCard.tsx
*
* Description: FlightDashboard / Revenue Card
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React from 'react';
import { IonCard, IonCardTitle } from '@ionic/react';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useSettingsLanguageStore } from '../../../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { renderFixedCurrency } from '../../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
type SumsType = {
    total: { "EUR": number, "USD": number },
    cc: { "EUR": number, "USD": number },
    cs: { "EUR": number, "USD": number },
    tip: { "EUR": number, "USD": number },
}
type type_Props = {
    sums: SumsType | undefined;
}
const RevenueCard: React.FC<type_Props> = (props) => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Utilities:
    ***************************************************************************/
    /***************************************************************************
    * getSum()
    *
    * Desc: Returns the sum in the parameter given currency.
    * Note:
    *
    */
    const getSum = (currency: "EUR" | "USD"): number => {

        let sumToReturn: number = 0,
            paymentType = "total" as "total";


        if(props.sums !== undefined){
            sumToReturn = props.sums[paymentType][currency];
        }


        return sumToReturn;

    }; // eo function getSum()



    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonCard className="ion-no-margin pv-2 ph-4">
            <IonCardTitle className="ion-margin-bottom">
                { lang.components.FlightDashboard.RevenueCard.Titles[gl_shared_language] }
            </IonCardTitle>

            <div>
                <div className="flex flex-col mt-0 mb-2">
                    <p className="sdr-text-extra-large sdr-text-color-primary sdr-font-weight-500 mb-1 mt-0">
                        { renderFixedCurrency(getSum("EUR"), "EUR") }
                    </p>

                    <div className="flex flex-row">
                        <p className="sdr-text-normal sdr-text-color-medium ion-no-margin mr-2">
                            { renderFixedCurrency(getSum("USD"), "USD") }
                        </p>
                    </div>
                </div>
            </div>
        </IonCard>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default RevenueCard;
