/*
* categories.tsx
*
* Description: Database Management with DEXIE for the Categories Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from 'dexie';
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { debugWriter } from '../tsx/utilities';
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBCategories } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Category } from '../types/products';
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #  /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type type_DBCategories_addCategories = (categoriesToAdd: Array<type_products_Category>) => Promise<void>;
type type_DBCategories_addCategory = (categoryToAdd: type_products_Category) => Promise<void>;
type type_DBCategories_getAllCategories = () => Promise<Array<type_Schema_DBCategories>>;
type type_DBCategories_getTableHeader = () => Promise<Array<IndexSpec>>;




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                              /$$     /$$
// #  | $$_____/                             | $$    |__/
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBCategories_addCategories()
*
* Desc: Adds categories to the database, which were send from the backend.
* Note:
*
*/
export const DBCategories_addCategories: type_DBCategories_addCategories = async (categoriesToAdd) => {

    debugWriter(" >>> Database: DBCategories_addCategories");
    for(let i = 0; i < categoriesToAdd.length; i++) {
        await DBCategories_addCategorie(categoriesToAdd[i]);
    }

}; // eo function DBCategories_addCategorie()


/*******************************************************************************
* ASYNC DBCategories_addCategorie()
*
* Desc: Adds categorie to the database, which were send from the backend.
* Note:
*
*/
export const DBCategories_addCategorie: type_DBCategories_addCategory = async (categorieToAdd) => {

    const db = await DB_openDatabase(),
          categoriesTable = db.table<type_Schema_DBCategories>("categories");


    debugWriter(" >>> Database: DBCategories_addCategorie");
    await categoriesTable
        .put({
            code: categorieToAdd.code,
            description: categorieToAdd.description,
            id: categorieToAdd.id,
            name: categorieToAdd.name
        }, "code")
        .catch(() => {
            // do nothing
        });

}; // eo function DBCategories_addCategorie()


/*******************************************************************************
* ASYNC DBCategories_getAllCategories()
*
* Desc: Returns the categories which were saved.
* Note:
*
*/
export const DBCategories_getAllCategories: type_DBCategories_getAllCategories = async () => {

    const db = await DB_openDatabase(),
          categoriesTable = db.table<type_Schema_DBCategories>("categories");


    debugWriter(">>> Database: DBCategories_getAllCategories");
    return await categoriesTable
        .toArray()
        .catch(() => {
            return [];
        });

}; // eo function DBCategories_getAllCategories()


/*******************************************************************************
* ASYNC DBCategories_deleteCategories()
*
* Desc: Delete categoires from local database.
* Note:
*
*/
export const DBCategories_deleteCategories = (categoryCodesToDelete: Array<string>) => {

    debugWriter(">>> Database: DBCategories_deleteCategories");
    categoryCodesToDelete.forEach((code) => {
        DBCategories_deleteCategory(code);
    });

}; // eo fucntion DBCategories_deleteCategories()


/*******************************************************************************
* ASYNC DBCategories_deleteCategory()
*
* Desc: Delete products from local database.
* Note:
*
*/
export const DBCategories_deleteCategory = async (categoryCodeToDelete: string) => {

    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBCategories>("categories");


    debugWriter(">>> Database: DBCategories_deleteCategory");
    await productsTable
        .delete(categoryCodeToDelete)
        .catch(() => {
            // do nothing here
        });

}; // eo fucntion DBCategories_deleteCategory()


/*******************************************************************************
* ASYNC DBCategories_keepDataConsistency()
*
* Desc: Delete all cateogires that are not send from Backend, but are saved
* Desc: locally.
* Note:
*
*/
export const DBCategories_keepDataConsistency = async (categoriesFromBackend: Array<type_products_Category>) => {

    let categoryCodesInLocalDatabase: Array<string> = [],
        categoryCodesInBackendDatabase: Array<string> = [],
        categoryCodesToDelete: Array<string> = [];


    debugWriter(">>> Database: DBCategories_keepDataConsistency");
    categoriesFromBackend.forEach(category => {
        categoryCodesInBackendDatabase.push(category.code);
    });

    (await DBCategories_getAllCategories()).forEach(category => {
        categoryCodesInLocalDatabase.push(category.code);
    });


    // Symmetric Difference (only if there are products in the local database)
    if(categoryCodesInLocalDatabase.length > 0) {
        categoryCodesToDelete =
            categoryCodesInBackendDatabase.filter(x => !categoryCodesInLocalDatabase.includes(x))
            .concat(categoryCodesInLocalDatabase.filter(x => !categoryCodesInBackendDatabase.includes(x)));
    }

    DBCategories_deleteCategories(categoryCodesToDelete);

}; // eo function DBCategories_keepDataConsistency()


/*******************************************************************************
* ASYNC DBCategories_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note:
*
*/
export const DBCategories_getTableHeader: type_DBCategories_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          categoriesTable = db.table<type_Schema_DBCategories>("categories");


    debugWriter(">>> Database: DBCategories_getTableHeader");
    let tableSchema = categoriesTable
        .schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBCategories_getTableHeader()
