/*
* products.tsx
*
* Description: Global Store for Products
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Public Modules ----------------------------------------------------------
import create from 'zustand';
import axios from "axios";
import { produce } from "immer";
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useNetworkStore } from '../shared/network';
import { useSettingsLanguageStore } from '../shared/settings';
import { useFlightSelectionStore } from '../flightselection/flights';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DBProducts_addProducts, DBProducts_getAllProducts, DBProducts_getBestsellerProducts, DBProducts_keepDataConsistency } from "../../databases/products";
import { DBCategories_addCategories, DBCategories_getAllCategories, DBCategories_keepDataConsistency } from "../../databases/categories";
import { DBVats_addVats, DBVats_getAllVats } from "../../databases/vats";

import { hideSpinner, showSpinner, showToastError, showToastWarning, exchangeCalculation, vatCalculation, setLocalStorage_LastOnlineUpdate } from '../../tsx/utilities';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_Response } from "../../types/response";
import { type_products_Category, type_products_GetProducts_Response, type_products_Product, type_products_Product_Extended, type_products_Vat, type_products_GetVats_Response } from '../../types/products';
import { type_Schema_DBCategories, type_Schema_DBProducts } from '../../types/databases';
import { type_flight_flight } from '../../types/flight';
import { DBLogs_addLog } from '../../databases/logs';
import lang from "../../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #   /$$$$$$$$
// #  |__  $$__/
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$
// #     | $$| $$  | $$| $$  | $$| $$_____/
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$
// #     |__/ \____  $$| $$____/  \_______/
// #          /$$  | $$| $$
// #         |  $$$$$$/| $$
// #          \______/ |__/
// #
//-----------------------------------------------------------------------------/
type StateType = {
    products: Array<type_products_Product_Extended>,
    categories: Array<type_products_Category>,
    bestseller: Array<type_products_Product_Extended>,
    vats: Array<type_products_Vat>,

    get_bestsellerProducts: () => Promise<Array<type_products_Product_Extended>>;
    get_ProductsOfCategory: (categoryID: number | undefined) => Array<type_products_Product_Extended>;
    get_PriceOfProduct: (productCode: string, currency: "EUR" | "USD") => number;

    mut_call_getProducts_API: (response: Array<type_products_GetProducts_Response>) => void,
    mut_call_getProducts_DB: (response: Array<type_Schema_DBProducts>) => void,
    mut_call_getCategories_DB: (response: Array<type_Schema_DBCategories>) => void,
    mut_call_getBestseller: (response: Array<type_products_Product_Extended>) => void,
    mut_call_getVats_API: (response: type_products_GetVats_Response) => void,
    mut_call_getVats_DB: (response: type_products_GetVats_Response) => void,

    mut_rebuild_Products_DB: (selectedFlight: type_flight_flight) => Promise<void>,

    actn_call_getProducts: () => void,
    actn_setSoldOutProduct: (productCode: string, isSoldOut: boolean) => void,
    actn_resetProductsStore: () => void,
    actn_call_getVats: () => void,
};



//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$           /$$   /$$     /$$           /$$
// #  |_  $$_/          |__/  | $$    |__/          | $$
// #    | $$   /$$$$$$$  /$$ /$$$$$$   /$$  /$$$$$$ | $$
// #    | $$  | $$__  $$| $$|_  $$_/  | $$ |____  $$| $$
// #    | $$  | $$  \ $$| $$  | $$    | $$  /$$$$$$$| $$
// #    | $$  | $$  | $$| $$  | $$ /$$| $$ /$$__  $$| $$
// #   /$$$$$$| $$  | $$| $$  |  $$$$/| $$|  $$$$$$$| $$
// #  |______/|__/  |__/|__/   \___/  |__/ \_______/|__/
// #    /$$$$$$   /$$                /$$
// #   /$$__  $$ | $$               | $$
// #  | $$  \__//$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
// #  |  $$$$$$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
// #   \____  $$ | $$     /$$$$$$$  | $$    | $$$$$$$$
// #   /$$  \ $$ | $$ /$$/$$__  $$  | $$ /$$| $$_____/
// #  |  $$$$$$/ |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
// #   \______/   \___/  \_______/   \___/   \_______/
// #
//-----------------------------------------------------------------------------/
const initalState = {
    products: [],
    categories: [],
    bestseller: [],
    vats:[],
};



//-----------------------------------------------------------------------------/
// #
// #    /$$$$$$   /$$
// #   /$$__  $$ | $$
// #  | $$  \__//$$$$$$    /$$$$$$   /$$$$$$  /$$$$$$
// #  |  $$$$$$|_  $$_/   /$$__  $$ /$$__  $$/$$__  $$
// #   \____  $$ | $$    | $$  \ $$| $$  \__/ $$$$$$$$
// #   /$$  \ $$ | $$ /$$| $$  | $$| $$     | $$_____/
// #  |  $$$$$$/ |  $$$$/|  $$$$$$/| $$     |  $$$$$$$
// #   \______/   \___/   \______/ |__/      \_______/
// #
//-----------------------------------------------------------------------------/
export const useProductsStore = create<StateType>((set, get) => ({
    ...initalState,



    /***************************************************************************
    * get_bestsellerProducts()
    *
    * Desc: Get bestseller every time directly from the local dexie db.
    * Note:
    *
    */
    get_bestsellerProducts: async () => {

        return await DBProducts_getBestsellerProducts();

    }, // eo function get_bestsellerProducts()


    /***************************************************************************
    * get_ProductsOfCategory()
    *
    * Desc:
    * Note:
    *
    */
    get_ProductsOfCategory: (categoryID) => {

        let productsOfCategory: Array<type_products_Product_Extended> = [];


        get().products.forEach(product => {
            if(product.idCategory === categoryID) {
                productsOfCategory.push(product);
            }
        });


        return productsOfCategory;

    }, // eo function get_ProductsOfCategory()


    /***************************************************************************
    * get_PriceOfProduct()
    *
    * Desc:
    * Note:
    *
    */
    get_PriceOfProduct: (productCode, currency) => {

        let productPrice = 0;


        get().products.forEach(product => {
            if(product.code === productCode) {
                if(currency === "EUR") {
                    productPrice = product.priceEUR;
                }
                if(currency === "USD") {
                    productPrice = product.priceUSD;
                }
            }
        });


        return productPrice;

    }, // eo function get_PriceOfProduct()


    //-------------------------------------------------------------------------/
    // #
    // #   /$$      /$$             /$$                /$$
    // #  | $$$    /$$$            | $$               | $$
    // #  | $$$$  /$$$$ /$$   /$$ /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$
    // #  | $$ $$/$$ $$| $$  | $$|_  $$_/  |____  $$|_  $$_/   /$$__  $$
    // #  | $$  $$$| $$| $$  | $$  | $$     /$$$$$$$  | $$    | $$$$$$$$
    // #  | $$\  $ | $$| $$  | $$  | $$ /$$/$$__  $$  | $$ /$$| $$_____/
    // #  | $$ \/  | $$|  $$$$$$/  |  $$$$/  $$$$$$$  |  $$$$/|  $$$$$$$
    // #  |__/     |__/ \______/    \___/  \_______/   \___/   \_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * mut_call_getProducts_API()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getProducts_API: async (response) => {

        let products: Array<type_products_Product> = [],
            productsWithUpdatesPrices: Array<type_products_Product_Extended> = [],
            categories: Array<type_products_Category> = [];


        response.forEach(category => {
            category.products.forEach(product => {
                products.push(product);
            });

            categories.push({
                code: category.code,
                description: category.description,
                id: category.id,
                name: category.name
            });
        });

        productsWithUpdatesPrices = _extendCategoriesWithExchangeRates(products);


        set({ products: productsWithUpdatesPrices });
        set({ categories: categories });

        await DBProducts_addProducts(productsWithUpdatesPrices);
        await DBCategories_addCategories(categories);

        DBProducts_keepDataConsistency(products);
        DBCategories_keepDataConsistency(categories);

    }, // eo function mut_call_getProducts_API()


    /***************************************************************************
    * mut_call_getProducts_DB()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getProducts_DB: (response) => {

        let productsWithUpdatesPrices: Array<type_products_Product_Extended> = [];


        productsWithUpdatesPrices = _extendCategoriesWithExchangeRates(response);

        set({ products: productsWithUpdatesPrices });

    }, // eo function mut_call_getProducts_DB()


    /***************************************************************************
    * mut_call_getCategories_DB()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getCategories_DB: (response) => {

        set({ categories: response });

    }, // eo function mut_call_getCategories_DB()


    /***************************************************************************
    * mut_call_getBestseller()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getBestseller: (response) => {

        set({ bestseller: response });

    }, // eo function mut_call_getBestseller()


    /***************************************************************************
    * mut_call_getVats_API()
    *
    * Desc:
    * Note:
    * Note: Save 'last online update' here. after all calls are done.
    *
    */
    mut_call_getVats_API: async (response) => {

        let vats: Array<type_products_Vat> = [];


        response.forEach(vat => {
            vats.push(vat);
        });


        set({ vats: vats });
        await DBVats_addVats(vats);

        // >>> save 'last online db update' -----
        await setLocalStorage_LastOnlineUpdate();

    }, // eo function mut_call_getVats_API()


    /***************************************************************************
    * mut_call_getVats_DB()
    *
    * Desc:
    * Note:
    *
    */
    mut_call_getVats_DB: async (response) => {

        let vats: Array<type_products_Vat> = [];


        response.forEach(vat => {
            vats.push(vat);
        });


        set({ vats: vats });

    }, // eo function mut_call_getVats_DB()



    /***************************************************************************
    * mut_rebuild_Products_DB()
    *
    * Desc: Rebuild Products State with right VAT (via Dep Airport). git:#7
    * Note:
    *
    */
    mut_rebuild_Products_DB: async (selectedFlight) => {

        let tmp_Products = null,
            tmp: type_products_Vat | undefined = undefined,
            vatToUse: null | number = null,
            depIata = selectedFlight.departureAirportInfo.iataCode,
            depIsGermany = get().vats.find(vat => vat.aptIataCode === depIata)?.isGermany,
            depIsEu = get().vats.find(vat => vat.aptIataCode === depIata)?.isEu,
            depHasNullVat = get().vats.find(vat => vat.aptIataCode === depIata)?.vat === null,
            desIata = selectedFlight.destinationAirportInfo.iataCode,
            desIsGermany = get().vats.find(vat => vat.aptIataCode === desIata)?.isGermany,
            desIsEu = get().vats.find(vat => vat.aptIataCode === desIata)?.isEu,
            desHasNullVat = get().vats.find(vat => vat.aptIataCode === desIata)?.vat === null,
            hasWarning = false,
            warningComment: null | string = null;


        // Case 1: dep apt in germany, des apt in EU
        if(depIsGermany === true && desIsEu === true) {
            tmp = get().vats.find(vat => vat.aptIataCode === depIata);
            vatToUse = tmp!.vat;
        }

        // Case 2: dep apt in germany, des apt outside EU
        if(depIsGermany === true && desIsEu === false) {
            vatToUse = 0;
        }

        // Case 3: dep apt outside EU, des apt in germany
        if(depIsEu === false && desIsGermany === true) {
            vatToUse = 0;
        }

        // Case 4: dep apt in EU without germany, des apt in germany
        if((depIsEu === true && depIsGermany === false) && desIsGermany === true) {
            tmp = get().vats.find(vat => vat.aptIataCode === depIata);
            vatToUse = tmp!.vat;
        }

        // Case 5: dep apt in EU without germany, des apt outside EU
        if((depIsEu === true && depIsGermany === false) && desIsEu === false) {
            vatToUse = 0;
        }

        // Case 6: dep apt in EU without germany, des apt in EU without germany
        if((depIsEu === true && depIsGermany === false) && (desIsEu === true && desIsGermany === false)) {
            tmp = get().vats.find(vat => vat.aptIataCode === depIata);
            vatToUse = tmp!.vat;
        }

        // Case 7: dep apt outside EU, des apt outside EU
        if(depIsEu === false && desIsEu === false) {
            vatToUse = 0;
        }

        // Case 8: dep apt outside EU, des apt in EU without germany
        if(depIsEu === false && (desIsEu === true && desIsGermany === false)) {
            vatToUse = 0;
        }

        // Case "9": dep apt not available in vats list
        if(depIsGermany === undefined || depIsEu === undefined) {
            vatToUse = 0;
            hasWarning = true;
            warningComment = `${depIata} not found.`;
        }

        // Case "10": des apt not available in vats list
        if(desIsGermany === undefined || desIsEu === undefined) {
            vatToUse = 0;
            hasWarning = true;
            warningComment = `${desIata} not found.`;
        }

        // Case "11": vat for airport NOT added to vats list
        if(vatToUse === null) {
            vatToUse = 0;
            hasWarning = true;
            warningComment = `VAT for ${depIata} not found.`;
        }

        // Case "12": apt dep vat is null?
        if(depHasNullVat === true) {
            hasWarning = true;
            warningComment = `VAT for ${depIata} not found.`;
        }

        // Case "13": apt des vat is null?
        if(desHasNullVat === true) {
            hasWarning = true;
            warningComment = `VAT for ${desIata} not found.`;
        }


        tmp_Products = produce(get().products, draft => {
            draft.forEach(product => {
                product.vatEur = vatCalculation(product.price1, 'EUR', vatToUse);
                product.vatUsd = vatCalculation(product.price1, 'USD', vatToUse);
                product.vat = vatToUse!; // #8
                product.hasWarning = hasWarning;
                product.warningComment = warningComment;
            });
        });


        set({ products: tmp_Products }); // update

        // update products DB #8
        await DBProducts_addProducts(tmp_Products);
        await DBProducts_keepDataConsistency(tmp_Products);

    }, // eo function mut_rebuild_Products_DB()



    //-------------------------------------------------------------------------/
    // #
    // #    /$$$$$$             /$$     /$$
    // #   /$$__  $$           | $$    |__/
    // #  | $$  \ $$  /$$$$$$$/$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
    // #  | $$$$$$$$ /$$_____/_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
    // #  | $$__  $$| $$       | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$
    // #  | $$  | $$| $$       | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
    // #  | $$  | $$|  $$$$$$$ |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
    // #  |__/  |__/ \_______/  \___/  |__/ \______/ |__/  |__/|_______/
    // #
    //-------------------------------------------------------------------------/
    /***************************************************************************
    * actn_call_getProducts()
    *
    * Desc: Read the products from the database or from the API.
    * Note: If there are no infos in the database table, call the API.
    * Note: Call the API only if there is a network connection.
    *
    */
    actn_call_getProducts: () => set(async () => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected,
              gl_shared_language = useSettingsLanguageStore.getState().language;

        let readDataFromDB = gl_networkConnected === false;


        showSpinner();

        if (readDataFromDB === false) {
            await axios.get('/shop/category')
                .then(async (response: type_Response.response) => {
                    if (response.headers["air41-status"] === "OK") {
                        DBLogs_addLog("Loading Products/Categories via API");
                        get().mut_call_getProducts_API(response.data);
                    } else {
                        readDataFromDB = true;
                        showToastWarning(
                            lang.components.Shared.Toasts.Warning[gl_shared_language],
                            lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                        );
                    }
                }).catch(() => {
                    readDataFromDB = true;
                    showToastWarning(
                        lang.components.Shared.Toasts.Warning[gl_shared_language],
                        lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                    );
                });
        }

        if (readDataFromDB === true) {
            let products = await DBProducts_getAllProducts();

            if (products.length > 0) {
                DBLogs_addLog("Loading Products/Categories via DB");
                get().mut_call_getProducts_DB(products);
            } else {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                );
            }

            //look up the database table for available categories
            let categories = await DBCategories_getAllCategories();
            if (categories.length > 0) {
                get().mut_call_getCategories_DB(categories);
            } else {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                );
            }
        }


        PubSub.publish("call_getProducts_Done");
        hideSpinner();

    }), // eo function actn_call_getProducts()


    /***************************************************************************
    * actn_resetFlightSelectionStore()
    *
    * Desc: Reset Store to inital state.
    *
    */
    actn_setSoldOutProduct: (productCode, isSoldOut) => {

        const updatedProducts = produce(get().products, draft => {
            draft.forEach(product => {
                if(product.code === productCode) {
                    product.soldOut = isSoldOut;
                }
            });
        });

        const updatedBestseller = produce(get().bestseller, draft => {
            draft.forEach(product => {
                if(product.code === productCode) {
                    product.soldOut = isSoldOut;
                }
            });
        });


        set({ products: updatedProducts });
        set({ bestseller: updatedBestseller });

    }, // eo function actn_setSoldOutProduct()


    /***************************************************************************
    * actn_resetFlightSelectionStore()
    *
    * Desc: Reset Store to inital state.
    * Note:
    *
    */
    actn_resetProductsStore: () => {

        set(initalState);

    }, // eo function actn_resetProductsStore()


    /***************************************************************************
    * actn_call_getVats()
    *
    * Desc: Get Vats for every Airports. git:#7
    * Note:
    *
    */
    actn_call_getVats: async () => {

        const gl_networkConnected = useNetworkStore.getState().networkConnected,
              gl_shared_language = useSettingsLanguageStore.getState().language;
        let readDataFromDB = gl_networkConnected === false;


        showSpinner();

        if (readDataFromDB === false) {
            await axios.get('/shop/getvats')
                .then(async (response: type_Response.response) => {
                    if (response.headers["air41-status"] === "OK") {
                        DBLogs_addLog("Loading vats via API");
                        get().mut_call_getVats_API(response.data);
                    } else {
                        readDataFromDB = true;
                        showToastWarning(
                            lang.components.Shared.Toasts.Warning[gl_shared_language],
                            lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                        );
                    }
                }).catch(() => {
                    readDataFromDB = true;
                    showToastWarning(
                        lang.components.Shared.Toasts.Warning[gl_shared_language],
                        lang.components.Shared.Toasts.ReadDataFromDatabase[gl_shared_language]
                    );
                });
        }

        if (readDataFromDB === true) {
            let vats = await DBVats_getAllVats();

            if (vats.length > 0) {
                DBLogs_addLog("Loading Vats via DB");
                get().mut_call_getVats_DB(vats);
            } else {
                showToastError(
                    lang.components.Shared.Toasts.Error[gl_shared_language],
                    lang.components.Shared.Toasts.UnexpectedError[gl_shared_language]
                );
            }
        }

        hideSpinner();

    }, // eo function actn_call_getVats()

}));



/***************************************************************************
* _extendCategoriesWithExchangeRates()
*
* Desc:
* Note:
*
*/
const _extendCategoriesWithExchangeRates = (products: Array<type_products_Product>) => {

    return produce(products, () => {

        let extendedProducts: Array<type_products_Product_Extended> = [];


        products.forEach(product => {
            extendedProducts.push(Object.assign(product, {
                priceEUR: exchangeCalculation(product.price1, 'EUR'),
                priceUSD: exchangeCalculation(product.price1, 'USD'),
                hasWarning: false,
                warningComment: null,
                vatEur: vatCalculation(product.price1, 'EUR', 0),// TODO: weg!
                vatUsd: vatCalculation(product.price1, 'USD', 0),// TODO: weg!
                vat: 0, // TODO: weg!
                soldOut: false, // TODO: nicht hier setzen
            }));
        });


        return extendedProducts;

    });

}; // eo function _extendCategoriesWithExchangeRates()
