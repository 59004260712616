/*
* UserDashboard.tsx
*
* Description: Flight Infos, Orders, Sales
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> React/Ionic Modules -----------------------------------------------------
import React, { useEffect, useRef } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { App } from '@capacitor/app';
// <<< React/Ionic Modules -----------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
import { useHistory } from 'react-router';
// <<< Public Modules ----------------------------------------------------------
// >>> Private Components ------------------------------------------------------
import SalesReports from "../components/UserDashboard/SalesReports/SalesReports";
import Flights from "../components/UserDashboard/Flights/Flights";
import AdminArea from "../components/UserDashboard/AdminArea/AdminArea";
// <<< Private Components ------------------------------------------------------
// >>> Global State ------------------------------------------------------------
import { useLoginStore } from '../stores/login/login';
import { useProductsStore } from '../stores/products/products';
import { useCurrencyStore } from '../stores/currency/currency';
import { useSettingsLanguageStore } from '../stores/shared/settings';
// <<< Global State ------------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import lang from "../tsx/language.json";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$                                 /$$       /$$$$$$$$/$$$$$$
// #  | $$__  $$                               | $$      | $$_____/$$__  $$
// #  | $$  \ $$  /$$$$$$   /$$$$$$   /$$$$$$$/$$$$$$    | $$    | $$  \__/
// #  | $$$$$$$/ /$$__  $$ |____  $$ /$$_____/_  $$_/    | $$$$$ | $$
// #  | $$__  $$| $$$$$$$$  /$$$$$$$| $$       | $$      | $$__/ | $$
// #  | $$  \ $$| $$_____/ /$$__  $$| $$       | $$ /$$  | $$    | $$    $$
// #  | $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$$ |  $$$$/$$| $$    |  $$$$$$/
// #  |__/  |__/ \_______/ \_______/ \_______/  \___/|__/|__/     \______/
// #
//-----------------------------------------------------------------------------/
const UserDashboard: React.FC = () => {
    // >>> --------------------------------------------------------------------- Local State
    // <<< --------------------------------------------------------------------- Local State

    // >>> --------------------------------------------------------------------- Global State
    const gl_login_username = useLoginStore((state) => state.username);
    const gl_login_isAdmin = useLoginStore(state => state.isAdmin);
    const gl_shared_language = useSettingsLanguageStore(state => state.language);
    // <<< --------------------------------------------------------------------- Global State
    // >>> --------------------------------------------------------------------- Global Actions
    const actn_call_products_getProducts = useProductsStore(state => state.actn_call_getProducts);
    const actn_call_currency_getExchangeRates = useCurrencyStore(state => state.actn_call_getExchangeRates);
    const actn_call_getVats = useProductsStore(state => state.actn_call_getVats);
    // <<< --------------------------------------------------------------------- Global Actions

    // >>> --------------------------------------------------------------------- Global Functions
    const history = useHistory();
    // <<< --------------------------------------------------------------------- Global Functions

    // >>> --------------------------------------------------------------------- References
    const userDashboardRef = useRef<HTMLElement>(null);
    // <<< --------------------------------------------------------------------- References



    /***************************************************************************
    * Lifecycle:
    ***************************************************************************/
    /***************************************************************************
    * useIonViewDidEnter()
    *
    * Desc:
    * Note: "Hack": Categories-Calls needs to be done after Exchange-Call
    * Note: because we need the new exchange rates for recalculating the
    * Note: product prices.
    *
    */
    useIonViewDidEnter(async () => {

        App.addListener('backButton', onEmit_backbutton);
        actn_call_currency_getExchangeRates();

    }); // eo function useIonViewDidEnter()


    /***************************************************************************
    * useEffect()
    *
    * Desc: On every Render-Cycle the
    * Desc:    old listener will be destroyed
    * Desc:    and a new one will be set.
    * Note: Otherwise we would have an "outdated" state in the onEmit_...
    * Note: Function.
    *
    */
    useEffect(() => {

        PubSub.subscribe('call_getExchangeRates_Done', onEmit_getExchangeRates_Done);
        PubSub.subscribe('call_getAvailableFlights_Done', onEmit_getAvailableFlights_Done);

        return () => {
            PubSub.unsubscribe('call_getExchangeRates_Done');
            PubSub.unsubscribe('call_getAvailableFlights_Done');

        }

    }); // eo function useEffect()


    /***************************************************************************
    * useIonViewDidLeave()
    *
    * Desc:
    *
    */
    useIonViewDidLeave(() => {

        App.removeAllListeners();

    }); // eo function useIonViewDidLeave()


    /***************************************************************************
    * onEmit_backbutton()
    *
    * Desc:
    *
    */
    const onEmit_backbutton = () => {

        // do nothing here :)

    }; // eo function onEmit_backbutton()


    /***************************************************************************
    * onEmit_getExchangeRates_Done()
    *
    * Desc:
    *
    */
    const onEmit_getExchangeRates_Done = () => {

        actn_call_products_getProducts();

    }; // eo function onEmit_getExchangeRates_Done()


    /***************************************************************************
    * onClick_goToSettings()
    *
    * Desc:
    *
    */
    const onClick_goToSettings = () => {

        history.push('/settings');

    }; // eo function onClick_goToSettings()


    /***************************************************************************
    * onClick_goToFAQ()
    *
    * Desc:
    *
    */
    const onClick_goToFAQ = () => {

        history.push('/faq');

    }; // eo function onClick_goToFAQ()


    /***************************************************************************
    * onEmit_getAvailableFlights_Done()
    *
    * Desc:
    *
    */
    const onEmit_getAvailableFlights_Done = () => {

        actn_call_getVats();

    }; // eo function onEmit_getAvailableFlights_Done()




    /***************************************************************************
    * Components JSX Part.
    *
    */
    return (
        <IonPage ref={userDashboardRef}>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton href="/login">
                            { lang.global.Labels.Logout[gl_shared_language] }
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={onClick_goToSettings}>
                            { gl_shared_language === "de-DE" ? "DE" : "EN" }
                        </IonButton>
                        <IonButton onClick={onClick_goToFAQ}>
                            ?
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{gl_login_username}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense" className="ion-no-border">
                    <IonToolbar>
                        <IonTitle size="large">{gl_login_username}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <SalesReports
                    presentingElementRef={userDashboardRef} />

                <Flights
                    pageRef={userDashboardRef} />

                { gl_login_isAdmin === true ?
                    <AdminArea
                        presentingElementRef={userDashboardRef} />
                : null }

            </IonContent>
        </IonPage>
    );
};




//-----------------------------------------------------------------------------/
// #
// #   /$$$$$$$$                                          /$$
// #  | $$_____/                                         | $$
// #  | $$      /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
// #  | $$$$$  |  $$ /$$/ /$$__  $$ /$$__  $$ /$$__  $$|_  $$_/
// #  | $$__/   \  $$$$/ | $$  \ $$| $$  \ $$| $$  \__/  | $$
// #  | $$       >$$  $$ | $$  | $$| $$  | $$| $$        | $$ /$$
// #  | $$$$$$$$/$$/\  $$| $$$$$$$/|  $$$$$$/| $$        |  $$$$/
// #  |________/__/  \__/| $$____/  \______/ |__/         \___/
// #                     | $$
// #                     | $$
// #                     |__/
// #
//-----------------------------------------------------------------------------/
export default UserDashboard;
