/*
* products.tsx
*
* Description: Database Management with DEXIE for the Products Database.
*
* This file is part of: "http://apps.sundair.com/", "SunCater"
* Copyright 2020,2021,2022 Sundair GmbH
* Contact:  http://www.sundair.com/
* @author Christian Arp <christian.arp@sundair.com>
* @author Michael Bröker <michael.broeker@sundair.com>
*
*/
// >>> Dexie -------------------------------------------------------------------
import { IndexSpec } from "dexie";
// <<< Dexie -------------------------------------------------------------------
// >>> Public Modules ----------------------------------------------------------
// <<< Public Modules ----------------------------------------------------------
// >>> Utilities ---------------------------------------------------------------
import { DB_openDatabase } from "./utilities";
import { type_Schema_DBProducts } from '../types/databases';
// <<< Utilities ---------------------------------------------------------------
// >>> Resources ---------------------------------------------------------------
import { type_products_Product, type_products_Product_Extended } from '../types/products';
import { debugWriter, showToastWarning } from "../tsx/utilities";
import { OrderProductType } from "../tsx/types";
import lang from "../tsx/language.json";
import { useSettingsLanguageStore } from "../stores/shared/settings";
// <<< Resources ---------------------------------------------------------------




//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                                     
// #  |__  $$__/                                     
// #     | $$ /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$
// #     | $$| $$  | $$ /$$__  $$ /$$__  $$ /$$_____/
// #     | $$| $$  | $$| $$  \ $$| $$$$$$$$|  $$$$$$ 
// #     | $$| $$  | $$| $$  | $$| $$_____/ \____  $$
// #     | $$|  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$$$$$$/
// #     |__/ \____  $$| $$____/  \_______/|_______/ 
// #          /$$  | $$| $$                          
// #         |  $$$$$$/| $$                          
// #          \______/ |__/ 
// #
//-----------------------------------------------------------------------------/
type type_DBProducts_addProducts = (productsToAdd: Array<type_products_Product_Extended>) => Promise<void>;
type type_DBProducts_addProduct = (productToAdd: type_products_Product_Extended) => Promise<void>;
type type_DBProducts_getAllProducts = () => Promise<Array<type_Schema_DBProducts>>;
type type_DBProducts_getBestsellerProducts = () => Promise<Array<type_products_Product_Extended>>;
type type_DBProducts_increaseProductBestsellerCounter = (productToUpdate: OrderProductType) => void;
type type_DBProducts_getTableHeader = () => Promise<Array<IndexSpec>>;



//-----------------------------------------------------------------------------/ 
// #
// #   /$$$$$$$$                              /$$     /$$                              
// #  | $$_____/                             | $$    |__/                              
// #  | $$    /$$   /$$ /$$$$$$$   /$$$$$$$ /$$$$$$   /$$  /$$$$$$  /$$$$$$$   /$$$$$$$
// #  | $$$$$| $$  | $$| $$__  $$ /$$_____/|_  $$_/  | $$ /$$__  $$| $$__  $$ /$$_____/
// #  | $$__/| $$  | $$| $$  \ $$| $$        | $$    | $$| $$  \ $$| $$  \ $$|  $$$$$$ 
// #  | $$   | $$  | $$| $$  | $$| $$        | $$ /$$| $$| $$  | $$| $$  | $$ \____  $$
// #  | $$   |  $$$$$$/| $$  | $$|  $$$$$$$  |  $$$$/| $$|  $$$$$$/| $$  | $$ /$$$$$$$/
// #  |__/    \______/ |__/  |__/ \_______/   \___/  |__/ \______/ |__/  |__/|_______/ 
// #
//-----------------------------------------------------------------------------/
/*******************************************************************************
* ASYNC DBProducts_addProducts()
*
* Desc: Adds products to the database, which were send from the backend.
* Note: Param is a Category-Array, each category includes the products of this 
* Note: category.
*
*/
export const DBProducts_addProducts: type_DBProducts_addProducts = async (productsToAdd) => {

    debugWriter(">>> Database: DBProducts_addProducts");
    for(let i = 0; i < productsToAdd.length; i++) {
        await DBProducts_addProduct(productsToAdd[i]);
    }

}; // eo fucntion DBProducts_addProducts()


/*******************************************************************************
* ASYNC DBProducts_addProduct()
*
* Desc: Adds product to the database, which were send from the backend.
* Note: 
*
*/
export const DBProducts_addProduct: type_DBProducts_addProduct = async (productToAdd) => {

    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBProducts>("products");


    // INSERT OR UPDATE
    // 1. Check if product already exists in DB
    let prod = await productsTable.get({ code: productToAdd.code });

    
    debugWriter(">>> Database: DBProducts_addProduct");
    // 2. Insert if product not found in DB
    if(!prod) {
        await productsTable
            .put({
                code: productToAdd.code,
                description: productToAdd.description,
                extras: productToAdd.extras,
                id: productToAdd.id,
                idCategory: productToAdd.idCategory,
                name: productToAdd.name,
                price1: productToAdd.price1,
                price2: productToAdd.price2,
                priceEur: productToAdd.priceEUR,
                priceUsd: productToAdd.priceUSD,
                vatEur: productToAdd.vatEur,
                vatUsd: productToAdd.vatUsd,
                vat: productToAdd.vat,
                hasWarning: productToAdd.hasWarning,
                warningComment: productToAdd.warningComment,
                bestsellerCounter: 0
            }, "code")
            .catch(() => {
                // do nothing here
            });
    }

    //2. Update if product found in DB
    if(prod) {
        await productsTable
            .put({
                code: productToAdd.code,
                description: productToAdd.description,
                extras: productToAdd.extras,
                id: productToAdd.id,
                idCategory: productToAdd.idCategory,
                name: productToAdd.name,
                price1: productToAdd.price1,
                price2: productToAdd.price2,
                priceEur: productToAdd.priceEUR,
                priceUsd: productToAdd.priceUSD,
                vatEur: productToAdd.vatEur,
                vatUsd: productToAdd.vatUsd,
                vat: productToAdd.vat,
                hasWarning: productToAdd.hasWarning,
                warningComment: productToAdd.warningComment,
                bestsellerCounter: prod.bestsellerCounter
            }, "code")
            .catch(() => {
                // do nothing here
            });
    }
    
}; // eo function DBProducts_addProduct()


/*******************************************************************************
* ASYNC DBProducts_increaseProductBestsellerCounter()
*
* Desc: Adds product to the database, which were send from the backend.
* Note: 
*
* @param {Object} productToAdd (product, add to database)
*/
export const DBProducts_increaseProductBestsellerCounter: type_DBProducts_increaseProductBestsellerCounter = async (productToUpdate) => {
    
    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBProducts>("products");

    let prod = await productsTable.get({ code: productToUpdate.code });


    debugWriter(">>> Database: DBProducts_increaseProductBestsellerCounter");
    if(prod) {
        await productsTable
            .update(productToUpdate.code, {
                bestsellerCounter: prod.bestsellerCounter + productToUpdate.counter!
            })
            .catch(() => {
                // do nothing here
            });
    }
    
}; // eo function DBProducts_addProduct()


/*******************************************************************************
* ASYNC DBProducts_getAllProducts()
*
* Desc: Returns the products which were saved.
* Note: 
*
*/
export const DBProducts_getAllProducts: type_DBProducts_getAllProducts = async () => {

    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBProducts>("products");

    
    debugWriter(">>> Database: DBProducts_getAllProducts");
    return await productsTable
        .toArray()
        .catch(() => {
            return [];
        });

}; // eo function DBProducts_getAllProducts()


/*******************************************************************************
* ASYNC DBProducts_getBestsellerProducts()
*
* Desc: Returns the bestseller products which were saved.
* Note: Bestseller are products with the largest bestsellerCounter.
*
*/
export const DBProducts_getBestsellerProducts: type_DBProducts_getBestsellerProducts = async () => {

    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBProducts>("products");
    
    let productsToReturn: Array<type_products_Product_Extended> = [],
        savedProducts = await productsTable
            .orderBy("bestsellerCounter")
            .reverse()
            .limit(10)
            .toArray()
            .catch(() => {
                return [];
            });


    debugWriter(">>> Database: DBProducts_getBestsellerProducts");
    savedProducts.forEach((product) => {

        productsToReturn.push(Object.assign(product, {
            priceEUR: product.priceEur,
            priceUSD: product.priceUsd,
            soldOut: false
        }));

    });


    return productsToReturn;

}; // eo function DBProducts_getBestsellerProducts()


/*******************************************************************************
* ASYNC DBProducts_deleteProducts()
*
* Desc: Delete products from local database.
* Note: 
*
*/
export const DBProducts_deleteProducts = (productCodesToDelete: Array<string>) => {

    debugWriter(">>> Database: DBProducts_deleteProducts");
    productCodesToDelete.forEach((code) => {
        DBProducts_deleteProduct(code);
    });

}; // eo fucntion DBProducts_deleteProducts()


/*******************************************************************************
* ASYNC DBProducts_deleteProducts()
*
* Desc: Delete products from local database.
* Note: 
*
*/
export const DBProducts_deleteProduct = async (productCodeToDelete: string) => {

    const db = await DB_openDatabase(),
          productsTable = db.table<type_Schema_DBProducts>("products"),
          gl_shared_language = useSettingsLanguageStore.getState().language;


    debugWriter(">>> Database: DBProducts_deleteProduct");
    await productsTable
        .delete(productCodeToDelete)
        .catch(() => {
            showToastWarning(
                lang.components.Shared.Toasts.Warning[gl_shared_language], 
                lang.components.Shared.Toasts.ErrorOnSavingNewProducts[gl_shared_language]
            );
        });

}; // eo fucntion DBProducts_deleteProducts()


/*******************************************************************************
* ASYNC DBProducts_keepDataConsistency()
*
* Desc: Delete all products that are not send from Backend, but are saved
* Desc: locally. 
* Note: 
*
*/
export const DBProducts_keepDataConsistency = async (productsFromBackend: Array<type_products_Product>) => {

    let productCodesInLocalDatabase: Array<string> = [],
        productCodesInBackendDatabase: Array<string> = [],
        productCodesToDelete: Array<string> = [];


    debugWriter(">>> Database: DBProducts_keepDataConsistency");
    productsFromBackend.forEach(product => {
        productCodesInBackendDatabase.push(product.code);
    });

    (await DBProducts_getAllProducts()).forEach(product => {
        productCodesInLocalDatabase.push(product.code);
    });


    // Symmetric Difference (only if there are products in the local database)
    if(productCodesInLocalDatabase.length > 0) {
        productCodesToDelete = 
            productCodesInBackendDatabase.filter(x => !productCodesInLocalDatabase.includes(x))
            .concat(productCodesInLocalDatabase.filter(x => !productCodesInBackendDatabase.includes(x)));
    }
    
    
    DBProducts_deleteProducts(productCodesToDelete);

}; // eo function DBProducts_keepDataConsistency()


/*******************************************************************************
* ASYNC DBProducts_getTableHeader()
*
* Desc: Returns the tables schema as array.
* Note: 
*
*/
export const DBProducts_getTableHeader: type_DBProducts_getTableHeader = async () => {

    const db = await DB_openDatabase(),
          productsTable = db.table("products");


    debugWriter(">>> Database: DBProducts_getTableHeader");
    let tableSchema = productsTable.schema;


    return tableSchema.indexes.concat(tableSchema.primKey ?? []);

}; // eo function DBProducts_getTableHeader()